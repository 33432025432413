import { Box, Button, MenuItem, Popover, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { read, utils } from "xlsx";
import { initialData } from '../ProductForm/Schema/initial-data';
import { v4 as uuidv4 } from 'uuid';
// import { converterTest } from '../../data/converterTest';
import { converterTest } from '../../data/converterBonV1';
import { categoryConversion, roomConversion, usageConversion } from '../../data/conversions';
import { variantData } from '../ProductForm/Schema/variant-data';
const merge = require('deepmerge')

const sheets = [
  "All filter variables",
  "No category-specific variables",
  "HVAC & Plumbing"
]

const setProperty = (obj, path, value) => {
  const [head, ...rest] = path.split('.')

  return {
      ...obj,
      [head]: rest.length
          ? setProperty(obj[head], rest.join('.'), value)
          : value
  }
}

const toNumber = (str) => {
  if (typeof(str) === "string") {
    const result = str.replace(/[^\d.]/g, '');

    return parseFloat(result);
  } else {
    return str;
  }
}

// const getSheet = (sheet) => {
//   const ws = wb.Sheets[sheet];

//   try {
//     ws['!ref'] = "A10:" + getRefMax(ws['!ref']);
//   } catch (exceptionVar) {
//     console.log("sheet not found")
//   }

//   return ws;
// }

function removeEmpty(object) {
  let newObject = Object.assign({}, object)
  for (const key in newObject) {
    if (newObject[key]?.value === "" && newObject[key]?.values.length === 0 && newObject[key]?.min === "" && newObject[key]?.max === "") {
      delete newObject[key]
    }
  }
  return newObject
}

function valueToValues(object) {
  let newObject = Object.assign({}, object)
  for (const key in newObject) {
      if (newObject[key].value) {
        const values = newObject[key].values;
        const newValues = values.concat([newObject[key].value])
        newObject[key] = { ...newObject[key], values: newValues, multipleValues: true}
      }
      newObject[key] = { ...newObject[key], value: "" }
  }
  return newObject
}

function getVariantData(object, format) {
  const formatFile = format()
  let newObject = Object.assign({}, object)
  let output = {}
  for (const key in newObject) {
    if (formatFile.hasOwnProperty(key)) {
      output[key] = newObject[key]
    }
  }
  return output
}

const getMin = (varA, varB) => {
  if (isNumber(varA) && isNumber(varB)) {
    return Math.min(varA, varB)
  } else {
    return varA
  }
}

const getMax = (varA, varB) => {
  if (isNumber(varA) && isNumber(varB)) {
    return Math.max(varA, varB)
  } else {
    return varA
  }

}

const mergeArrays = (a, b, predicate = (a, b) => a === b) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}

function isNumber(value) {
  return typeof value === 'number';
}

function mergeDetails(source, destination) {
  let newDetails = {}

  for (const key in source) {
    if (destination[key]) {
      let newObject = Object.assign({}, source[key])
      newObject["min"] = getMin(source[key]["min"], destination[key]["min"])
      newObject["max"] = getMax(source[key]["max"], destination[key]["max"])
      const values = mergeArrays(source[key].values, destination[key].values)
      newObject["values"] = values.sort(function(a, b){return a - b})
      newDetails[key] = newObject
    } else {
      let newObject = Object.assign({}, source[key])
      newDetails[key] = newObject
    }
  }

  for (const key in destination) {
    if (!source[key]) {
      let newObject = Object.assign({}, destination[key])
      newDetails[key] = newObject
    }
  }

  return newDetails
}

function mergeCustom(source, destination) {
  let newCustomList = []

  source.forEach((sourceObject) => {
    console.log(sourceObject)
    const found = destination.some(el => el.name === sourceObject.name);
    if (found) {
      const destinationObject = destination.find(x => x.name === sourceObject.name);
      let newObject = Object.assign({}, sourceObject)
      newObject["min"] = getMin(sourceObject["min"], destinationObject["min"])
      newObject["max"] = getMax(sourceObject["max"], destinationObject["max"])
      const values = mergeArrays(sourceObject.values, destinationObject.values)
      newObject["values"] = values.sort(function(a, b){return a - b})
      newCustomList.push(newObject)
    } else {
      let newObject = Object.assign({}, sourceObject)
      newCustomList.push(newObject)
    }
  })

  destination.forEach((destinationObject) => {
    const found = source.some(el => el.name === destinationObject.name);
    if (!found) {
      let newObject = Object.assign({}, destinationObject)
      newCustomList.push(newObject)
    }
  })

  return newCustomList
}


export const ImportFile = forwardRef((props, ref) => {
  const { anchorEl, onClose, open, setArray, file, setFile, productList, setProductList, setHasProducts, ...other } = props;
 // const [ productList, setProductList ] = useState([]);
 const selectedBrand = useSelector((store) => store.user.selectedBrand);


  useImperativeHandle(ref, () => ({
    submit() {
      handleOnSubmit();
    }
  }));

  const fileReader = new FileReader();


  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };


  const getRefMax = (range) => {
    return range.split(':')[1];
  }

  // function newObject(format) {
  //   return format;
  // }

  // RUN PARAMETER CONVERSIONS

  function runConversions (variable, to, conversion, format) {
    //let convertedVariable = variable;
    for (let i = 0; i < conversion.length; i++) {
      const conv = conversion[i];
      // convert parameter type
      // if (conv?.type === "unit") {

      // }

      // convert units
      if (conv?.type === "unit") {
        // multiply, divide or ??

        if (conv?.operation === "multiply") {
          variable = variable * conv?.calc;
          format = setProperty(format, to, variable);
        } else if (conv?.operation === "divide") {
          variable = variable / conv?.calc;
          format = setProperty(format, to, variable);
        }

        if (conv?.from !== conv?.to) {
          const unitVariable =  to.replace(".value", ".unit");
          format = setProperty(format, unitVariable, conv?.to)
        }

      } else if (conv?.type === "conversionString") {
          // convert range
          if (typeof(variable) === "string" && variable?.includes("-")) {
            const array = variable.split('-').map(function (value) {
              return value.trim();
            });
            const minTo = to.replace(".value", ".min");
            const maxTo = to.replace(".value", ".max");
            const rangeTo = to.replace(".value", ".range");
            const minVariable = array[0];
            const maxVariable = array[1];
            format = setProperty(format, to, null);
            format = setProperty(format, rangeTo, true);
            format = setProperty(format, minTo, minVariable);
            format = setProperty(format, maxTo, maxVariable);

          // convert multiple values
          } else if (typeof(variable) === "string" && variable?.includes(";")) {
            const array = variable.split(';').map(function (value) {
              return value.trim();
            });
            const valuesTo = to.replace(".value", ".values");
            const multipleTo = to.replace(".value", ".multipleValues");
            format = setProperty(format, valuesTo, array);
            format = setProperty(format, multipleTo, true);
            format = setProperty(format, to, null);
          } else {
            format = setProperty(format, to, variable);
          }

      } else if (conv?.type === "conversionNumeric") {

        // convert range
        if (typeof(variable) === "string" && variable?.includes("-")) {
          const array = variable.split('-').map(function (value) {
            return value.trim();
          });
          const minTo = to.replace(".value", ".min");
          const maxTo = to.replace(".value", ".max");
          const rangeTo = to.replace(".value", ".range");
          const minVariable = toNumber(array[0]);
          const maxVariable = toNumber(array[1]);
          format = setProperty(format, to, null);
          format = setProperty(format, rangeTo, true);
          format = setProperty(format, minTo, minVariable);
          format = setProperty(format, maxTo, maxVariable);

        // convert multiple values
        } else if (typeof(variable) === "string" && variable?.includes(";")) {
          const array = variable.split(';').map(function (value) {
            value = toNumber(value);
            return value;
          });
          const valuesTo = to.replace(".value", ".values");
          const multipleTo = to.replace(".value", ".multipleValues");
          format = setProperty(format, valuesTo, array);
          format = setProperty(format, multipleTo, true);
          format = setProperty(format, to, null);
        } else {
          format = setProperty(format, to, toNumber(variable));
        }
        // convert to numeric values

      } else if (conv?.type === "delimStringToArray") {
        if (to === "usage") {
          console.log(variable);
          console.log(to);
        }
        variable = variable.split(';').map(function (value) {
          return value.trim();
        });
      } else if (conv?.type === "categoryConversion") {
        let newValue = "";
        try {
          categoryConversion.filter(obj => {
            if (obj.full === variable) {
              newValue = obj.slug
            }
          })
        }
        catch(err) {
          console.log(err)
        }
        format = setProperty(format, to, newValue);
      } else if (conv?.type === "roomConversion") {
        let newArray = [];
        try {
          if (Array.isArray(variable)) {
            for (let i = 0; i < variable.length; i++) {
              const object = variable[i];
              roomConversion.filter(obj => {
                if (obj.full === object) {
                  newArray.push(obj.slug)
                }
              })
            }
          }
        }
        catch(err) {
          console.log(err)
        }

        format = setProperty(format, to, newArray);

      } else if (conv?.type === "usageConversion") {
        let newArray = [];
        try {
          if (Array.isArray(variable)) {
            for (let i = 0; i < variable.length; i++) {
              const object = variable[i];
              usageConversion.filter(obj => {
                if (obj.full === object) {
                  newArray.push(obj.slug)
                }
              })
            }
          }
        }
        catch(err) {
          console.log(err)
        }

        format = setProperty(format, to, newArray);

      } else if (conv?.type === "separateFilenames") {
        variable = variable.split(';').map(function (value) {
          return value.trim();
        });
        format = setProperty(format, to, variable);
      }
    }

    return format;
  }

  // CONVERT FORMAT

  const convertFormat = (data, converter, format, variantFormat, determineVariableProductsBasedOn = "", identifier = "") => {
    let output = [];
    let variableProductIds = [];

    if (data) {
      for (let i = 0; i < data.length; i++) {
        const object = data[i];
        let variable_product = false;

        // If object["Variable"] exists, is true, set variable_product = true, else variable_product = false
        if (object.hasOwnProperty(determineVariableProductsBasedOn)) {
          if (object[determineVariableProductsBasedOn]) {
            variable_product = true;
          }
        }

        // if variable_product = true, and is not already found on variableProductsIds,
        // append id to variableProductsIds
        if (variable_product && !variableProductIds.includes(object[identifier])) {
          variableProductIds.push(object[identifier])
        }

        // // if variable_product = true, and id is found on variableProductsIds,
        // // append product details and add new data as variant to object["variants"]


        // // else assign normally

        // Object.assign(object,
        //   {
        //     id: uuidv4(),
        //     productId: uuidv4(),
        //     sku: uuidv4(),
        //     brandId: selectedBrand?.brandId,
        //     brandName: selectedBrand?.brandName,
        //     status: "pending"
        //   })
        const objectKeys = Object.keys(object);
        let convertedObject = format;
        let customVariables = [];
        let etimVariables = [];

        for (let j = 0; j < objectKeys.length; j++) {
          const index = converter.findIndex(e => e.from === objectKeys[j]);
          if (index > -1) {
            const newVariableName = converter[index].to;
            const newVariable = object[objectKeys[j]];
            if (converter[index].conversion) {
              convertedObject = runConversions(newVariable, newVariableName, converter[index]?.conversionOperations, convertedObject)
            } else {
              convertedObject = setProperty(convertedObject, newVariableName, object[objectKeys[j]])
            }
          } else if (objectKeys[j].startsWith("EF")) {
            const newVar = {
              name: objectKeys[j],
              value: object[objectKeys[j]]
            }
            etimVariables.push(newVar)
          } else {
            const newVar = {
              name: objectKeys[j],
              value: '',
              values: [object[objectKeys[j]]],
              min: '',
              max: '',
              unit: '',
              standard: '',
              range: false
            }
            customVariables.push(newVar)
            // convertedObject = setProperty(convertedObject, "customDetails", )
          }
        }

        // convert details
        const details = Object.assign({}, convertedObject?.details)
        const trimmedDetails = Object.assign({}, removeEmpty(details))
        const newDetails = Object.assign({}, valueToValues(trimmedDetails))
        convertedObject = {...convertedObject, details: newDetails}


        // SWITCH TO BACK TO NAME?
        if (convertedObject?.manufacturerProductId) {
          convertedObject = setProperty(convertedObject, "etimFeatures", etimVariables);
          convertedObject = setProperty(convertedObject, "customDetails", customVariables);

          // if variable_product is true and manufacturerProductId is found on output,
          // update existing row by pushing convertedObject to variants array


          if (variable_product) {
            const index = output.findIndex(object => {
              return object.manufacturerProductId === convertedObject?.manufacturerProductId;
            });

            if (index >= 0) {
              const existingVariants = output[index]?.variants;
              const newVariant = getVariantData(Object.assign({}, convertedObject), variantFormat)
              const arr = existingVariants.concat([newVariant]);
              output[index]["variants"] = arr;

              // merge details
              const existingDetails = output[index]?.details;
              output[index].details = mergeDetails(existingDetails, newVariant.details)
              // merge custom
              const existingCustomDetails = output[index]?.customDetails;
              output[index].customDetails = mergeCustom(existingCustomDetails, newVariant.customDetails)

            } else {
              const newVariant = getVariantData(Object.assign({}, convertedObject), variantFormat)

              Object.assign(convertedObject,
                {
                  id: uuidv4(),
                  productId: uuidv4(),
                  sku: uuidv4(),
                  brandId: selectedBrand?.brandId,
                  brandName: selectedBrand?.brandName,
                  status: "pending",
                  variants: [newVariant]
                })

              output.push(convertedObject);
            }
          } else {
            Object.assign(convertedObject,
              {
                id: uuidv4(),
                productId: uuidv4(),
                sku: uuidv4(),
                brandId: selectedBrand?.brandId,
                brandName: selectedBrand?.brandName,
                status: "pending"
              })

            output.push(convertedObject);
          }
          // else push as a new file
        }
      }


      console.log(output);
      return output;
    } else {
      return [];
    }
  }


  const handleOnSubmit = () => {
    //e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  // ADD FILE

  function handleFile(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      const wb = read(e.target.result);
      //console.log(wb);
      let output = []

      for (let i = 0; i < sheets.length; i++) {
        const sheet = sheets[i]
        //const ws = getSheet(sheet);
        // const ws = wb.Sheets[sheets[i]];
        // //ws['!ref'] = "A9:" + getRefMax(ws['!ref']);

        // ws['!ref'] = "A10:" + getRefMax(ws['!ref']);
        const ws = wb.Sheets[sheet];

        try {
          ws['!ref'] = "A10:" + getRefMax(ws['!ref']);
        } catch (exceptionVar) {
          console.log(sheet + " not found")
        }
        const json = utils.sheet_to_json(ws);
        const newArray = [...output, ...json];
        output = newArray;
        //setProductList(...productList, ...json);
        //setProductList(output);
      }
      /* DO SOMETHING WITH workbook HERE */

      const converted = convertFormat(output, converterTest, initialData, variantData, "Variable", "Product ID *");

      if (converted.length > 0) {
        setProductList(converted);
        setHasProducts(true);
      }
    };
    reader.readAsArrayBuffer(file);
  }


  return (
    <Box sx={{my: 2}}>
      <form>
        {/* <input
          type="file"
          id="csvFileInput"
          accept={[".csv", ".xlsx"]}
          onChange={handleOnChange}
        /> */}
        <input
          type="file"
          id="xlsxFileInput"
          accept={[".xlsx"]}
          onChange={handleFile}
        />
      </form>
    </Box>
  );
});
