import { Box, Button, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import bonVariableList from "./Schema/bon-variables"
import { FaCopy, FaTrash } from "react-icons/fa";
import { filteredBonVariables } from "./Schema/filterable-bon-variables";

const tableOptions = [
  undefined,
  "",
  "Acoustic",
  "Appearance",
  "Approvals",
  "Construction",
  "Dimensions",
  "Electrical",
  "Energy efficiency",
  "Fire properties",
  "Lighting features",
  "Materials",
  "Quantities",
  "Technical features",
  "Sustainability"
];

const typeOptions = [
  {key: "bon", label: "Built-in"},
  {key: "custom", label: "Custom"}
];

export const AttributeField = (props) => {  
  const {
    formik,
    attribute,
    name,
    label,
    type,
    index,
    values,
    table,
    range,
    unit,
    specification,
    min,
    max,
    handleDeleteVariable,
    handleDuplicateVariable
  } = props;

  const [ varType, setVarType ] = useState("");
  const [ standardOptions, setStandardOptions ] = useState([]);
  const [ unitOptions, setUnitOptions ] = useState([]);
  const [ menuOptions, setMenuOptions ] = useState([]);
  const [ minMaxOption, setMinMaxOption ] = useState(false);
  const [ newValue, setNewValue ] = useState(null);
  const [ bonVariables, setBonVariables ] = useState([]);
  
  useEffect(() => {
    const variableList = filteredBonVariables(formik.values.unitSystem);
    setBonVariables(variableList);
  }, [])

  useEffect(() => {
    if (attribute.type === "bon") {
      for (let i = 0; i < bonVariables.length; i++) {
        if (bonVariables[i].name === attribute.name) {
          setVarType(bonVariables[i]?.type);
          if (bonVariables[i]?.minMaxOption === false) {
            formik.setFieldValue(range, false);
          }
          formik.setFieldValue(table, bonVariables[i]?.parameterClass);
          formik.setFieldValue(unit, bonVariables[i]?.unitOptions[0] || "")
          formik.setFieldValue(label, bonVariables[i]?.label || "");
          setStandardOptions(bonVariables[i]?.standardOptions);
          setUnitOptions(bonVariables[i]?.unitOptions);
          setMenuOptions(bonVariables[i]?.menuOptions);
          setMinMaxOption(bonVariables[i]?.minMaxOption);
        }
      }
    } else if (attribute.type === "custom") {
      setVarType("");
      setStandardOptions([]);
      setUnitOptions([]);
      setMenuOptions([]);
      setMinMaxOption(true);
    }
  }, [attribute.type, attribute.name])

  useEffect(() => {
    if (attribute?.range) {
      formik.setFieldValue(values, [])
    } else if (!attribute?.range) {
      formik.setFieldValue(min, "")
      formik.setFieldValue(max, "")
    }
  }, [attribute.range])

  const handleDelete = (index) => {
    formik.setFieldValue(values, [...attribute.values.filter((_, i) => i !== index)]);
  }


  const handleBlur = () => {
    if (newValue !== "" && newValue !== null) {
      formik.setFieldValue(values, [...attribute.values, newValue]);
      setNewValue("");
    }
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      if (newValue !== "" && newValue !== null) {
        formik.setFieldValue(values, [...attribute.values, newValue]);
        setNewValue("");
      }
    }
  }

  const handleNameChange = (e) => {
    formik.setFieldValue(values, [])
    formik.setFieldValue(name, e.target.value);
  }

  const handleTypeChange = (e) => {
    if (e.target.value === "bon") {
      formik.setFieldValue(values, [])
    } else if (e.target.value === "custom") {
      formik.setFieldValue(values, [])
      formik.setFieldValue(table, "")
      formik.setFieldValue(unit, "")
      formik.setFieldValue(label, "")
    }

    formik.setFieldValue(type, e.target.value);
  }

  const handleValueChange = (e) => {
    setNewValue(e.target.value)
  }

  const handleSetSingleValue = () => {
    formik.setFieldValue(range, false)
  }

  const handleSetRange = () => {
    formik.setFieldValue(range, true)
  }

  return (
    <>
      <Box sx={{mb: 1, width: "100%", paddingBottom: 2, borderBottom: "1px solid #d9d9d9"}}>
        <Typography variant="body2" sx={{color: "#175c83", paddingX: 1, paddingBottom: 1.5, paddingTop: 0}}>Attribute {index + 1}: {attribute?.label}</Typography>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    size='small'
                    value={attribute?.type}
                    name={type}
                    label="Type"
                    //onChange={formik.handleChange}
                    onChange={handleTypeChange}
                  >
                    {typeOptions.map((type, index) => {
                      return (
                        <MenuItem key={"type." + index} value={type.key}>{type.label}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {attribute?.type === "bon" ? 
                  <FormControl fullWidth>
                    <InputLabel>Attribute name</InputLabel>
                    <Select
                      size='small'
                      value={attribute?.name}
                      name={name}
                      label="Attribute name"
                      //onChange={formik.handleChange}
                      onChange={handleNameChange}
                    >
                      {bonVariables.map((variable, index) => {
                        return (
                          <MenuItem key={index} value={variable.name}>{variable.label}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl> :
                  <TextField
                    //margin="dense"
                    variant="outlined"
                    label="Name"
                    size="small"
                    name={label}
                    value={attribute?.label}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
              </Grid>
              
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={1}>
            
              {!attribute?.range ? 
                <Grid item xs={12}>
                  <Button disabled={minMaxOption ? false : true} sx={{p: 0}} size="small" onClick={handleSetRange} fullWidth>
                    <Typography variant="caption">
                      <b>Values</b><br/>
                      Switch to range
                    </Typography>
                  </Button>
                </Grid>
              :
                <Grid item xs={12}>
                  <Button sx={{p: 0}} size="small" onClick={handleSetSingleValue} fullWidth>
                    <Typography variant="caption">
                      <b>Range</b><br/>
                      Switch to values
                    </Typography>
                  </Button>
                </Grid>
              }
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="table-select-label">Table</InputLabel>
                  <Select
                    size='small'
                    value={attribute?.table}
                    name={table}
                    label="Table"
                    onChange={formik.handleChange}
                    disabled={attribute?.type === "bon" ? true : false}
                  >
                    {tableOptions.map((key, index) => {
                      return (
                        <MenuItem key={index} value={key}>{key}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3.5}>
            <Grid container spacing={1}>
              {attribute?.range ?                   
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type={varType === "Number" || varType === "Integer" ? "number" : "text"}
                      label={"Min. value"}
                      name={min}
                      value={attribute?.min}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{attribute?.unit}</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // error={Boolean(formik.touched.thermalTransmittance && formik.errors.thermalTransmittance)}
                      fullWidth
                      size="small"
                      //variant="standard"
                      type={varType === "Number" || varType === "Integer" ? "number" : "text"}
                      label={"Max. value"}
                      name={max}
                      value={attribute?.max}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{attribute?.unit}</InputAdornment>,
                      }}
                    />
                  </Grid>
                </> :
                <>
                  <Grid item xs={12}>
                    <TextField
                      // error={Boolean(formik.touched.thermalTransmittance && formik.errors.thermalTransmittance)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      select={varType === "Menu" ? true : false}
                      label={`Add value`}
                      type={varType === "Number" || varType === "Integer" ? "number" : "text"}
                      //onBlur={formik.handleBlur}
                      onBlur={handleBlur}
                      onChange={handleValueChange}
                      onKeyDown={handleKeyUp}
                      
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{attribute?.unit}</InputAdornment>,
                      }}
                      name="value"
                      value={newValue}
                    >
                      {varType === "Menu" && menuOptions.map((option,index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{maxHeight: "60px", overflowY: "auto"}}>
                      <Grid container spacing={0.2}>
                        {attribute?.values.map((value, index) => (
                          <Grid item xs={4}>
                            <Tooltip title={value}>
                              <Chip sx={{borderRadius: 1 }} size="small" key={`${name}-chip-values-${index}`} onDelete={()=>handleDelete(index)} label={`${value}`}/>
                            </Tooltip>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>
          
          <Grid item xs={2.5}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {attribute?.type === "bon" ?
                  <TextField
                    fullWidth
                    select
                    size="small"
                    //label={unitLabel}
                    label="unit"
                    name={unit}
                    value={attribute?.unit}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={unitOptions.length > 1 ? false : true}
                  >
                    {unitOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                :
                  <TextField
                    //margin="dense"
                    variant="outlined"
                    label="Unit"
                    size="small"
                    name={unit}
                    value={attribute?.unit}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  //margin="dense"
                  variant="outlined"
                  label="Specification"
                  size="small"
                  name={specification}
                  value={attribute?.specification}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={1} sx={{padding: "4px 10px"}}>
              <Grid item xs={12} >
                <IconButton aria-label="delete variable" onClick={() => handleDeleteVariable(index)}>
                  <FaTrash size="20px" color="#175c83" />
                </IconButton>
              </Grid>
              <Grid item xs={12} >
                <IconButton  aria-label="duplicate variable" onClick={() => handleDuplicateVariable(index)}>
                  <FaCopy size="20px" color="#175c83" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
};