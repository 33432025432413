import { 
  Fragment, 
  useState 
  // useEffect, 
  // forwardRef 
} from 'react';
// import { useRouter } from 'next/router';
// import numeral from 'numeral';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  AppBar,
  Toolbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { Image as ImageIcon } from '../../icons/image';
import { Scrollbar } from '../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { v4 as uuidv4 } from 'uuid';
import { ProductEditForm } from './product-edit-form';
//import { ProductDataTable } from './product-data-table';
import { createSearchParams, useNavigate } from 'react-router-dom';
import ProductPreview from '../ProductPreview/ProductPreview';
import { portalUpdateProduct, publishProduct, unpublishProduct } from '../../api';
import ProductSummary from '../ProductSummary';
import ProductSummaryV2 from '../ProductSummaryV2';

const ProductImage = (props) => {
  const {productImages} = props;

  let imageArray = []

  for (let i = 0; i < productImages.length; i++) {
    const image = productImages[i]
    if ('deleted' in image) {
      if (!image.deleted) {
        imageArray.push(image)
      }
    } else {
      imageArray.push(image)
    }
  }

  if (imageArray.length > 0) {
    return <img style={{maxHeight: "100%", maxWidth: "100%"}} src={`https://cdn.bimroom.com/image/${imageArray[0]?.newName}`} alt=""/>
  } else {
    return <ImageIcon fontSize="small" />
  }
}




export const ProductListTable = (props) => {
  const {
    //updateProducts,
    updated,
    onPageChange,
    onRowsPerPageChange,
    page,
    products,
    productsCount,
    rowsPerPage,
    ...other
  } = props;
  const [openProduct, setOpenProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [openSummaryV2, setOpenSummaryV2] = useState(false);
  const navigate = useNavigate();

  const goToEdit = (product) => {
    if (product?.formId === "bon.0.2") {
      navigate({
        pathname: "/product/form/v2",
        search: createSearchParams({
            mode: "edit",
            id: product.id
        }).toString()
      })
    } else {
      navigate({
        pathname: "/product/form/v1",
        search: createSearchParams({
            mode: "edit",
            id: product.productId
        }).toString()
      })
    }
  }

  const handleOpenProduct = (productId) => {
    setOpenProduct((prevValue) => (prevValue === productId ? null : productId));
    let product = products.find(o => o.id === productId);
    setEditedProduct(product);
  };

  const handleOpenSummary = (product) => {
    if (product?.formId === "bon.0.1") {
      setSelectedProduct(product);
      setOpenSummary(true);
    } else if (product?.formId === "bon.0.2") {
      setSelectedProduct(product);
      setOpenSummaryV2(true);
    }
  }

  const handleCloseSummary = () => {
    setOpenSummary(false);
  }

  const handleCloseSummaryV2 = () => {
    setOpenSummaryV2(false);
  }

  const handleOpenPreview = (product) => {
    setSelectedProduct(product);
    setOpenPreview(true);
  }

  const handleClosePreview = () => {
    setOpenPreview(false);
  }

  const handleCancelEdit = () => {
    setOpenProduct(null);
  };

  const handlePublishProduct = async (product) => {
    // ADD POST CALL TO AZURE - CHANGE STATUS TO 'DELETED'
    let publishedProduct = product;
    
    if (publishedProduct && product?.status === "processed" || product?.status === "unpublished") {
      const payload = {
        id: publishedProduct.id,
        // userId: 
      }

      const updatedProduct = await publishProduct(payload);
      // if status code is 200 then update the product
      if (updatedProduct) {
        updated();
      }
    }
  };

  const handleUnpublishProduct = async (product) => {
    // ADD POST CALL TO AZURE - CHANGE STATUS TO 'DELETED'
    let unpublishedProduct = product;
    
    if (unpublishedProduct && product?.status === "published") {
      const payload = {
        id: unpublishedProduct.id,
        // userId: 
      }

      const updatedProduct = await unpublishProduct(payload);
      if (updatedProduct) {
        updated();
      }
    }
  };

  const handleDeleteProduct = async (product) => {
    // ADD POST CALL TO AZURE - CHANGE STATUS TO 'DELETED'
    let deletedProduct = product;
    
    if (deletedProduct) {
      deletedProduct.status = "deleted";
      const updatedProduct = await portalUpdateProduct(deletedProduct);
      if (updatedProduct) {
        updated();
      }
    }
    
    setOpenProduct(null);
    toast.success('Product deleted');
  };

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleCloseEdit = () => {
    setOpenModal(false);
  };




  return (
    <div {...other}>
      <Scrollbar>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left" width="60%">
                Name
              </TableCell>
              <TableCell align="right">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => {
              const open = product.id === openProduct;

              return (
                <Fragment key={product.id}>
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        ...(open && {
                          position: 'relative',
                          '&:after': {
                            position: 'absolute',
                            content: '" "',
                            top: 0,
                            left: 0,
                            backgroundColor: 'primary.main',
                            width: 3,
                            height: 'calc(100% + 1px)'
                          }
                        })
                      }}
                      width="35%"
                    >
                      <IconButton onClick={() => handleOpenProduct(product.id)}>
                        {open
                          ? <ChevronDownIcon fontSize="small" />
                          : <ChevronRightIcon fontSize="small" />}
                      </IconButton>
                    </TableCell>
                    <TableCell width="25%">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                        onClick={() => handleOpenProduct(product.id)}
                      >
                        {product?.images.length > 0
                          ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                // backgroundColor: '#fff',
                                // backgroundImage: `url(${product.image})`,
                                // backgroundPosition: 'center',
                                // backgroundSize: 'cover',
                                // borderRadius: 1,
                                display: 'flex',
                                height: 60,
                                justifyContent: 'center',
                                overflow: 'hidden',
                                width: 60
                              }}
                            > 
                              {/* <img style={{maxHeight: "100%", maxWidth: "100%"}} src={`https://cdn.bimroom.com/image/${product?.images[0]?.newName}`} alt=""/> */}
                              <ProductImage productImages={product.images}/>
                            </Box>
                          )
                          : (
                            <Box
                              sx={{
                                alignItems: 'center',
                                //backgroundColor: 'background.default',
                                borderRadius: 1,
                                display: 'flex',
                                height: 60,
                                justifyContent: 'center',
                                width: 60
                              }}
                            >
                              <ImageIcon fontSize="small" />
                            </Box>
                          )}
                        <Box
                          sx={{
                            cursor: 'pointer',
                            ml: 2
                          }}
                          onClick={() => handleOpenProduct(product.id)}
                        >
                          <Typography variant="subtitle2">
                            {product.name}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {product.category}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {product?.status === "published" && 
                      <Button 
                        color="inherit"
                        size="small" 
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to unpublish this product?"
                          )
                          if (confirmBox === true) {
                            handleUnpublishProduct(product)
                          }
                        }}
                        variant="text">
                          Unpublish
                        </Button>
                      }
                      {(product?.status === "processed" || product?.status === "unpublished") && <Button size="small" onClick={() => handlePublishProduct(product)} variant="outlined">Publish</Button>}
                      <SeverityPill sx={{ml: 1}} color={product.status === 'published' ? 'success' : 'info'}>
                        {product.status}
                      </SeverityPill>
                    </TableCell>
                  </TableRow>
                  {open && (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        sx={{
                          p: 0,
                          position: 'relative',
                          '&:after': {
                            position: 'absolute',
                            content: '" "',
                            top: 0,
                            left: 0,
                            backgroundColor: 'primary.main',
                            width: 3,
                            height: 'calc(100% + 1px)'
                          }
                        }}
                      >
                        <Divider />
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            px: 2,
                            py: 1
                          }}
                        >
                          <Button
                            disabled
                            onClick={() => handleOpenPreview(product)}
                            //onClick={() => console.log(product)}
                            sx={{ m: 1 }}
                            //type="submit"
                            variant="contained"
                          >
                            Preview
                          </Button>
                          <Button
                            onClick={() => handleOpenSummary(product)}
                            sx={{ m: 1 }}
                            color="info"
                            //type="submit"
                            variant="contained"
                          >
                            Summary
                          </Button>
                          <Button
                            onClick={() => goToEdit(product)}
                            sx={{ m: 1 }}
                            //type="submit"
                            variant="contained"
                          >
                            Edit
                          </Button>

                          <Dialog
                            fullScreen
                            open={openModal}
                            onClose={handleCloseEdit}
                            //TransitionComponent={Transition}
                          >
                            <AppBar sx={{ position: 'relative' }}>
                              <Toolbar>
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  onClick={handleCloseEdit}
                                  aria-label="close"
                                >
                                  <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                  Edit product
                                </Typography>
                                <Button autoFocus color="inherit" onClick={handleCloseEdit}>
                                  save
                                </Button>
                              </Toolbar>
                            </AppBar>
                            <ProductEditForm 
                              productdata={editedProduct} 
                              // addproduct={addProduct} 
                              handlecloseedit={handleCloseEdit} 
                              />
                          </Dialog>
                          <Button
                            onClick={handleCancelEdit}
                            sx={{ m: 1 }}
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                          <Button
                            //onClick={handleDeleteProduct}
                            onClick={() => {
                              const confirmBox = window.confirm(
                                "Do you really want to delete this product?"
                              )
                              if (confirmBox === true) {
                                handleDeleteProduct(product)
                              }
                            }}
                            color="error"
                            sx={{
                              m: 1,
                              ml: 'auto'
                            }}
                          >
                            Delete product
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={productsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <ProductSummaryV2 key="product-summary-v2" open={openSummaryV2} handleClose={handleCloseSummaryV2} productData={selectedProduct} />
      <ProductSummary key="product-summary" open={openSummary} handleClose={handleCloseSummary} productData={selectedProduct} />
      <ProductPreview open={openPreview} handleClose={handleClosePreview} product={selectedProduct} />
    </div>
  );
};

// ProductListTable.propTypes = {
//   products: PropTypes.array.isRequired,
//   productsCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
