import PropTypes from 'prop-types';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { Users as UsersIcon } from '../../icons/users';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { useNavigate } from 'react-router-dom';

export const ContentCard = (props) => {
    const { header, title, content, buttonText, link, icon, buttonOnClick } = props;
    const navigate = useNavigate();
  
    return (
    <Card>
      <CardContent >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {/* <UsersIcon color="primary" /> */}
          {icon}
          <Typography
            color="primary.main"
            sx={{ pl: 1 }}
            variant="subtitle2"
          >
           {header}
          </Typography>
        </Box>
        <Typography
          sx={{ my: 2 }}
          variant="h6"
        >
          {title}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {content}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="outlined"
          onClick={() => navigate(link)}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
    )
  }