import { useState, useEffect } from 'react';
import { 
  Box, 
  Card, 
  Container,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { ProductListFilters, ProjectListFilters } from './product-list-filters';
import { ProductListTable } from './product-list-table';
import { useMounted } from '../../hooks/use-mounted';
import { portalPending, portalProducts } from '../../api';
import { useSelector } from 'react-redux';
import { ExternalSourceDatagrid } from './external-source-datagrid';

const applyFilters = (products, filters) => products.filter((product) => {
  if (filters.name) {
    const nameMatched = product.name.toLowerCase().includes(filters.name.toLowerCase());

    if (!nameMatched) {
      return false;
    }
  }

  // It is possible to select multiple category options
  if (filters.category?.length > 0) {
    const categoryMatched = filters.category.includes(product.category);

    if (!categoryMatched) {
      return false;
    }
  }

  // It is possible to select multiple status options
  if (filters.status?.length > 0) {
    const statusMatched = filters.status.includes(product.status);

    if (!statusMatched) {
      return false;
    }
  }

  // Present only if filter required
  if (typeof filters.inStock !== 'undefined') {
    const stockMatched = product.inStock === filters.inStock;

    if (!stockMatched) {
      return false;
    }
  }

  return true;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const applyPagination = (products, page, rowsPerPage) => products.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);

export const ProductList = () => {
  const isMounted = useMounted();
  const [updated, setUpdated] = useState(0);
  const [products, setProducts] = useState([]);
  const [externalSourceProducts, setExternalSourceProducts] = useState([]);
  const [externalSourcesCount, setExternalSourcesCount] = useState(0);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [removeStatus, setRemoveStatus] = useState("deleted");
  const [filters, setFilters] = useState({
    name: undefined,
    category: [],
    status: [],
    inStock: undefined
  });

  const handleUpdate = () => {
     setUpdated(updated + 1);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  
  //const getProducts = useCallback(async () => {
  const getProducts = async () => {
    try {
      if (isMounted() && selectedBrand?.brandId) {
        const response = await portalProducts({
          brandId: selectedBrand?.brandId
        });
        setProducts(response);
        setProductCount(response.length);
        //console.log(products);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getExternalSources = async () => {
    try {
      if (isMounted() && selectedBrand?.brandId) {
        const products = await portalPending({
          brandId: selectedBrand?.brandId,
          removeStatus: removeStatus
        });
        //const response = []
        setExternalSourceProducts(products);
        setExternalSourcesCount(products?.length);
        //console.log(products);
      }
    } catch (err) {
      console.error(err);
    }
  };
  //}, [isMounted]);

  useEffect(() => {
      getProducts();
      getExternalSources();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updated, selectedBrand]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(products, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, rowsPerPage);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            <Box >
              <Tabs  value={ value } onChange={ handleTabChange } variant="fullWidth" aria-label="tabs basic">
                <Tab label={"Bimroom database (" + productCount + ")"} {...a11yProps(0)} />
                <Tab label={"External sources (" + externalSourcesCount + ")"} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <ProductListFilters onChange={handleFiltersChange} />
              <ProductListTable
                //updateProducts={getProducts}
                updated={handleUpdate}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                products={paginatedProducts}
                productsCount={filteredProducts.length}
                rowsPerPage={rowsPerPage}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ExternalSourceDatagrid products={externalSourceProducts} update={handleUpdate} removeStatus={removeStatus} setRemoveStatus={setRemoveStatus} />
            </CustomTabPanel>
          </Card>
        </Container>
      </Box>
    </>
  );
};

// ProductList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

//export default ProductList;
