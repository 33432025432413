import { Autocomplete, Box, Button, Checkbox, Grid, Modal, Radio, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Plus } from "../../icons/plus";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { 
  countryList, areaList
} from '../../data/countries';
import { useState } from "react";
import { Trash } from "../../icons/trash";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //minWidth: 400,
  width: "80vw",
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "auto"
};



const AddContactModal = (props) => {
  const { formikBrand, handleClose, open, setChangesMade } = props;

//  const handleAddContact = (values) => {
//     formikBrand.setFieldValue("publicContacts", [...formikBrand.values?.publicContacts, values]);
//  }

  const formik = useFormik({
    initialValues: {
      default: formikBrand.values?.publicContacts?.length === 0 ? true : false || false,
      name: '',
      address: '',
      city: '',
      zip: '',
      country:  '',
      phoneNumber: '',
      email: '',
      locationsResponsible: []
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().max(255),
      address: Yup.string().max(255),
      city: Yup.string().max(255),
      zip: Yup.string().max(255),
      country: Yup.string().max(255),
      email: Yup.string(),
      phoneNumber: Yup.string().max(255),
      locationsResponsible: Yup.array()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        formikBrand.setFieldValue("publicContacts", [...formikBrand.values?.publicContacts, values]);
        //handleAddContact(values);
        setChangesMade(true);
        handleClose();
        helpers.resetForm();
      } catch (err) {
        console.error(err);
        //toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });


  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h6">Add contact person</Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <TextField
                            //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
                            fullWidth
                            label="Name"
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <TextField
                            //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                            fullWidth
                            label="Address"
                            name="address"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            sx={{ mt: 1 }}
                            value={formik.values.address}
                        />
                        <Grid container spacing={1}>
                            <Grid 
                                item 
                                xs={8}
                            >
                                <TextField
                                //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                                fullWidth
                                label="City"
                                name="city"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                sx={{ mt: 1 }}
                                value={formik.values.city}
                                />
                            </Grid>
                            <Grid 
                                item 
                                xs={4}
                            >
                                <TextField
                                //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                                fullWidth
                                label="ZIP"
                                name="zip"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                sx={{ mt: 1 }}
                                value={formik.values.zip}
                                />
                            </Grid>  
                        </Grid>
                        <TextField
                            //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                            fullWidth
                            label="Country"
                            name="country"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            sx={{ mt: 1 }}
                            value={formik.values.country}
                        />
                        <TextField
                            //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
                            fullWidth
                            label="Phone number"
                            name="phoneNumber"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            sx={{ mt: 1 }}
                            value={formik.values.phoneNumber}
                        />
                        <TextField
                            //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                            fullWidth
                            label="Email"
                            name="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            sx={{ mt: 1 }}
                            value={formik.values.email}
                        />
                        <Autocomplete
                            multiple
                            sx={{mt: 1}}
                            id="area-list-outlined"
                            options={areaList}
                            // groupBy={option => option.group ? 'continent' : (option.group ? 'country' : '')}
                            groupBy={(option) => option.group}
                            getOptionLabel={(option) => option.label}
                            // push to locationsResponsible array
                            onChange={(e, value) => formik.setFieldValue("locationsResponsible", value.map((v) => v.value))}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Areas represented"
                                    placeholder="No areas selected"
                                />
                            )}
                        />
                        <Button 
                            sx={{mt: 2}} 
                            type="submit" 
                            variant="contained"
                        >
                            Add contact
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Modal>
  )
};

const PublicContacts = (props) => {
    const { formik, setChangesMade } = props;
    const [openContactModal, setOpenContactModal] = useState(false);

    const handleOpenContactModal = () => {
        setOpenContactModal(true);
    }

    const handleCloseContactModal = () => {
        setOpenContactModal(false);
    }

    const handleChange = (contact) => {
        // set default to true for the selected contact and false for the rest
        formik.setFieldValue("publicContacts", formik.values.publicContacts.map((c) => {
            return {
                ...c,
                default: c === contact
            }
        }));
    }

    return (
        <>
            <Table sx={{borderCollapse: "unset"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Default
                        </TableCell>
                        <TableCell>
                            Contact person
                        </TableCell>
                        {/* <TableCell>
                            Phone number/email
                        </TableCell>
                        <TableCell>
                            Address
                        </TableCell> */}
                        <TableCell>
                            Areas
                        </TableCell>
                        <TableCell align="right">
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                {formik?.values?.publicContacts?.length === 0 && 
                    <TableRow>
                        <TableCell colSpan={4} align="center">
                            No public contact details available
                        </TableCell>
                    </TableRow>}
                {formik.values?.publicContacts?.map((contact, index) => { 
                    return (
                    <TableRow key={index}>
                        <TableCell>
                            <Radio
                                checked={contact?.default === true}
                                onChange={() => handleChange(contact)}
                                value={contact?.default}
                                name="default"
                                //slotProps={{ input: { 'aria-label': 'A' } }}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2">
                                {contact?.name}
                            </Typography>
                            <Typography variant="body2">
                                {contact?.phoneNumber}
                            </Typography>
                            <Typography variant="body2">
                                {contact?.email}
                            </Typography>
                            <Typography variant="body2">
                                {contact?.address}
                            </Typography>
                            <Typography variant="body2">
                                {contact?.zip} {contact?.city}
                            </Typography>
                            <Typography variant="body2">
                                {contact?.country}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {contact?.locationsResponsible.map((area, index) => {
                                return <Typography key={index} variant="body2">{area}</Typography>
                            })}
                        </TableCell>
                        <TableCell align="right">
                            <Button variant="text" onClick={() => {
                                setChangesMade(true);
                                // if default contact is deleted, set the first contact as default
                                formik.setFieldValue("publicContacts", formik.values.publicContacts.filter((_, i) => i !== index));
                                
                            }}>
                                <Trash />
                            </Button>
                        </TableCell>
                    </TableRow>)})}
                    <TableRow key="add-contact-button">
                        <TableCell 
                            align="center" 
                            onClick={handleOpenContactModal} 
                            sx={{
                                cursor: "pointer", 
                                //backgroundColor: "#175c83", 
                                //color: '#fff',
                                border: "1px dashed #175c83",
                                borderRadius: 1,
                                color: '#175c83',
                                transition: "all 0.3s",
                                '&:hover': {
                                    backgroundColor: '#175c831A',
                                }  
                            }}
                            colSpan={4}
                        >
                            <Box sx={{display: "flex", justifyContent: "center"}} >
                                <Plus />
                                <Typography variant="subtitle2">Add contact</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody> 
            </Table>

            <AddContactModal 
                formikBrand={formik}
                handleClose={handleCloseContactModal}
                open={openContactModal}
                setChangesMade={setChangesMade}
            />
        </>
    )
}

export default PublicContacts;
