import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

// components
import OverviewPage from "../components/Overview";
import useDocumentTitle from "../useDocumentTitle";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";

const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const isLoading = useSelector((store) => store.user.isLoading);
  const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const { accounts, inProgress } = useMsal();
  useDocumentTitle('Bimroom portal - Home');
  const navigate = useNavigate();

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
          <DashboardLayout>
            <OverviewPage /> 
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default Home;