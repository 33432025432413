import * as Yup from 'yup';
//import moment from 'moment';

const generalSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(140, 'Too Long!')
    .required('Required')
});

const technicalDetailsSchema = Yup.object().shape({
  ecoLabelSpecifier: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
});

const filesSchema = Yup.object().shape();


export const validationSchema = [
  generalSchema,
  technicalDetailsSchema,
  filesSchema
];
