import { 
  Box, 
  Container,
  Typography
} from '@mui/material';

const ToBePublished = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 4,
          minHeight: "calc(100vh - 64px)"
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{height: "calc(100vh - 64px)", marginTop: 1, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FFFFFF", borderRadius: 1}}>
            <Typography variant="h6">
              This page is temporarily unavailable - it will be published again soon.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ToBePublished;
