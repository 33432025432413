import PropTypes from 'prop-types';
import { Box, Button, Card, Chip, Typography } from '@mui/material';

export const OverviewBanner = (props) => {
  const { onDismiss, ...other } = props;

  return (
    <Card
      sx={{
        alignItems: 'center',
        // backgroundColor: 'primary.main',
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        //color: '#313133',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        p: 4
      }}
      {...other}>
      <Box
        sx={{
          mr: 4,
          width: 200,
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& img': {
            height: 120,
            width: 'auto'
          }
        }}
      >
        <img
          alt=""
          //src="/static/banner-illustration.png"
          src="/static/white-logo.png"
        />
      </Box>
      <div>
        {/* <div>
          <Chip
            color="secondary"
            label="New"
          />
        </div> */}
        <Typography
          color="inherit"
          sx={{ mt: 2 }}
          variant="h4"
        >
          {/* Welcome to Bimroom Customer Portal */}
          Welcome to our new customer portal
        </Typography>
        <Typography
          color="inherit"
          sx={{ mt: 1 }}
          variant="body2"
        >
         {/* The Bimroom Customer Portal allows you to control your company's BIM content in the Bimroom library, view real-time analytics, request modelling services and sign contracts with Bimroom. */}
         Bimroom portal is the all-in-one solution for controlling and managing your assets in the Bimroom library. Bimroom is a cutting-edge online platform designed to fulfill the potential of your Building Information Modeling (BIM) objects. With the Bimroom portal, you can manage your BIM assets in the Bimroom ecosystem, access library analytics, request modelling services and sign contracts. 
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button
            color="secondaryButton"
            onClick={onDismiss}
            variant="contained"
          >
            Dismiss Banner
          </Button>
        </Box>
      </div>
    </Card>
  );
};

OverviewBanner.propTypes = {
  onDismiss: PropTypes.func
};
