import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Grid,
  FormControl,
  FormGroup,
  FormLabel,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  FormHelperText,
  Input,
  OutlinedInput,
  Tabs,
  Tab,
  Button
} from '@mui/material';
// import { 
//   categoryOptions, 
//   getCategoryIndex, 
//   getSubcategoryIndex,
// } from '../../../data/categories';
import { 
  etimClassification
} from '../../../data/etim';
import { 
  roomOptions, 
  usageOptions, 
} from '../../../data/formOptions';
import { 
  europeList, 
  africaList, 
  antarcticaList, 
  asiaList, 
  northAmericaList, 
  southAmericaList, 
  oceaniaList, 
  countryList
} from '../../../data/countries';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormikProvider, FastField, FieldArray } from 'formik';
import { ContentState, EditorState, convertFromHTML} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { FaTrash } from 'react-icons/fa';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { categories } from '../../../api';

const ClassificationField = (props) => {
  const { formik, index } = props;

  const removeClassification = () => {
    formik.setFieldValue("classification", formik.values.classification.filter((_, i) => i !== index));
  }

  return (
    <Grid container spacing={1} sx={{mb: 1}}>
      <Grid item xs={6}>
        <TextField
          // error={Boolean(
          //   formik.touched.categories[index].category && formik.errors.categories[index].category
          // )}
          fullWidth
          label="Classification"
          name={`classification[${index}].label`}
          size="small"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.classification[index].label}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          disabled={formik.values.classification[index].key === "custom" ? false : true}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          // error={Boolean(
          //   formik.touched.categories[index].category && formik.errors.categories[index].category
          // )}
          fullWidth
          label="Value"
          name={`classification[${index}].value`}
          size="small"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.classification[index].value}
        />
      </Grid>
      <Grid item xs={1}>
          <Button sx={{height: 1, maxWidth: "100%"}} disabled={formik.values.classification[index].key === "custom" ? false : true} onClick={removeClassification}>
            <FaTrash />
          </Button>
        </Grid>
    </Grid>
  )
}

const CategoryField = (props) => {
  const { formik, index, categoryOptions, getCategoryIndex, getSubcategoryIndex, checkSubcategory, showSubcategory, showSubcategory2 } = props;
  
  const removeCategory = () => {
    formik.setFieldValue("categories", formik.values.categories.filter((_, i) => i !== index));
  }

  return (
    <>
      <Grid container spacing={1} sx={{mb: 1}}>
        <Grid item xs={11} >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                // error={Boolean(
                //   formik.touched.categories[index].category && formik.errors.categories[index].category
                // )}
                fullWidth
                label="Category"
                name={`categories[${index}].category`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                select
                value={formik.values.categories[index].category}
                required
              >
                {categoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                // error={Boolean(
                //   formik.touched.categories[index].subcategory1 && formik.errors.categories[index].subcategory1
                // )}
                fullWidth
                label="Subcategory 1"
                name={`categories[${index}].subcategory1`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ display: showSubcategory(index) }}
                select
                value={checkSubcategory(1, formik.values.categories[index].subcategory1, index)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.categories[index].category)
                ]?.subcategories.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                // error={Boolean(
                //   formik.touched.categories[index].subcategory2 && formik.errors.categories[index].subcategory2
                // )}
                fullWidth
                label="Subcategory 2"
                name={`categories[${index}].subcategory2`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ display: showSubcategory2(index) }}
                select
                value={checkSubcategory(2, formik.values.categories[index].subcategory2, index)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.categories[index].category)
                ]?.subcategories[
                  getSubcategoryIndex(formik.values.categories[index].category, formik.values.categories[index].subcategory1)
                ]?.subcategories.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Button sx={{height: 1, maxWidth: "100%"}} disabled={index === 0 ? true : false} onClick={removeCategory}>
            <FaTrash />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`description-tabpanel-${index}`}
      aria-labelledby={`description-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{py: 1}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `description-tab-${index}`,
    'aria-controls': `description-tabpanel-${index}`,
  };
}

export default function GeneralForm(props) {
  const { formik, editingMode } = props;
  const [ activeDescription, setActiveDescription ] = useState(0);
  const [ categoryOptions, setCategoryOptions ] = useState([]);
  
  // Editor state
  const [ editorStateEn, setEditorStateEn ] = useState(
    EditorState.createEmpty()
  );
  const [ editorStateDe, setEditorStateDe ] = useState(
    EditorState.createEmpty()
  );
  const [ editorStateFr, setEditorStateFr ] = useState(
    EditorState.createEmpty()
  );
  const [ editorStateIt, setEditorStateIt ] = useState(
    EditorState.createEmpty()
  );
  const [ editorStateEs, setEditorStateEs ] = useState(
    EditorState.createEmpty()
  );

  const editorEn = useRef(null);
  const editorDe = useRef(null);
  const editorFr = useRef(null);
  const editorIt = useRef(null);
  const editorEs = useRef(null);

  const languageOptions = [
    {
      value: 'en',
      label: 'English',
      editor: editorEn,
      editorState: editorStateEn,
      setEditorState: setEditorStateEn,
      onChange: editorStateEn => setEditorStateEn(editorStateEn)
    },
    {
      value: 'de',
      label: 'German',
      editor: editorDe,
      editorState: editorStateDe,
      setEditorState: setEditorStateDe,
      onChange: editorStateDe => setEditorStateDe(editorStateDe)
    },
    {
      value: 'fr',
      label: 'French',
      editor: editorFr,
      editorState: editorStateFr,
      setEditorState: setEditorStateFr,
      onChange: editorStateFr => setEditorStateFr(editorStateFr)
    },
    {
      value: 'it',
      label: 'Italian',
      editor: editorIt,
      editorState: editorStateIt,
      setEditorState: setEditorStateIt,
      onChange: editorStateIt => setEditorStateIt(editorStateIt)
    },
    {
      value: 'es',
      label: 'Spanish',
      editor: editorEs,
      editorState: editorStateEs,
      setEditorState: setEditorStateEs,
      onChange: editorStateEs => setEditorStateEs(editorStateEs)
    }
  ];

  useEffect(() => {
    // fetch category options from db
    const fetchData = async () => {
      const data = await categories();
      if (data && data.length > 0) {
        setCategoryOptions(data);
      }
      
    }

    fetchData();
  }, [])

  
  function getCategoryIndex(value) {
    try {
      let indexNumber = categoryOptions?.findIndex(obj => obj.value === value);
      if (indexNumber === -1) {
          return 0;
      } else {
          return indexNumber;
      }
    } catch (error) {
      return 0;
    }
  };
  
  function getSubcategoryIndex(upperCategory, value) {
    try {
      let indexNumber = categoryOptions[getCategoryIndex(upperCategory)]?.subcategories.findIndex(obj => obj.value === value);
      if (indexNumber === -1) {
        return 0;
      } else {
        return indexNumber;
      }
    } catch (error) {
      return 0;
    }
  }

  // useEffect(() => {
  //   // html text to editor
  //     const blocksFromHTML = convertFromHTML(formik.values.descriptions[activeDescription].description);
  //     //console.log(blocksFromHTML);

  //     const state = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap
  //     )
  //     setEditorState( EditorState.createWithContent(state));
  // }, [editingMode])
  
  // useEffect(() => {
  //   //console.log(convertToRaw(editorState.getCurrentContent()));
  //   let html = stateToHTML(editorState.getCurrentContent());
  //   formik.setFieldValue('descriptions', html);
  // }, [editorState])

  useEffect(() => {
    // html text to editor
    languageOptions.forEach((option) => {
      const selectedDescription = formik.values.descriptions.find(desc => desc.lang === option.value);
      if (!selectedDescription) {
        option.setEditorState(EditorState.createEmpty());
        return;
      }

      const blocksFromHTML = convertFromHTML(selectedDescription?.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      option.setEditorState(EditorState.createWithContent(state));
    })
  }, [editingMode])

  function showSubcategory(index) {
    let length = categoryOptions[getCategoryIndex(formik.values.categories[index].category)]?.subcategories.length;
    if (length === 1) {
      formik.values.categories[index].subcategory1 = '';
      //formik.setFieldValue("subcategory1", "");
      return 'none';
    } else {
      return 'block';
    }
  }

  function showSubcategory2(index) {
    let length = categoryOptions[getCategoryIndex(formik.values.categories[index].category)]?.subcategories[getSubcategoryIndex(formik.values.categories[index].category,formik.values.categories[index].subcategory1)]?.subcategories.length;
    if (length === 1) {
      formik.values.categories[index].subcategory2 = '';
      //formik.setFieldValue("subcategory2", "");
      return 'none';
    } else {
      return 'block';
    }
  }

  function checkSubcategory(level, value, index) {
    if (level === 1) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.categories[index].category)]?.subcategories.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.categories[index].subcategory1;
      } else {
        formik.values.categories[index].subcategory1 = '';
        //formik.setFieldValue("subcategory1", "");
        return formik.values.categories[index].subcategory1;
      }
    } else if (level === 2) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.categories[index].category)]?.subcategories[getSubcategoryIndex(formik.values.categories[index].category,formik.values.categories[index].subcategory1)]?.subcategories.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.categories[index].subcategory2;
      } else {
        formik.values.categories[index].subcategory2 = '';
        //formik.setFieldValue("subcategory2", "");
        return formik.values.categories[index].subcategory2;
      }
    } else {
      return value;
    }
  }

  const addCategory = () => {
    formik.setFieldValue("categories", [
      ...formik.values.categories,
      {
        category: "",
        subcategory1: "",
        subcategory2: ""
      }
    ]);
  }

  const addClassification = () => {
    formik.setFieldValue("classification", [
      ...formik.values.classification,
      {
        label: "",
        key: "custom",
        value: ""
      }
    ]);
  }

  function getColor(value) {
    if (value > 0) {
      return "neutral.100"
    } else {
      return "#fff"
    }
  }

  function getLength(value) {
    switch(value) {
      case "europe":
        return formik.values.europe.length;

      case "africa":
        return formik.values.africa.length;

      case "asia":
        return formik.values.asia.length;

      case "northAmerica":
        return formik.values.northAmerica.length;

      case "southAmerica":
        return formik.values.southAmerica.length;

      case "antarctica":
        return formik.values.antarctica.length;

      case "oceania":
        return formik.values.oceania.length;  

      default:
        return;
    } 
  }

  const CountriesSelectedText = (props) => {
    const { continent } = props;
    const length = getLength(continent);
    if (length > 1) {
      return <Typography color="neutral.900" sx={{borderRadius: 1, backgroundColor: '#fff', px: 2, mr: 2}}>{length} countries selected</Typography>;
    } else if (length === 1) {
      return <Typography color="neutral.900" sx={{borderRadius: 1, backgroundColor: '#fff', px: 2, mr: 2}}>{length} country selected</Typography>;
    } else {
      return <></>
    }
  }

  const handleChange = (event, newValue) => {
    setActiveDescription(newValue);
  };


  return (
    <> 
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Basic details</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label="Product name"
                name="name"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                required
              />
              <TextField
                error={Boolean(formik.touched.nameSpecification && formik.errors.nameSpecification)}
                fullWidth
                helperText={formik.touched.nameSpecification && formik.errors.nameSpecification}
                label="Product name specification"
                name="nameSpecification"
                size="small"
                sx={{ mt: 1 }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.nameSpecification}
              />
              <TextField
                error={Boolean(formik.touched.manufacturerProductId && formik.errors.manufacturerProductId)}
                fullWidth
                label="Unique product id"
                name="manufacturerProductId"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1 }}
                value={formik.values.manufacturerProductId}
                required
              />
              <TextField
                error={Boolean(formik.touched.brandName && formik.errors.brandName)}
                fullWidth
                label="Brand"
                name="brand"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1 }}
                value={formik.values.brandName}
                disabled
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(formik.touched.model && formik.errors.model)}
                    fullWidth
                    helperText={formik.touched.model && formik.errors.model}
                    label="Model name"
                    name="model"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.model}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.gtin && formik.errors.gtin)}
                    fullWidth
                    // helperText={formik.touched.gtin && formik.errors.gtin}
                    label="GTIN"
                    name="gtin"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.gtin}
                  />
                </Grid>
              </Grid>
              <Autocomplete
                fullWidth
                disablePortal
                id="country-combo-box"
                sx={{ mt: 1 }}
                options={countryList}
                size="small"
                //sx={{ width: 300 }}
                //isOptionEqualToValue={(option, value) => option.id === value.id}
                value={formik.values.countryOfManufacture}
                onChange={(e, value) => formik.setFieldValue("countryOfManufacture", value?.label)}
                renderInput={(params) => <TextField {...params} label="Country of Manufacture"  /> }
              />
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                  
                }}
                variant="subtitle2"
              >
                Description
              </Typography>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant="fullWidth" value={activeDescription} onChange={handleChange} aria-label="language tabs">
                  {languageOptions.map((option, index) => (
                    <Tab label={option?.label} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </Box>
              {languageOptions.map((option, index) => (
                <CustomTabPanel key={index} value={activeDescription} index={index}>
                  <Grid item>
                    {/* <Editor
                      id="description-text"
                      ref={option?.editor}
                      editorState={option?.editorState}
                      onChange={option.onChange}
                      onBlur={() => formik.setFieldValue(`descriptions[${index}].description`, stateToHTML(option?.editorState.getCurrentContent()))}
                      //onBlur={() => console.log("blur")}
                    /> */}
                      <Editor
                        id="description-text"
                        editorState={option?.editorState}
                        ref={option?.editor}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={option.onChange}
                        onBlur={() => formik.setFieldValue(`descriptions[${index}].description`, stateToHTML(option?.editorState.getCurrentContent()))}
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                  </Grid>
                </CustomTabPanel>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Category & Classification</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  //mt: 2,
                }}
                variant="subtitle2"
              >
                  Bimroom category
              </Typography>
              <FormikProvider value={formik}>
                <FieldArray 
                  name="categories" 
                  render={() => (
                    <div>
                      {formik.values.categories.map((p, index) => {
                        //const category = `categories[${index}].category`;
                        return (
                          <Box key={"category." + index}>
                            <CategoryField
                              formik={formik}
                              index={index}
                              categoryOptions={categoryOptions}
                              getCategoryIndex={getCategoryIndex}
                              getSubcategoryIndex={getSubcategoryIndex}
                              checkSubcategory={checkSubcategory}
                              showSubcategory={showSubcategory}
                              showSubcategory2={showSubcategory2}
                            />
                          </Box>
                        );
                      })}
                    </div>
                  )}
                />
              </FormikProvider>
              <Button variant="outlined" fullWidth onClick={addCategory}>Add another category</Button>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                Usage
              </Typography>
              <FormikProvider value={formik}>
                <FormControl fullWidth>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: "block"
                    }}
                  >
                    {usageOptions.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            type="checkbox"
                            name="usage"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <Typography
                  color="textSecondary"
                  sx={{
                    mb: 1,
                    mt: 2,
                  }}
                  variant="subtitle2"
                >
                  Room
                </Typography>
                <FormControl fullWidth>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: "block"
                    }}
                  >
                    {roomOptions.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            type="checkbox"
                            name="room"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                Classification
              </Typography>
              <FormikProvider value={formik}>
                <FieldArray 
                  name="classification" 
                  render={() => (
                    <Grid container spacing={1}>
                      {formik.values.classification.map((p, index) => {
                        //const category = `categories[${index}].category`;
                        return (
                          <Grid item key={"classification." + index} xs={12}>
                            <ClassificationField formik={formik} index={index} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                />
              </FormikProvider>
              <Button variant="outlined" fullWidth onClick={addClassification}>Add custom classification</Button>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                  ETIM
              </Typography>
              <Autocomplete
                // disablePortal
                name="etim-category"
                options={etimClassification}
                fullWidth
                size="small"
                sx={{mt: 1}}
                //isOptionEqualToValue={(option, value) => option.id === value.id}
                value={formik.values.etimClassification}
                onChange={(e, value) => formik.setFieldValue("etimClassification", value)}
                renderInput={(params) => <TextField {...params} label="ETIM ART CLASS" />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Market Area</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Product availability
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Accordion sx={{backgroundColor: getColor(getLength("europe"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ justifyContent: 'space-between' }}
                >
                  <Typography color="neutral.900">Europe</Typography>
                  <CountriesSelectedText continent="europe" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >

                      <FormGroup
                        row={true}
                        sx={{mb: 1}}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "europe",
                                europeList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("europe", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                          //display: showCountries(formik.values.availableEurope),
                        }}
                      >
                        {europeList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                // component={Checkbox}
                                name="europe"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ transform: "scale(0.8)", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("africa"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography color="neutral.900">Africa</Typography>
                  <CountriesSelectedText continent="africa" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{mb: 1}}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "africa",
                                africaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("africa", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                         // display: showCountries(formik.values.availableAfrica),
                        }}
                      >
                        {africaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="africa"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("asia"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography color="neutral.900">Asia</Typography>
                  <CountriesSelectedText continent="asia" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{ mb: 1 }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "asia",
                                asiaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("asia", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                        //  display: showCountries(formik.values.availableAsia),
                          backgroundColor: '#fff'
                        }}
                      >
                        {asiaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="asia"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("northAmerica"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography color="neutral.900">North America</Typography>
                  <CountriesSelectedText continent="northAmerica" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                            mb: 1
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "northAmerica",
                                northAmericaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("northAmerica", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                        }}
                      >
                        {northAmericaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="northAmerica"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("southAmerica"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography color="neutral.900">South America</Typography>
                  <CountriesSelectedText continent="southAmerica" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                          mb: 1
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "southAmerica",
                                southAmericaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("southAmerica", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                        }}
                      >
                        {southAmericaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                               // component={Checkbox}
                                name="southAmerica"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("oceania"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography color="neutral.900">Oceania</Typography>
                  <CountriesSelectedText continent="oceania" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                          mb: 1
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "oceania",
                                oceaniaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("oceania", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                         // display: showCountries(formik.values.availableOceania),
                          backgroundColor: '#fff'
                        }}
                      >
                        {oceaniaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                               // component={Checkbox}
                                name="oceania"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("antarctica"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography color="neutral.900">Antarctica</Typography>
                  <CountriesSelectedText continent="antarctica" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                          mb: 1,
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "antarctica",
                                antarcticaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("antarctica", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          // display: showCountries(formik.values.availableAntarctica),
                          backgroundColor: '#fff'
                        }}
                      >
                        {antarcticaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="antarctica"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {/* <Button variant="outlined" fullWidth onClick={() => console.log(formik.values)}>Check values</Button> */}
        </CardContent>
      </Card>
    </>  
  );
}
