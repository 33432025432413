import { createSvgIcon } from '@mui/material/utils';

export const Code = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="currentColor">
    <g>
      <path
        d="m 24.165211,29.185885 c -2.376655,0.41731 -3.689,2.62847 -5.127765,4.35295 L 9.5468976,44.9603 c -1.37593,1.65168 -3.354315,3.43275 -3.078977,5.79598 0.194414,1.66855 1.432832,2.86321 2.454973,4.09127 l 5.5392114,6.64832 5.711795,6.81879 c 1.486701,1.78619 3.140122,3.57032 5.696009,2.87072 2.539489,-0.69501 3.758177,-3.58481 2.634952,-5.93917 -0.695517,-1.45786 -2.040865,-2.69803 -3.074082,-3.92081 -2.052593,-2.42936 -4.074603,-4.88566 -6.109228,-7.3302 -0.708882,-0.85167 -2.771566,-2.55636 -2.873524,-3.67805 -0.09463,-1.04106 2.390873,-3.20842 3.043994,-3.99309 l 6.107523,-7.3302 c 0.997078,-1.19806 2.384702,-2.457995 2.975721,-3.920795 1.175048,-2.90754 -1.099701,-6.46814 -4.410054,-5.88718 m 30.00268,0 c -3.89916,0.68444 -4.17805,5.66488 -5.06466,8.785155 -1.72464,6.06992 -3.37513,12.16081 -5.06534,18.24027 -0.92088,3.31189 -2.14826,6.67099 -2.74661,10.05772 -0.47868,2.70842 1.64333,5.40491 4.52359,5.06039 3.98626,-0.4768 4.32618,-5.5464 5.18637,-8.64026 l 5.1141,-18.41074 c 0.92087,-3.31188 2.14826,-6.670985 2.7466,-10.057705 0.48721,-2.75667 -1.76333,-5.54931 -4.69405,-5.03483 m 20.626838,0 c -2.73109,0.47953 -4.31152,3.31189 -3.22102,5.88718 0.64983,1.53473 2.08621,2.833875 3.14874,4.091265 2.00507,2.37311 3.98081,4.77179 5.96815,7.15973 0.62989,0.75672 3.17619,2.98254 3.00879,3.99309 -0.20013,1.20829 -2.26026,2.91401 -3.03811,3.84852 l -6.24959,7.50067 c -0.98702,1.18443 -2.37499,2.44215 -2.89611,3.92081 -0.94884,2.69172 0.89445,5.92774 3.96103,5.76017 2.54051,-0.13876 3.87444,-2.27645 5.35514,-4.05547 l 9.63257,-11.59195 c 1.4091,-1.6914 3.50349,-3.54185 3.22102,-5.96644 -0.202,-1.73334 -1.53542,-2.98732 -2.59608,-4.26175 l -6.10845,-7.3302 -5.28456,-6.307375 c -1.25551,-1.50661 -2.74388,-3.02703 -4.90152,-2.64825 z"
      />
    </g>
  </svg>, 'Code');
