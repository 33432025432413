import { Fragment, cloneElement, useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box, Button, Checkbox, Collapse, Dialog, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography
} from '@mui/material';
import { ArrowNarrowRight as ArrowNarrowRightIcon } from '../../icons/arrow-narrow-right';
import { Link as LinkIcon } from '../../icons/link';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { createSchema } from 'genson-js';
import { ChevronDown } from '../../icons/chevron-down';
import { ChevronUp } from '../../icons/chevron-up';
import bonSchema from './bon-schema-v1.json';
import Papa from 'papaparse';
import { Plus } from '../../icons/plus';


function generate(element) {
  return [0, 1, 2].map((value) =>
    cloneElement(element, {
      key: value,
    }),
  );
}

const mapper = {
  id: "123dmawid9201udawio",
  name: "Mapper 1",
  importRestAsCustom: false,
  mappedItems: [
    {
      id: 1,
      from: "Sound reduction index",
      to: "details.soundReductionIndex.value",
      conversion: true,
      conversionOperations: []
    },
    {
      id: 2,
      from: "Colour",
      to: "details.colour.value",
      conversion: true,
      conversionOperations: []
    },
    {
      id: 3,
      from: "Certificates",
      to: "details.certificates.value",
      conversion: true,
      conversionOperations: []
    },
    {
      id: 4,
      from: "Testing",
      to: "details.testing.value",
      conversion: true,
      conversionOperations: []
    },
    {
      id: 5,
      from: "Number of Leafs",
      to: "details.numberOfLeafs.value",
      conversion: true,
      conversionOperations: []
    },
    {
      id: 6,
      from: "Muntins",
      to: "details.muntins.value",
      conversion: true,
      conversionOperations: []
    },
    {
      id: 7,
      from: "Vertical muntins max",
      to: "details.verticalMuntinsMax.value",
      conversion: true,
      conversionOperations: []
    }
  ]
}

const SourceList = (props) => {
  const { sourceJson, index, item, getSelected, selectParameter } = props;
  //console.log(sourceJson);
  const [ open, setOpen ] = useState(false);
  const type = sourceJson[item]?.type;

  return (
    <>
      <ListItem
        sx={{
          borderRadius: 1,
          border: getSelected(index) ? "1px solid #ccc" : "1px solid #fff",
          backgroundColor: getSelected(index) && '#ccc',
          p: 1,
          cursor: "pointer",
          "&:hover": {
            border: "1px dashed #ccc"
          },
          mb: "2px"
        }}
        key={index}
      >
        <ListItemText
          primary={item}
          secondary={type}
          onClick={() => selectParameter(index)}
          primaryTypographyProps={{fontWeight: 700}}
        />
        { type === "array" && 
          ( open
          ? <ChevronDownIcon onClick={() => setOpen(!open)} fontSize="small" />
          : <ChevronRightIcon onClick={() => setOpen(!open)} fontSize="small" />
          )
        }
        { type === "object" && 
          ( open
          ? <ChevronDownIcon onClick={() => setOpen(!open)} fontSize="small" />
          : <ChevronRightIcon onClick={() => setOpen(!open)} fontSize="small" />
          )
        }
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List component="div" sx={{paddingLeft: 1}}>
          {type === "object" && sourceJson[item]?.properties && Object.keys(sourceJson[item]?.properties).map((item2, index2) => {
            const newIndex = index + "." + index2;
            return (
              <SourceList key={newIndex} index={newIndex} sourceJson={sourceJson[item]?.properties} item={item2} getSelected={getSelected} selectParameter={selectParameter} />
            )
          }
          )}
          {type === "array" && sourceJson[item]?.items?.properties && (Object.keys(sourceJson[item]?.items?.properties).map((item2, index2) => {
            const newIndex = index + "." + index2;
            return (
              <SourceList key={newIndex} index={newIndex} sourceJson={sourceJson[item]?.items?.properties} item={item2}  getSelected={getSelected} selectParameter={selectParameter} />
            )
          }
          ))}
        </List>
      </Collapse>
    </>
  )
}

const DestinationList = (props) => {
  const { destinationJson, index, item, isSelected } = props;
  //console.log(sourceJson);
  const [ open, setOpen ] = useState(false);
  const type = destinationJson[item]?.type;


  return (
    <>
      <ListItem
        sx={{
          borderRadius: 1,
          border: isSelected ? "1px dashed #ccc" : "1px solid #fff",
          borderRadius: 1,
          p: 1,
          "&:hover": isSelected && {
            backgroundColor: "#ccc",
            cursor: "pointer"
          },
          mb: "2px"
        }}
        key={index}
      >
        <ListItemText
          primary={item}
          secondary={type}
          //onClick={() => selectParameter(index)}
          primaryTypographyProps={{fontWeight: 700}}
        />
        { type === "array" && 
          ( open
          ? <ChevronDownIcon onClick={() => setOpen(!open)} fontSize="small" />
          : <ChevronRightIcon onClick={() => setOpen(!open)} fontSize="small" />
          )
        }
        { type === "object" && 
          ( open
          ? <ChevronDownIcon onClick={() => setOpen(!open)} fontSize="small" />
          : <ChevronRightIcon onClick={() => setOpen(!open)} fontSize="small" />
          )
        }
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List component="div" sx={{paddingLeft: 1}}>
          {type === "object" && destinationJson[item]?.properties && Object.keys(destinationJson[item]?.properties).map((item2, index2) => {
            const newIndex = index + "." + index2;
            return (
              <DestinationList key={newIndex} index={newIndex} destinationJson={destinationJson[item]?.properties} item={item2} isSelected={isSelected} />
            )
          }
          )}
          {type === "array" && destinationJson[item]?.items?.properties && (Object.keys(destinationJson[item]?.items?.properties).map((item2, index2) => {
            const newIndex = index + "." + index2;
            return (
              <DestinationList key={newIndex} index={newIndex} destinationJson={destinationJson[item]?.items?.properties} item={item2} isSelected={isSelected} />
            )
          }
          ))}
        </List>
      </Collapse>
    </>
  )
}

export const DataMappers = (props) => {
  const [dense, setDense] = useState(true);
  const [selected, setSelected] = useState(-1);
  const [isSelected, setIsSelected] = useState(false);
  const {
    updated
  } = props;
  const [openItem, setOpenItem] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [ source, setSource ] = useState([]);
  const [bimroomSchema, setBimroomSchema] = useState(0);
  const [sourceFileType, setSourceFileType] = useState(0);
  const [ open, setOpen] = useState(false);
  const [ sourceMimeType, setSourceMimeType] = useState("");
  const [ sourceSchema, setSourceSchema] = useState(null);
  const [ addNew, setAddNew ] = useState(false);

  const handleOpenItem = (itemId) => {
    setOpenItem((prevValue) => (prevValue === itemId ? null : itemId));
    let item = mapper.mappedItems.find(o => o.id === itemId);
    setEditedItem(item);
  };

  const readJsonFile = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = event => {
      if (event.target) {
        resolve(JSON.parse(event.target.result))
      }
    }

    fileReader.onerror = error => reject(error)
    fileReader.readAsText(file)
  })


  const handleFile = async (event) => {
    if (event.target.files) {
      if (sourceFileType === 0) {
        const parsedData = await readJsonFile(event.target.files[0])
        setSourceSchema(createSchema(parsedData));
      } else if (sourceFileType === 1) {
        const parsedData = await readJsonFile(event.target.files[0])
        setSourceSchema(parsedData);
      } else if (sourceFileType === 2) {
        Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setSourceSchema(createSchema(results.data));
          },
        });
      }
    }
  }

  useEffect(() => {
    //console.log(sourceSchema);
    if (sourceSchema?.type === "array") {
      setSource(sourceSchema?.items?.properties);
    } else if (sourceSchema?.type === "object") {
      if (sourceSchema?.properties) {
        setSource(sourceSchema?.properties);
      }
    } else {
      setSource([])
    }
  }, [sourceSchema])

  const selectParameter = (index) => {
    if (index === selected) {
      setIsSelected(false);
      setSelected(-1)
    } else {
      setIsSelected(true);
      setSelected(index);
    }
  }

  const handleBimroomSchemaChange = (event) => {
    const confirmBox = window.confirm(
      "All mapped items will be erased. Do you really want to continue?"
    )
    if (confirmBox === true) {
      setBimroomSchema(event.target.value)
    }
  }

  const handleFileTypeChange = (event) => {

    setSourceFileType(event.target.value)
    setSourceSchema(null)
  }

  const getSelected = (index) => {
    
    if (isSelected && index === selected) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (sourceFileType === 0 || sourceFileType === 1) {
      setSourceMimeType(".json, application/json")
    } else if (sourceFileType === 2) {
      setSourceMimeType("text/csv")
    } else if (sourceFileType === 3) {
      setSourceMimeType("application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    } else {
      setSourceMimeType(".json, application/json")
    }
  }, [sourceFileType])

  const handleAddNew = () => {
    setAddNew(!addNew);
  }
 

  return (
    <>  
      <Box sx={{mb: 2}}>
        <TableContainer sx={{borderRadius: 1, mb: 2}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  ID
                </TableCell>
                <TableCell>
                  Data mapper name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  123
                </TableCell>
                <TableCell>
                  Data mapper #123
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        
        <Button 
          disabled={addNew} 
          variant="contained" 
          startIcon={<Plus />}
          onClick={handleAddNew}
        >
          Add a new mapper
        </Button>

      </Box>
      <Box sx={{
        display: addNew ? "block" : "none",
        border: "1px solid #175c831A",
        borderRadius: 1
      }}>
        <Box sx={{
          p: 1
        }}>
          <Box sx={{
            height: 50, 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center"
          }}>
            
            <Typography variant="subtitle2" sx={{mt: 1, fontSize: 16}}>
              New data mapper
            </Typography>
          </Box>
          <Grid container spacing={1} sx={{my: 1}}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="mapperName"
                label="Data mapper name"
                sx={{ flexGrow: 1 }}
                name="mapperName"
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                // value={formik.values.companyName}
              />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{
                height: 52, 
                display: "flex", 
                //justifyContent: "center", 
                alignItems: "center"
              }}>
                <FormGroup>
                  <FormControlLabel 
                    control={<Checkbox />} label="Import rest as custom" 
                  />
                </FormGroup>
              </Box>
            </Grid>
          </Grid>
          <Grid container>        
            <Grid item xs={12} sm={5} sx={{
              my: 1,
              border: "1px solid #175c831A",
              borderRadius: 1,
              py: 2,
              px: 0.5
            }}>
              <Grid container spacing={1} sx={{}}>
                <Grid item xs={6}>
                  <FormControl fullWidth >
                    <InputLabel id="select-file-type-input-label">File type</InputLabel>
                    <Select
                      labelId="select-file-type-input-label"
                      id="select-file-type"
                      value={sourceFileType}
                      label="File type"
                      onChange={handleFileTypeChange}
                      sx={{
                        height: 52,
                        mb: "26px"
                      }}
                    >
                      <MenuItem value={0} key={"json-data"}>JSON</MenuItem>
                      <MenuItem value={1} key={"json-schema"}>JSON schema</MenuItem>
                      <MenuItem value={2} key={"csv-data"}>CSV</MenuItem>
                      <MenuItem value={3} key={"excel-data"}>Excel</MenuItem>
                      {/* <MenuItem value={1}>BON v2</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{
                    width: "100%",
                    height: 50,
                    display: "flex",
                    //justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #fff",
                    borderRadius: 1
                  }}>
                    <input
                      type="file" 
                      accept={sourceMimeType}
                      onChange={handleFile}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{
                width: "100%",
                height: 50,
                color: '#175C83',
                border: "1px solid #175C83",
                borderRadius: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: '#fff'
              }}>
                <Typography variant="subtitle2">Your parameters</Typography>
              </Box>
              <Box sx={{
                maxHeight: 400,
                overflowY: "auto"
              }}>
                <List dense={dense}>
                  {source && Object.keys(source).map((item, index) => {
                    const newIndex = "source-" + index;
                    return (
                      <SourceList key={newIndex} index={newIndex} sourceJson={source} item={item} getSelected={getSelected} selectParameter={selectParameter} />   
                    )
                  }
                )}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} sx={{my: 1}}>
              <Box sx={{mx: 1, display: "flex", height: "100%", justifyContent: "center", alignItems: "center"}}>
                {/* <Button startIcon={<LinkIcon />} variant="outlined">AUTO</Button> */}
                <Button variant="outlined">AUTO MATCH</Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} sx={{
              my: 1,
              border: "1px solid #175c831A",
              borderRadius: 1,
              py: 2,
              px: 0.5
            }}>
              <FormControl fullWidth>
                <InputLabel id="select-bimroom-schema-input-label">Bimroom schema</InputLabel>
                <Select
                  labelId="select-bimroom-schema-input-label"
                  id="select-bimroom-schema"
                  value={bimroomSchema}
                  label="Bimroom schema"
                  disabled
                  onChange={handleBimroomSchemaChange}
                  sx={{
                    height: 52,
                    mb: "26px"
                  }}
                >
                  <MenuItem value={0} key={"bon-v1"}>BON v1</MenuItem>
                  {/* <MenuItem value={1}>BON v2</MenuItem> */}
                </Select>
              </FormControl>
              <Box sx={{
                width: "100%",
                height: 50,
                backgroundColor: '#175C83',
                border: "1px solid #fff",
                borderRadius: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: '#fff'
              }}>
                <Typography variant="subtitle2">Bimroom parameters</Typography>
              </Box>
              <Box sx={{
                maxHeight: 400,
                overflowY: "auto"
              }}>
                <List dense={dense}>
                  {bonSchema && Object.keys(bonSchema).map((item, index) => {
                    const newIndex = "destination-" + index;
                    return (
                    <DestinationList destinationJson={bonSchema} item={item} key={newIndex} index={newIndex} isSelected={isSelected} />
                    )} 
                  )}
                </List>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "end", height: 50}}>
            <Typography variant="subtitle2">
              Mapped items
            </Typography>
          </Box>
          <TableContainer sx={{
            my: 2,
            borderRadius: 1,
            border: "1px solid #175c831A"
          }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>

                  </TableCell>
                  <TableCell>
                    From
                  </TableCell>
                  <TableCell>
                    To
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mapper?.mappedItems.map((item, index) => {
                  const open = item.id === openItem;

                  return (
                    <Fragment key={item.id}>
                      <TableRow
                        hover
                        key={item.id}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            ...(open && {
                              position: 'relative',
                              '&:after': {
                                position: 'absolute',
                                content: '" "',
                                top: 0,
                                left: 0,
                                backgroundColor: 'primary.main',
                                width: 3,
                                height: 'calc(100% + 1px)'
                              }
                            })
                          }}
                          width="35%"
                        >
                          <IconButton onClick={() => handleOpenItem(item.id)}>
                            {open
                              ? <ChevronDownIcon fontSize="small" />
                              : <ChevronRightIcon fontSize="small" />}
                          </IconButton>
                        </TableCell>
                        <TableCell width="25%">
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                            onClick={() => handleOpenItem(item.id)}
                          >
                            <Box
                              sx={{
                                cursor: 'pointer',
                                ml: 2
                              }}
                              onClick={() => handleOpenItem(item.id)}
                            >
                              <Typography variant="subtitle2">
                                {item.from}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                        </TableCell>
                      </TableRow>
                      {open && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            sx={{
                              p: 0,
                              position: 'relative',
                              '&:after': {
                                position: 'absolute',
                                content: '" "',
                                top: 0,
                                left: 0,
                                backgroundColor: 'primary.main',
                                width: 3,
                                height: 'calc(100% + 1px)'
                              }
                            }}
                          >
                            <Divider />
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                px: 2,
                                py: 1
                              }}
                            >
                              <Button
                              //   onClick={() => navigate({
                              //     pathname: "/item/form",
                              //     search: createSearchParams({
                              //         mode: "edit",
                              //         id: item.itemId
                              //     }).toString()
                              // })}
                                sx={{ m: 1 }}
                                //type="submit"
                                variant="contained"
                              >
                                Edit
                              </Button>

                              <Dialog
                                fullScreen
                                //open={openModal}
                                //onClose={handleCloseEdit}
                                //TransitionComponent={Transition}
                              >
                                <AppBar sx={{ position: 'relative' }}>
                                  <Toolbar>
                                    <IconButton
                                      edge="start"
                                      color="inherit"
                                    // onClick={handleCloseEdit}
                                      aria-label="close"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                      Edit item
                                    </Typography>
                                    {/* <Button autoFocus color="inherit" onClick={handleCloseEdit}>
                                      save
                                    </Button> */}
                                  </Toolbar>
                                </AppBar>
                              </Dialog>
                              <Button
                                //onClick={handleCancelEdit}
                                sx={{ m: 1 }}
                                variant="outlined"
                              >
                                Cancel
                              </Button>
                              <Button
                                //onClick={handleDeleteItem}
                                onClick={() => {
                                  const confirmBox = window.confirm(
                                    "Do you really want to delete this item?"
                                  )
                                  if (confirmBox === true) {
                                    //handleDeleteItem()
                                  }
                                }}
                                color="error"
                                sx={{
                                  m: 1,
                                  ml: 'auto'
                                }}
                              >
                                Delete item
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                )})}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={handleAddNew}>
                Save data mapper
              </Button>
            </Grid>
            <Grid item>
              <Button color="error" variant="contained" onClick={handleAddNew}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
