import { 
  useState 
  // useEffect, 
  // useLayoutEffect, 
  // useRef 
} from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { 
  useFormik, 
  FormikProvider, 
  // Field, 
  // Form, 
  FastField } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormGroup,
  Checkbox,
  //CheckboxWithLabel,
  FormControl,
  FormLabel,
  // FormControlLabel,
  // FormHelperText,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  //Switch,
  TextField,
  // TextFieldProps,
  Tooltip,
  Typography,
  // InputLabel,
  // Select
} from '@mui/material';
import { FileDropzone } from '../file-dropzone';
import { categoryOptions, getCategoryIndex, getSubcategoryIndex } from '../../data/categories';
import { roomOptions, usageOptions, initialData } from '../../data/formOptions';
import { europeList, africaList, antarcticaList, asiaList, northAmericaList, southAmericaList, oceaniaList, countryList, showCountries } from '../../data/countries';
import { v4 as uuidv4 } from 'uuid';

export const ProductEditForm = (props) => {
  //const router = useRouter();
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  // const [toggle, setToggle] = useState();
  const formik = useFormik({
    initialValues: props.productdata,
    validationSchema: Yup.object({
      barcode: Yup.string().max(255),
      brand: Yup.string().max(255),
      category: Yup.string().max(255).required(),
      subcategory1: Yup.string().max(255),
      subcategory2: Yup.string().max(255),
      description: Yup.string().max(5000),
      images: Yup.array(),
      name: Yup.string().max(255).required(),
      sku: Yup.string().max(255).required()
    }),
    enableReinitialize: true,
    validateOnChange: validateAfterSubmit,
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        setValidateAfterSubmit(true);
       // console.log("testing 2");
        // const { endpoint, key, database, container } = azureCosmosConfig;
  
        // const client = new CosmosClient({ 
        //   endpoint, 
        //   key,
        //   connectionPolicy: {
        //     enableEndpointDiscovery: false
        //   } 
        // });
  
        // const databaseID = client.database(database);
        // const containerID = databaseID.container(container);

        // values.id = uuidv4();

        // const { resource: createdItem } = await containerID.items.create(values);
        // console.log(`\r\nCreated new item: ${createdItem.id} - ${createdItem.name}\r\n`);
        // const oldId = values.id;
        values.id = uuidv4();
        
        // const createProductResponse = await createProduct(values, "bimroomTesti");

        // if (createProductResponse.status === 200) {
        //   await updateStatus("bimroomTesti", oldId, values.sku, "deleted")
        //     .then((data) => {
        //       console.log(data);
        //     })
        //     .catch((err) => console.log("Error in status update"));
        // };
        
        // props.handlecloseedit();
        
        toast.success('Product edited!');
        
        //router.push('/dashboard/products').catch(console.error);
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const uploadFileToBlob = (file) => {
    console.log(file);
    //upload(file);
  };


  const handleDrop = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleDrop2 = (newImages) => {
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleRemove2 = (file) => {
    setImages((prevImages) => prevImages.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll2 = () => {
    setImages([]);
  };

  const handleImageUpload = () => {
    images.map((img) => 
    // AZURE FILE SHARE API CALL HERE
    // sendFile(img);
    uploadFileToBlob(img)
    //formik.values.images.push(img)
    );
    //console.log(formik.values.images);
  };

  const handleFileUpload = () => {
    files.map((file) => 
    // AZURE FILE SHARE API CALL HERE
    // sendFile(file);
    uploadFileToBlob(file)
    );
    //console.log(formik.values.files);
  };


  function showSubcategory() {
    let length = categoryOptions[getCategoryIndex(formik.values.category)].subcategory.length;
    if (length === 1) {
      formik.values.subcategory1 = '';
      return 'none';
    } else {
      return 'block';
    }
  }

  function showSubcategory2() {
    let length = categoryOptions[getCategoryIndex(formik.values.category)].subcategory[getSubcategoryIndex(formik.values.category,formik.values.subcategory1)].subcategory.length;
    if (length === 1) {
      formik.values.subcategory2 = '';
      return 'none';
    } else {
      return 'block';
    }
  }

  function checkSubcategory(level, value) {
    if (level === 1) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.category)].subcategory.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.subcategory1;
      } else {
        formik.values.subcategory1 = '';
        return formik.values.subcategory1;
      }
    } else if (level === 2) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.category)].subcategory[getSubcategoryIndex(formik.values.category,formik.values.subcategory1)].subcategory.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.subcategory2;
      } else {
        formik.values.subcategory2 = '';
        return formik.values.subcategory2;
      }
    } else {
      return value;
    }
  }
  


//   const formOutput = (val) => {
//     if (val === true) {
  return (
    <form onSubmit={formik.handleSubmit} {...props}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Basic details</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label="Product name"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <TextField
                error={Boolean(formik.touched.brand && formik.errors.brand)}
                fullWidth
                label="Brand"
                name="brand"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.brand}
              />
              <TextField
                error={Boolean(formik.touched.model && formik.errors.model)}
                fullWidth
                helperText={formik.touched.model && formik.errors.model}
                label="Model name"
                name="model"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.model}
              />
              {/* <FormikProvider value={formik}>
                <FastField name="barcode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.barcode} />
              </FormikProvider>
               <TextField
                disabled
                error={Boolean(formik.touched.barcode && formik.errors.barcode)}
                fullWidth
                label="Barcode"
                name="barcode"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.barcode}
              /> */}

              <TextField
                error={Boolean(formik.touched.sku && formik.errors.sku)}
                fullWidth
                label="SKU"
                name="sku"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.sku}
              />           
              <TextField
                error={Boolean(formik.touched.countryOfManufacture && formik.errors.countryOfManufacture)}
                fullWidth
                label="Country of Manufacture"
                name="countryOfManufacture"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                select
                value={formik.values.countryOfManufacture}
              > 
              {countryList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 2,
                  mt: 3,
                }}
                variant="subtitle2"
              >
                Description
              </Typography>
              {/* <QuillEditor
                onChange={(value) => {
                  formik.setFieldValue("description", value);
                }}
                placeholder="Write something"
                sx={{ height: 400 }}
                value={formik.values.description}
              />
              {Boolean(
                formik.touched.description && formik.errors.description
              ) && (
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>
                    {formik.errors.description}
                  </FormHelperText>
                </Box>
              )}  */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Category</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.category && formik.errors.category
                )}
                fullWidth
                label="Category"
                name="category"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                select
                value={formik.values.category}
              >
                {categoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={Boolean(
                  formik.touched.subcategory1 && formik.errors.subcategory1
                )}
                fullWidth
                label="Subcategory 1"
                name="subcategory1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2, display: showSubcategory() }}
                select
                value={checkSubcategory(1, formik.values.subcategory1)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.category)
                ].subcategory.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                error={Boolean(
                  formik.touched.subcategory2 && formik.errors.subcategory2
                )}
                fullWidth
                label="Subcategory 2"
                name="subcategory2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2, display: showSubcategory2() }}
                select
                value={checkSubcategory(2, formik.values.subcategory2)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.category)
                ].subcategory[
                  getSubcategoryIndex(formik.values.category, formik.values.subcategory1)
                ].subcategory.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Market Area</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Product availability
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableEurope"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">Europe</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      display: showCountries(formik.values.availableEurope),
                    }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "europe",
                            europeList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("europe", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(formik.values.availableEurope),
                    }}
                  >
                    {europeList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="europe"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableAfrica"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">Africa</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      display: showCountries(formik.values.availableAfrica),
                    }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "africa",
                            africaList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("africa", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(formik.values.availableAfrica),
                    }}
                  >
                    {africaList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="africa"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableAsia"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">Asia</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{ display: showCountries(formik.values.availableAsia) }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "asia",
                            asiaList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("asia", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(formik.values.availableAsia),
                    }}
                  >
                    {asiaList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="asia"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableOceania"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">Oceania</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      display: showCountries(formik.values.availableOceania),
                    }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "oceania",
                            oceaniaList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("oceania", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(formik.values.availableOceania),
                    }}
                  >
                    {oceaniaList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="oceania"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableAntarctica"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">Antarctica</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      display: showCountries(formik.values.availableAntarctica),
                    }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "antarctica",
                            antarcticaList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("antarctica", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(formik.values.availableAntarctica),
                    }}
                  >
                    {antarcticaList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="antarctica"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableNorthAmerica"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">North America</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      display: showCountries(
                        formik.values.availableNorthAmerica
                      ),
                    }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "northAmerica",
                            northAmericaList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("northAmerica", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(
                        formik.values.availableNorthAmerica
                      ),
                    }}
                  >
                    {northAmericaList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="northAmerica"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
              <FormikProvider value={formik}>
                <FormControl
                  component="fieldset"
                  style={{ display: "flex", marginBottom: "15px" }}
                >
                  <FormGroup row={true}>
                    <FastField
                      type="checkbox"
                      name="availableSouthAmerica"
                      style={{ cursor: "pointer" }}
                    />
                    <FormLabel component="legend">South America</FormLabel>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      display: showCountries(
                        formik.values.availableSouthAmerica
                      ),
                    }}
                  >
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() =>
                          formik.setFieldValue(
                            "southAmerica",
                            southAmericaList.map((opt) => opt.value)
                          )
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Select All
                      </span>
                    </Link>
                    <Link
                      underline="none"
                      style={{
                        color: "neutral.400",
                        fontSize: "14px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => formik.setFieldValue("southAmerica", [])}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        Unselect All
                      </span>
                    </Link>
                  </FormGroup>
                  <FormGroup
                    row={true}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "7px",
                      padding: "10px",
                      display: showCountries(
                        formik.values.availableSouthAmerica
                      ),
                    }}
                  >
                    {southAmericaList.map((opt) => (
                      <Tooltip title={opt.label} key={opt.value}>
                        <FormLabel
                          style={{
                            marginRight: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            width: "19%",
                            fontSize: "14px",
                          }}
                        >
                          <FastField
                            className="countryField"
                            type="checkbox"
                            component={Checkbox}
                            name="southAmerica"
                            key={opt.value}
                            value={opt.value}
                            label={opt.label}
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          />
                          {opt.label}
                        </FormLabel>
                      </Tooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </FormikProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/*       model: '',
      depthInMm: '',
      widthInMm: '',
      heightInMm: '',
      thicknessInMm: '',
      weightInKg: '',
      primaryMaterial: '',
      secondaryMaterial: '',*/}
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Filters</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
            <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 0,
                }}
                variant="subtitle2"
              >
                Usage
            </Typography>
            <FormikProvider value={formik}>
              <FormControl fullWidth>
                <FormGroup
                  row={true}
                  sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: "7px",
                    padding: "10px",
                    display: "block"
                  }}
                >
                  {usageOptions.map((opt) => (
                    <Tooltip title={opt.label} key={opt.value}>
                      <FormLabel
                        style={{
                          marginRight: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          width: "24%",
                          fontSize: "14px",
                        }}
                      >
                        <FastField
                          type="checkbox"
                          component={Checkbox}
                          name="usage"
                          key={opt.value}
                          value={opt.value}
                          label={opt.label}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                        {opt.label}
                      </FormLabel>
                    </Tooltip>
                  ))}
                </FormGroup>
              </FormControl>
            </FormikProvider>
            <FormikProvider value={formik}>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                Room
              </Typography>
              <FormControl fullWidth>
                <FormGroup
                  row={true}
                  sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: "7px",
                    padding: "10px",
                    display: "block"
                  }}
                >
                  {roomOptions.map((opt) => (
                    <Tooltip title={opt.label} key={opt.value}>
                      <FormLabel
                        style={{
                          marginRight: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          width: "24%",
                          fontSize: "14px",
                        }}
                      >
                        <FastField
                          type="checkbox"
                          component={Checkbox}
                          name="room"
                          key={opt.value}
                          value={opt.value}
                          label={opt.label}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                        {opt.label}
                      </FormLabel>
                    </Tooltip>
                  ))}
                </FormGroup>
              </FormControl>
            </FormikProvider>
            <TextField
                error={Boolean(formik.touched.energyEfficiencyClass && formik.errors.energyEfficiencyClass)}
                fullWidth
                label="Energy efficiency class"
                name="energyEfficiencyClass"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                select
                value={formik.values.energyEfficiencyClass}
              > 
              {countryList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={Boolean(formik.touched.thermalTransmittance && formik.errors.thermalTransmittance)}
                fullWidth
                label="Thermal transmittance (U)"
                name="thermalTransmittance"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">W/m²K</InputAdornment>,
                }}
                sx={{ mt: 2 }}
                value={formik.values.thermalTransmittance}
              />
              <TextField
                error={Boolean(formik.touched.soundReductionIndex && formik.errors.soundReductionIndex)}
                fullWidth
                label="Sound reduction index (Rw)"
                name="soundReductionIndex"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">dB</InputAdornment>,
                }}
                sx={{ mt: 2 }}
                value={formik.values.thermalTransmittance}
              />
              <TextField
                error={Boolean(formik.touched.fireRatingMaterials && formik.errors.fireRatingMaterials)}
                fullWidth
                label="Fire rating (materials)"
                name="fireRatingMaterials"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.fireRatingMaterials}
              />
              <TextField
                error={Boolean(formik.touched.fireRatingObjects && formik.errors.fireRatingObjects)}
                fullWidth
                label="Fire rating (objects)"
                name="fireRatingObjects"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.fireRatingObjects}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Product dimensions</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(formik.touched.dephtInMm && formik.errors.dephtInMm)}
                fullWidth
                label="Depth"
                name="dephtInMm"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                value={formik.values.dephtInMm}
              />
              <TextField
                error={Boolean(formik.touched.widthInMm && formik.errors.widthInMm)}
                fullWidth
                label="Width"
                name="widthInMm"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                sx={{ mt: 2 }}
                value={formik.values.widthInMm}
              />
              <TextField
                error={Boolean(formik.touched.heightInMm && formik.errors.heightInMm)}
                fullWidth
                label="Height"
                name="heightInMm"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                sx={{ mt: 2 }}
                value={formik.values.heightInMm}
              />
              <TextField
                error={Boolean(formik.touched.thicknessInMm && formik.errors.thicknessInMm)}
                fullWidth
                label="Thickness"
                name="thicknessInMm"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                }}
                sx={{ mt: 2 }}
                value={formik.values.thicknessInMm}
              />
              <TextField
                error={Boolean(formik.touched.www && formik.errors.www)}
                fullWidth
                label="WWW"
                name="www"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 2 }}
                value={formik.values.www}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Images</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload product images.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <FileDropzone
                accept="image/*"
                files={images}
                onDrop={handleDrop2}
                onRemove={handleRemove2}
                onRemoveAll={handleRemoveAll2}
                onUpload={handleImageUpload}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Files</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload files.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <FileDropzone
                files={files}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
                onUpload={handleFileUpload}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          mx: -1,
          mb: -1,
          mt: 3,
        }}
      >
        <Button
          color="error"
          sx={{
            m: 1,
            mr: "auto",
          }}
        >
          Delete
        </Button>
        <Button sx={{ m: 1 }} variant="outlined">
          Cancel
        </Button>
        <Button
          sx={{ m: 1 }}
          type="submit"
          variant="contained"
          
          // onClick={() => {
          //   console.log(formik.values);
          //   console.log(JSON.stringify(formik.values));
          //   console.log(images);
          //   setValidateAfterSubmit(true);}}
        >
          Update
        </Button>
      </Box>
    </form>
  )
//           } else {
//               return (
//                 <form>

//                 </form>
//               )
//           }
//  };

// return(formOutput(isMounted.current));

};