import { Autocomplete, Box, Button, Card, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { 
  countryList
} from '../../data/countries';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //minWidth: 400,
  width: "80vw",
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "auto"
};



export const AddContactModal = (props) => {
  const { formikBrand, handleClose } = props;

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      country:  '',
      email: '',
      phoneNumber: '',
      areas: [],
      submit: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      name: Yup.string().max(255),
      address1: Yup.string().max(255),
      address2: Yup.string().max(255),
      city: Yup.string().max(255),
      zip: Yup.string().max(255),
      country: Yup.string().max(255),
      email: Yup.string(),
      phoneNumber: Yup.string().max(255),
      areas: Yup.array()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        try {
        //  const updatedInfo = await updateManufacturerInfo(updateBillingData);
          // let newContacts = formikBrand.values.publicContacts;
          // newContacts.push(values);
          // formikBrand.setFieldValue("publicContacts", newContacts);
          // push to the formikBrand values

          


          // console.log(newContacts);
        } catch (err) {
          console.error(err);
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        // toast.success('Contract sent!');
        // await wait(500);
        handleClose();
        helpers.resetForm();
      } catch (err) {
        console.error(err);
        //toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });


  return (
    <Box sx={style}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">Add a contact person</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
              fullWidth
              label="Address 1"
              name="address1"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 1 }}
              value={formik.values.address1}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
              fullWidth
              label="Address 2"
              name="address2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 1 }}
              value={formik.values.address2}
            />
            <Grid container spacing={1}>
              <Grid 
                item 
                xs={8}
              >
                <TextField
                  //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                  fullWidth
                  label="City"
                  name="city"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{ mt: 1 }}
                  value={formik.values.city}
                />
              </Grid>
              <Grid 
                item 
                xs={4}
              >
                <TextField
                  //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                  fullWidth
                  label="ZIP"
                  name="zip"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{ mt: 1 }}
                  value={formik.values.zip}
                />
              </Grid>  
            </Grid>
            <TextField
              //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
              fullWidth
              label="Country"
              name="country"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 1 }}
              value={formik.values.country}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
              fullWidth
              label="Phone number"
              name="phoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 1 }}
              value={formik.values.phoneNumber}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
              fullWidth
              label="Email"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 1 }}
              value={formik.values.email}
            />
            <Autocomplete
              multiple
              id="tags-outlined"
              options={countryList}
              sx={{mt: 1}}
              getOptionLabel={(option) => option.label}
              //defaultValue={[top100Films[13]]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Areas represented"
                  placeholder="No countries selected"
                />
              )}
            />
            <Button 
              sx={{mt: 2}} 
              type="submit" 
              variant="contained"
            >
              Add contact
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
};