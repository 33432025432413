import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { portalUpdateBrand, unpublishBrand } from "../../api";

const BrandPageStatus = (props) => {
    const { formik, updated, setUpdated, changesMade } = props;

    const handleUnpublish = async (data) => {
        data["status"] = "unpublished";
        const response = await portalUpdateBrand(data);
        if (response) {
          const payload = {
            brandId: data.brandId
          }
          const unpublished = await unpublishBrand(payload);
          if (unpublished) {
            setUpdated(!updated);
          }
        }
    }

    return (
        <>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Status</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="subtitle2">{formik.values?.status}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Last published</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="subtitle2">{formik.values?.lastUpdated}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Live URL</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  {formik.values?.status === "published" ? 
                    <Link variant="subtitle2" href={"https://bimroom.com/brand/" + formik.values?.brandSlug}>
                      https://bimroom.com/brand/{formik.values?.brandSlug}
                    </Link> :
                    <Typography variant="subtitle2">Not published yet</Typography>
                  }
                </Grid>
                <Grid item xs={12} sx={{mt: 2}}>
                  <Grid container spacing={1} sx={{display: "flex", alignItems: "center", justifyContent: "center", border: "1px dashed #175c83", borderRadius: 1, padding: 2, backgroundColor: '#175c831A'}}>
                    <Grid item xs={12} sx={{mb: 1}}>
                      <Typography variant="subtitle2">Publishing will make your brand page live on bimroom.com</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {
                        formik.values?.status === "published" && 
                          <>
                              <Button fullWidth onClick={() => handleUnpublish(formik.values)} variant="contained" color="error">Unpublish</Button>
                          </>
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Button type="submit" fullWidth variant="contained">{formik.values?.status === "published" ? "Publish changes" : "Publish"}</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
        </>
    )
}

export default BrandPageStatus;
