import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import toast from 'react-hot-toast';
import { wait } from '../../utils/wait';
import { FileDropzone } from '../file-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { portalModelling, portalModellingRequest, upload } from '../../api';
import { FileDropzoneSmall } from '../file-dropzone-small';

const Modelling = (props) => {
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const [requests, setRequests] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: '',
      brandName: selectedBrand?.brandName || '',
      mainProfileId: '',
      date: '',
      email: '',
      noOfProducts: 0,
      description: '',
      status: '',
      files: [],
      submit: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      brandName: Yup.string().max(255),
      mainProfileId: Yup.string().max(255).required(),
      date: Yup.string().max(255),
      email: Yup.string().max(255),
      status: Yup.string().max(255),
      noOfProducts: Yup.number(),
      description: Yup.string().max(255),
      files: Yup.array()
      //contactDate: Yup.string().max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        const response = await portalModellingRequest(values);
        if (response) {
          helpers.resetForm();
          toast.success('Modelling request sent!');
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          setUploadedFiles([]);
          updateRefresh();
        }
        // console.log(values);
        // alert('Changes saved')
        
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const ids = new Set(formik.values.files.map(d => d?.newName));
    const merged = [...formik.values.files, ...uploadedFiles.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("files", merged);
    const existingValues = uploadedFiles.map(item => item.name);
    setFiles(() => files.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedFiles])


  useEffect(() => {
    formik.setFieldValue("brandName", selectedBrand?.brandName)
    formik.setFieldValue("brandId", selectedBrand?.brandId)
  }, [selectedBrand])

  const getModelling = async () => {
    try {
      if (selectedBrand?.brandId) {
        const response = await portalModelling({
          mainProfileId: mainProfileId,
          status: 'requested'
        });
        setRequests(response);
        //console.log(products);
      }
    } catch (err) {
      console.error(err);
    }
  };
  //}, [isMounted]);

  useEffect(() => {
      getModelling();
      formik.setFieldValue('id', uuidv4());
      formik.setFieldValue('status', 'requested');
      formik.setFieldValue('mainProfileId', mainProfileId)
      formik.setFieldValue('date', new Date());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh]);



  const updateRefresh = () => {
    setRefresh(!refresh);
  }
  
  const handleDrop = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleDeleteFile = (name) => {
    let array = formik.values.files;
    const objIndex = array.findIndex((obj => obj.newName == name));
    array[objIndex].deleted = true;
    formik.setFieldValue("files", array)
  }

  const uploadFileToBlob = async (file, id, folder) => {
      const newFile = new File([file], id, {
        type: file?.type,
      });
      // console.log(newFile);
      const response = await upload(newFile, folder);
      return response;
  };

  const handleUpload = () => {
    files.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4()
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "modelling").then(res => {
          if (res.status === 200) {
            setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
          }
        });
        
      } catch (error) {
        console.error(error);
      }

    });
  };


  return (
    <form
    onSubmit={formik.handleSubmit}
    // {...other}
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          minHeight: "calc(100vh - 48px)"
        }}
        {...props}>
        <Card sx={{mt: 1}}>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Typography variant="h6">
                  Requests
                </Typography>
                <Typography sx={{mt: 1}} variant="body2">
                  
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
              >
                <Table>
                  <TableHead>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Request id
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Date
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Offer
                    </TableCell>
                  </TableHead>
                    {requests.length === 0 && 
                      <TableRow>
                        <TableCell align="center" sx={{height: "64px"}} colSpan={4}>
                          No modelling requests
                        </TableCell>
                      </TableRow>
                    }
                    {requests.map((request, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {request.id}
                        </TableCell>
                        <TableCell align="center">
                          {request.date}
                        </TableCell>
                        <TableCell align="center">
                          {request.status}
                        </TableCell>
                        <TableCell align="center">
                          {/* {request.date} */}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Typography variant="h6">
                  Deliveries
                </Typography>
                <Typography sx={{mt: 1}} variant="body2">
                  
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
              >
                <Table>
                  <TableHead>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Date
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Request number
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Download
                    </TableCell>
                  </TableHead>
                    {deliveries.length === 0 && 
                      <TableRow>
                        <TableCell align="center" sx={{height: "64px"}} colSpan={4}>
                          No deliveries
                        </TableCell>
                      </TableRow>
                    }
                    {deliveries.map((delivery, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {delivery.date}
                        </TableCell>
                        <TableCell align="center">
                          {delivery.invoiceId}
                        </TableCell>
                        <TableCell align="center">
                          {delivery.status}
                        </TableCell>
                        <TableCell align="center">
                          {/* {delivery.date} */}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Typography variant="h6">
                  Add a new request
                </Typography>
                <Typography sx={{mt: 1}} variant="body2">
                  Get a quote by filling this form.
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
                //align="right"
              >
                {/* <Divider /> */}
                <Box
                  sx={{
                    display: 'flex',
                    //mt: 2,
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    id="brandName"
                    label="Brand name"
                    size="small"
                    sx={{ flexGrow: 1 }}
                    name="brandName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.brandName}
                  />

                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    mt: 2,
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    id="noOfProducts"
                    label="Number of products"
                    size="small"
                    type="number"
                    sx={{ flexGrow: 1 }}
                    name="noOfProducts"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.noOfProducts}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    mt: 2,
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    id="email"
                    label="Email"
                    size="small"
                    type="number"
                    sx={{ flexGrow: 1 }}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    mt: 2,
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    minRows={4}
                    size="small"
                    sx={{ flexGrow: 1, mb: 2 }}
                    name="description"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </Box>
                <FileDropzoneSmall
                  accept="*"
                  files={files}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  onUpload={handleUpload}
                />
                <FormikProvider value={formik}>
                  <FieldArray name="files">
                    {() => (
                      <div>
                        {formik.values.files.map((p, index) => {
                          const name = `files[${index}].name`;
                          const info = `files[${index}].info`;

                          return (
                            !p.deleted &&  <Box key={p.newName}>
                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  <TextField
                                    margin="dense"
                                    variant="outlined"
                                    label="File name"
                                    name={name}
                                    value={p.name}
                                    disabled
                                    fullWidth
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    margin="dense"
                                    variant="outlined"
                                    label="Info"
                              
                                    name={info}
                                    value={p.info}
                                    fullWidth
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={2} sx={{
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center"
                                }}>
                                  <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteFile(p?.newName)}>Delete</Button>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>
                </FormikProvider>
              </Grid>
              <Grid
                item
                xs={12}
                align="right"
              >
                <Button type="submit" variant="contained">
                  Send
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </form>
  );
};

export default Modelling;