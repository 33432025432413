import React, { useEffect, useState } from "react";
import { useSearchParams} from 'react-router-dom';

// components
import ProductFormV2 from "../components/ProductFormV2";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import useDocumentTitle from "../useDocumentTitle";
import { BrandGuard } from "../components/brand-guard";

const ProductFormPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [productId, setProductId] = useState("");
  const [mode, setMode] = useState("new");
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom Portal - Product form');


  useEffect(() => {
    const isModeSet = searchParams.get("mode");
    const isProductIdSet = searchParams.get("id");

    if (isModeSet) {
      setMode(isModeSet);
      setProductId(isProductIdSet);
    }

  }, [mode])

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
            <DashboardLayout header="Product form">
              <BrandGuard>
                <ProductFormV2 /> 
              </BrandGuard>
            </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ProductFormPage;