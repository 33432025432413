import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { Scrollbar } from '../../scrollbar';
import { format } from 'date-fns';


export const SubscriptionListTable = (props) => {
  const {
    subscriptions,
    subscriptionCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const navigate = useNavigate();

  // Reset selected subscriptions when subscriptions change
  useEffect(() => {
      if (selectedSubscriptions.length) {
        setSelectedSubscriptions([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptions]);

  const handleSelectAllSubscriptions = (event) => {
    setSelectedSubscriptions(event.target.checked
      ? subscriptions.map((subscription) => subscription.id)
      : []);
  };

  const getColor = (status) => {

    if (status === "subscribed") {
      return "success.main";
    } else if (["contacted","subscription expired"].includes(status)) {
      return "warning.main";
    } else if (["not interested"].includes(status)) {
      return "error.main";
    } else {
      return "#000";
    }
  }

  const enableBulkActions = selectedSubscriptions.length > 0;
  const selectedSomeSubscriptions = selectedSubscriptions.length > 0
    && selectedSubscriptions.length < subscriptions.length;
  const selectedAllSubscriptions = selectedSubscriptions.length === subscriptions.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllSubscriptions}
          indeterminate={selectedSomeSubscriptions}
          onChange={handleSelectAllSubscriptions}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell>
                Subscription type
              </TableCell>
              <TableCell>
                Timespan
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Next renewal
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.length === 0 && <TableRow
              hover
              key="not-available"
              //sx={{cursor: "pointer"}}
            >
              <TableCell colSpan={5} sx={{height: "64px"}} align="center">No subscriptions available</TableCell>
            </TableRow>}
            {subscriptions.map((subscription) => {
              const isSubscriptionSelected = selectedSubscriptions.includes(subscription.id);

              return (
                <TableRow
                  hover
                  key={subscription.id}
                  selected={isSubscriptionSelected}
                  //sx={{cursor: "pointer"}}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                    >
                      {subscription?.type}
                    </Typography>
                    </Box>
                  </TableCell>
                  <TableCell >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {subscription?.timespan}
                    </Box>
                  </TableCell>
                  <TableCell >
                    <Typography
                      color={getColor(subscription.status)}
                      variant="subtitle2"
                    >
                      {subscription?.status}
                    </Typography>
                  </TableCell>
                  <TableCell >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {subscription?.nextRenewal}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton component="a">
                      <PencilAltIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={subscriptionCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

// SubscriptionListTable.propTypes = {
//   subscriptions: PropTypes.array.isRequired,
//   subscriptionsCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
