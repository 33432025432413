import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // query: '',
  // next: [],
  // previous: [],
  // status: [],
  // priority: [],
  filters: {
    query: '',
    next: [],
    previous: [],
    status: [],
    priority: [],
  },
  filterItems: []
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // setSearch: (state, action) => {
    //   state.search = action.payload;
    // },

    // setNext: (state, action) => {
    //   state.next = action.payload;
    // },

    // setPrevious: (state, action) => {
    //   state.previous = action.payload;
    // },

    // setStatus: (state, action) => {
    //   state.status = action.payload;
    // },

    // setPriority: (state, action) => {
    //   state.priority = action.payload;
    // },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setFilterItems: (state, action) => {
      state.filterItems = action.payload;
    },

    resetAllFilters: (state) => {
      // state.search = "";
      // state.next = [];
      // state.previous = [];
      // state.status = [];
      // state.priority = [];
      state.filters = {
        query: '',
        next: [],
        previous: [],
        status: [],
        priority: []
      }
    },

    // setSearchState: (state, action) => {
    //   state.searchState = action.payload;
    // },

    // setPreviousSearch: (state, action) => {
    //   state.previousSearch = action.payload;
    // },

    // setBrand: (state, action) => {
    //   state.brand = action.payload;
    // },

    // setCartProduct: (state, action) => {
    //   state.cartProduct = action.payload;
    // },

    // setRatingAndReview: (state, action) => {
    //   state.productRatingAndReview = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  // setSearchState,
  // setNext,
  // setPrevious,
  // setStatus,
  // setPriority,
  setFilters,
  setFilterItems,
  resetAllFilters,
} = customerSlice.actions;

export default customerSlice.reducer;
