import React from "react";
//import listOfBrands from "../assets/brand-images/brand_logos.json";
// import brand from "../../assets/images/product/brand.png";

const Brand = (props) => {
  const { product } = props;
  if (product?.brandId) {
    return (
      // <img src={`/brandImages/${brandImg}`} alt=""/>
      <img src={`https://cdn.bimroom.com/brand-images/${product?.brandId}`} alt=""/>
    )
  } else {
    return <img alt="" />
  }


};

export default Brand;
