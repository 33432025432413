import React from "react";

import "./unauthenticatedAccess.scss";
import { Logo } from "../logo";

import { loginRequest } from "../../authConfig";
import { 
  useMsal
  //useIsAuthenticated 
} from "@azure/msal-react";

const UnauthenticatedAccess = () => {
  const { instance, accounts } = useMsal();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
     <div style={{width: '100vw', height: '100vh'}}>    
      <div className="unauthenticated-section">
        <div className="unauthenticated-container">
          <div className="broom-logo">
            <Logo />
          </div>
          <div className="welcome-text">
            <br />
          </div>
          <div className="signin-btn" onClick={() => handleLogin(instance)}>
            Sign in
          </div>
        </div>
      </div>
    </div>

  );
};

export default UnauthenticatedAccess;
