import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { FileDropzone } from '../../file-dropzone';
import { upload } from '../../../api';
import { v4 as uuidv4} from 'uuid';
import { FieldArray, FormikProvider } from 'formik';
import { useMounted } from '../../../hooks/use-mounted';
import { FaTrash } from 'react-icons/fa';

const languages = [
  {id: "en", name: "English"},
  {id: "es", name: "Spanish"},
  {id: "fr", name: "French"},
  {id: "de", name: "German"},
  {id: "it", name: "Italian"}
];

const FileSpecs = (props) => {
  const { formik, p, name, scope, language, info, path, type, handleDelete } = props;

  return (
    <Box key={p.newName}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TextField
            margin="dense"
            variant="outlined"
            label="File name"
            name={name}
            value={p.name}
            disabled
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth margin="dense">
            <InputLabel>Scope</InputLabel>
            <Select
              value={p.scope}
              name={scope}
              label="Variable"
              variant="outlined"
              onChange={formik.handleChange}
              defaultValue="global"
            >
              <MenuItem key={"global-variant"} value={"global"}>Global</MenuItem>
              {formik.values.variants.map((variant, index) => {
                return (
                  <MenuItem key={"variant." + index} value={variant.id}>{variant.variantName}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth margin="dense">
            <InputLabel>Language</InputLabel>
            <Select
              value={p.language}
              name={language}
              label="Variable"
              variant="outlined"
              onChange={formik.handleChange}
              defaultValue="en"
            >
              {languages.map((lang, index) => {
                return (
                  <MenuItem key={"language." + index} value={lang.id}>{lang.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Info"
            name={info}
            value={p.info}
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={1} sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center"
        }}>
          <Button sx={{maxWidth: "100%"}} margin="dense" size="small" color="error" onClick={() => handleDelete(p?.newName)}>
            <FaTrash />
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}


const FilesForm = (props) => {
  const { formik } = props;
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [revitFiles, setRevitFiles] = useState([]);
  const [uploadedRevitFiles, setUploadedRevitFiles] = useState([]);
  const [otherBimFormats, setOtherBimFormats] = useState([]);
  const [uploadedOtherBimFormats, setUploadedOtherBimFormats] = useState([]);
  const [documentation, setDocumentation] = useState([]);
  const [uploadedDocumentation, setUploadedDocumentation] = useState([]);
  const isMounted = useMounted();
  const [count, setCount] = useState(0);

  useEffect(() => {
      const ids = new Set(formik.values.images.map(d => d?.newName));
      const merged = [...formik.values.images, ...uploadedImages.filter(d => !ids.has(d.newName))];
      formik.setFieldValue("images", merged);
      const existingValues = uploadedImages.map(item => item.name);
      setImages(() => images.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedImages])

  useEffect(() => {
    const ids = new Set(formik.values.revitFiles.map(d => d?.newName));
    const merged = [...formik.values.revitFiles, ...uploadedRevitFiles.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("revitFiles", merged);
    const existingValues = uploadedRevitFiles.map(item => item.name);
    setRevitFiles(() => revitFiles.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedRevitFiles])

  useEffect(() => {
    const ids = new Set(formik.values.otherBimFormats.map(d => d?.newName));
    const merged = [...formik.values.otherBimFormats, ...uploadedOtherBimFormats.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("otherBimFormats", merged);
    const existingValues = uploadedOtherBimFormats.map(item => item.name);
    setOtherBimFormats(() => otherBimFormats.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedOtherBimFormats])

  useEffect(() => {
    const ids = new Set(formik.values.documentation.map(d => d?.newName));
    const merged = [...formik.values.documentation, ...uploadedDocumentation.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("documentation", merged);
    const existingValues = uploadedDocumentation.map(item => item.name);
    setDocumentation(() => documentation.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedDocumentation])


  const uploadPendingFiles = () => {
    // check product images
    if (images.length > 0) {
      handleUpload();
    }

    // check revit
    if (revitFiles.length > 0) {
      handleUpload2();
    }

    // check other bim formats
    if (otherBimFormats.length > 0) {
      handleUpload3();
    }

    // check documentation
    if (documentation.length > 0) {
      handleUpload4();
    }
  };
  

  const uploadFileToBlob = async (file, id, folder) => {
      const newFile = new File([file], id, {
        type: file?.type,
      });
      // console.log(newFile);
      const response = await upload(newFile, folder);
      return response;
    };
  
  const handleDrop = (newFiles) => {
    setImages((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setImages((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setImages([]);
  };

  const handleUpload = () => {
    images.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4()
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "image").then(res => {
          if (res.status === 200) {
            setUploadedImages((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }

    });
  };

  const handleDrop2 = (newFiles) => {
    // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setRevitFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove2 = (file) => {
    setRevitFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll2 = () => {
    setRevitFiles([]);
  };

  const handleUpload2 = () => {
    revitFiles.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4()
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "rvt").then(res => {
          if (res.status === 200) {
            // formik.setFieldValue("revit", "https://cdn.bimroom.com/brand-images/" + res?.data)
            setUploadedRevitFiles((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
        // Expected output: ReferenceError: nonExistentFunction is not defined
        // (Note: the exact output may be browser-dependent)
      }
    });
  };

  const handleDrop3 = (newFiles) => {
    // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setOtherBimFormats((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove3 = (file) => {
    setOtherBimFormats((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll3 = () => {
    setOtherBimFormats([]);
  };

  const handleUpload3 = () => {
    otherBimFormats.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4()
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "other").then(res => {
          if (res.status === 200) {
            setUploadedOtherBimFormats((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }
      
    });
  };

  const handleDrop4 = (newFiles) => {
    // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setDocumentation((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove4 = (file) => {
    setDocumentation((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll4 = () => {
    setDocumentation([]);
  };

  const handleUpload4 = () => {
    documentation.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4()
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "documentation").then(res => {
          if (res.status === 200) {
            setUploadedDocumentation((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleDeleteImage = (name) => {
    let imageArray = formik.values.images;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("images", imageArray)
  }

  const handleDeleteRevitFile = (name) => {
    let array = formik.values.revitFiles;
    const objIndex = array.findIndex((obj => obj.newName == name));
    array[objIndex].deleted = true;
    formik.setFieldValue("revitFiles", array)
  }

  const handleDeleteOtherBimFormats = (name) => {
    let array = formik.values.otherBimFormats;
    const objIndex = array.findIndex((obj => obj.newName == name));
    array[objIndex].deleted = true;
    formik.setFieldValue("otherBimFormats", array)
  }

  const handleDeleteDocumentation = (name) => {
    let array = formik.values.documentation;
    const objIndex = array.findIndex((obj => obj.newName == name));
    array[objIndex].deleted = true;
    formik.setFieldValue("documentation", array)
  }

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Images</Typography>
              <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                Accepted file types: png, jpg, jpeg, webp, tiff.
              </Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload product images.
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <FileDropzone
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg", ".jpeg"],
                  "image/webp": [".webp"],
                  "image/tiff": [".tiff"]
                }}
                files={images}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
                onUpload={handleUpload}
              />
              <FormikProvider value={formik}>
                <FieldArray 
                  name="images" 
                  render={() => (
                    <div>
                      
                      {formik.values.images.map((p, index) => {
                        const name = `images[${index}].name`;
                        const info = `images[${index}].info`;
                        const path = `images[${index}].path`;
                        const scope = `images[${index}].scope`;
                        const language = `images[${index}].language`;

                        return (
                          !p.deleted && <FileSpecs
                            key={p.newName}
                            formik={formik}
                            p={p}
                            name={name}
                            scope={scope}
                            language={language}
                            info={info}
                            path={path}
                            type="images"
                            handleDelete={handleDeleteImage}
                          />  
                        );
                      })}
                    </div>
                  )}
                />
              </FormikProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Revit files</Typography>
              <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                Accepted file types: rfa, rvt, rte, rft.
              </Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload Revit families and/or template files
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <FileDropzone
                accept={{"": [".rvt",".rfa",".rte",".rft"]}}
                files={revitFiles}
                onDrop={handleDrop2}
                onRemove={handleRemove2}
                onRemoveAll={handleRemoveAll2}
                onUpload={handleUpload2}
              />
            {/* </Grid>
            <Grid> */}
              <FormikProvider value={formik}>
                <FieldArray name="revitFiles">
                  {() => (
                    <div>
                      {formik.values.revitFiles.map((p, index) => {
                        const name = `revitFiles[${index}].name`;
                        const scope = `revitFiles[${index}].scope`;
                        const info = `revitFiles[${index}].info`;
                        const language = `revitFiles[${index}].language`;
                        const path = `revitFiles[${index}].path`;
                      

                        return (
                          !p.deleted &&  <FileSpecs
                            key={p.newName}
                            formik={formik}
                            p={p}
                            name={name}
                            scope={scope}
                            language={language}
                            info={info}
                            path={path}
                            type="revitFiles"
                            handleDelete={handleDeleteRevitFile}
                          />
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Other BIM formats</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload all other BIM formats.
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <FileDropzone
               // accept="image/*"
                files={otherBimFormats}
                onDrop={handleDrop3}
                onRemove={handleRemove3}
                onRemoveAll={handleRemoveAll3}
                onUpload={handleUpload3}
              />
              <FormikProvider value={formik}>
                <FieldArray name="otherBimFormats">
                  {() => (
                    <div>
                      {formik.values.otherBimFormats.map((p, index) => {
                        const name = `otherBimFormats[${index}].name`;
                        const info = `otherBimFormats[${index}].info`;
                        const path = `otherBimFormats[${index}].path`;
                        const scope = `otherBimFormats[${index}].scope`;
                        const language = `otherBimFormats[${index}].language`;

                        return (
                          !p.deleted && <FileSpecs
                            key={p.newName}
                            formik={formik}
                            p={p}
                            name={name}
                            scope={scope}
                            language={language}
                            info={info}
                            type="otherBimFormats"
                            path={path}
                            handleDelete={handleDeleteOtherBimFormats}
                          />
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Other files</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload documentation and other files formats.
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <FileDropzone
                
                files={documentation}
                onDrop={handleDrop4}
                onRemove={handleRemove4}
                onRemoveAll={handleRemoveAll4}
                onUpload={handleUpload4}
              />
              <FormikProvider value={formik}>
                <FieldArray name="documentation">
                  {() => (
                    <div>
                      {formik.values.documentation.map((p, index) => {
                        const name = `documentation[${index}].name`;
                        const info = `documentation[${index}].info`;
                        const path = `documentation[${index}].path`;
                        const scope = `documentation[${index}].scope`;
                        const language = `documentation[${index}].language`;

                        return (
                          !p?.deleted && <FileSpecs
                            key={p.newName}
                            formik={formik}
                            p={p}
                            name={name}
                            scope={scope}
                            language={language}
                            info={info}
                            path={path}
                            type="documentation"
                            handleDelete={handleDeleteDocumentation}
                          />
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </> 
  )
}

export default FilesForm;
