import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  Container,
} from '@mui/material';
import { ReportsTable } from './reports-table';
import { getContracts } from "../../api";
import { useSelector } from 'react-redux';
import { ReportsFilters } from './reports-filters';

const sortOptions = [
  {
    label: 'Last update (newest)',
    value: 'latestDate|desc'
  },
  {
    label: 'Last update (oldest)',
    value: 'latestDate|asc'
  }
];

const applyFilters = (customers, filters) => customers.filter((customer) => {
  if (filters.query) {
    let queryMatched = false;
    const properties = ['companyName'];

    properties.forEach((property) => {
      if ((customer[property]).toLowerCase().includes(filters.query.toLowerCase())) {
        queryMatched = true;
      }
    });

    if (!queryMatched) {
      return false;
    }
  }

  if (filters.hasAcceptedMarketing && !customer.hasAcceptedMarketing) {
    return false;
  }

  if (filters.isProspect && !customer.isProspect) {
    return false;
  }

  if (filters.isReturning && !customer.isReturning) {
    return false;
  }

  return true;
});

const descendingComparator = (a, b, sortBy) => {
  // When compared to something undefined, always returns false.
  // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (sortDir, sortBy) => (sortDir === 'desc'
  ? (a, b) => descendingComparator(a, b, sortBy)
  : (a, b) => -descendingComparator(a, b, sortBy));

const applySort = (customers, sort) => {
  const [sortBy, sortDir] = sort.split('|');
  const comparator = getComparator(sortDir, sortBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
        const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

        return a[1] - b[1];
  });

    return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (customers, page, rowsPerPage) => customers.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);

const Reports = () => {
  const [customers, setCustomers] = useState([]);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [refresh, setRefresh] = useState(0);
  const initialRefresh = useRef(true);
  const [filters, setFilters] = useState({
    query: '',
    hasAcceptedMarketing: undefined,
    isProspect: undefined,
    isReturning: undefined
  });


  // useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);

  const getContractTable = async () => {
    try {
      //const data = await customerApi.getCustomers();
      if (selectedBrand) {
        const payload = {
          companyId: selectedBrand?.id
        }
        //console.log(selectedBrand);
        const data = await getContracts(payload);
        setCustomers(data);
        //console.log("getting data");
      }
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    if (initialRefresh.current) {
      initialRefresh.current = false;
    } else {
      getContractTable();
      console.log("refreshed");
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [refresh]);

  useEffect(() => {

    if (Object.keys(selectedBrand).length > 0) {
      getContractTable();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedBrand]);

  const handleRefresh = () => {
    setRefresh(refresh + 1);
  }


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };



  // Usually query is done on backend with indexing solutions
  const filteredCustomers = applyFilters(customers, filters);
  const sortedCustomers = applySort(filteredCustomers, sort);
  const paginatedCustomers = applyPagination(sortedCustomers, page, rowsPerPage);

  return (
    <>
      <Box
        component="main"
        sx={{
          //backgroundColor: 'background.default',
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            <ReportsFilters />
            <ReportsTable
              // customers={paginatedCustomers}
              // customersCount={filteredCustomers.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
              refresh={handleRefresh}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default Reports;