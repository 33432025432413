import { 
  useEffect, 
  useRef, 
  useState 
} from 'react';
// import NextLink from 'next/link';
// import Head from 'next/head';
import { 
  Box, 
  // Breadcrumbs,
  // Button, 
  Card, 
  Container, 
  Link, 
  Tab, 
  Tabs, 
  Typography 
} from '@mui/material';
import { ImporterBimroom } from './importer-bimroom';
import { ImporterCustom } from './importer-custom';
import { DataMappers } from './data-mappers';
//import { ImportPopover } from './import-popover';
// import { AuthGuard } from '../../../components/authentication/auth-guard';
// import { ProductCreateForm } from './product-create-form';

// import { gtm } from '../../../lib/gtm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export const ProductImporter = () => {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    console.log(event);
    setValue(newValue);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            <Box sx={{px: 4, py: 2}}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Bimroom format" {...a11yProps(0)} />
                <Tab label="Custom format" {...a11yProps(1)} />
                {/* <Tab label="Data mapping" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ImporterBimroom />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ImporterCustom handleChangeTab={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DataMappers  />
            </TabPanel>
            {/* <Box sx={{height: "90vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography variant="h6">Available in June 2023</Typography>
            </Box> */}
          </Card>
        </Container>
      </Box>
    </>
  );
};

// ProductCreate.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

//export default ProductPage;
