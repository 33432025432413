import { Box, Button, Link, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useCropperImage, CropperRef, Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import {  CropperDropzone } from './cropper-dropzone';
import { uploadAsWebp } from '../api';
import { v4 as uuidv4} from 'uuid';

export const ImageCropper = (props) => {
    const cropperRef = useRef(null);
    const { setEditingImage, handleCloseEditing, formik } = props;
    const [ images, setImages ] = useState([]);
    const [ imageFile, setImageFile ] = useState();
    const [ outputImage, setOutputImage ] = useState(null);
    // const [ coordinates, setCoordinates ] = useState(null);
    //const cropperImage = useCropperImage();

    const onChange = (cropper) => {
        cropperRef.current = cropper;
        // console.log(cropper.getCoordinates(), cropper.getCanvas());
        // setCoordinates(cropper.getCoordinates());
        // You are able to do different manipulations at a canvas
        // but there we just get a cropped image, that can be used
        // as src for <img/> to preview result
        setOutputImage(cropper.getCanvas()?.toDataURL());
    };

    const handleDrop = (newFiles) => {
      setImages(() => [...newFiles]);
      setImageFile(URL.createObjectURL(newFiles[0]));
    };
  
    const handleUpload = async () => {
      // images.map((file) => {
        //console.log(formik.values)
        if (outputImage) {
          const base64Response = await fetch(outputImage);

          const blob = await base64Response.blob();

          try {
            // await uploadFileToBlob(blob, "logo").then(res => {
            //   if (res.status === 200) {
            //     //formik.setFieldValue("logoUrl", "https://cdn.bimroom.com/logo/" + res?.data)
            //     formik.setFieldValue("logoUrl", "https://platformdev123.blob.core.windows.net/logo/" + res?.data)
            //     setEditingImage(false);
            //     setImages([]);
            //   }
            // });

            const res = await uploadFileToBlob(blob, "logo")
            
            if (res.status === 200) {
              //formik.setFieldValue("logoUrl", "https://cdn.bimroom.com/logo/" + res?.data)
              console.log(res?.data)
              formik.setFieldValue("logoUrl", "https://platformdev123.blob.core.windows.net/logo/" + res?.data)
              setEditingImage(false);
              setImages([]);
            } else {
              alert("Unable to upload image")
            }
            
          } catch (error) {
            console.error(error);
            // Expected output: ReferenceError: nonExistentFunction is not defined
            // (Note: the exact output may be browser-dependent)
          }
        }
      // })
    };

    const uploadFileToBlob = async (file, folder) => {
      const newName = formik.values.brandId + ".webp"
      const newFile = new File([file], newName, {
        type: file?.type,
      });

      const response = await uploadAsWebp(newFile, folder);
      return response;
    };

    return (
        <>
          <CropperDropzone 
            accept="image/*"
            maxFiles={1}
            files={images}
            onDrop={handleDrop}
            // onRemove={handleRemove}
            // onRemoveAll={handleRemoveAll}
            // onUpload={handleUpload}
          />
          {imageFile && <Cropper
              src={imageFile}
              onChange={onChange}
              className={'cropper'}
              //imageRestriction='none'
              style={{height: "400px", maxWidth: "100%", backgroundColor: "#fff", color: "#175c83", marginTop: "8px", borderRadius: "8px"}}
          />}
          {/* <Button onClick={() => onDownload(cropperRef.current)}>Download file</Button> */}
          <Box sx={{mt: 1}}>
            {/* <Button onClick={handleUpload} variant="contained">
              Save
            </Button> */}
            <Button onClick={() => handleUpload()} variant="contained">
              Save
            </Button>
            <Button sx={{ml: 1}} onClick={handleCloseEditing} variant="contained">
              Cancel
            </Button>
          </Box>
        </>
    )
};