import { 
  Box, 
  Typography, 
  Modal, 
  TextField, 
  Tooltip, 
  Button, 
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  tooltipClasses
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { updateContract, signContract } from '../../api';
import toast from 'react-hot-toast';
import { wait } from '../../utils/wait';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "100%",
  //overflow:'scroll',
  p: 4,
};

const downloadCla = () => {
  window.open("https://cdn.bimroom.com/static/bimroom_copyright_license_agreement.pdf", '_blank', 'noreferrer');
  //window.location.href = "https://cdn.bimroom.com/static/bimroom_copyright_license_agreement.pdf"
}

const downloadClaDocx = () => {
  window.open("https://cdn.bimroom.com/static/Bimroom_Copyright_License_Agreement.docx", '_blank', 'noreferrer');
  //window.location.href = "https://cdn.bimroom.com/static/bimroom_copyright_license_agreement.pdf"
}

export const ContractModal = (props) => {
  const { close, refresh, edited, contractdata, ...other } = props;

  const formik = useFormik({
    initialValues: {
      id: contractdata?.id,
      documentType: contractdata?.documentType || '',
      name: contractdata?.name || '',
      email: contractdata?.email || '',
      grantRight: contractdata?.grantRight || 'grants',
      manufacturerName: contractdata?.companyName || '',
      manufacturerAddress: contractdata?.manufacturerAddress || '',
      submit: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      name: Yup.string().max(255).required('Signer name is required'),
      email: Yup.string().max(255).required('Email address is required'),
      manufacturerName: Yup.string().max(255).required('Company name is required'),
      manufacturerAddress: Yup.string().max(255).required('Company address is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        const contractData = {
          name: formik.values.name,
          email: formik.values.email,
          grantRight: formik.values.grantRight,
          manufacturerName: formik.values.manufacturerName,
          manufacturerAddress: formik.values.manufacturerAddress
        }
       
        try {
          const newContract = await signContract(contractData);
          console.log(newContract);
          if (newContract) {
            
            const updateContractData = {
              id: contractdata.id,
              externalID: newContract?.id,
              status: newContract?.status,
              name: contractData?.name,
              email: contractData?.email,
              grantRight: contractData?.grantRight,
              manufacturerName: contractData?.manufacturerName,
              manufacturerAddress: contractData?.manufacturerAddress         
            }
  
            await updateContract(updateContractData);
          }
        } catch (err) {
          console.error(err);
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Contract sent!');
        await wait(500);
        helpers.resetForm();
        refresh();

        close();
        

      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleChangeGrant = (event) => {
    formik.setFieldValue("grantRight", event.target.value)
  }

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });



  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        >
          <Box sx={style}>
            <Typography variant="h6" component="h2" sx={{mb: 1, width: "100%"}}>
              Sign document
            </Typography>
            <Typography  variant="subtitle2" sx={{mb: 2, mt: 1, width: "100%"}}>
              You can prefill the form fields, click "Send the link to your email", and the link to external e-signature service is sent to the email address you provided
            </Typography>
            <Tooltip title="Company name">
              <TextField
                id="manufacturerName"
                label="Company name"
                sx={{mb: 1, width: "100%"}}
                name="manufacturerName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.manufacturerName}
              />
            </Tooltip>
            <Tooltip title="Street address/P.O. box, zip, city, country">
              <TextField
                id="manufacturerAddress"
                label="Company address "
                sx={{mb: 1, width: "100%"}}
                name="manufacturerAddress"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.manufacturerAddress}
              />
            </Tooltip>
            <Tooltip title="Name of the person who will sign the document">
              <TextField
                id="name"
                label="Signer name"
                sx={{mb: 1, width: "100%"}}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Tooltip>
            <Tooltip title="Document will be sent to this email address">
              <TextField
                id="email"
                label="Signer email"
                required
                sx={{mb: 1, width: "100%"}}
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </Tooltip>
            <Grid container>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <InputLabel id="select-grant-right-input-label">Can Bimform model generic objects based on your products?</InputLabel>
                  <Select
                    labelId="select-grant-right-input-label"
                    id="grantRight"
                    value={formik.values.grantRight}
                    label="Can Bimform model generic objects based on your products?"
                    onChange={handleChangeGrant}
                    sx={{
                      height: 52,
                      mb: 1
                    }}
                  >
                    <MenuItem value={"grants"} key={"grants"}>Yes</MenuItem>
                    <MenuItem value={"does not grant"} key={"does not grant"}>No</MenuItem>
                    {/* <MenuItem value={1}>BON v2</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CustomWidthTooltip title="We kindly request your permission to utilize your BIM objects as a foundation for our generic objects. In various projects, particularly public projects, the use of company-branded objects during the design phase may not be permissible. To address this, we are developing a library of generic objects that resemble real-world products. In the later phases of the projects, these generic objects can be seamlessly replaced with actual products in Revit using our Revit plugin. If a generic object is modeled based on your products, it significantly increases the likelihood of your product appearing at the top of the list of potential replacements.">
                  <Typography sx={{mb: 1}} variant="subtitle2">Why do we ask?</Typography>
                </CustomWidthTooltip>
              </Grid>
            </Grid>         
            <Button 
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
            >
              Send the link to your email
            </Button>
            <Typography variant="h6" component="h2" sx={{my: 2, width: "100%"}}>
              OR
            </Typography>
            <Typography variant="subtitle2" sx={{my: 2, width: "100%"}}>
              You can download, sign, and send the signed document to info@bimform.ch
            </Typography>
            <Grid container spacing={1} sx={{mb: 1}}>
              <Grid item xs={6}>
                <Button fullWidth onClick={downloadClaDocx} target="_blank" variant="contained" download={true}>
                  Download as word document
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth onClick={downloadCla} target="_blank" variant="contained" download={true}>
                  Download as pdf
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
    </>
  );
};
