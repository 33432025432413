import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { Scrollbar } from '../../scrollbar';
import { ContractModal } from './contract-modal';
import { Pdf as PdfIcon } from '../../icons/pdf';
import { getSignedContract } from '../../api';


export const ContractListTable = (props) => {
  const {
    contracts,
    contractsCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    refresh,
    ...other
  } = props;
  const [selectedContract, setSelectedContract] = useState([]);
  const [contractOpen, setContractOpen] = useState(false);

  // Reset selected contracts when contracts change
  useEffect(() => {
      if (selectedContract.length) {
        setSelectedContract([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contracts]);

  const handleSelectAllContracts = (event) => {
    setSelectedContract(event.target.checked
      ? contracts.map((customer) => customer.id)
      : []);
  };

  const handleOpenContract = (contract) => {
    setSelectedContract(contract);
    setContractOpen(true);
  };

  const handleCloseContract = () => {
    setContractOpen(false);
  };

  const handleDownloadContract = async (contract) => {
    const payload = {
      id: contract.externalID,
      filename: "Bimroom - " + contract.documentType + ".pdf"
    };

    const response = await getSignedContract(payload);
    window.open(response?.file_url, '_blank', 'noreferrer');

  };

  const getColor = (status) => {

    if (status === "Completed") {
      return "success.main";
    } else if (["Sign document","Created"].includes(status)) {
      return "#000";
    } else if (["Cancelled", "Deleted"].includes(status)) {
      return "error.main";
    } else {
      return "#000";
    }
  }

  const enableBulkActions = selectedContract.length > 0;
  const selectedSomeContracts = selectedContract.length > 0
    && selectedContract.length < contracts.length;
  const selectedAllContracts = selectedContract.length === contracts.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllContracts}
          indeterminate={selectedSomeContracts}
          onChange={handleSelectAllContracts}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell width="30%">
                Document type
              </TableCell>
              <TableCell width="30%">
                Document signer
              </TableCell>
              <TableCell width="20%" >
                Status
              </TableCell>
              <TableCell width="20%" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.length === 0 && <TableRow
              hover
              key="not-available"
              //sx={{cursor: "pointer"}}
            >
              <TableCell colSpan={4} sx={{height: "64px"}} align="center">No documents available</TableCell>
            </TableRow>}
            {contracts.map((contract) => {
              return (
                <TableRow
                  hover
                  key={contract.id}
                >
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                    >
                      {contract?.documentType}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      
                      variant="body2"
                    >
                      {contract?.name}
                    </Typography>
                    <Typography
                      
                      variant="body2"
                    >
                      {contract?.email}
                    </Typography>
                  </TableCell>
                  <TableCell >
                    <Typography
                      color={getColor(contract?.status)}
                      variant="body2"
                    >
                      {contract?.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                  {contract?.status === "Sign document" && 
                    <Button 
                      variant="outlined" 
                      onClick={() => handleOpenContract(contract)} 
                      startIcon={<PencilIcon />}
                    >
                      Sign
                    </Button>
                  }
                  {contract?.status === "Completed" && 
                      <IconButton
                        onClick={() => handleDownloadContract(contract)}
                        color="success"
                      >
                        <PdfIcon />
                      </IconButton> 
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ContractModal open={contractOpen} close={handleCloseContract} contractdata={selectedContract} refresh={refresh}/>
      </Scrollbar>
      <TablePagination
        component="div"
        count={contractsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

// CustomerListTable.propTypes = {
//   contracts: PropTypes.array.isRequired,
//   contractsCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
