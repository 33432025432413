import React from "react";


// components
import Subscriptions from "../components/Subscriptions";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import Loader from "../components/Loader/loader";
import useDocumentTitle from "../useDocumentTitle";
import { MainProfileGuard } from "../components/main-profile-guard";

const SubscriptionPage = () => {
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom Portal - Account - Subscriptions');

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
            <DashboardLayout header="Account - subscriptions">
              <MainProfileGuard>
                <Subscriptions />
              </MainProfileGuard>
            </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default SubscriptionPage;