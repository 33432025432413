import { format, subDays } from 'date-fns';
//import numeral from 'numeral';
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  CardActions,
  Button,
  Divider,
  TextField,
  MenuItem,
  CardContent,
  useTheme
} from '@mui/material';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import ReactApexChart from 'react-apexcharts';

export const OverviewTotalDownloads = (props) => {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ['#175C83', '#FF6961', '#F1F7B5', '#A8D1D1', '#9EA1D4','#829460'],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    grid: {
      borderColor: theme.palette.divider
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    }
  };

  const chartSeries = [
    // {
    //   name: 'Revit',
    //   data: [5, 10, 7, 2, 1, 21, 44, 2, 21, 5, 0, 0]
    // },
    // {
    //   name: 'Website',
    //   data: [4, 5, 3, 1, 5, 22, 2, 3, 4, 0, 12, 1]
    // }
  ];

  return (
    <Card {...props}>
      <CardHeader title="Total downloads" action={
        <TextField
          defaultValue="week"
          label="Period"
          select
          size="small"
          //sx={{ m: 1 }}
        >
          <MenuItem value="week">
            Last week
          </MenuItem>
          <MenuItem value="month">
            Last month
          </MenuItem>
          <MenuItem value="year">
            Last year
          </MenuItem>
          <MenuItem value="all-time">
            All time
          </MenuItem>
        </TextField>
      } />
      <CardContent sx={{p: 0}}>
        <ReactApexChart
          height={250}
          options={chartOptions}
          series={chartSeries}
          type="area"
        />
      </CardContent>
      <Divider />
      <CardActions> 
        <Button
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="outlined"
        >
          Analytics Dashboard
        </Button>
        <Button
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="outlined"
        >
          Reports
        </Button>
      </CardActions>
    </Card>
  );
};
