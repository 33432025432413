import { Box, Grid, Radio, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Plus } from "../../icons/plus";

function imageExists(image_url){

    const http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

}

const SingleImage = (props) => {
    const { image, formik, handleChange } = props;

    return (
        <Grid container sx={{ height: 150, mb: 2 }}>

            <Grid item xs={2}>
                <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Radio
                        checked={formik.values.coverImageUrl === image.url}
                        onChange={handleChange}
                        value={image.url}
                        name="coverImageUrl"
                        //slotProps={{ input: { 'aria-label': 'A' } }}
                    />
                </Box>
            </Grid>

            <Grid item xs={10} sx={{height: "100%"}}>
                <Box sx={{ height: "100%", overflow: "hidden" }}>
                    {
                        (image.userUploaded && !imageExists(image.url)) ? 
                            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                Image not found
                            </Box> 
                        : 
                        <img 
                            src={image.url} 
                            alt={image.alt} 
                            style={{objectFit: "cover", width: "100%", maxHeight: "100%"}}
                        />
                    }
                </Box>
            </Grid>

        </Grid>
    )
};





const CoverImageSelector = (props) => {
    const { formik, handleChange } = props;
    const [images, setImages] = useState([
        {
            url: "https://www.prodraw.net/fb_cover/images/fb_cover_65.jpg",
            alt: "cover image 1",
            userUploaded: false
        },
        {
            url: "https://www.prodraw.net/fb_cover/images/fb_cover_48.jpg",
            alt: "cover image 2",
            userUploaded: false
        },
        {
            url: "https://www.prodraw.net/fb_cover/images/fb_cover_55.jpg",
            alt: "cover image 3",
            userUploaded: false
        }
    ]);

    useEffect(() => {
        if (formik.values?.userUploadedCoverImages) {
            // add user uploaded images to the list of images if same url does not exist
            formik.values.userUploadedCoverImages.forEach((image) => {
                if (!images.some((img) => img.url === image.url)) {
                    setImages([...images, image]);
                }
            });

            // setImages([...images, ...formik.values.userUploadedCoverImages]);
        }
    }, [formik.values?.userUploadedCoverImages]);

    return (
        <>
            <Box>
                {images.map((image, index) => (
                    <SingleImage key={index} image={image} formik={formik} handleChange={handleChange} />
                ))}
            </Box>
            {/* <Box sx={{
                color: "#175c83", 
                width: "100%", 
                height: "60px", 
                border: "1px dashed #175c83", 
                borderRadius: 1, 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",
                transition: "all 0.3s",
                '&:hover': {
                    backgroundColor: '#175c831A',
                    //height: "150px",
                }  
            }}>
                <Plus />
                <Typography component="h6" variant="subtitle2">Add new cover image</Typography>
            </Box> */}
        </>
    )
};

export default CoverImageSelector;