import {
  Modal
} from '@mui/material';
import { AddBrandModal } from './add-brand-modal';
import { AddContactModal } from './add-contact-modal';
import { Box } from '@mui/system';

function getComponent(modal, handleClose, formik) {
  switch (modal) {
     case "add-brand":
        return <AddBrandModal handleClose={handleClose} />;
     case "add-contact":
        return <AddContactModal handleClose={handleClose} formikBrand={formik} />;
     default:
        return <div />;
  }
}

const ModalComponent = (props) => {
  const { open, handleClose, modal, formik } = props;

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>{getComponent(modal, handleClose, formik)}</Box>
      </Modal>
  );
};

export default ModalComponent;