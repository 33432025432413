import { Box, Grid, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { stateToHTML } from 'draft-js-export-html';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`description-tabpanel-${index}`}
      aria-labelledby={`description-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{py: 1}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `description-tab-${index}`,
    'aria-controls': `description-tabpanel-${index}`,
  };
}

const BrandDetails = (props) => {
    const { formik, brandData, counter, setChangesMade, handleChange } = props;
    const [ activeDescription, setActiveDescription ] = useState(0);
    // const [editorState, setEditorState] = useState(
    //   EditorState.createEmpty()
    // );
    // const editor = useRef(null);

      // Editor state
      const [ editorStateEn, setEditorStateEn ] = useState(
        EditorState.createEmpty()
      );
      const [ editorStateDe, setEditorStateDe ] = useState(
        EditorState.createEmpty()
      );
      const [ editorStateFr, setEditorStateFr ] = useState(
        EditorState.createEmpty()
      );
      const [ editorStateIt, setEditorStateIt ] = useState(
        EditorState.createEmpty()
      );
      const [ editorStateEs, setEditorStateEs ] = useState(
        EditorState.createEmpty()
      );
    
      const editorEn = useRef(null);
      const editorDe = useRef(null);
      const editorFr = useRef(null);
      const editorIt = useRef(null);
      const editorEs = useRef(null);
    
      const languageOptions = [
        {
          value: 'en',
          label: 'English',
          editor: editorEn,
          editorState: editorStateEn,
          setEditorState: setEditorStateEn,
          onChange: editorStateEn => setEditorStateEn(editorStateEn)
        },
        {
          value: 'de',
          label: 'German',
          editor: editorDe,
          editorState: editorStateDe,
          setEditorState: setEditorStateDe,
          onChange: editorStateDe => setEditorStateDe(editorStateDe)
        },
        {
          value: 'fr',
          label: 'French',
          editor: editorFr,
          editorState: editorStateFr,
          setEditorState: setEditorStateFr,
          onChange: editorStateFr => setEditorStateFr(editorStateFr)
        },
        {
          value: 'it',
          label: 'Italian',
          editor: editorIt,
          editorState: editorStateIt,
          setEditorState: setEditorStateIt,
          onChange: editorStateIt => setEditorStateIt(editorStateIt)
        },
        {
          value: 'es',
          label: 'Spanish',
          editor: editorEs,
          editorState: editorStateEs,
          setEditorState: setEditorStateEs,
          onChange: editorStateEs => setEditorStateEs(editorStateEs)
        }
      ];

    useEffect(() => {
      // html text to editor
      languageOptions.forEach((option) => {
        const selectedDescription = brandData?.descriptions?.find(desc => desc.lang === option.value);
        // try {
        if (selectedDescription?.description) {
          const blocksFromHTML = convertFromHTML(selectedDescription?.description);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          )
          option.setEditorState(EditorState.createWithContent(state));
        } else {
          option.setEditorState(EditorState.createEmpty());
        }
      })
    }, [counter])


    const handleTabChange = (event, newValue) => {
      setActiveDescription(newValue);
    };

    const handleDescriptionBlur = (index, option) => {
      setChangesMade(true);
      formik.setFieldValue(`descriptions[${index}].description`, stateToHTML(option?.editorState.getCurrentContent()));
    }

    return (
        <>
          <Tooltip title="Brand name visible throughout the Bimroom library">
              <Box
                sx={{
                  display: 'flex',
                  //mt: 2,
                  alignItems: 'center'
                }}
              >
                <TextField
                  id="brandName"
                  label="Name"
                  // multiline
                  // minRows={4}
                  size="small"
                  sx={{ flexGrow: 1 }}
                  name="brandName"
                  onBlur={formik.handleBlur}
                  onChange={handleChange}
                  value={formik.values?.brandName}
                />
              </Box>
            </Tooltip>
            <Tooltip title="A shortened and URL-friendly name for the brand">
              <Box
                sx={{
                  display: 'flex',
                  mt: 1,
                  alignItems: 'center'
                }}
              >                    
                <TextField
                  id="brandSlug"
                  label="Slug"
                  disabled
                  // multiline
                  // minRows={4}
                  size="small"
                  sx={{ flexGrow: 1 }}
                  name="brandSlug"
                  onBlur={formik.handleBlur}
                  onChange={handleChange}
                  value={formik.values?.brandSlug}
                />
              </Box>
            </Tooltip>
            <Tooltip title="Brand homepage URL">
              <Box
                sx={{
                  display: 'flex',
                  mt: 1,
                  alignItems: 'center'
                }}
              >
                <TextField
                  id="brandUrl"
                  label="URL"
                  size="small"
                  sx={{ flexGrow: 1 }}
                  name="brandUrl"
                  onBlur={formik.handleBlur}
                  onChange={handleChange}
                  value={formik.values?.brandUrl}
                />
              </Box>
            </Tooltip>
          <Typography
            color="textSecondary"
            sx={{
              mb: 1,
              mt: 1,
              
            }}
            variant="subtitle2"
          >
            Description
          </Typography>
            <Box
              sx={{
                mt: 1,
                alignItems: 'center'
              }}
            >
              {/* <Editor
                ref={editor}
                editorState={editorState}
                onChange={editorState => { 
                  setEditorState(editorState)
                  setChangesMade(true);
                }
                }
              /> */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant="fullWidth" value={activeDescription} onChange={handleTabChange} aria-label="language tabs">
                  {languageOptions.map((option, index) => (
                    <Tab key={"language-" + index} label={option?.label} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </Box>
               {languageOptions.map((option, index) => (
                <CustomTabPanel key={index} value={activeDescription} index={index}>
                  <Editor
                    id="description-text"
                    editorState={option?.editorState}
                    ref={option?.editor}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={option.onChange}
                    onBlur={() => handleDescriptionBlur(index, option)}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />

                </CustomTabPanel>
              ))}
            </Box>
        </>
    )
}

export default BrandDetails;
