const categoryConversion = [
    {
      full: "Building Elements & Modules",
      slug: "building-elements-and-modules"
    },
    {
      full: "Balconies",
      slug: "balconies"
    },
    {
      full: "Shelving & Storage",
      slug: "shelving-and-storage"
    },
    {
      full: "Autowalks",
      slug: "autowalks"
    },
    {
      full: "Acoustics",
      slug: "acoustics"
    },
    {
      full: "Door Accessories",
      slug: "door-accessories"
    },
    {
      full: "Communication",
      slug: "communication"
    },
    {
      full: "Appliances",
      slug: "appliances"
    },
    {
      full: "Fire Dampers",
      slug: "fire-dampers"
    },
    {
      full: "Carpets",
      slug: "carpets"
    },
    {
      full: "Air Conditioning & Cooling",
      slug: "air-conditioning-and-cooling"
    },
    {
      full: "Ceiling",
      slug: "ceiling"
    },
    {
      full: "Benches",
      slug: "benches"
    },
    {
      full: "Trees",
      slug: "trees"
    },
    {
      full: "Drains",
      slug: "drains"
    },
    {
      full: "Fences",
      slug: "fences"
    },
    {
      full: "Traffic & Circulation",
      slug: "traffic-and-circulation"
    },
    {
      full: "Fixed Windows",
      slug: "fixed-windows"
    },
    {
      full: "Waste Systems",
      slug: "waste-systems"
    },
    {
      full: "Lockers",
      slug: "lockers"
    },
    {
      full: "Curtain Walls",
      slug: "curtain-walls"
    },
    {
      full: "Structural Framing",
      slug: "structural-framing"
    },
    {
      full: "Door Handles",
      slug: "door-handles"
    },
    {
      full: "Single Leaf",
      slug: "single-leaf"
    },
    {
      full: "Telescopic Doors",
      slug: "telescopic-doors"
    },
    {
      full: "Conduits",
      slug: "conduits"
    },
    {
      full: "Freezers",
      slug: "freezers"
    },
    {
      full: "Audio Systems",
      slug: "audio-systems"
    },
    {
      full: "Hydrants",
      slug: "hydrants"
    },
    {
      full: "Fireplaces",
      slug: "fireplaces"
    },
    {
      full: "Countertops",
      slug: "countertops"
    },
    {
      full: "Urinals",
      slug: "urinals"
    },
    {
      full: "Dryers",
      slug: "dryers"
    },
    {
      full: "Hot Tubs",
      slug: "hot-tubs"
    },
    {
      full: "Blinds & Curtains",
      slug: "blinds-and-curtains"
    },
    {
      full: "Casework",
      slug: "casework"
    },
    {
      full: "Modular Spaces",
      slug: "modular-spaces"
    },
    {
      full: "Kitchen Cabinets",
      slug: "kitchen-cabinets"
    },
    {
      full: "Elevators",
      slug: "elevators"
    },
    {
      full: "Beams",
      slug: "beams"
    },
    {
      full: "Swing Doors",
      slug: "swing-doors"
    },
    {
      full: "Control Panels",
      slug: "control-panels"
    },
    {
      full: "Entertainment",
      slug: "entertainment"
    },
    {
      full: "Fire Hydrants & Extinguishers",
      slug: "fire-hydrants-and-extinguishers"
    },
    {
      full: "Laminate",
      slug: "laminate"
    },
    {
      full: "Armchairs & Recliners",
      slug: "armchairs-and-recliners"
    },
    {
      full: "Daylighting",
      slug: "daylighting"
    },
    {
      full: "Chairs",
      slug: "chairs"
    },
    {
      full: "Shrubs",
      slug: "shrubs"
    },
    {
      full: "Other",
      slug: "other"
    },
    {
      full: "Railings",
      slug: "railings"
    },
    {
      full: "Basins & Sinks",
      slug: "basins-and-sinks"
    },
    {
      full: "Emergency Signage",
      slug: "emergency-signage"
    },
    {
      full: "Hinged Windows",
      slug: "hinged-windows"
    },
    {
      full: "Waste Bins & Containers",
      slug: "waste-bins-and-containers"
    },
    {
      full: "Warehouse Shelves",
      slug: "warehouse-shelves"
    },
    {
      full: "Curtain Roofs",
      slug: "curtain-roofs"
    },
    {
      full: "Structural Trusses",
      slug: "structural-trusses"
    },
    {
      full: "Door Hardware",
      slug: "door-hardware"
    },
    {
      full: "Double Leaf",
      slug: "double-leaf"
    },
    {
      full: "Cable Support Systems",
      slug: "cable-support-systems"
    },
    {
      full: "Dishwashers",
      slug: "dishwashers"
    },
    {
      full: "Projectors",
      slug: "projectors"
    },
    {
      full: "Extinguishers",
      slug: "extinguishers"
    },
    {
      full: "Heat Exchangers & Heat Recovery",
      slug: "heat-exchangers-and-heat-recovery"
    },
    {
      full: "Washing Machines",
      slug: "washing-machines"
    },
    {
      full: "Bidets",
      slug: "bidets"
    },
    {
      full: "Window Handles",
      slug: "window-handles"
    },
    {
      full: "Circulation",
      slug: "circulation"
    },
    {
      full: "Shelters, Booths etc.",
      slug: "shelters-booths-etc"
    },
    {
      full: "Wardrobes",
      slug: "wardrobes"
    },
    {
      full: "Escalators",
      slug: "escalators"
    },
    {
      full: "Ceilings",
      slug: "ceilings"
    },
    {
      full: "Sliding Doors",
      slug: "sliding-doors"
    },
    {
      full: "Controls",
      slug: "controls"
    },
    {
      full: "Information Technology",
      slug: "information-technology"
    },
    {
      full: "Firestops",
      slug: "firestops"
    },
    {
      full: "Linoleum",
      slug: "linoleum"
    },
    {
      full: "Beds",
      slug: "beds"
    },
    {
      full: "Hanging Systems",
      slug: "hanging-systems"
    },
    {
      full: "Sinks",
      slug: "sinks"
    },
    {
      full: "Floor",
      slug: "floor"
    },
    {
      full: "Playground",
      slug: "playground"
    },
    {
      full: "Vines",
      slug: "vines"
    },
    {
      full: "Piping",
      slug: "piping"
    },
    {
      full: "Gates",
      slug: "gates"
    },
    {
      full: "Bathtubs",
      slug: "bathtubs"
    },
    {
      full: "Hung Windows",
      slug: "hung-windows"
    },
    {
      full: "Facade Systems",
      slug: "facade-systems"
    },
    {
      full: "Door Locks",
      slug: "door-locks"
    },
    {
      full: "Pivot Doors",
      slug: "pivot-doors"
    },
    {
      full: "Televisions",
      slug: "televisions"
    },
    {
      full: "Solar Heating",
      slug: "solar-heating"
    },
    {
      full: "Microwaves",
      slug: "microwaves"
    },
    {
      full: "Window Hardware",
      slug: "window-hardware"
    },
    {
      full: "Construction",
      slug: "construction"
    },
    {
      full: "Bathroom Cabinets",
      slug: "bathroom-cabinets"
    },
    {
      full: "Gates & Barriers",
      slug: "gates-and-barriers"
    },
    {
      full: "Columns",
      slug: "columns"
    },
    {
      full: "Folding Doors",
      slug: "folding-doors"
    },
    {
      full: "Installations",
      slug: "installations"
    },
    {
      full: "Sprinklers",
      slug: "sprinklers"
    },
    {
      full: "Stone",
      slug: "stone"
    },
    {
      full: "Heating",
      slug: "heating"
    },
    {
      full: "Taps & Faucets",
      slug: "taps-and-faucets"
    },
    {
      full: "Desk & Table",
      slug: "desk-and-table"
    },
    {
      full: "Sports Equipment",
      slug: "sports-equipment"
    },
    {
      full: "Bathroom Accessories",
      slug: "bathroom-accessories"
    },
    {
      full: "Roof Windows",
      slug: "roof-windows"
    },
    {
      full: "Door Openers & Closers",
      slug: "door-openers-and-closers"
    },
    {
      full: "Boilers & Heaters",
      slug: "boilers-and-heaters"
    },
    {
      full: "Refrigerators",
      slug: "refrigerators"
    },
    {
      full: "Window Locks",
      slug: "window-locks"
    },
    {
      full: "Doors",
      slug: "doors"
    },
    {
      full: "Ladders",
      slug: "ladders"
    },
    {
      full: "Curtain Systems & Facades",
      slug: "curtain-systems-and-facades"
    },
    {
      full: "Roll-up & Sectional Doors",
      slug: "roll-up-and-sectional-doors"
    },
    {
      full: "Power Supply & Distribution",
      slug: "power-supply-and-distribution"
    },
    {
      full: "Controls & Displays",
      slug: "controls-and-displays"
    },
    {
      full: "Systems",
      slug: "systems"
    },
    {
      full: "Ventilation",
      slug: "ventilation"
    },
    {
      full: "Storage",
      slug: "storage"
    },
    {
      full: "Toilets",
      slug: "toilets"
    },
    {
      full: "Sliding Windows",
      slug: "sliding-windows"
    },
    {
      full: "Ovens",
      slug: "ovens"
    },
    {
      full: "Window Openers",
      slug: "window-openers"
    },
    {
      full: "Electrical",
      slug: "electrical"
    },
    {
      full: "Loading Equipment",
      slug: "loading-equipment"
    },
    {
      full: "Louvres & Shutters",
      slug: "louvres-and-shutters"
    },
    {
      full: "Revolving Doors",
      slug: "revolving-doors"
    },
    {
      full: "Sensors",
      slug: "sensors"
    },
    {
      full: "Security Devices",
      slug: "security-devices"
    },
    {
      full: "Tiles",
      slug: "tiles"
    },
    {
      full: "Decoration",
      slug: "decoration"
    },
    {
      full: "Smoke Extraction",
      slug: "smoke-extraction"
    },
    {
      full: "Outdoor",
      slug: "outdoor"
    },
    {
      full: "Tables",
      slug: "tables"
    },
    {
      full: "Water Heaters",
      slug: "water-heaters"
    },
    {
      full: "Sauna",
      slug: "sauna"
    },
    {
      full: "Window Accessories",
      slug: "window-accessories"
    },
    {
      full: "Stovetops",
      slug: "stovetops"
    },
    {
      full: "Electronics",
      slug: "electronics"
    },
    {
      full: "Ramps",
      slug: "ramps"
    },
    {
      full: "Materials & Coatings",
      slug: "materials-and-coatings"
    },
    {
      full: "Access Doors & Latches",
      slug: "access-doors-and-latches"
    },
    {
      full: "Conduits & Cable Support",
      slug: "conduits-and-cable-support"
    },
    {
      full: "Vinyl",
      slug: "vinyl"
    },
    {
      full: "Desks & Workstations",
      slug: "desks-and-workstations"
    },
    {
      full: "Pendants",
      slug: "pendants"
    },
    {
      full: "Waste & Recycling",
      slug: "waste-and-recycling"
    },
    {
      full: "Pumps",
      slug: "pumps"
    },
    {
      full: "Bathroom Screens & Partitions",
      slug: "bathroom-screens-and-partitions"
    },
    {
      full: "Other Appliances",
      slug: "other-appliances"
    },
    {
      full: "Fire Products",
      slug: "fire-products"
    },
    {
      full: "Stairs",
      slug: "stairs"
    },
    {
      full: "Profiles",
      slug: "profiles"
    },
    {
      full: "Wood",
      slug: "wood"
    },
    {
      full: "Footrests & Ottomans",
      slug: "footrests-and-ottomans"
    },
    {
      full: "Wall",
      slug: "wall"
    },
    {
      full: "Showers",
      slug: "showers"
    },
    {
      full: "Flooring",
      slug: "flooring"
    },
    {
      full: "Trusses & Frames",
      slug: "trusses-and-frames"
    },
    {
      full: "Hooks & Hangers",
      slug: "hooks-and-hangers"
    },
    {
      full: "Furniture",
      slug: "furniture"
    },
    {
      full: "Mirrors",
      slug: "mirrors"
    },
    {
      full: "HVAC",
      slug: "hvac"
    },
    {
      full: "Room Dividers & Screens",
      slug: "room-dividers-and-screens"
    },
    {
      full: "Kitchen",
      slug: "kitchen"
    },
    {
      full: "Sofas",
      slug: "sofas"
    },
    {
      full: "Lighting",
      slug: "lighting"
    },
    {
      full: "Stools",
      slug: "stools"
    },
    {
      full: "Medical & Research Equipment",
      slug: "medical-and-research-equipment"
    },
    {
      full: "Outdoor Furniture",
      slug: "outdoor-furniture"
    },
    {
      full: "Planting",
      slug: "planting"
    },
    {
      full: "Storage & Display",
      slug: "storage-and-display"
    },
    {
      full: "Plumbing",
      slug: "plumbing"
    },
    {
      full: "Materials",
      slug: "materials"
    },
    {
      full: "Railings & Fences",
      slug: "railings-and-fences"
    },
    {
      full: "Sanitary",
      slug: "sanitary"
    },
    {
      full: "Signage",
      slug: "signage"
    },
    {
      full: "Windows",
      slug: "windows"
    }
  ];

  const usageConversion = [
    {slug: 'commercial', full: 'Commercial'},
    {slug: 'educational', full: 'Educational'},
    {slug: 'industrial', full: 'Industrial'},
    {slug: 'medical', full: 'Medical'},
    {slug: 'residential', full: 'Residential'},
    {slug: 'institutional', full: 'Institutional'}
  ];

  const roomConversion = [
    {slug: 'bathroom', full: 'Bathroom'},
    {slug: 'bedroom', full: 'Bedroom'},
    {slug: 'kitchen', full: 'Kitchen'},
    {slug: 'living-room', full: 'Living Room'},
    {slug: 'lobby-lounge', full: 'Lobby/Lounge'},
    {slug: 'study', full: 'Study'},
    {slug: 'storage', full: 'Storage'},
    {slug: 'outdoor', full: 'Outdoor'},
    {slug: 'office', full: 'Office'}
  ];


  export { categoryConversion, roomConversion, usageConversion };