import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

// components

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import Reports from "../components/Reports";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";
import useDocumentTitle from "../useDocumentTitle";
import { BrandGuard } from "../components/brand-guard";

const AnalyticsReports = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { accounts } = useMsal();
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom Portal - Analytics - Reports');

  const navigate = useNavigate();

  return (
    <>

      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
          <DashboardLayout header="Analytics - reports">
            <BrandGuard>
              <Reports /> 
            </BrandGuard>
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default AnalyticsReports;