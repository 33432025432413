import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  Container
} from '@mui/material';
import { ContractListTable } from './contract-list-table';
import { useSelector } from 'react-redux';
import { ContractFilters } from './contract-filters';
import { portalDocuments } from '../../api';



const sortOptions = [
  {
    label: 'Last update (newest)',
    value: 'latestDate|desc'
  },
  {
    label: 'Last update (oldest)',
    value: 'latestDate|asc'
  }
];



const applyFilters = (contracts, filters) => contracts.filter((contract) => {
  if (filters.query) {
    let queryMatched = false;
    const properties = ['documentType','documentSigner'];

    properties.forEach((property) => {
      if ((contract[property]).toLowerCase().includes(filters.query.toLowerCase())) {
        queryMatched = true;
      }
    });

    if (!queryMatched) {
      return false;
    }
  }

  if (filters.status?.length > 0) {
    const statusMatched = filters.status.includes(contract.status);
  
    if (!statusMatched) {
      return false;
    }
  }

  return true;
});

const descendingComparator = (a, b, sortBy) => {
  // When compared to something undefined, always returns false.
  // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (sortDir, sortBy) => (sortDir === 'desc'
  ? (a, b) => descendingComparator(a, b, sortBy)
  : (a, b) => -descendingComparator(a, b, sortBy));

const applySort = (contracts, sort) => {
  const [sortBy, sortDir] = sort.split('|');
  const comparator = getComparator(sortDir, sortBy);
  const stabilizedThis = contracts.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
        const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

        return a[1] - b[1];
  });

    return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (contracts, page, rowsPerPage) => contracts.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);

const Contracts = () => {
  //const isMounted = useMounted();
  //const queryRef = useRef(null);
  const [contracts, setContracts] = useState([]);
  const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [filters, setFilters] = useState({
    query: '',
    status: []
  });

  const getContractTable = async () => {
    try {
      if (mainProfileId) {
        const payload = {
          mainProfileId: mainProfileId
        }
        const data = await portalDocuments(payload);
        setContracts(data);
      }
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    if (mainProfileId) {
      console.log(mainProfileId);
      getContractTable();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [mainProfileId, refresh]);

  const handleRefresh = () => {
    setRefresh(refresh + 1);
  }

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const filteredContracts = applyFilters(contracts, filters);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            <ContractFilters onChange={handleFiltersChange}/>
            <ContractListTable
              contracts={filteredContracts}
              contractsCount={filteredContracts.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
              refresh={handleRefresh}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default Contracts;