import React, 
{ 
  useEffect, useState
  //useState 
} from "react";
import "./App.css";

// packages
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// components
import Home from "./pages/Home";
import ContractPage from "./pages/ContractPage";
import Subscriptions from "./pages/SubscriptionPage";
import ProductList from "./pages/ProductList";
import ProductForm from "./pages/ProductForm";
import ProductFormV2 from "./pages/ProductFormV2";
import { 
  setUserBrands, 
  setSelectedBrand, 
  setProfileId, 
  setIsLoading 
} from './redux/reducers/user-reducer';
import { 
  // getBrands, 
  // portalBrands, 
  portalMainProfile, 
  portalUsers 
} from "./api";
import BrandInformationPage from "./pages/BrandInformationPage";
import AnalyticsDashboard from "./pages/AnalyticsDashboard";
import AnalyticsReports from "./pages/AnalyticsReports";
import ModellingPage from "./pages/ModellingPage";
import ProductImporter from "./pages/ProductImporter";
import BillingPage from "./pages/BillingPage";
import GeneralPage from "./pages/GeneralPage";
import InboxPage from "./pages/InboxPage";
import { AcceptPoliciesModal } from "./components/accept-policies-modal";
import LegalPage from "./pages/LegalPage";
import ZipUploaderPage from "./pages/ZipUploaderPage";
import ToBePublishedPage from "./pages/ToBePublished";


// apis
// import { getProjects, createUser, getUserDetail, getReviewAndRating, getReviews, getKey } from "./api";

function App() {
  const { instance, inProgress, accounts } = useMsal();
  const [ openPolicies, setOpenPolicies ] = useState(false);
  const brands = useSelector((store) => store.user.brands);
  //const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleOpenPolicies = () => {
    setOpenPolicies(!openPolicies)
  }

  // GET user
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: [ "https://bimroomportal.onmicrosoft.com/35580982-9c74-49a1-9884-e5914665d71a/read" ],
        account: accounts[0],
      };

      dispatch(setIsLoading(true));

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          portalUsers({userId: accounts[0]?.localAccountId}, accessToken)
          .then((response) => {
            //console.log(response);
            const role = response[0]?.role;
            const brands = response[0]?.brands;
            const userId = response[0]?.id;
            const policiesSigned = response[0]?.privacyPolicy;
            //const mainProfileId = response[0]?.mainProfileId;
                  
            if (brands) {
              dispatch(setUserBrands(brands));
              const previouslySelectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));

              if (previouslySelectedBrand) {
                dispatch(setSelectedBrand(previouslySelectedBrand));
              } else {
                dispatch(setSelectedBrand(brands[0]));
              }
            }

            if (role === "main") {
              portalMainProfile({userId: userId}).then((res) => {
                const mainProfileId = res[0]?.id;
                if (mainProfileId) {
                  dispatch(setProfileId(mainProfileId));
                }

                if (!policiesSigned) {
                  setOpenPolicies(true);
                }
              });
            }

            dispatch(setIsLoading(false));
          });

        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              //.acquireTokenPopup(accessTokenRequest)
              .acquireTokenRedirect(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                // callApi(accessToken).then((response) => {
                //   setApiData(response);
                // });
                portalUsers({userId: accounts[0]?.localAccountId}, accessToken)
                .then((response) => {
                  const role = response[0]?.role;
                  const brands = response[0]?.brands;
                  const userId = response[0]?.id;
                  const policiesSigned = response[0]?.privacyPolicy;
                  //const mainProfileId = response[0]?.mainProfileId;
                  
                  if (brands) {
                    dispatch(setUserBrands(brands));
                    const previouslySelectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));

                    if (previouslySelectedBrand) {
                      dispatch(setSelectedBrand(previouslySelectedBrand));
                    } else {
                      dispatch(setSelectedBrand(brands[0]));
                    }
                  }

                  if (role === "main") {
                    portalMainProfile({userId: userId}).then((res) => {
                      const mainProfileId = res[0]?.id;
                      
                      if (mainProfileId) {
                        dispatch(setProfileId(mainProfileId));
                      }

                      if (!policiesSigned) {
                        setOpenPolicies(true);
                      }
                    });
                  }
                  
                  dispatch(setIsLoading(false));
                });
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/analytics/dashboard" element={<ToBePublishedPage />} />
        <Route path="/analytics/reports" element={<AnalyticsReports />} />
        <Route path="/brand-information" element={<BrandInformationPage />} />
        <Route path="/account/documents" element={<ContractPage />} />
        <Route path="/account/subscriptions" element={<Subscriptions />} />
        <Route path="/product/search/*" element={<ProductList />} />
        <Route path="/product/form/v1" element={<ProductForm />} />
        <Route path="/product/form/v2" element={<ProductFormV2 />} /> 
        <Route path="/product/importer" element={<ProductImporter />} /> 
        <Route path="/modelling" element={<ModellingPage />} /> 
        <Route path="/account/billing" element={<BillingPage />} />
        <Route path="/account" element={<GeneralPage />} /> 
        <Route path="/inbox" element={<InboxPage />} /> 
        <Route path="/legal" element={<LegalPage />} /> 
        <Route path="/zip-uploader" element={<ZipUploaderPage />} /> 
        {/* <Route path="/favorite" element={<Favorite />} />
        <Route path="/manufacturers" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contacting" element={<Contacting />} />
        <Route path="/contacting/:id" element={<ContactingPage />} />
        <Route path="/demo" element={<DemoListPage />} />
        <Route path="/demo/:id" element={<Demo />} />
        <Route path="/clients" element={<Customers />} />
        <Route path="/clients/:id" element={<CustomerPage />} />
        <Route path="/plugin" element={<Plugin />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
      <AcceptPoliciesModal open={openPolicies} handleClose={handleOpenPolicies} />
    </>
  );
}

export default App;
