import { Card, CardContent, Grid, Typography } from "@mui/material";

const BrandCard = (props) => {
    const { title, helperText, children, cardProps} = props;

    return (
        <Card sx={{mt: 2, ...cardProps}}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <Typography sx={{mt:1}} variant="body2">
                        {helperText}
                    </Typography>
                    </Grid>
                    <Grid
                        item
                        md={8}
                        xs={12}
                    >
                        {children}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default BrandCard;