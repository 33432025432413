export const converterTest = [
  {
    from: "Product ID *",
    to: "manufacturerProductId",
    conversion: false
  },
  {
    from: "Product name",
    to: "name",
    conversion: false
  },
  {
    from: "Model name",
    to: "model",
    conversion: false
  },
  {
    from: "GTIN",
    to: "gtin",
    conversion: false
  },
  {
    from: "Variable",
    to: "variableProduct",
    conversion: false
  },
  {
    from: "Variant name",
    to: "variant",
    conversion: false
  },
  {
    from: "Main",
    to: "main",
    conversion: false
  },
  {
    from: "Country of Manufacture",
    to: "countryOfManufacture",
    conversion: false
  },
  {
    from: "Description language",
    to: "descriptionLang",
    conversion: false
  },
  {
    from: "Description",
    to: "description",
    conversion: false
  },
  {
    from: "Category",
    to: "category",
    conversion: true,
		conversionOperations: [
      {
        type: "categoryConversion"
      }
    ]
  },
  {
    from: "Subcategory 1",
    to: "subcategory1",
    conversion: true,
		conversionOperations: [
      {
        type: "categoryConversion"
      }
    ]
  },
  {
    from: "Subcategory 2",
    to: "subcategory2",
    conversion: true,
		conversionOperations: [
        {
          type: "categoryConversion"
        }
    ]
  },
  {
    from: "Usage",
    to: "usage",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      },
      {
        type: "usageConversion"
      }
    ]
  },
  {
    from: "Room",
    to: "room",
    conversion: true,
		conversionOperations: [  
      {
        type: "delimStringToArray"
      },
      {
        type: "roomConversion"
      }
    ]
  },
  {
    from: "Europe (full names)",
    to: "europeFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Europe (Alpha-2)",
    to: "europe",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Europe (Alpha-3)",
    to: "europeAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Asia (full names)",
    to: "asiaFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Asia (Alpha-2)",
    to: "asia",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Asia (Alpha-3)",
    to: "asia",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Africa (full names)",
    to: "africaFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Africa (Alpha-2)",
    to: "africa",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Africa (Alpha-3)",
    to: "africaAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "North America (full names)",
    to: "northAmericaFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "North America (Alpha-2)",
    to: "northAmerica",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "North America (Alpha-3)",
    to: "northAmericaAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "South America (full names)",
    to: "southAmericaFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "South America (Alpha-2)",
    to: "southAmerica",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "South America (Alpha-3)",
    to: "southAmericaAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Antarctica (full names)",
    to: "antarcticaFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Antarctica (Alpha-2)",
    to: "antarctica",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Antarctica (Alpha-3)",
    to: "antarcticaAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Oceania (full names)",
    to: "oceaniaFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Oceania (Alpha-2)",
    to: "oceania",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Oceania (Alpha-3)",
    to: "oceaniaAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "All countries (full names)",
    to: "allCountriesFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "All countries (Alpha-2)",
    to: "allCountries",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "All countries (Alpha-3)",
    to: "allCountriesAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Geoblock: Include (full names)",
    to: "geoblockIncludeFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Geoblock: Include (Alpha-2)",
    to: "geoblockInclude",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Geoblock: Include (Alpha-3)",
    to: "geoblockIncludeAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Geoblock: Exclude (full names)",
    to: "geoblockExcludeFullNames",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Geoblock: Exclude (Alpha-2)",
    to: "geoblockExclude",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "Geoblock: Exclude (Alpha-3)",
    to: "geoblockExcludeAlpha3",
    conversion: true,
		conversionOperations: [
      {
        type: "delimStringToArray"
      }
    ]
  },
  {
    from: "IFC Classification",
    to: "classification.ifcClassification",
    conversion: false
  },
  {
    from: "Uniclass 2015 Code",
    to: "classification.uniclass2015Code",
    conversion: false
  },
  {
    from: "Uniclass 2015 Desc",
    to: "classification.uniclass2015Desc",
    conversion: false
  },
  {
    from: "OmniClass Number",
    to: "classification.omniclassNumber",
    conversion: false
  },
  {
    from: "OmniClass Title",
    to: "classification.omniclassTitle",
    conversion: false
  },
  {
    from: "Uniclass 20 Code",
    to: "classification.uniclass20Code",
    conversion: false
  },
  {
    from: "Uniclass 20 Desc",
    to: "classification.uniclass20Desc",
    conversion: false
  },
  {
    from: "NBS Reference Code",
    to: "classification.nbsReferenceCode",
    conversion: false
  },
  {
    from: "NBS Reference Desc",
    to: "classification.nbsReferenceDesc",
    conversion: false
  },
  {
    from: "ETIM Art Class",
    to: "etimClassification",
    conversion: false
  },
  {
    from: "EPD Standard",
    to: "epd.standard",
    conversion: false
  },
  {
    from: "EPD Operator",
    to: "epd.operator",
    conversion: false
  },
  {
    from: "EPD Product name",
    to: "epd.productName",
    conversion: false
  },
  {
    from: "EPD Link",
    to: "epd.link",
    conversion: false
  },
  {
    from: "EPD Issue date",
    to: "epd.issueDate",
    conversion: false
  },
  {
    from: "EPD Valid to date",
    to: "epd.validToDate",
    conversion: false
  },
  {
    from: "Sound reduction index (dB)",
    to: "details.soundReductionIndex.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Colour",
    to: "details.colour.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Certificates",
    to: "details.certificates.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Testing",
    to: "details.testing.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Number of Leafs",
    to: "details.numberOfLeafs.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Muntins",
    to: "details.muntins.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Vertical muntins max",
    to: "details.verticalMuntinsMax.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Horizontal muntins max",
    to: "details.horizontalMuntinsMax.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Number of seats",
    to: "details.numberOfSeats.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Extendable",
    to: "details.extendable.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Foldable",
    to: "details.foldable.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Stackable",
    to: "details.stackable.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Weatherproof",
    to: "details.weatherproof.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Number of sashes",
    to: "details.numberOfSashes.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Depth with lid fully open (mm)",
    to: "details.depthWithLidFullyOpen.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Depth with lid fully open (cm)",
    to: "details.depthWithLidFullyOpen.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Daylight Width (mm)",
    to: "details.daylightWidth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Daylight Width (cm)",
    to: "details.daylightWidth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Daylight Height (mm)",
    to: "details.daylightHeight.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Daylight Height (cm)",
    to: "details.daylightHeight.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      } ,
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Frame Width (mm)",
    to: "details.frameWidth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Frame Width (cm)",
    to: "details.frameWidth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Frame Height (mm)",
    to: "details.frameHeight.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Frame Height (cm)",
    to: "details.frameHeight.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Leaf Thickness (mm)",
    to: "details.leafThickness.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Leaf Thickness (cm)",
    to: "details.leafThickness.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Nominal size (DN)",
    to: "details.nominalSize.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Length (mm)",
    to: "details.length.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Length (cm)",
    to: "details.length.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Length (m)",
    to: "details.length.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Width (mm)",
    to: "details.width.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Width (cm)",
    to: "details.width.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Width (m)",
    to: "details.width.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Height (mm)",
    to: "details.height.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Height (cm)",
    to: "details.height.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Height (m)",
    to: "details.height.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Thickness (mm)",
    to: "details.thickness.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Thickness (cm)",
    to: "details.thickness.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Thickness (m)",
    to: "details.thickness.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Diameter (mm)",
    to: "details.diameter.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Diameter (cm)",
    to: "details.diameter.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Diameter (m)",
    to: "details.diameter.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Radius (mm)",
    to: "details.radius.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Radius (cm)",
    to: "details.radius.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Radius (m)",
    to: "details.radius.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Area (mm2)",
    to: "details.area.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Area (cm2)",
    to: "details.area.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm²",
        to: "mm²",
        operation: "multiply",
        calc: 100
      }
    ]
  },
  {
    from: "Area (m2)",
    to: "details.area.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m²",
        to: "mm²",
        operation: "multiply",
        calc: 1000000
      }
    ]
  },
  {
    from: "Volume (cm³)",
    to: "details.volume.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Volume (m³)",
    to: "details.volume.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m³",
        to: "cm³",
        operation: "multiply",
        calc: 1000000
      }
    ]
  },
  {
    from: "Depth (mm)",
    to: "details.depth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Depth (cm)",
    to: "details.depth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "cm",
        to: "mm",
        operation: "multiply",
        calc: 10
      }
    ]
  },
  {
    from: "Depth (m)",
    to: "details.depth.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "m",
        to: "mm",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Frequency (Hz)",
    to: "details.frequency.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Voltage (V)",
    to: "details.voltage.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Fuse (A)",
    to: "details.fuse.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Load (W)",
    to: "details.load.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Rated power (W)",
    to: "details.ratedPower.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Connection type",
    to: "details.connectionType.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Energy consumption in convection mode (kWh)",
    to: "details.energyConsumptionInConvectionMode.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Energy consumption in recirculation mode (kWh)",
    to: "details.energyConsumptionInRecirculationMode.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Energy Source",
    to: "details.energySource.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Energy efficiency class",
    to: "details.energyEfficiencyClass.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Thermal transmittance (W/m²K)",
    to: "details.thermalTransmittance.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Energy consumption (kWh)",
    to: "details.energyConsumption.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Fire rating",
    to: "details.fireRating.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Bulb type",
    to: "details.bulbType.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
  ]
  },
  {
    from: "Luminous flux (lm)",
    to: "details.luminousFlux.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Luminous efficacy (lm/W)",
    to: "details.luminousEfficacy.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Colour temperature (K)",
    to: "details.colourTemperature.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Colour rendering index (CRI)",
    to: "details.colourRenderingIndex.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Colour of light",
    to: "details.colourOfLight.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Television lighting consistency index (TLCI)",
    to: "details.televisionLightingConsistencyIndex.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Dimming range (%)",
    to: "details.dimmingRange.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Average rated life (h)",
    to: "details.averageRatedLife.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Material",
    to: "details.material.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Net volume (m³)",
    to: "details.netVolume.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Weight (kg)",
    to: "details.weight.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Flow coefficient (m³/h)",
    to: "details.flowCoefficient.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Operating temperature (°C)",
    to: "details.operatingTemperature.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Operating pressure (kPa)",
    to: "details.operatingPressure.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Nominal pressure (PN) (bar)",
    to: "details.nominalPressure.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Protection class (IP rating)",
    to: "details.protectionClass.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Impact resistance (IK rating)",
    to: "details.impactResistance.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Installation type",
    to: "details.installationType.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Permissible humidity (%)",
    to: "details.permissibleHumidity.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Resistance to repeated opening and closing",
    to: "details.resistanceToRepeatedOpeningAndClosing.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Burglary protection",
    to: "details.burglaryProtection.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Air permeability",
    to: "details.airPermeability.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Watertightness",
    to: "details.watertightness.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Wind resistance",
    to: "details.windResistance.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Shear strength (kPa)",
    to: "details.shearStrength.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Shear strength (mPa)",
    to: "details.shearStrength.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "mPa",
        to: "kPa",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Tensile strength (kPa)",
    to: "details.tensileStrength.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Tensile strength (mPa)",
    to: "details.tensileStrength.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "mPa",
        to: "kPa",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Compressive strength (kPa)",
    to: "details.compressiveStrength.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Compressive strength (mPa)",
    to: "details.compressiveStrength.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "mPa",
        to: "kPa",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Density (kg/m³)",
    to: "details.density.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Current (A)",
    to: "details.current.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Thermal resistance (m²K/W)",
    to: "details.thermalResistance.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Mechanical stress (kPa)",
    to: "details.mechanicalStress.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Mechanical stress (mPa)",
    to: "details.mechanicalStress.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      },
      {
        type: "unit",
        from: "mPa",
        to: "kPa",
        operation: "multiply",
        calc: 1000
      }
    ]
  },
  {
    from: "Carbon footprint (kg CO₂e)",
    to: "details.carbonFootprint.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionNumeric"
      }
    ]
  },
  {
    from: "Passive House efficiency class",
    to: "details.passiveHouseEfficiencyClass.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Fire rating materials",
    to: "details.fireRatingMaterials.value",
    conversion: true,
		conversionOperations: [
      {
        type: "conversionString"
      }
    ]
  },
  {
    from: "Images",
    to: "imagesFilenames",
    conversion: true,
		conversionOperations: [
      {
        type: "separateFilenames"
      }
    ]
  },
  {
    from: "Revit",
    to: "revitFilenames",
    conversion: true,
		conversionOperations: [
      {
        type: "separateFilenames"
      }
    ]
  },
  {
    from: "Other BIM formats",
    to: "otherBimFormatsFilenames",
    conversion: true,
		conversionOperations: [
      {
        type: "separateFilenames"
      }
    ]
  },
  {
    from: "Other files",
    to: "otherFilesFilenames",
    conversion: true,
		conversionOperations: [
      {
        type: "separateFilenames"
      }
    ]
  },
  {
    from: "sku",
    to: "sku",
    conversion: false
  },
  {
    from: "brandId",
    to: "brandId",
    conversion: false
  },
  {
    from: "brandName",
    to: "brandName",
    conversion: false
  },
  {
    from: "id",
    to: "id",
    conversion: false
  },
  {
    from: "productId",
    to: "productId",
    conversion: false
  },
  {
    from: "status",
    to: "status",
    conversion: false
  }
]

