import React from "react";
import useDocumentTitle from "../useDocumentTitle";

// components
import General from "../components/General";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import Loader from "../components/Loader/loader";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";


const GeneralPage = () => {
  useDocumentTitle('Bimroom Portal - Account - General');
  const isLoading = useSelector((store) => store.user.isLoading);

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> :
          <DashboardLayout header="Account - general">
            <General />
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default GeneralPage;