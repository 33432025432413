import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Plus } from "../../icons/plus";
import { useState } from "react";
import { Trash } from "../../icons/trash";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #313133',
    borderRadius: '8px',
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "100%",
    //overflow:'scroll',
    p: 4,
  };

const channels = [
    {value: "youtube", label: "YouTube"}
]

  
const VideosModal = (props) => {
    const { formik, open, handleClose, setChangesMade } = props;
    const [channel, setChannel] = useState("youtube");
    const [videoId, setVideoId] = useState("");

    const handleAddVideos = () => {
        // if (channel === "" || videoId === "") {
        //     return;
        // }
        const payload = {
            "title": "video " + (formik.values?.videos.length + 1),
            "type": "video",
            "source": channel,
            "url": "https://img.youtube.com/vi/" + videoId + "/0.jpg",
            "id": videoId
        }
        formik.setFieldValue("videos", [...formik.values.videos, payload]);
        setChangesMade(true);
        setChannel("youtube");
        setVideoId("");
        handleClose();
    }

    const handleChangeChannel = (event) => {
        setChannel(event.target.value);
    }

    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Add video id</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel id="video-select-label">Channel</InputLabel>
                        <Select
                            labelId="video-select-label"
                            id="video-select"
                            value={channel}
                            label="Channel"
                            onChange={handleChangeChannel}
                        >
                            {channels.map((channel, index) => {
                                return <MenuItem key={index} value={channel.value}>{channel.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="video-id"
                        label="Video ID"
                        fullWidth
                        value={videoId}
                        onChange={(e) => setVideoId(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" onClick={handleAddVideos}>Add</Button>
                </Grid>
            </Grid>
            
          </Box>
        </Modal>
      </div>
    );
  }

const Videos = (props) => {
    const { formik, setChangesMade} = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Table sx={{borderCollapse: "unset"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                        Source
                        </TableCell>
                        <TableCell>
                        Id
                        </TableCell>
                        <TableCell align="right">
                        Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                {formik?.values?.videos?.length === 0 && 
                    <TableRow>
                        <TableCell colSpan={4} align="center">
                            No video ids added
                        </TableCell>
                    </TableRow>}
                {formik.values?.videos?.map((media, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell>
                            <Typography variant="body2">
                                {media?.source}
                            </Typography>
                        </TableCell>

                        <TableCell>
                            <Typography variant="body2">
                                {media?.id}
                            </Typography>
                        </TableCell>

                        <TableCell align="right">
                            <Button variant="text" onClick={() => {
                                setChangesMade(true);
                                formik.setFieldValue("videos", formik.values.videos.filter((_, i) => i !== index))
                            }}>
                                <Trash />
                            </Button>
                        </TableCell>


                    </TableRow>)})}
                    <TableRow key="add-video-button">
                        <TableCell 
                        align="center" 
                        onClick={handleOpen} 
                        sx={{
                            cursor: "pointer", 
                            //backgroundColor: "#175c83", 
                            //color: '#fff',
                            border: "1px dashed #175c83",
                            borderRadius: 1,
                            color: '#175c83',
                            transition: "all 0.3s",
                            '&:hover': {
                                backgroundColor: '#175c831A',
                            }  
                        }} 
                        colSpan={4}
                        >
                            <Box sx={{display: "flex", justifyContent: "center"}} >
                                <Plus />
                                <Typography variant="subtitle2">Add video</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody> 
            </Table>
            <VideosModal formik={formik} open={open} handleClose={handleClose} setChangesMade={setChangesMade} />
        </>
    )
}

export default Videos;
