import { 
  useEffect, 
  useState 
} from 'react';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  TextField
  // Slide,
  // Paper
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { wait } from '../../utils/wait';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { FileDropzoneSmall } from '../file-dropzone-small';
import { useSelector } from 'react-redux';
import { portalAddMapper, upload } from '../../api';


export const RequestMapperModal = (props) => {
  const { open, handleClose } = props;
  const [ files, setFiles ] = useState([]);
  const [ uploadedFiles, setUploadedFiles ] = useState([]);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const mainProfileId = useSelector((store) => store.user.mainProfileId)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    //width: "80vw",
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto"
  };

  useEffect(() => {
    const ids = new Set(formik.values.files.map(d => d?.newName));
    const merged = [...formik.values.files, ...uploadedFiles.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("files", merged);
    const existingValues = uploadedFiles.map(item => item.name);
    setFiles(() => files.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedFiles])


  useEffect(() => {
    formik.setFieldValue("brandName", selectedBrand?.brandName)
    formik.setFieldValue("brandId", selectedBrand?.brandId)
  }, [selectedBrand])

  useEffect(() => {
    formik.setFieldValue("status", "requested")
    formik.setFieldValue("id", uuidv4())
  }, [open])

  
  const handleDrop = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleDeleteFile = (name) => {
    let array = formik.values.files;
    const objIndex = array.findIndex((obj => obj.newName == name));
    array[objIndex].deleted = true;
    formik.setFieldValue("files", array)
  }

  const uploadFileToBlob = async (file, id, folder) => {
      const newFile = new File([file], id, {
        type: file?.type,
      });
      // console.log(newFile);
      const response = await upload(newFile, folder);
      return response;
  };

  const handleUpload = () => {
    files.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4()
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "mapper-files").then(res => {
          if (res.status === 200) {
            setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
          }
        });
        
      } catch (error) {
        console.error(error);
      }

    });
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      brandName: '',
      brandId: '',
      mainProfileId: '',
      mapperName: '',
      mapperDescription: '',
      status: '',
      files: []
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      brandName: Yup.string().max(255),
      brandId: Yup.string().max(255),
      mapperName: Yup.string().max(255),
      mapperDescription: Yup.string(),
      status: Yup.string().max(255),
      files: Yup.array()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        try {
          const response = await portalAddMapper(values);
          if (response === 200) {
            handleClose();
          }
          // console.log(values);
          // alert('Changes saved')
        } catch (err) {
          console.error(err);
        }

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Changes saved!');
        // await wait(500);
        // helpers.resetForm();
        // refresh();

        // close();

      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
      onSubmit={formik.handleSubmit}
      // {...other}
      >
        <Box sx={style}>
          <Typography
            color="textSecondary"
            sx={{
              my: 1,
              //mt: 2,
            }}
            variant="subtitle2"
          >
            Request a new mapper
          </Typography>
          <Typography
            color="textSecondary"
            sx={{
              my: 1
            }}
            variant="body2"
          >
            Add your request for the data mapper using the form below. Most importantly, we need a file from which we can create the mapper. Supported file formats are json (either product data or schema), excel, csv and xml.
          </Typography>
          <TextField
            // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
            fullWidth
            // helperText={formik.touched.gtin && formik.errors.gtin}
            disabled
            required
            label="Brand"
            name="brandName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.brandName}
          />
          <TextField
            // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
            fullWidth
            // helperText={formik.touched.gtin && formik.errors.gtin}
            label="Mapper name"
            name="mapperName"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.mapperName}
            sx={{ mt: 1 }}
          />
          <TextField
            // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
            fullWidth
            // helperText={formik.touched.gtin && formik.errors.gtin}
            label="Description"
            name="mapperDescription"
            multiline
            minRows={3}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.mapperDescription}
            sx={{ my: 1 }}
          />
          <Box sx={{ mb: 2 }}>
            <Typography color="textSecondary" variant="subtitle2">Files *</Typography>
            <FileDropzoneSmall
              // accept={{
              //   "image/png": [".png"],
              //   "image/jpeg": [".jpg", ".jpeg"],
              //   "image/webp": [".webp"],
              //   "image/tiff": [".tiff"]
              // }}
              files={files}
              onDrop={handleDrop}
              onRemove={handleRemove}
              onRemoveAll={handleRemoveAll}
              onUpload={handleUpload}
            />
            <FormikProvider value={formik}>
              <FieldArray name="files">
                {() => (
                  <div>
                    {formik.values.files.map((p, index) => {
                      const name = `files[${index}].name`;
                      const info = `files[${index}].info`;

                      return (
                        !p.deleted &&  <Box key={p.newName}>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <TextField
                                margin="dense"
                                variant="outlined"
                                label="File name"
                                name={name}
                                value={p.name}
                                disabled
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                margin="dense"
                                variant="outlined"
                                label="Info"
                          
                                name={info}
                                value={p.info}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Grid>
                            <Grid item xs={2} sx={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center"
                            }}>
                              <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteFile(p?.newName)}>Delete</Button>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </div> 
                )}
              </FieldArray>
            </FormikProvider>
          </Box>
          <Button disabled={formik?.values?.files?.length > 0 ? false : true} type="submit" variant="contained">Request mapper</Button>
        </Box>
      </form>
    </Modal>
  )
}
