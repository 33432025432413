 const SERVER_URL = "https://portal.bimroom.com";
 // const SERVER_URL = "http://localhost:7071";
 const ANALYTICS_SERVER_URL = "http://localhost:7072"


export const API = {
  // NEW API

  portalBrands: SERVER_URL + "/api/portal-brands",
  portalAddBrand: SERVER_URL + "/api/portal-add-brand",
  portalAddPending: SERVER_URL + "/api/portal-add-pending",
  portalUsers: SERVER_URL + "/api/portal-users",
  portalUpdateUser: SERVER_URL + "/api/portal-update-user",
  portalUpdateBrand: SERVER_URL + "/api/portal-update-brand",
  portalUpdateProduct: SERVER_URL + "/api/portal-update-product",
  portalAddBrand: SERVER_URL + "/api/portal-add-brand",
  portalProducts: SERVER_URL + "/api/portal-products",
  portalPending: SERVER_URL + "/api/portal-pending",
  portalProduct: SERVER_URL + "/api/portal-product",
  portalProductV2: SERVER_URL + "/api/portal-product-v2",
  portalAddProduct: SERVER_URL + "/api/portal-add-product",
  portalAddProducts: SERVER_URL + "/api/portal-add-products",
  portalSubscriptions: SERVER_URL + "/api/portal-subscriptions",
  portalDocuments: SERVER_URL + "/api/portal-documents",
  portalMessages: SERVER_URL + "/api/portal-messages",
  portalMainProfile: SERVER_URL + "/api/portal-main-profile",
  portalTasks: SERVER_URL + "/api/portal-tasks",
  portalAcceptPolicies: SERVER_URL + "/api/portal-accept-policies",
  portalAddMapper: SERVER_URL + "/api/portal-add-mapper",
  portalModellingRequest: SERVER_URL + "/api/portal-modelling-request",
  portalModelling: SERVER_URL + "/api/portal-modelling",

  // product publishing
  publishProduct: SERVER_URL + "/api/publish-product",
  unpublishProduct: SERVER_URL + "/api/unpublish-product",

  // file upload
  upload: SERVER_URL + "/api/upload",
  uploadAsWebp: SERVER_URL + "/api/upload-to-webp",
  
  // analytics

  getViewsPerDay: SERVER_URL + "/api/get-views-d",
  getTopList: SERVER_URL + "/api/get-top-list",
  locationData: SERVER_URL + "/api/location-data",

  // google analytics
  pageViews: ANALYTICS_SERVER_URL + "/api/pageViews",
  downloads: ANALYTICS_SERVER_URL + "/api/downloads",

  // contacting
  addCompany: SERVER_URL + "/api/contacting/post-contacting",
  getContacting: SERVER_URL + "/api/contacting/get-contacting",
  updateContacting: SERVER_URL + "/api/contacting/update-contacting",
  getSingleProfile: SERVER_URL + "/api/contacting/get-single-profile",
  
  /// contacting logs and contacts
  getContactingLog: SERVER_URL + "/api/contacting/get-contacting-log",
  postContactingLog: SERVER_URL + "/api/contacting/post-contacting-log",
  updateContactingLog: SERVER_URL + "/api/contacting/update-contacting-log",
  getContacts: SERVER_URL + "/api/contacting/get-contacts",
  postContact: SERVER_URL + "/api/contacting/post-contact",
  updateContact: SERVER_URL + "/api/contacting/update-contact",
  removeDefaults: SERVER_URL + "/api/contacting/remove-defaults",

  // demo accounts
  addDemo: SERVER_URL + "/api/post-demo-account",
  getDemos: SERVER_URL + "/api/get-demo-accounts",
  updateDemo: SERVER_URL + "/api/update-demo-account",
  getSingleDemo: SERVER_URL + "/api/get-single-demo",

  // demo products
  addDemoProduct: SERVER_URL + "/api/post-demo-product",
  getDemoProducts: SERVER_URL + "/api/get-demo-products",

  // contracts
  addContract: SERVER_URL + "/api/post-contract",
  getContracts: SERVER_URL + "/api/get-contracts",
  updateContract: SERVER_URL + "/api/update-contract",
  getSingleContract: SERVER_URL + "/api/get-single-contract",
  signContract: SERVER_URL + "/api/sign-contract",
  getSignedContract: SERVER_URL + "/api/get-signed-contract",

  // clients and clients' products
  getClients: SERVER_URL + "/api/clients/get-clients",
  postClient: SERVER_URL + "/api/clients/post-client",
  updateClient: SERVER_URL + "/api/clients/update-client",
  getProducts: SERVER_URL + "/api/clients/get-products",
  getCompanyInfo: SERVER_URL + "/api/get-company-info",

  // subscriptions


  // etim
  
  getEtimFeatures: SERVER_URL + "/api/get-etim-features",
  getEtimValues: SERVER_URL + "/api/get-etim-values",

  // user

  getBrands: SERVER_URL + "/api/get-companies",

  // categories

  categories: SERVER_URL + "/api/categories",

  // publish brand

  publishBrand: SERVER_URL + "/api/publish-brand",
  unpublishBrand: SERVER_URL + "/api/unpublish-brand",


};
