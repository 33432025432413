import React, { useEffect } from "react";

// components
import useDocumentTitle from "../useDocumentTitle";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";
import ToBePublished from "../components/ToBePublished";

const ToBePublishedPage = () => {
  const isLoading = useSelector((store) => store.user.isLoading);

  useDocumentTitle('Bimroom portal - Home');

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
          <DashboardLayout>
            <ToBePublished />
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default ToBePublishedPage;