import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

// components
import Legal from "../components/Legal";
import useDocumentTitle from "../useDocumentTitle";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";

const LegalPage = () => {
  const [ document, setDocument] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom portal - Legal');

  useEffect(() => {
    const documentType = searchParams.get("document");
    let url = "";
    if (documentType === "terms") {
      url = "https://cdn.bimroom.com/static/Terms%20and%20Conditions.pdf"
    } else {
      url = "https://cdn.bimroom.com/static/Privacy%20Policy.pdf"
    }
    setDocument(url)
  }, [searchParams])

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
          <DashboardLayout>
            <Legal document={document} /> 
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default LegalPage;