import { 
  Fragment, 
  useEffect, 
  useRef, 
  useState 
  // useEffect, 
  // forwardRef 
} from 'react';
// import { useRouter } from 'next/router';
// import numeral from 'numeral';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  // InputAdornment,
  // LinearProgress,
  // MenuItem,
  // Switch,
  // Modal,
  Table,
  TableBody,
  TableCell,
  // TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  // TextField,
  Typography,
  // ListItemText, 
  // ListItem,
  // List,
  AppBar,
  Toolbar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  LinearProgress
  // Slide,
  // Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { DotsHorizontal as DotsHorizontalIcon } from '../../icons/dots-horizontal';
import { Image as ImageIcon } from '../../icons/image';
import { Scrollbar } from '../scrollbar';
import { v4 as uuidv4 } from 'uuid';
import { ImportFile } from './import-file';
import { ImportTable } from './import-table';
import { Download as DownloadIcon } from '../../icons/download';
import { wait } from '../../utils/wait';
import { useSelector } from 'react-redux';
import { portalAddProducts } from '../../api';
import { AttachFilesTable } from './attach-files-table';
import { useNavigate } from 'react-router-dom';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const downloadFile = (link) => {
  window.open(link, '_blank', 'noreferrer');
  //window.location.href = "https://cdn.bimroom.com/static/bimroom_copyright_license_agreement.pdf"
}

const steps = [
  {
    label: 'Download a template and fill in your data',
    description: `Please download a template file and fill it with your product data. If you have already done that, you may proceed to the next step.`,
    action: 1
  },
  {
    label: 'Import the completed file',
    description: `Please choose the completed file from your computer.`,
    action: 2
  },
  {
    label: 'Preview',
    description: `Please ensure the accuracy of the contents and once you confirm that everything is in order, click on the Upload button. The import tool will then transfer the products to our database.`,
    action: 3
  },
  {
    label: 'Attach files',
    description: `The products have been successfully uploaded to Bimroom. The table below displays the status of each product. In the final stage, you can attach files to each product which can also be completed at a later time in the product catalog.`,
    action: 4
  },
];

const applyPagination = (products, page, rowsPerPage) => products.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);


const DownloadTemplate = () => {
 
  // const {
  //   text,
  //   file
  // } = props;

  return (
    <Box>
      <Box sx={{
          height: 50,
          width: 250,
          border: "1px solid #175c831A",
          borderRadius: 1,
          my: 1,
          "&:hover": {
            backgroundColor: '#175c831A',
            cursor: "pointer"
          }
        }}
        
        onClick={() => downloadFile("https://cdn.bimroom.com/static/Bimroom_Import_Form.xlsx")}
      >
        <Grid container sx={{height: "100%"}}>
          <Grid 
            item 
            xs={2} 
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <DownloadIcon sx={{color: "#175c83"}}/>
          </Grid>
          <Grid 
            item 
            xs={6} 
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Typography variant="subtitle2" color="#175c83">Excel template</Typography>
          </Grid>
          <Grid 
            item 
            xs={4} 
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontStyle: "italic"
            }}
          >
            <Typography variant="body2" color="#175c83">Metric</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const fakeUploader = async (products, newArray, setProgress) => {
  if (products) {
    const productCount = products.length;
    let tmp = [];

    for (let i = 0; i < productCount; i++) {
      await wait(1000);
      setProgress(Math.floor((i+1)/productCount * 100));
      //console.log(products[i]?.name);
      tmp.push({
        id: products[i]?.id,
        name: products[i]?.name,
        //description: products[i]?.description
      });
    }
    newArray(tmp);
    setProgress(0);
  }
}

export const ImporterBimroom = (props) => {
  const {
    updated
  } = props;
  const ref = useRef();
  const [file, setFile] = useState();
  const [productList, setProductList] = useState([]);
  const [hasProducts, setHasProducts] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [array, setArray] = useState([]);
  const [completedArray, setCompletedArray] = useState([]);
  const [page, setPage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const navigate = useNavigate();

  const handleNext = async () => {
    //console.log(activeStep);
    if (activeStep === 1) {
      try {
        await ref.current.submit();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error(error);
      }
    } else if (activeStep === 2) {
      try {
        //await fakeUploader(array, setCompletedArray, setProgress);
        const payload = {
          brandId: selectedBrand?.brandId,
          products: productList
        }
        const response = await portalAddProducts(payload);

        if (response === 200) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          alert("Product upload failed")
        }
        
      } catch (error) {
        console.error(error);
      }
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const paginatedProducts = applyPagination(productList, page, rowsPerPage);
  const paginatedCompleted = applyPagination(completedArray, page, rowsPerPage);

  useEffect(() => {
    if (hasProducts) {
      setActiveStep(activeStep + 1);
      setHasProducts(false);
    }
  }, [hasProducts])

  return (
    <Box sx={{ mx: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
                { step.action === 1 && <DownloadTemplate /> }
                { step.action === 2 && <ImportFile 
                    ref={ref} 
                    setArray={setArray}
                    file={file}
                    setFile={setFile}
                    //productList={productList}
                    setHasProducts={setHasProducts}
                    setProductList={setProductList}
                  />
                }
                { step.action === 3 && <ImportTable 
                    version={"preview"}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    products={paginatedProducts}
                    setProducts={setProductList}
                    productsCount={productList.length}
                    rowsPerPage={rowsPerPage}
                  /> 
                } 
                { step.action === 4 && <AttachFilesTable 
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    products={paginatedProducts}
                    setProducts={setProductList}
                    productsCount={productList.length}
                    rowsPerPage={rowsPerPage}
                  /> 
                }
                { progress > 0 && <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value={progress} />
                </Box>
                }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={ handleNext }
                    sx={{ mt: 1, mr: 1 }}
                    disabled={ progress > 0 || productList.length === 0 && index === 1 ? true : false }
                  >
                    { index === 0 || index === 3 ? 'Continue' : 'Upload' }
                  </Button>
                  <Button
                    disabled={ index === 0 }
                    onClick={ handleBack }
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Grid container spacing={1}>
            <Grid item >
              <Button variant="contained" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Grid>
            <Grid item >
              <Button variant="contained" onClick={() => navigate("/product/search")} sx={{ mt: 1, mr: 1 }}>
                Go to product list
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

// ProductListTable.propTypes = {
//   products: PropTypes.array.isRequired,
//   productsCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
