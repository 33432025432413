import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
//import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  IconButton,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '../icons/menu';
import { Bell as BellIcon } from '../icons/bell';
import { Search as SearchIcon } from '../icons/search';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { Users as UsersIcon } from '../icons/users';
import { AccountPopover } from './account-popover';
import { Bimroom as BimroomIcon} from '../icons/bimroom-small';
// import { ContactsPopover } from './contacts-popover';
// import { ContentSearchDialog } from './content-search-dialog';
// import { NotificationsPopover } from './notifications-popover';
// import { LanguagePopover } from './language-popover';

// const languages = {
//   en: '/static/icons/uk_flag.svg',
//   de: '/static/icons/de_flag.svg',
//   es: '/static/icons/es_flag.svg'
// };

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  //backgroundColor: theme.palette.background.paper,
  //backgroundColor: 'transparent',
  boxShadow: 'none',
  background: 'linear-gradient(144deg, rgba(11,46,65,1) 0%, rgba(23,92,131,1) 86%)',
  backgroundAttachment: 'fixed',
  //height: 32,
  //alignItems: 'start'
  // ...(theme.palette.mode === 'light'
  //   ? {
  //     boxShadow: theme.shadows[3]
  //   }
  //   : {
  //     backgroundColor: theme.palette.background.paper,
  //     borderBottomColor: theme.palette.divider,
  //     borderBottomStyle: 'solid',
  //     borderBottomWidth: 1,
  //     boxShadow: 'none'
  //   })
}));


export const DashboardNavbar = (props) => {
  const { header, onOpenSidebar, ...other } = props;

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          },
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            height: 32,
            // left: 0,
            // px: 2,
            alignItems: 'start'
          }}
          variant="dense"
        >
          <Box sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none'
            },
            height: 32, 
            width: 32,
            alignSelf: "center",
            }}>
            <BimroomIcon style={{alignSelf: "center", margin: "auto"}} />
          </Box>
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              },
              // backgroundColor: '#fff',
              // "&:hover": {
              //   backgroundColor: '#fff'
              // }
              color: '#fff',
              zIndex: 900
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{
            position: 'fixed',
            left: {
              xs: 0,
              lg: 280
            },
            width: {
              xs: "100%",
              lg: "calc(100% - 280px)"
            },
            height: 32
          }}>
            <Box sx={{
              display: "flex", 
              height: "100%", 
              justifyContent: "center", 
              alignItems: "center"
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: 1,
                letterSpacing: 0.5,
                textTransform: 'uppercase'
              }} align="center">{header}</Typography>
            </Box>
          </Box>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          {/* <LanguageButton /> */}
          {/* <ContentSearchButton />
          <ContactsButton />
          <NotificationsButton /> */}
          {/* <AccountButton /> */}
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

// DashboardNavbar.propTypes = {
//   onOpenSidebar: PropTypes.func
// };
