import { 
  useLocation,
  useNavigate 
} from 'react-router-dom';
import { 
  useSelector
} from 'react-redux';
import { 
  Box, 
  Card, 
  CardContent, 
  Grid, 
  Typography
} from '@mui/material';

export const BrandGuard = (props) => {

  const selectedBrand = useSelector((store) => store.user.selectedBrand);

  const { children } = props;

  return (
    <>
      {!selectedBrand?.brandId ? 
        <Box
        sx={{
          //backgroundColor: 'background.default',
          flexGrow: 1,
          p: 3,
          minHeight: "calc(100vh - 48px)"
        }}
        {...props}>
          <Card sx={{
            mt: 1, 
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">
                    You need to select a brand from the sidebar menu
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box> : 
        children
      }
    </>
  );
};

// DashboardSidebar.propTypes = {
//   onClose: PropTypes.func,
//   open: PropTypes.bool
// };
