import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Plus } from "../../icons/plus";
import { useState } from "react";
import { Trash } from "../../icons/trash";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #313133',
    borderRadius: '8px',
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "100%",
    //overflow:'scroll',
    p: 4,
  };

const channels = [
    {value: "facebook", label: "Facebook"},
    {value: "instagram", label: "Instagram"},
    {value: "twitter", label: "Twitter/X"},
    {value: "linkedIn", label: "LinkedIn"},
    {value: "pinterest", label: "Pinterest"},
    {value: "youtube", label: "YouTube"},
    {value: "tiktok", label: "TikTok"},
    {value: "snapchat", label: "Snapchat"}
]
  
const SocialMediaModal = (props) => {
    const { formik, open, handleClose, setChangesMade } = props;
    const [channel, setChannel] = useState("");
    const [link, setLink] = useState("");

    const handleAddSocialMedia = () => {
        // if (channel === "" || link === "") {
        //     return;
        // }
        formik.setFieldValue("socialMedia", [...formik.values.socialMedia, {channel: channel, link: link}]);
        setChangesMade(true);
        setChannel("");
        setLink("");
        handleClose();
    }

    const handleChangeChannel = (event) => {
        setChannel(event.target.value);
    }

    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Add social media channel</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel id="social-media-select-label">Channel</InputLabel>
                        <Select
                            labelId="social-media-select-label"
                            id="social-media-select"
                            value={channel}
                            label="Channel"
                            onChange={handleChangeChannel}
                        >
                            {channels.map((channel, index) => {
                                return <MenuItem key={index} value={channel.value}>{channel.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="social-media-link"
                        label="URL"
                        fullWidth
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" onClick={handleAddSocialMedia}>Add</Button>
                </Grid>
            </Grid>
            
          </Box>
        </Modal>
      </div>
    );
  }

const SocialMedia = (props) => {
    const { formik, setChangesMade } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Table sx={{borderCollapse: "unset"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                        Channel
                        </TableCell>
                        <TableCell>
                        URL
                        </TableCell>
                        <TableCell align="right">
                        Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {formik?.values?.socialMedia?.length === 0 && 
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                No social media links added
                            </TableCell>
                        </TableRow>
                    }
                    {formik.values?.socialMedia?.map((media, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <Typography variant="body2">
                                        {media?.channel}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography variant="body2">
                                        {media?.link}
                                    </Typography>
                                </TableCell>

                                <TableCell align="right">
                                    <Button variant="text" onClick={() => {
                                        setChangesMade(true);
                                        formik.setFieldValue("socialMedia", formik.values?.socialMedia.filter((_, i) => i !== index))
                                    }}>
                                        <Trash />
                                    </Button>
                                </TableCell>


                            </TableRow>
                        )
                    })}
                    <TableRow key="add-social-media-button">
                        <TableCell 
                        align="center" 
                        onClick={handleOpen} 
                        sx={{
                            cursor: "pointer", 
                            //backgroundColor: "#175c83", 
                            //color: '#fff',
                            border: "1px dashed #175c83",
                            borderRadius: 1,
                            color: '#175c83',
                            transition: "all 0.3s",
                            '&:hover': {
                                backgroundColor: '#175c831A',
                            }  
                        }} 
                        colSpan={4}
                        >
                            <Box sx={{
                                display: "flex", 
                                justifyContent: "center", 

                            }} >
                                <Plus />
                                <Typography variant="subtitle2">Add social media channel</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody> 
            </Table>
            <SocialMediaModal formik={formik} open={open} handleClose={handleClose} setChangesMade={setChangesMade} />
        </>
    )
}

export default SocialMedia;
