import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Divider, Grid, Input, Typography } from '@mui/material';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { Search as SearchIcon } from '../../icons/search';
import { MultiSelect } from '../multi-select';
import { useNavigate } from 'react-router-dom';

const categoryOptions = [
  {
      label: 'Building Elements & Modules',
      value: 'building-elements-and-modules'
  }, 
  {
      label: 'Casework',
      value: 'casework'
  }, 
  {
      label: 'Circulation',
      value: 'circulation'
  }, 
  {
      label: 'Construction',
      value: 'construction'
  }, 
  {
      label: 'Doors',
      value: 'doors'
  }, 
  {
      label: 'Electrical',
      value: 'electrical'
  }, 
  {
      label: 'Electronics',
      value: 'electronics'
  }, 
  {
      label: 'Fire Products',
      value: 'fire-products'
  }, 
  {
      label: 'Flooring',
      value: 'flooring'
  }, 
  {
      label: 'Furniture',
      value: 'furniture'
  }, 
  {
      label: 'HVAC',
      value: 'hvac'
  }, 
  {
      label: 'Kitchen',
      value: 'kitchen'
  }, 
  {
      label: 'Lighting',
      value: 'lighting'
  }, 
  {
      label: 'Medical & Research Equipment',
      value: 'medical-and-research-equipment'
  }, 
  {
      label: 'Outdoor Furniture',
      value: 'outdoor-furniture'
  }, 
  {
      label: 'Planting',
      value: 'planting'
  }, 
  {
      label: 'Plumbing',
      value: 'plumbing'
  }, 
  {
      label: 'Railings & Fences',
      value: 'railings-and-fences'
  }, 
  {
      label: 'Sanitary',
      value: 'sanitary'
  },
  {
      label: 'Signage',
      value: 'signage'
  },
  {
      label: 'Windows',
      value: 'windows'
  }, 
  {
      label: 'Waste & Recycling',
      value: 'waste-and-recycling'
  }
];

const statusOptions = [
  {
    label: 'Published',
    value: 'published'
  },
  {
    label: 'Processed',
    value: 'processed'
  },
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Draft',
    value: 'draft'
  }
];

// const stockOptions = [
//   {
//     label: 'All',
//     value: 'all'
//   },
//   {
//     label: 'Available',
//     value: 'available'
//   },
//   {
//     label: 'Out of Stock',
//     value: 'outOfStock'
//   }
// ];

export const ProductListFilters = (props) => {
  const { onChange, ...other } = props;
  const [queryValue, setQueryValue] = useState('');
  const [filterItems, setFilterItems] = useState([]);
  const navigate = useNavigate();

  useUpdateEffect(() => {
      const filters = {
        name: undefined,
        category: [],
        status: [],
        inStock: undefined
      };

      // Transform the filter items in an object that can be used by the parent component to call the
      // serve with the updated filters
      filterItems.forEach((filterItem) => {
        switch (filterItem.field) {
          case 'name':
            // There will (or should) be only one filter item with field "name"
            // so we can set up it directly
            filters.name = filterItem.value;
            break;
          case 'category':
            filters.category.push(filterItem.value);
            break;
          case 'status':
            filters.status.push(filterItem.value);
            break;
          case 'inStock':
            // The value can be "available" or "outOfStock" and we transform it to a boolean
            filters.inStock = filterItem.value === 'available';
            break;
          default:
            break;
        }
      });

      onChange?.(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterItems]);

  const handleDelete = (filterItem) => {
    setFilterItems((prevState) => prevState.filter((_filterItem) => {
      return !(filterItem.field === _filterItem.field && filterItem.value === _filterItem.value);
    }));
  };

  const handleQueryChange = (event) => {
    setQueryValue(event.target.value);
  };

  const handleQueryKeyup = (event) => {
    if (event.code === 'Enter' && queryValue) {
      // We only allow one chip for the name field

      const filterItem = filterItems.find((filterItem) => filterItem.field === 'name');

      if (filterItem) {
        setFilterItems((prevState => prevState.map((filterItem) => {
          if (filterItem.field === 'name') {
            return {
              ...filterItem,
              value: queryValue
            };
          }

          return filterItem;
        })));
      } else {
        setFilterItems((prevState) => [
          ...prevState,
          {
            label: 'Name',
            field: 'name',
            value: queryValue
          }
        ]);
      }

      setQueryValue('');
    }
  };

  const handleCategoryChange = (values) => {
    setFilterItems((prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'category') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = categoryOptions.find((option) => option.value === value);

          newFilterItems.push({
            label: 'Category',
            field: 'category',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    });
  };

  const handleStatusChange = (values) => {
    setFilterItems((prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'status') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = statusOptions.find((option) => option.value === value);

          newFilterItems.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    });
  };

  // const handleStockChange = (values) => {
  //   // Stock can only have one value, even if displayed as multi-select, so we select the first one.
  //   // This example allows you to select one value or "All", which is not included in the
  //   // rest of multi-selects.

  //   setFilterItems((prevState) => {
  //     // First cleanup the previous filter items
  //     const newFilterItems = prevState.filter((filterItem) => filterItem.field !== 'inStock');
  //     const latestValue = values[values.length - 1];

  //     switch (latestValue) {
  //       case 'available':
  //         newFilterItems.push({
  //           label: 'Stock',
  //           field: 'inStock',
  //           value: 'available',
  //           displayValue: 'Available'
  //         });
  //         break;
  //       case 'outOfStock':
  //         newFilterItems.push({
  //           label: 'Stock',
  //           field: 'inStock',
  //           value: 'outOfStock',
  //           displayValue: 'Out of Stock'
  //         });
  //         break;
  //       default:
  //         // Should be "all", so we do not add this filter
  //         break;
  //     }

  //     return newFilterItems;
  //   });
  // };

  // We memoize this part to prevent re-render issues
  const categoryValues = useMemo(() => filterItems
    .filter((filterItems) => filterItems.field === 'category')
    .map((filterItems) => filterItems.value), [filterItems]);

  const statusValues = useMemo(() => filterItems
    .filter((filterItems) => filterItems.field === 'status')
    .map((filterItems) => filterItems.value), [filterItems]);

  // const stockValues = useMemo(() => {
  //   const values = filterItems
  //     .filter((filterItems) => filterItems.field === 'inStock')
  //     .map((filterItems) => filterItems.value);

  //   // Since we do not display the "all" as chip, we add it to the multi-select as a selected value
  //   if (values.length === 0) {
  //     values.unshift('all');
  //   }

  //   return values;
  // }, [filterItems]);

  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <SearchIcon fontSize="small" />
        <Box
          sx={{
            flexGrow: 1,
            ml: 3
          }}
        >
          <Input
            disableUnderline
            fullWidth
            onChange={handleQueryChange}
            onKeyUp={handleQueryKeyup}
            placeholder="Search by product name"
            value={queryValue}
          />
        </Box>
      </Box>
      <Divider />
      {filterItems.length > 0
        ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 2
            }}
          >
            {filterItems.map((filterItem, i) => (
              <Chip
                key={i}
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      '& span': {
                        fontWeight: 600
                      }
                    }}
                  >
                      <span>
                        {filterItem.label}
                      </span>
                    :
                    {' '}
                    {filterItem.displayValue || filterItem.value}
                  </Box>
                )}
                onDelete={() => handleDelete(filterItem)}
                sx={{ m: 1 }}
                variant="outlined"
              />
            ))}
          </Box>
        )
        : (
          <Box sx={{ p: 3 }}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              No filters applied
            </Typography>
          </Box>
        )}
      <Divider />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 1
            }}
          >
            <MultiSelect
              label="Category"
              onChange={handleCategoryChange}
              options={categoryOptions}
              value={categoryValues}
            />
            <MultiSelect
              label="Status"
              onChange={handleStatusChange}
              options={statusOptions}
              value={statusValues}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 1
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Button onClick={() => navigate("/product/form/v2")} variant="outlined">Add new</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => navigate("/product/importer")} variant="outlined">Import</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

ProductListFilters.propTypes = {
  onChange: PropTypes.func
};
