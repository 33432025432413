import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const usageOptions = [
    {value: 'commercial', label: 'Commercial'},
    {value: 'educational', label: 'Educational'},
    {value: 'industrial', label: 'Industrial'},
    {value: 'medical', label: 'Medical'},
    {value: 'residential', label: 'Residential'},
    {value: 'institutional', label: 'Institutional'}
  ];

  const roomOptions = [
    {value: 'bathroom', label: 'Bathroom'},
    {value: 'bedroom', label: 'Bedroom'},
    {value: 'kitchen', label: 'Kitchen'},
    {value: 'living-room', label: 'Living Room'},
    {value: 'lobby-lounge', label: 'Lobby/Lounge'},
    {value: 'study', label: 'Study'},
    {value: 'storage', label: 'Storage'},
    {value: 'outdoor', label: 'Outdoor'},
    {value: 'office', label: 'Office'}
  ];

  const energyEfficiencyClassOptions = [
    {value: 'a+++', label: 'A+++'},
    {value: 'a++', label: 'A++'},
    {value: 'a+', label: 'A+'},
    {value: 'a', label: 'A'},
    {value: 'b', label: 'B'},
    {value: 'c', label: 'C'},
    {value: 'd', label: 'D'},
    {value: 'e', label: 'E'},
    {value: 'f', label: 'F'},
    {value: 'g', label: 'G'}
  ];

  const initialData = {
    // barcode: '',
    id: '',
    formId: '',
    
    // basic details
    productId: '',
    description: '',
    sku:  '',
    status: 'draft',      
    name: '',
    brandName: '',
    brandId: '',
    countryOfManufacture: '',
    model: '',

    // category
    category: '',
    subcategory1: '',
    subcategory2: '',   
    
    // etim

    etimFeatures: [],

    // market area
    availableEurope: false,
    availableAfrica: false,
    availableAntarctica: false,
    availableAsia: false,
    availableNorthAmerica: false,
    availableSouthAmerica: false,
    availableOceania: false,
    europe: [],
    africa: [],
    antarctica: [],
    asia: [],
    northAmerica: [],
    southAmerica: [],
    oceania: [],
    
    // dimensions
    
    depth: '',
    width: '',
    height: '',
    thickness: '',
    weight: '',
    multipleVariations: [],
    primaryMaterial: '',
    secondaryMaterial: '',

    // filters
    usage: [],
    room: [],
    fireRatingMaterials: [],
    fireRatingObjects: [],
    //thermalTransmittance: '',
    thermalTransmittance: {
      value: '',
      unit: '',
      standard: ''
    },
    thermalResistance: {
      value: '',
      unit: '',
      standard: ''
    },
    soundReductionIndex: '',
    energyEfficiencyClass: '',
    levelOfDetail: '',

    // classifications
    ifcClassification: '',
    uniclass2015Code: '',
    uniclass2015Desc: '',
    omniClassNumber: '',
    omniClassTitle: '',
    uniclass20Code: '',
    uniclass20Desc: '',
    nbsReferenceCode: '',
    nbsReferenceDesc: '',

    certifications: [],
    
    // files
    images: [],
    files: [],
    submit: null
  };

  const ecoLabelsList = [
    {value: 0, label: ''},
    {value: 1, label: 'ABNT Ecolabel'},
    {value: 2, label: 'AENOR Medio Ambiente'},
    {value: 3, label: 'B Corporation'},
    {value: 4, label: 'BASF Eco-Efficiency'},
    {value: 5, label: 'BASS (Product inventory for the construction industry)'},
    {value: 6, label: 'BASTA'},
    {value: 7, label: 'BRE Global Certified Environmental Profile'},
    {value: 8, label: 'Blue Angel'},
    {value: 9, label: 'British Allergy Foundation Seal of Approval'},
    {value: 10, label: 'Burn Wise EPA'},
    {value: 11, label: 'Byggvarubedömningen'},
    {value: 12, label: 'CHPS - Collaborative for High Performance Schools'},
    {value: 13, label: 'CRI Green Label'},
    {value: 14, label: 'Carbon Neutral Certification'},
    {value: 15, label: 'Carbon Reduction Label'},
    {value: 16, label: 'CarbonCare'},
    {value: 17, label: 'CarbonFree® Certified'},
    {value: 18, label: 'Certified Green Dealer'},
    {value: 19, label: 'Certipur'},
    {value: 20, label: 'China Environmental Labelling'},
    {value: 21, label: 'Climatop'},
    {value: 22, label: 'Cradle to Cradle Certified(CM) Products Program'},
    {value: 23, label: 'DUBOkeur'},
    {value: 24, label: 'Danish Indoor Climate Label'},
    {value: 25, label: 'Declare'},
    {value: 26, label: 'Degree of Green®'},
    {value: 27, label: 'ECOLOGO'},
    {value: 28, label: 'ECOproduct'},
    {value: 29, label: 'ENERGY STAR: USA'},
    {value: 30, label: 'EU Ecolabel'},
    {value: 31, label: 'EU Energy Label'},
    {value: 32, label: 'Earthsure'},
    {value: 33, label: 'Eco-Leaf'},
    {value: 34, label: 'Eco-Rail Mark'},
    {value: 35, label: 'EcoMark: Japan'},
    {value: 36, label: 'EcoMaterial'},
    {value: 37, label: 'EcoStandard EcoProduct South Africa'},
    {value: 38, label: 'Ecomark: India'},
    {value: 39, label: 'Effinature'},
    {value: 40, label: 'Ekologicky setrny vyrobek / Environmentally Friendly Product'},
    {value: 41, label: 'Energy Saving Recommended'},
    {value: 42, label: 'Environmental Choice New Zealand'},
    {value: 43, label: 'Environmental Product Declaration'},
    {value: 44, label: 'Environmentally Friendly Label: Croatia'},
    {value: 45, label: 'Environmentally Preferable Product (EPP) Downstream'},
    {value: 46, label: 'Forest Stewardship Council (FSC) Chain of Custody Certification'},
    {value: 47, label: 'GEV-Emicode'},
    {value: 48, label: 'GREENGUARD'},
    {value: 49, label: 'Global GreenTag Certified'},
    {value: 50, label: 'Good Environmental Choice Australia (GECA)'},
    {value: 51, label: 'Green Advantage Certification'},
    {value: 52, label: 'Green Choice: Phillipines'},
    {value: 53, label: 'Green Crane: Ukraine'},
    {value: 54, label: 'Green Products Standard'},
    {value: 55, label: 'Green Seal'},
    {value: 56, label: 'Green Tick'},
    {value: 57, label: 'GreenCircle'},
    {value: 58, label: 'GreenPla'},
    {value: 59, label: 'GreenSure - Sherwin Williams'},
    {value: 60, label: 'Greener Product Certification Seal'},
    {value: 61, label: 'Greenspec PASS'},
    {value: 62, label: 'Home Depot Eco Options'},
    {value: 63, label: 'Hong Kong Eco-label'},
    {value: 64, label: 'Hong Kong Green Label (HKGLS)'},
    {value: 65, label: 'Hungarian Ecolabel / Környezetbarát Termék Védjegy'},
    {value: 66, label: 'IBU Type III Environmental Declaration (IBU Environmental Product Declaration)'},
    {value: 67, label: 'Indoor Air Comfort'},
    {value: 68, label: 'Indoor airPLUS'},
    {value: 69, label: 'Korean Ecolabel'},
    {value: 70, label: 'M1 Emission Classification of Building Materials'},
    {value: 71, label: 'MAS Certified Green'},
    {value: 72, label: 'Minergie-ECO'},
    {value: 73, label: 'NAHB Certified Green Professional'},
    {value: 74, label: 'NAHB Green'},
    {value: 75, label: 'NSF Sustainability Certified Product'},
    {value: 76, label: 'NSF/ANSI 332 Sustainability Assessment for Resilient Floor Coverings'},
    {value: 77, label: 'NSF/ANSI 336: Sustainability Assessment for Commercial Furnishings Fabric'},
    {value: 78, label: 'NSF/ANSI 342 Sustainability Assessment for Wallcovering Products'},
    {value: 79, label: 'National Programme of Environmental Assessment and Ecolabelling in the Slovak Republik (NPEHOV)'},
    {value: 80, label: 'Nordic Ecolabel (Swan)'},
    {value: 81, label: 'OK Compost'},
    {value: 82, label: 'OK Compost HOME'},
    {value: 83, label: 'OK biobased'},
    {value: 84, label: 'OK biodegradable SOIL'},
    {value: 85, label: 'OK biodegradable WATER'},
    {value: 86, label: 'Passivhaus'},
    {value: 87, label: 'Programme for the Endorsement of Forest Certification (PEFC) schemes'},
    {value: 88, label: 'SCS FloorScore®'},
    {value: 89, label: 'SCS Indoor Advantage'},
    {value: 90, label: 'SCS Recycled Content'},
    {value: 91, label: 'SCS Sustainable Choice'},
    {value: 92, label: 'SEE What You Are Buying Into'},
    {value: 93, label: 'SIRIM Certified'},
    {value: 94, label: 'SMaRT Consensus Sustainable Product Standards'},
    {value: 95, label: 'Singapore Green Label Scheme (SGLS)'},
    {value: 96, label: 'Smart WaterMark'},
    {value: 97, label: 'Sourcemap'},
    {value: 98, label: 'SundaHus Miljödata'},
    {value: 99, label: 'Sustainable Forestry Initiative (SFI)'},
    {value: 100, label: 'SustentaX'},
    {value: 101, label: 'TRA Certification - Green Modular and Manufactured Homes'},
    {value: 102, label: 'TRA Certification - Green Recreational Vehicles (RVs)'},
    {value: 103, label: 'Thai Green Label'},
    {value: 104, label: 'U.S. EPA Safer Choice'},
    {value: 105, label: 'UL Energy Efficiency Verified'},
    {value: 106, label: 'UL Environment Multi-Attribute Certification'},
    {value: 107, label: 'UL Environmental Claim Validation'},
    {value: 108, label: 'USDA Certified BioBased'},
    {value: 109, label: 'Vitality Leaf'},
    {value: 110, label: 'Water Efficiency Labelling & Standards (WELS) Scheme'},
    {value: 111, label: 'WaterSense'},
    {value: 112, label: 'Waterwise Marque'},
    {value: 113, label: 'WindMade'},
    {value: 114, label: 'eco-INSTITUT-Label'},
    {value: 115, label: 'level'},
    {value: 116, label: 'natureplus'}  
  ];

  export { usageOptions, roomOptions, initialData, energyEfficiencyClassOptions, ecoLabelsList };