import { useState } from 'react';
import {
  Box,
  Card,
  Container,
  IconButton,
} from '@mui/material';
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { ArrowLeft as ArrowLeftIcon } from '../../icons/arrow-left';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
//pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

const Legal = (props) => {
  const { document } = props;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleChangePage = (direction) => {
    if (direction === "previous") {
      setPageNumber(pageNumber - 1)
    } else if (direction === "next") {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            {/* <Document file="https://cdn.bimroom.com/static/Terms%20and%20Conditions.pdf" /> */}
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
                <Page scale={2.0} pageNumber={pageNumber} />
              </Document>
            </Box>
            <Box sx={{mb: 2, display: "flex", justifyContent: "center", alignItems: "center"}}>
              <IconButton onClick={() => handleChangePage("previous")} disabled={pageNumber === 1 ? true : false}>
                <ArrowLeftIcon />
              </IconButton>
              <IconButton onClick={() => handleChangePage("next")} disabled={pageNumber === numPages ? true : false}>
                <ArrowRightIcon />
              </IconButton>
              <Box>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default Legal;