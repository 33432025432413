import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
  const { variant, ...other } = props;

  //const color = variant === 'light' ? '#FEB449' : '#5A5A5C';
  //const color = variant === 'light' ? '#FEB449' : '#313133';
  const color = variant === 'light' ? '#FFF' : '#175C83';

  return (
    <svg
      viewBox="0 0 389 114"
      version="1.1">
      <path
        fill={color} d="m 28.5,25.4 c -6.5,0 -12,2.5 -15.6,7.1 V 5.4 H 0 v 45.8 c 0,15.9 10.6,26.6 26.5,26.6 15.9,0 26.9,-11 26.9,-26.7 0,-16.9 -12.5,-25.7 -24.9,-25.7 z m -2,40.3 c -8.2,0 -13.7,-5.6 -13.7,-14.1 0,-8.5 5.6,-14.1 13.7,-14.1 8.1,0 13.8,5.8 13.8,14.1 0,8.3 -5.6,14.1 -13.8,14.1 z"
        id="path2" />
      <polygon
        fill={color}
        points="79,91.8 66.2,99.2 66.2,138.6 79,138.6 "
        id="polygon4"
        transform="translate(-5.6,-62.1)" />
      <path
        fill={color}
        d="m 137.3,25.4 c -7.3,0 -13.3,2.9 -16.8,8.3 -3.7,-5.3 -9.6,-8.3 -16.8,-8.3 -13.1,0 -21,8.5 -21,22.8 V 76.6 H 95.6 V 48.2 c 0,-7 3.2,-10.6 9.3,-10.6 6.1,0 9.3,3.8 9.3,10.6 v 28.4 h 12.9 V 48.2 c 0,-6.9 3.3,-10.6 9.2,-10.6 6.1,0 9.3,3.7 9.3,10.6 v 28.4 h 12.9 V 48.2 C 158.2,33.9 150.4,25.4 137.3,25.4 Z"
        id="path6" />
      <path
        fill={color}
        d="m 187.7,25.4 c -13.9,0 -21.9,8.1 -21.9,22.3 v 28.9 h 12.9 V 47.7 c 0,-6.8 3,-10.1 9,-10.1 v 0 h 9.9 V 25.4 h -10 z"
        id="path8" />
      <path
        fill={color}
        d="m 224.7,25.4 c -15.5,0 -26.8,11 -26.8,26.2 0,15.2 11.3,26.2 26.8,26.2 15.5,0 26.8,-11 26.8,-26.2 0,-15.2 -11.3,-26.2 -26.8,-26.2 z m 0,40.3 c -8.1,0 -13.8,-5.8 -13.8,-14.1 0,-8.3 5.7,-14.1 13.8,-14.1 8.1,0 13.7,5.7 13.7,14.1 0,8.4 -5.5,14.1 -13.7,14.1 z"
        id="path10" />
      <path
        fill={color}
        d="m 281.9,25.4 c -15.5,0 -26.8,11 -26.8,26.2 0,15.2 11.3,26.2 26.8,26.2 15.5,0 26.8,-11 26.8,-26.2 0,-15.2 -11.3,-26.2 -26.8,-26.2 z m 0,40.3 c -8.1,0 -13.8,-5.8 -13.8,-14.1 0,-8.3 5.7,-14.1 13.8,-14.1 8.1,0 13.7,5.7 13.7,14.1 0,8.4 -5.6,14.1 -13.7,14.1 z"
        id="path12" />
      <path
        fill={color}
        d="m 367.8,25.4 c -7.3,0 -13.3,2.9 -16.8,8.3 -3.7,-5.3 -9.6,-8.3 -16.8,-8.3 -13.1,0 -21,8.5 -21,22.8 v 28.4 h 12.9 V 48.2 c 0,-7 3.2,-10.6 9.3,-10.6 6.1,0 9.3,3.8 9.3,10.6 v 28.4 h 12.9 V 48.2 c 0,-6.9 3.3,-10.6 9.2,-10.6 5.9,0 9.3,3.7 9.3,10.6 V 76.6 H 389 V 48.2 C 388.8,33.9 381,25.4 367.8,25.4 Z"
        id="path14" />
      <polygon
        fill={color}
        opacity="0.7"
        points="70.8,90.1 61.5,84.6 61.5,75.4 70.8,80.9 "
        id="polygon16"
        transform="translate(-5.6,-62.1)" />
      <polygon
        fill={color}
        points="70.8,90.1 80.2,84.6 80.2,75.4 70.8,80.9 "
        id="polygon18"
        transform="translate(-5.6,-62.1)" />
      <polygon
        fill={color}
        points="70.8,62.1 61.5,67.5 61.5,75.4 70.8,70 "
        id="polygon20"
        transform="translate(-5.6,-62.1)" />
      <polygon
        fill={color}
        opacity="0.3"
        points="70.8,62.1 80.2,67.5 80.2,75.4 70.8,70 "
        id="polygon22"
        transform="translate(-5.6,-62.1)" />
      <g
        fill={color}
        id="g36"
        transform="translate(-5.6,-62.1)">
        <path
          d="m 296.1,160.6 c 0,-1.4 0.2,-2.6 0.7,-3.7 0.4,-1.1 1.1,-2 1.8,-2.8 0.8,-0.8 1.7,-1.4 2.8,-1.8 1.1,-0.4 2.3,-0.6 3.7,-0.6 1.3,0 2.5,0.2 3.6,0.7 1.1,0.4 2.1,1.1 2.9,1.8 0.8,0.8 1.4,1.7 1.9,2.8 0.4,1.1 0.7,2.3 0.7,3.5 0,1.4 -0.2,2.7 -0.7,3.8 -0.5,1.1 -1.1,2 -1.9,2.8 -0.8,0.7 -1.7,1.3 -2.7,1.7 -1,0.4 -2.1,0.6 -3.1,0.6 -1.4,0 -2.7,-0.3 -3.8,-0.9 -1.1,-0.6 -1.9,-1.4 -2.5,-2.4 h -0.1 v 10 h -3.2 v -15.5 z m 9,-6 c -0.9,0 -1.6,0.1 -2.3,0.4 -0.7,0.3 -1.3,0.7 -1.8,1.2 -0.5,0.5 -0.9,1.1 -1.2,1.9 -0.3,0.7 -0.4,1.5 -0.4,2.3 0,0.9 0.1,1.6 0.4,2.4 0.3,0.7 0.7,1.3 1.2,1.8 0.5,0.5 1.1,0.9 1.8,1.2 0.7,0.3 1.5,0.4 2.3,0.4 0.8,0 1.6,-0.1 2.4,-0.4 0.7,-0.3 1.3,-0.7 1.8,-1.2 0.5,-0.5 0.9,-1.1 1.2,-1.8 0.3,-0.7 0.4,-1.5 0.4,-2.4 0,-0.8 -0.1,-1.6 -0.4,-2.3 -0.3,-0.7 -0.7,-1.3 -1.2,-1.9 -0.5,-0.5 -1.1,-0.9 -1.8,-1.2 -0.7,-0.3 -1.6,-0.4 -2.4,-0.4 z"
          id="path24" />
        <path
          d="m 334.2,160.5 c 0,1.3 -0.2,2.4 -0.7,3.5 -0.4,1.1 -1.1,2 -1.9,2.8 -0.8,0.8 -1.8,1.4 -2.9,1.8 -1.1,0.4 -2.3,0.7 -3.6,0.7 -1.3,0 -2.6,-0.2 -3.7,-0.7 -1.1,-0.4 -2.1,-1.1 -2.9,-1.8 -0.8,-0.8 -1.4,-1.7 -1.9,-2.8 -0.4,-1.1 -0.7,-2.3 -0.7,-3.5 0,-1.3 0.2,-2.4 0.7,-3.5 0.4,-1.1 1.1,-2 1.9,-2.8 0.8,-0.8 1.8,-1.4 2.9,-1.8 1.1,-0.4 2.3,-0.7 3.7,-0.7 1.3,0 2.5,0.2 3.6,0.7 1.1,0.4 2.1,1.1 2.9,1.8 0.8,0.7 1.4,1.7 1.9,2.8 0.5,1 0.7,2.2 0.7,3.5 z m -9.1,5.8 c 0.9,0 1.6,-0.1 2.4,-0.4 0.7,-0.3 1.3,-0.7 1.8,-1.2 0.5,-0.5 0.9,-1.1 1.2,-1.8 0.3,-0.7 0.4,-1.5 0.4,-2.4 0,-0.8 -0.1,-1.6 -0.4,-2.3 -0.3,-0.7 -0.7,-1.3 -1.2,-1.9 -0.5,-0.5 -1.1,-0.9 -1.8,-1.2 -0.7,-0.3 -1.5,-0.4 -2.4,-0.4 -0.9,0 -1.6,0.1 -2.4,0.4 -0.8,0.3 -1.3,0.7 -1.8,1.2 -0.5,0.5 -0.9,1.1 -1.2,1.9 -0.3,0.7 -0.4,1.5 -0.4,2.3 0,0.9 0.1,1.6 0.4,2.4 0.3,0.7 0.7,1.3 1.2,1.8 0.5,0.5 1.1,0.9 1.8,1.2 0.8,0.3 1.6,0.4 2.4,0.4 z"
          id="path26" />
        <path
          d="M 348.1,159.2 V 159 c 0,-1.5 -0.4,-2.7 -1.1,-3.3 -0.7,-0.7 -1.7,-1 -2.9,-1 -1.2,0 -2.2,0.3 -2.9,1 -0.7,0.7 -1.1,1.8 -1.1,3.3 v 9.9 h -3.2 V 159 c 0,-1.2 0.2,-2.3 0.5,-3.2 0.4,-0.9 0.8,-1.7 1.5,-2.3 0.6,-0.6 1.4,-1.1 2.3,-1.4 0.9,-0.3 1.9,-0.4 3,-0.4 1.1,0 2,0.1 2.9,0.4 0.9,0.3 1.6,0.7 2.3,1.4 0.6,0.6 1.1,1.4 1.5,2.3 0.4,0.9 0.5,2 0.5,3.3 v 0.2 h -3.3 z"
          id="path28" />
        <path
          d="m 367,162.5 c 0,1.1 -0.2,2.1 -0.5,3 -0.3,0.8 -0.8,1.6 -1.4,2.1 -0.6,0.5 -1.3,1 -2.1,1.3 -0.8,0.3 -1.7,0.4 -2.7,0.4 -1,0 -1.9,-0.1 -2.7,-0.4 -0.8,-0.3 -1.5,-0.7 -2.1,-1.3 -0.6,-0.6 -1,-1.3 -1.4,-2.1 -0.3,-0.9 -0.5,-1.8 -0.5,-3 v -16.2 h 3.2 v 5.7 h 9.2 v 3 h -9.2 v 7.5 c 0,1.3 0.3,2.3 1,2.9 0.7,0.6 1.5,0.9 2.6,0.9 1.1,0 1.9,-0.3 2.6,-0.9 0.6,-0.6 1,-1.6 1,-2.9 v -0.4 h 3.2 v 0.4 z"
          id="path30" />
        <path
          d="m 387,168.9 h -3 v -3.5 0 c -0.5,1.2 -1.3,2.2 -2.5,2.9 -1.2,0.7 -2.5,1.1 -4,1.1 -1.1,0 -2.1,-0.2 -3.2,-0.6 -1,-0.4 -1.9,-1 -2.7,-1.7 -0.8,-0.7 -1.4,-1.7 -1.9,-2.8 -0.5,-1.1 -0.7,-2.4 -0.7,-3.8 0,-1.3 0.2,-2.4 0.7,-3.5 0.4,-1.1 1.1,-2 1.9,-2.8 0.8,-0.8 1.8,-1.4 2.9,-1.8 1.1,-0.4 2.3,-0.7 3.7,-0.7 1.3,0 2.5,0.2 3.6,0.6 1.1,0.4 2.1,1 2.8,1.8 0.8,0.8 1.4,1.7 1.8,2.8 0.4,1.1 0.7,2.3 0.7,3.7 v 8.3 z m -9,-2.6 c 0.9,0 1.6,-0.1 2.4,-0.4 0.7,-0.3 1.3,-0.7 1.8,-1.2 0.5,-0.5 0.9,-1.1 1.2,-1.8 0.3,-0.7 0.4,-1.5 0.4,-2.4 0,-0.8 -0.1,-1.6 -0.4,-2.3 -0.3,-0.7 -0.7,-1.3 -1.2,-1.9 -0.5,-0.5 -1.1,-0.9 -1.8,-1.2 -0.7,-0.3 -1.5,-0.4 -2.4,-0.4 -0.9,0 -1.6,0.1 -2.4,0.4 -0.8,0.3 -1.3,0.7 -1.8,1.2 -0.5,0.5 -0.9,1.1 -1.2,1.9 -0.3,0.7 -0.4,1.5 -0.4,2.3 0,0.9 0.1,1.6 0.4,2.4 0.3,0.7 0.7,1.3 1.2,1.8 0.5,0.5 1.1,0.9 1.8,1.2 0.8,0.3 1.5,0.4 2.4,0.4 z"
          id="path32" />
        <path
          d="m 390.9,168.9 v -24.1 h 3.2 v 24.1 z"
          id="path34" />
      </g>
    </svg>
   );

})``;


Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
