import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";


export default function Image3D({ image }) {

  const glb = useGLTF(
    `${image}`,
    "https://www.gstatic.com/draco/versioned/decoders/1.4.0/"
  )

  return (
    <>
      {glb ? <primitive object={glb.scene} scale={0.8} /> : null}
    </>
  );

}
