import { format, subDays } from 'date-fns';
//import numeral from 'numeral';
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Divider,
  CardActions,
  Button,
  MenuItem,
  TextField
} from '@mui/material';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';

const orders = [
  // {
  //   orderId: 123,
  //   date: new Date()
  // }
];

export const OverviewDeliveries = (props) => (
  <Card {...props}>
    <CardHeader title="Modelling services" />
      {orders.length === 0 ? 
        <Table >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Date
              </TableCell>
              <TableCell align="center">
                Order ID
              </TableCell>
              <TableCell align="center">
                Deliverables
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} sx={{height: "64px"}} align="center">
                No deliveries
              </TableCell>
            </TableRow>
          </TableBody> 
        </Table> :
        <Scrollbar>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Date
                </TableCell>
                <TableCell align="center">
                  Order ID
                </TableCell>
                <TableCell align="center">
                  Deliverables
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order.orderId}
                  sx={{
                    '&:last-child td': {
                      border: 0
                    }
                  }}
                >
                  <TableCell>
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="subtitle2"
                    >
                      {format(order.date, 'dd.M.yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" align="center">
                      {order?.orderId}
                    </Typography>
                  </TableCell>

                  <TableCell>
                  </TableCell> 
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar> 
      }
    <Divider />
    <CardActions> 
      <Button
        endIcon={<ArrowRightIcon fontSize="small" />}
        size="small"
        variant="outlined"
      >
        Go to deliveries
      </Button>
    </CardActions>
  </Card>
);
