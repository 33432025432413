import { 
  Box, 
  Button, 
  Grid, 
  TextField, 
  Typography 
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { 
  ContentState, 
  Editor, 
  EditorState, 
  convertFromHTML, 
  //RichUtils
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useEffect, useRef, useState } from 'react';
import { 
  portalAddBrand, 
  //portalUpdateBrand, 
  portalUpdateUser, 
  upload 
} from '../../api';
import { ImageDropzone } from '../image-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vw",
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflowY: "auto"
};



export const AddBrandModal = (props) => {
  const [editingImage, setEditingImage] = useState(false);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );
  const [images, setImages] = useState([]);
  const { handleClose } = props;
  const { accounts } = useMsal();
  const mainProfileId = useSelector((store) => store.user.mainProfileId);


  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
    formik.setFieldValue('brandId', uuidv4());
  }


  const formik = useFormik({
    initialValues: {
      id: '',
      brandId: '',
      mainProfileId: mainProfileId || '',
      brandName: '',
      brandSlug: '',
      brandUrl: '',
      description: '',
      logoUrl: '',
      contactPerson: {
        name: "",
        address: "",
        phoneNumber: "",
        email: ""
      },
      publicContacts: [],
      country:  '',
      submit: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255).required(),
      brandId: Yup.string().max(255).required(),
      mainProfileId: Yup.string().max(255).required(),
      brandName: Yup.string().max(255).required("Brand name is a required field"),
      brandSlug: Yup.string().max(255),
      description: Yup.string(),
      logoUrl: Yup.string().max(255),
      contactPerson: Yup.object({
        name: Yup.string().max(255),
        address: Yup.string().max(255),
        phoneNumber: Yup.string().max(255),
        email: Yup.string().max(255)
      }),
      publicContacts: Yup.array()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        try {
          await portalAddBrand(values).then((res) => {
            if (res === 200) {
              portalUpdateUser({
                userId: accounts[0]?.localAccountId,
                brandId: values.brandId,
                brandName: values.brandName,
              }).then((res) => {
                if (res === 200) {
                  handleClose();
                } else {
                  console.log("error occurred");
                }
              });
            }
          })
          .catch((err) => {
            console.log(err)
          });

        } catch (err) {
          console.error(err);
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        helpers.resetForm();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const editor = useRef(null);
  
  useEffect(() => {
    let html = stateToHTML(editorState.getCurrentContent());
    formik.setFieldValue('description', html);
  }, [editorState]);

  useEffect(() => {
    // html text to editor
    const blocksFromHTML = convertFromHTML(formik.values.description);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    setEditorState( EditorState.createWithContent(state));
  }, []);

  useEffect(() => {
    // html text to editor
    setNewIds();
  }, []);

  const handleCloseEditing = () => {
    setEditingImage(false);
  }

  const handleOpenEditing = () => {
    setEditingImage(true);
  }

  const uploadFileToBlob = async (file, folder) => {
    const newFile = new File([file], formik.values.brandId, {
      type: file?.type,
    });
    const response = await upload(newFile, folder);
    return response;
  };

  const handleDrop = (newFiles) => {
    setImages((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setImages((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setImages([]);
  };


  const handleUpload = () => {
    images.map((file) => {
      if (file) {
        try {
          uploadFileToBlob(file, "brand-images").then(res => {
            if (res.status === 200) {
              formik.setFieldValue("logoUrl", "https://cdn.bimroom.com/brand-images/" + res?.data)
              setEditingImage(false);
              setImages([]);
            }
          });
        } catch (error) {
          console.error(error);
          // Expected output: ReferenceError: nonExistentFunction is not defined
          // (Note: the exact output may be browser-dependent)
        }
      }
    })
    //console.log(formik.values.images);
  };

  return (
    <Box sx={style}>
      <form onSubmit={formik.handleSubmit} {...props}>
        {mainProfileId ? <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">New brand</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              error={Boolean(formik.touched.brandName && formik.errors.brandName)}
              fullWidth
              helperText={formik.touched.brandName && formik.errors.brandName}
              label="Brand name"
              name="brandName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.brandName}
            />
            <TextField
              error={Boolean(formik.touched.brandUrl && formik.errors.modbrandUrlel)}
              fullWidth
              helperText={formik.touched.brandUrl && formik.errors.brandUrl}
              label="Website URL"
              name="brandUrl"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 2 }}
              value={formik.values.brandUrl}
            />
            <Typography
              color="textSecondary"
              sx={{
                mb: 1,
                mt: 2,
              }}
              variant="subtitle2"
            >
              Logo
            </Typography>
            {editingImage === false ? 
              <Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  {formik.values.logoUrl !== "" ? 
                    <Grid item sx={{ mt: 1 }}>
                      <Box
                        component="img"
                        sx={{
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                        }}
                        alt="Brand logo"
                        src={formik.values.logoUrl}
                      />
                    </Grid> : 
                    <Box>
                      No image
                    </Box>
                  }
                </Box>
                <Button onClick={handleOpenEditing} sx={{mt: 1}} variant="contained">
                  Change
                </Button>
              </Box> : 
              <Box>
                <Box
                  sx={{my: 2}}
                >
                  <Box>
                    <Grid item sx={{ mt: 1 }}>
                      <Typography variant="subtitle2">Old Image</Typography>
                    </Grid>
                    {formik.values.logoUrl !== "" ? 
                      <Grid item sx={{ mt: 1 }}>
                        <Box
                          component="img"
                          sx={{
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="Brand logo"
                          src={formik.values.logoUrl}
                        /> 
                      </Grid> : 
                      <Box>No image</Box>
                    }
                  </Box>
                  <Box>
                    <Grid item sx={{ mt: 2 }}>
                      <Typography variant="subtitle2">New Image</Typography>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                      <ImageDropzone 
                        accept="image/*"
                        maxFiles={1}
                        files={images}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                        onRemoveAll={handleRemoveAll}
                        onUpload={handleUpload}
                      />
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{mt: 1}}>
                  <Button onClick={handleUpload} variant="contained">
                    Save
                  </Button>
                  <Button sx={{ml: 1}} onClick={handleCloseEditing} variant="contained">
                    Cancel
                  </Button>
                </Box>
              </Box>
            }
            <Typography
              color="textSecondary"
              sx={{
                mb: 1,
                mt: 2,
              }}
              variant="subtitle2"
            >
              Description
            </Typography>
              <Editor
                ref={editor}
                editorState={editorState}
                onChange={editorState => setEditorState(editorState)}
              />
            {/* </Box> */}
            <Typography
              color="textSecondary"
              sx={{
                mb: 1,
                mt: 2,
              }}
              variant="subtitle2"
            >
              Contact person
            </Typography>
            <TextField
              //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
              fullWidth
              label="Name"
              name="contactPerson.name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contactPerson.name}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
              fullWidth
              label="Address"
              name="contactPerson.address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 2 }}
              value={formik.values.contactPerson.address}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
              fullWidth
              label="Phone number"
              name="contactPerson.phoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 2 }}
              value={formik.values.contactPerson.phoneNumber}
            />
            <TextField
              //error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
              fullWidth
              label="Email"
              name="contactPerson.email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ mt: 2 }}
              value={formik.values.contactPerson.email}
            />
            <Button sx={{mt: 2}} type="submit" variant="contained">
              Save brand
            </Button>
          </Grid>
        </Grid> : <></>}
      </form>
    </Box>
  )
};
