import React from "react";
import useDocumentTitle from "../useDocumentTitle";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import Loader from "../components/Loader/loader";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import InboxTable from "../components/Inbox";
import { BrandGuard } from "../components/brand-guard";


const InboxPage = () => {
  useDocumentTitle('Bimroom Portal - Inbox');
  const isLoading = useSelector((store) => store.user.isLoading);

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> :
          <DashboardLayout header="Inbox">
            <BrandGuard>
              <InboxTable />
            </BrandGuard>
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

export default InboxPage;