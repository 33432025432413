import { Typography } from '@mui/material'
import { memo } from 'react'
import { useDrop } from 'react-dnd'
const style = {
  maxHeight: '8rem',
  minHeight: '3rem',
  width: '100%',
  //marginRight: '1.5rem',
  //marginBottom: '1.5rem',
  //color: 'black',
  //padding: '1rem',
  textAlign: 'center',
  //fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  borderRadius: "5px",
  overflowY: "auto"
}
export const TargetBin = memo(function TargetBin({
  accept,
  //lastDroppedItem,
  items,
  onDrop,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = '#fff'
  let color = "#175c83"
  let border = "1px solid #ccc"
  if (isActive) {
    backgroundColor = '#175c83'
    color = "white"
    border = "1px solid #175c83"
  } else if (canDrop) {
    backgroundColor = '#175c831A'
    color = "#175c83"
    border = "1px dashed #175c83"
  }
  return (
    <div ref={drop} style={{ ...style, backgroundColor, color, border }} data-testid="targetbin">
      {isActive
        ? <Typography variant="subtitle2">Drop</Typography>
        : <Typography variant="subtitle2">{accept.join(', ')}</Typography>
      }

      {items && (
        // <p>Items: {JSON.stringify(items)}</p>
        items?.map((item, index) => (
          // <p key={"item-" + index}>{item?.name}</p>
          <Typography key={"item-" + index}>{item?.name}</Typography>
        )
        )
      )}
    </div>
  )
})