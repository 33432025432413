import { 
  useState,
  useEffect, 
  //useCallback, 
  useRef 
} from 'react';
import {
  Box,
  Card,
  Container,
} from '@mui/material';

import { SubscriptionListTable } from './subscription-list-table';
import { useMounted } from '../../hooks/use-mounted';
import { SubscriptionFilters } from './subscription-filters';
import { portalSubscriptions } from '../../api';
import { useSelector } from 'react-redux';
//import { AddCompany } from '../../add-company';
//import { gtm } from '../../../lib/gtm';



// const sortOptions = [
//   {
//     label: 'Last update (newest)',
//     value: 'latestDate|desc'
//   },
//   {
//     label: 'Last update (oldest)',
//     value: 'latestDate|asc'
//   }
//   // {
//   //   label: 'Total orders (highest)',
//   //   value: 'totalOrders|desc'
//   // },
//   // {
//   //   label: 'Total orders (lowest)',
//   //   value: 'totalOrders|asc'
//   // }
// ];

const applyFilters = (subscriptionData, filters) => subscriptionData.filter((subscription) => {
  if (filters.query) {
    let queryMatched = false;
    const properties = ['type'];

    properties.forEach((property) => {
      if ((subscription[property]).toLowerCase().includes(filters.query.toLowerCase())) {
        queryMatched = true;
      }
    });

    if (!queryMatched) {
      return false;
    }
  }

  // It is possible to select multiple status options
  if (filters.status?.length > 0) {
    const statusMatched = filters.status.includes(subscription.status);

    if (!statusMatched) {
      return false;
    }
  }

  return true;
});

const descendingComparator = (a, b, sortBy) => {
  // When compared to something undefined, always returns false.
  // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (sortDir, sortBy) => (sortDir === 'desc'
  ? (a, b) => descendingComparator(a, b, sortBy)
  : (a, b) => -descendingComparator(a, b, sortBy));

const applySort = (subscriptionData, sort) => {
  const [sortBy, sortDir] = sort.split('|');
  const comparator = getComparator(sortDir, sortBy);
  const stabilizedThis = subscriptionData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
        const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

        return a[1] - b[1];
  });

    return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (subscriptionData, page, rowsPerPage) => subscriptionData.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);

const Subscriptions = () => {
  const isMounted = useMounted();
  const queryRef = useRef(null);
  const [subscriptionData, setSubscriptions] = useState([]);
  // const [open, setOpen] = useState(true);
  // const [currentTab, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [sort, setSort] = useState(sortOptions[0].value);
  const [refresh, setRefresh] = useState(0);
  const [filters, setFilters] = useState({
    query: undefined,
    status: []
  });
  const mainProfileId = useSelector((store) => store.user.mainProfileId);

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const getSubscriptions = async () => {
    try {

      if (mainProfileId) {
        const payload = {
          mainProfileId: mainProfileId
        }
        const data = await portalSubscriptions(payload);
        if (data) {
          setSubscriptions(data);
        }
        
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (mainProfileId) {
      getSubscriptions();
      //console.log("brand updated");
    }
  }, [mainProfileId])

  // Usually query is done on backend with indexing solutions
  const filteredSubscriptions = applyFilters(subscriptionData, filters);
  //const sortedSubscriptions = applySort(filteredSubscriptions, sort);
  //const paginatedSubscriptions = applyPagination(sortedSubscriptions, page, rowsPerPage);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            <SubscriptionFilters onChange={handleFiltersChange}/>
            <SubscriptionListTable
              subscriptions={filteredSubscriptions}
              subscriptionCount={filteredSubscriptions.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
              refresh={handleRefresh}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default Subscriptions;