import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { OverviewBanner } from './overview-banner';
import { OverviewMostDownloaded } from './overview-most-downloaded';
import { OverviewTotalDownloads } from './overview-total-downloads';
import { OverviewDeliveries} from './overview-deliveries';

import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { Box as BoxIcon } from '../../icons/box';
import { ChartBar as ChartIcon } from '../../icons/chart-bar';

import { useMsal } from '@azure/msal-react';
import { Check as CheckIcon } from '../../icons/check';
import { ContentCard } from './overview-content-card';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { portalTasks } from '../../api';



const Overview = () => {
  const [displayBanner, setDisplayBanner] = useState(true);
  const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const [tasks, setTasks] = useState([]);
  const { accounts } = useMsal();
  const navigate = useNavigate();


  useEffect(() => {
    // Restore the persistent state from local/session storage
    const value = localStorage.getItem('dismiss-banner');

    if (value === 'true') {
      setDisplayBanner(false);
    }
  }, []);

  const getTasks = async (payload) => {
    const response = await portalTasks(payload);
    if (response) {
      const filteredResponse = response?.filter((o) => {
        return o.count > 0
      })
      //console.log(filteredResponse);
      setTasks(filteredResponse);
    }
  }

  useEffect(() => {
    if (mainProfileId && selectedBrand?.brandId) {
      getTasks({
        brandId: selectedBrand?.brandId,
        mainProfileId: mainProfileId
      });
    } else {
      setTasks([])
    }
  }, [selectedBrand, mainProfileId])

  const handleDismissBanner = () => {
    // Update the persistent state
    setDisplayBanner(false);
  };

  const user = {
    name: accounts[0].name
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          //background: 'linear-gradient(144deg, rgba(11,46,65,1) 0%, rgba(23,92,131,1) 86%)',
          flexGrow: 1,
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card
            sx={{
              alignItems: 'center',
              // backgroundColor: 'primary.main',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              //color: '#313133',
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row'
              },
              p: 4,
              mb: 2,
              mt: 1
          }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item
                md={6}
                //xs={12} 
                >
                <Typography variant="h7" sx={{color: "#fff"}}>
                  Hello,
                </Typography>
                <Typography variant="h4" sx={{color: "#fff"}}>
                  {user?.name}!
                </Typography>
              </Grid>
              <Grid item
                md={6}
                //xs={12} 
                align="right"
                >
                {/* <Typography variant="h4" sx={{color: "#fff"}}>
                  Hello, {user?.name}!
                </Typography> */}
                {tasks?.length === 0 ? <Box sx={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    height: '30px'}}
                  >
                    <Box sx={{display: "flex"}}>
                      <CheckIcon />
                      <Typography variant="h7" sx={{ color: "#fff", ml: 1 }}>
                        You have no open tasks
                      </Typography>
                    </Box>
                  </Box> : 
                  <Box sx={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    height: '100%',
                    minWidth: 300}}
                  >
                    <Box sx={{display: "flex"}}>
                      <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                        You have {tasks?.length} open {tasks?.length === 1 ? "task" : "tasks"}:
                      </Typography>
                    </Box>
                    {tasks.map((task, index) => (
                      <Box 
                        key={index}
                        onClick={() => task?.task === "documents" ? navigate("/account/documents") : navigate("/product/search")}
                        sx={{
                          display: "flex", 
                          mt: 2, 
                          alignItems: 'center', 
                          justifyContent: 'space-between',
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            opacity: [0.9, 0.8, 0.7],
                            cursor: 'pointer'
                          },
                          '&:hover #go-to-arrow': {
                            mr: -0.5,
                            ml: 0.5,
                            transition: "all 0.2s"
                          }
                        }}
                      >
                        <Typography variant="body2" sx={{ color: "#fff", mr: 1 }}>
                          {task?.task === "documents" ? "You have " + task?.count + " document to be signed" : "You have " + task?.count + " products to be approved"}
                        </Typography>
                        <ArrowRightIcon id='go-to-arrow'/>
                      </Box>
                    ))}
                  </Box>
                }
              </Grid>
            </Grid>
          </Card>
          <Grid
            container
            spacing={2}
          >
            {displayBanner && (
              <Grid
                item
                xs={12}
              >
                <OverviewBanner onDismiss={handleDismissBanner} />
              </Grid>
            )}
            <Grid item
              md={6}
              xs={12}
            >
              <Grid
                item
                md={12}
                xs={12}
                sx={{mb: 2}}
              >
                <OverviewMostDownloaded />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                sx={{mb: 2}}
              >
                <ContentCard 
                  icon={<ChartIcon color="primary" />}
                  header="Bimroom Analytics"
                  title="Interactive dashboard view and downloadable reports"
                  content="Bimroom analytics offers valuable information like the quantity of product downloads and views, along with the locations where your products are generating the highest amount of traffic. Through the interactive Dashboard view, you can choose the desired time frame and products to access analytics data. Furthermore, the Reports view allows you to download monthly or yearly analytics reports."                  
                  buttonText="Go to reports"
                  link="/analytics/reports"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <OverviewDeliveries />
              </Grid>
            </Grid>
            <Grid item
              md={6}
              xs={12}
            >
              <Grid
                item
                md={12}
                xs={12}
                sx={{mb: 2}}
              >
                <ContentCard 
                  icon={<BoxIcon color="primary" />}
                  header="Product catalog"
                  title="Manage products in your Bimroom catalog"
                  content="In Bimroom portal, you can utilize the Product catalog view to conveniently observe and modify your products. To add new products into Bimroom, you have the option to utilize either the Importer tool or the Add Product form."
                  buttonText="Add a product"
                  link="/product/search"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                sx={{mb: 2}}
              >
                <OverviewTotalDownloads />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <ContentCard 
                  icon={<PencilIcon color="primary" />}
                  header="BIM Modelling"
                  title="Does your company need BIM object modelling?"
                  content="We can create high-quality BIM models of your products. If you're interested in receiving a quote for your products or have any further inquiries, please don't hesitate to get in touch with us."
                  buttonText="Send a request"
                  link="/modelling"
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Overview;
