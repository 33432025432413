import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies } from '../../authConfig';

// const publicContacts = [
// ];

const General = (props) => {
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const { instance, inProgress, accounts } = useMsal();
  const organizations = useSelector((store) => store.user.brands);

  // const handleProfileEdit = () => {
  //   if (inProgress === InteractionStatus.None) {
  //       instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
  //   }
  // };

  const handlePasswordChange = () => {
    if (inProgress === InteractionStatus.None) {
        instance.acquireTokenRedirect(b2cPolicies.authorities.forgotPassword);
    }
  };
  

  return (
    <Box
      sx={{
        //backgroundColor: 'background.default',
        flexGrow: 1,
        p: 3,
        minHeight: "calc(100vh - 48px)"
      }}
      {...props}>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Typography variant="h6">
                User details
              </Typography>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="subtitle2">Username</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">{accounts[0]?.name}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="subtitle2">Email</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">{accounts[0]?.username}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="subtitle2">Access</Typography>
                    </TableCell>
                    <TableCell align="right">
                      {organizations?.map((organization, i) => (
                      <Typography key={i} variant="body2">
                        {organization?.brandName}
                      </Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Typography variant="h6">
                Edit profile
              </Typography>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Typography
                sx={{ mb: 3 }}
                variant="subtitle1"
              >
                You can change your password by clicking the button below. If you do not remember your current password, contact us for next steps to recover your password.
              </Typography>
              <Button
                onClick={handleProfileEdit}
                variant="contained"
              >
                Edit profile
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Typography variant="h6">
                Change password
              </Typography>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Typography
                sx={{ mb: 3 }}
                variant="subtitle1"
              >
                You can change your password by clicking the button below. If you do not have access to the email address you provided, contact us for next steps to recover your password.
              </Typography>
              <Button
                onClick={handlePasswordChange}
                variant="contained"
              >
                Change password
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Typography variant="h6">
                Delete account
              </Typography>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Typography
                sx={{ mb: 3 }}
                variant="subtitle1"
              >
                Delete your account and all of your account data. This is irreversible. Products will not be deleted.
              </Typography>
              <Button
                color="error"
                variant="contained"
              >
                Delete account
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default General;