import React from "react";

// components
import Modelling from "../components/Modelling";


import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";
import useDocumentTitle from "../useDocumentTitle";
import { MainProfileGuard } from "../components/main-profile-guard";

const ModellingPage = () => {
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom Portal - Modelling');

  return (
    <>

      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
            <DashboardLayout header="Modelling services">
              <MainProfileGuard>
                <Modelling /> 
              </MainProfileGuard>
            </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ModellingPage;