import { 
  Fragment, 
  useEffect, 
  useRef, 
  useState 
  // useEffect, 
  // forwardRef 
} from 'react';
// import { useRouter } from 'next/router';
// import numeral from 'numeral';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Typography,
  Select,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  LinearProgress,
  FormControl,
  MenuItem,
  InputLabel
  // Slide,
  // Paper
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { ImportFile } from './import-file';
import { ImportTable } from './import-table';
import { wait } from '../../utils/wait';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { FileDropzone } from '../file-dropzone';
import { FileDropzoneSmall } from '../file-dropzone-small';
import { useSelector } from 'react-redux';
import { RequestMapperModal } from './request-mapper-modal';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

// const RequestMapperModal = (props) => {
//   const { open, handleClose } = props;
//   const [ files, setFiles ] = useState([]);
//   const [ uploadedFiles, setUploadedFiles ] = useState([]);
//   const selectedBrand = useSelector((store) => store.user.selectedBrand);

//   const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     minWidth: 400,
//     //width: "80vw",
//     bgcolor: 'background.paper',
//     //border: '2px solid #000',
//     borderRadius: 1,
//     boxShadow: 24,
//     p: 4,
//     maxHeight: "90vh",
//     overflowY: "auto"
//   };

//   useEffect(() => {
//     const ids = new Set(formik.values.files.map(d => d?.newName));
//     const merged = [...formik.values.files, ...uploadedFiles.filter(d => !ids.has(d.newName))];
//     formik.setFieldValue("files", merged);
//     const existingValues = uploadedFiles.map(item => item.name);
//     setFiles(() => files.filter((item) =>!existingValues.includes(item.name)));
//   }, [uploadedFiles])


//   useEffect(() => {
//     formik.setFieldValue("brandName", selectedBrand?.brandName)
//   }, [selectedBrand])

//   useEffect(() => {
//     formik.setFieldValue("status", "requested")
//     formik.setFieldValue("id", uuidv4())
//   }, [open])

  
//   const handleDrop = (newFiles) => {
//     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
//   };

//   const handleRemove = (file) => {
//     setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
//   };

//   const handleRemoveAll = () => {
//     setFiles([]);
//   };

//   const handleDeleteFile = (name) => {
//     let array = formik.values.files;
//     const objIndex = array.findIndex((obj => obj.newName == name));
//     array[objIndex].deleted = true;
//     formik.setFieldValue("files", array)
//   }

//   const handleUpload = () => {
//     files.map((file) => {

//       const newFile = {
//         name: file?.name,
//         size: file?.size,
//         type: file?.type,
//         lastModified: file?.lastModified,
//         newName: uuidv4()
//       };

//       try {
//         // uploadFileToBlob(file, newFile?.newName, "image").then(res => {
//         //   if (res.status === 200) {
//         //     setUploadedImages((prevFiles) => [...prevFiles, newFile]);
//         //   }
//         // });
//         setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
//       } catch (error) {
//         console.error(error);
//       }

//     });
//   };

//   const formik = useFormik({
//     initialValues: {
//       id: '',
//       brandName: '',
//       mapperName: '',
//       mapperDescription: '',
//       status: '',
//       files: []
//     },
//     enableReinitialize: true,
//     validationSchema: Yup.object({
//       id: Yup.string().max(255),
//       brandName: Yup.string().max(255),
//       mapperName: Yup.string().max(255),
//       mapperDescription: Yup.string(),
//       status: Yup.string().max(255),
//       files: Yup.array()
//     }),
//     onSubmit: async (values, helpers) => {
//       try {
//         // NOTE: Make API request

//         try {
//           //const updatedInfo = await portalUpdateBrand(values);
//           console.log(values);
//           alert('Changes saved')
//         } catch (err) {
//           console.error(err);
//         }

//         helpers.setStatus({ success: true });
//         helpers.setSubmitting(false);
//         toast.success('Changes saved!');
//         await wait(500);
//         //helpers.resetForm();
//         //refresh();

//         //close();

//       } catch (err) {
//         console.error(err);
//         toast.error('Something went wrong!');
//         helpers.setStatus({ success: false });
//         helpers.setErrors({ submit: err.message });
//         helpers.setSubmitting(false);
//       }
//     }
//   });

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <form
//       onSubmit={formik.handleSubmit}
//       // {...other}
//       >
//         <Box sx={style}>
//           <Typography
//             color="textSecondary"
//             sx={{
//               my: 1,
//               //mt: 2,
//             }}
//             variant="subtitle2"
//           >
//             Request a new mapper
//           </Typography>
//           <Typography
//             color="textSecondary"
//             sx={{
//               my: 1
//             }}
//             variant="body2"
//           >
//             Add your request for the data mapper using the form below. Most importantly, we need a file from which we can create the mapper. Supported file formats are json (either product data or schema), excel, csv and xml.
//           </Typography>
//           <TextField
//             // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
//             fullWidth
//             // helperText={formik.touched.gtin && formik.errors.gtin}
//             disabled
//             required
//             label="Brand"
//             name="brandName"
//             onBlur={formik.handleBlur}
//             onChange={formik.handleChange}
//             value={formik.values.brandName}
//           />
//           <TextField
//             // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
//             fullWidth
//             // helperText={formik.touched.gtin && formik.errors.gtin}
//             label="Mapper name"
//             name="mapperName"
//             required
//             onBlur={formik.handleBlur}
//             onChange={formik.handleChange}
//             value={formik.values.mapperName}
//             sx={{ mt: 1 }}
//           />
//           <TextField
//             // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
//             fullWidth
//             // helperText={formik.touched.gtin && formik.errors.gtin}
//             label="Description"
//             name="mapperDescription"
//             multiline
//             minRows={3}
//             onBlur={formik.handleBlur}
//             onChange={formik.handleChange}
//             value={formik.values.mapperDescription}
//             sx={{ my: 1 }}
//           />
//           <Box sx={{ mb: 2 }}>
//             <Typography color="textSecondary" variant="subtitle2">Files *</Typography>
//             <FileDropzoneSmall
//               // accept={{
//               //   "image/png": [".png"],
//               //   "image/jpeg": [".jpg", ".jpeg"],
//               //   "image/webp": [".webp"],
//               //   "image/tiff": [".tiff"]
//               // }}
//               files={files}
//               onDrop={handleDrop}
//               onRemove={handleRemove}
//               onRemoveAll={handleRemoveAll}
//               onUpload={handleUpload}
//             />
//             <FormikProvider value={formik}>
//               <FieldArray name="files">
//                 {() => (
//                   <div>
//                     {formik.values.files.map((p, index) => {
//                       const name = `files[${index}].name`;
//                       const info = `files[${index}].info`;

//                       return (
//                         !p.deleted &&  <Box key={p.newName}>
//                           <Grid container spacing={1}>
//                             <Grid item xs={4}>
//                               <TextField
//                                 margin="dense"
//                                 variant="outlined"
//                                 label="File name"
//                                 name={name}
//                                 value={p.name}
//                                 disabled
//                                 fullWidth
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                               />
//                             </Grid>
//                             <Grid item xs={6}>
//                               <TextField
//                                 margin="dense"
//                                 variant="outlined"
//                                 label="Info"
                          
//                                 name={info}
//                                 value={p.info}
//                                 fullWidth
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                               />
//                             </Grid>
//                             <Grid item xs={2} sx={{
//                               display: "flex",
//                               alignContent: "center",
//                               justifyContent: "center"
//                             }}>
//                               <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteFile(p?.newName)}>Delete</Button>
//                             </Grid>
//                           </Grid>
//                         </Box>
//                       );
//                     })}
//                   </div>
//                 )}
//               </FieldArray>
//             </FormikProvider>
//           </Box>
//           <Button type="submit" variant="contained">Request mapper</Button>
//         </Box>
//       </form>
//     </Modal>
//   )
// }

const steps = [
  {
    label: 'Select a data mapper',
    description: `Create a new data mapper or use an existing one.`,
    action: 1
  },
  {
    label: 'Import a file',
    description: `Select a file from your computer.`,
    action: 2
  },
  {
    label: 'Preview',
    description: `Verify the contents for accuracy, and upon confirmation, select Upload, and the import tool will transfer the products to our database. `,
    action: 3
  },
  {
    label: 'Attach files',
    description: `The products have been uploaded to Bimroom. You can see the status of each product in the below table. The final stage involves attaching files to each product. This can also be done later in the product catalog.`,
    action: 4
  },
];

const applyPagination = (products, page, rowsPerPage) => products.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);



const SelectDataMapper = (props) => {
  const { dataMapper, setDataMapper, changeTab } = props;
  const [ openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    setDataMapper(e.target.value);
  }

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  }

  const mappers = [
    // { 
    //   name: "My mapper",
    //   id: "12314151512"
    // },
    // { 
    //   name: "My mapper 2",
    //   id: "12314151514"
    // }
  ];

  const bimroomMappers = [
    // { 
    //   name: "Bimroom mapper 123",
    //   id: "1231411241414"
    // },
    // { 
    //   name: "ETIM mapper",
    //   id: "12839012890151514"
    // }
  ];

  return (
    <>
      <Box sx={{my: 2}}>
        <Box >
          <FormControl fullWidth>
            <InputLabel id="select-data-mapper-input-label">Select a data mapper</InputLabel>
            <Select
              labelId="select-data-mapper-input-label"
              id="select-data-mapper"
              value={dataMapper}
              label="Select a data mapper"
              onChange={handleChange}
              disabled
            >
              {bimroomMappers.map((mapper) => 
                <MenuItem key={mapper?.id} value={mapper?.id}>{mapper?.name}</MenuItem>
              )}
              {mappers.map((mapper) => 
                <MenuItem key={mapper?.id} value={mapper?.id}>{mapper?.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center", 
          height: 50}}
        >
          <Typography variant="subtitle2">OR</Typography>
        </Box>
        <Box>
          {/* <Button variant="contained" onClick={() => changeTab({}, 2)}>Add a new mapper</Button> */}
          <Button variant="contained" onClick={handleOpenModal}>Request a new mapper</Button>
        </Box>
      </Box>
      <RequestMapperModal open={openModal} handleClose={handleOpenModal} />
    </>
  )
}

const fakeUploader = async (products, newArray, setProgress) => {
  if (products) {
    const productCount = products.length;
    let tmp = [];

    for (let i = 0; i < productCount; i++) {
      await wait(1000);
      setProgress(Math.floor((i+1)/productCount * 100));
      //console.log(products[i]?.name);
      tmp.push({
        id: products[i]?.id,
        name: products[i]?.name,
        //description: products[i]?.description
      });
    }
    newArray(tmp);
    setProgress(0);
  }
}

export const ImporterCustom = (props) => {
  const {
    handleChangeTab
  } = props;
  const ref = useRef();
  const [file, setFile] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [array, setArray] = useState([]);
  const [completedArray, setCompletedArray] = useState([]);
  const [page, setPage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [ dataMapper, setDataMapper] = useState("");

  const handleNext = async () => {
    //console.log(activeStep);
    if (activeStep === 1) {
      try {
        await ref.current.submit();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error(error);
      }
    } else if (activeStep === 2) {
      try {
        await fakeUploader(array, setCompletedArray, setProgress);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error(error);
      }
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const paginatedProducts = applyPagination(array, page, rowsPerPage);
  const paginatedCompleted = applyPagination(completedArray, page, rowsPerPage);

  return (
    <Box sx={{ mx: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
                { step.action === 1 && <SelectDataMapper 
                    dataMapper={dataMapper} 
                    setDataMapper={setDataMapper}
                    changeTab={handleChangeTab}
                  /> 
                }
                { step.action === 2 && <ImportFile 
                    ref={ref} 
                    setArray={setArray}
                    file={file}
                    setFile={setFile} 
                  /> 
                }
                { step.action === 3 && <ImportTable 
                    version={"preview"}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    products={paginatedProducts}
                    productsCount={array.length}
                    rowsPerPage={rowsPerPage}
                  /> 
                } 
                { step.action === 4 && <ImportTable 
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    products={paginatedCompleted}
                    productsCount={completedArray.length}
                    rowsPerPage={rowsPerPage}
                  /> 
                }
                { progress > 0 && <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value={progress} />
                </Box>
                }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={ handleNext }
                    sx={{ mt: 1, mr: 1 }}
                    disabled={ progress > 0 || dataMapper === "" && index === 0 || !file && index === 1 ? true : false }
                  >
                    { index === 0 || index === 3 ? 'Continue' : 'Upload' }
                  </Button>
                  <Button
                    disabled={ index === 0 }
                    onClick={ handleBack }
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
};
