import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import useDocumentTitle from "../useDocumentTitle";

// components
import Billing from "../components/Billing";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/loader";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import { MainProfileGuard } from "../components/main-profile-guard";

const BillingPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { accounts } = useMsal();
  useDocumentTitle('Bimroom Portal - Account - Billing');
  const isLoading = useSelector((store) => store.user.isLoading);
  
  const navigate = useNavigate();

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
          <DashboardLayout header="Account - Billing">
            <MainProfileGuard>
              <Billing /> 
            </MainProfileGuard>
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default BillingPage;