export const initialData = {

      id: '',
      formId: 'bon.0.2',
      bonVersion: 2,
      
      // basic details
      manufacturerProductId: '',
      descriptions: [
        {
          lang: 'en',
          description: '',
          translated: false
        },
        {
          lang: 'de',
          description: '',
          translated: false
        },
        {
          lang: 'fr',
          description: '',
          translated: false
        },
        {
          lang: 'it',
          description: '',
          translated: false
        },
        {
          lang: 'es',
          description: '',
          translated: false
        },
      ],
      slug: '',
      status: 'pending',
      libraryStatus: '',
      name: '',
      nameSpecification: '',
      brandName: '',
      brandId: '',
      countryOfManufacture: "",
      model: '',
      gtin: '',
      unitSystem: "metric",
  
      // category
      categories: [
        {
          category: '',
          subcategory1: '',
          subcategory2: ''
        }
      ],

      room: [],
      usage: [],
      
      // etim
  
      etimFeatures: [],
      etimClassification: "",
  
      // market area
      europe: [],
      africa: [],
      antarctica: [],
      asia: [],
      northAmerica: [],
      southAmerica: [],
      oceania: [],

      // variants
      variants: [],

      // global attributes
      attributes: [],

      // classification
      classification: [
        {
          type: "bon",
          label: "Uniclass 2015 Code",
          key: "uniclass2015Code",
          value: ""
        },
        {
          type: "bon",
          label: "Uniclass 2015 Description",
          key: "uniclass2015Desc",
          value: ""
        },
        {
          type: "bon",
          label: "OmniClass Number",
          key: "omniClassNumber",
          value: ""
        },
        {
          type: "bon",
          label: "OmniClass Title",
          key: "omniClassTitle",
          value: ""
        },
        {
          type: "bon",
          label: "Uniclass 2015 Code",
          key: "uniclass20Code",
          value: ""
        },
        {
          type: "bon",
          label: "Uniclass 2015 Description",
          key: "uniclass20Desc",
          value: ""
        },
        {
          type: "bon",
          label: "NBS Reference Code",
          key: "nbsReferenceCode",
          value: ""
        },
        {
          type: "bon",
          label: "NBS Reference Description",
          key: "nbsReferenceDesc",
          value: ""
        },
        {
          type: "bon",
          label: "IFC Classification",
          key: "ifcClassification",
          value: ""
        }
      ],

      // certifications & epd
      certifications: [],
      epd: [],
      
      // files
      images: [],
      revitFiles: [],
      otherBimFormats: [],
      documentation: [],

      // older versions
      versionHistory: [],

      // data enrichment
      dataEnriched: false,
  };