import React from "react";
import useDocumentTitle from "../useDocumentTitle";

// components
import Contracts from "../components/Contracts";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import Loader from "../components/Loader/loader";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import { MainProfileGuard } from "../components/main-profile-guard";

const ContractPage = () => {
  useDocumentTitle('Bimroom portal - Contracts');
  const isLoading = useSelector((store) => store.user.isLoading);
  
  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> :
          <DashboardLayout header="Account - documents">
            <MainProfileGuard>
              <Contracts />
            </MainProfileGuard>
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ContractPage;