import axios from "axios";
import { API } from "./api-routes";
//const qs = require('qs');

// ANALYTICS

const getViewsPerDay = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getViewsPerDay, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getTopList = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getTopList, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const locationData = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.locationData, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// GOOGLE ANALYTICS

const pageViews = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.pageViews, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

const downloads = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.downloads, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
}

// CONTRACTS


const addContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getContracts = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getContracts, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const signContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.signContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getSignedContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getSignedContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// PRODUCTS

const getProducts = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getProducts, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// CLIENTS & CLIENTS'S PRODUCTS

const getCompanyInfo = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getCompanyInfo, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// SUBSCRIPTIONS


// UPLOAD & DOWNLOAD

// const upload = (file) => {
//   const formData = new FormData();
//   formData.append("filename", file);
//   const query = API.upload + "?filename=" + file.name;
//   return new Promise((resolve, reject) => {
//     axios
//       .post(query, formData)
//       .then((res) => {
//         resolve(res.data);
//         console.log("File uploaded successfully!");
//       })
//       .catch((err) => {
//         reject(err.response.data);
//         console.log("File upload failed!");
//       });
//   });
// };

const upload = (file, folder, contentType) => {
  const formData = new FormData();
  formData.append("filename", file);
  // const config = {
  //   headers: {
  //     'Content-Type': contentType
  //   }
  // }
  const query = API.upload + "?filename=" + file.name + "&folder=" + folder;
  return new Promise((resolve, reject) => {
    axios
      .post(query, formData)
      .then((res) => {
        //console.log(res);
        resolve(res);
        console.log("File uploaded successfully!");
      })
      .catch((err) => {
        reject(err.response.status);
        console.log("File upload failed!");
      });
  });
};

const uploadAsWebp = (file, folder, contentType) => {
  const formData = new FormData();
  formData.append("filename", file);
  // const config = {
  //   headers: {
  //     'Content-Type': contentType
  //   }
  // }
  const query = API.uploadAsWebp + "?filename=" + file.name + "&folder=" + folder;
  return new Promise((resolve, reject) => {
    axios
      .post(query, formData)
      .then((res) => {
        //console.log(res);
        resolve(res);
        console.log("File uploaded successfully!");
      })
      .catch((err) => {
        reject(err.response.status);
        console.log("File upload failed!");
      });
  });
};

const getBrands = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getBrands, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// ETIM

const getEtimFeatures = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getEtimFeatures + "/?id=" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getEtimValues = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getEtimValues + "/?id=" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// Categories

const categories = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.categories)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// NEW API

const portalBrands = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalBrands, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


const portalUsers = (payload, token) => {
  
  const config = {
    headers:{
      Authorization: "Bearer " + token
    }
  };
  
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalUsers, payload, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalMainProfile = (payload) => {
  
  // const config = {
  //   headers:{
  //     Authorization: "Bearer " + token
  //   }
  // };
  
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalMainProfile, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalUpdateBrand = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalUpdateBrand, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalUpdateProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalUpdateProduct, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAddBrand = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalAddBrand, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalProducts = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalProducts, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalPending = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalPending, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalProduct, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalProductV2 = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalProductV2, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAddProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalAddProduct, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAddPending = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalAddPending, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAddMapper = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalAddMapper, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAddProducts = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalAddProducts, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalUpdateUser = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalUpdateUser, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalSubscriptions = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalSubscriptions, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalDocuments = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalDocuments, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalMessages = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalMessages, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalTasks = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalTasks, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAcceptPolicies = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalAcceptPolicies, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalModelling = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalModelling, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalModellingRequest = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalModellingRequest, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const publishProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.publishProduct, payload)
      .then((res) => {
        resolve(res.status);
      }
      )
      .catch((err) => {
        reject(err.response.data);
      }
      );
  }
  );
};
// brand publishing

const publishBrand = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.publishBrand, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const unpublishProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.unpublishProduct, payload)
      .then((res) => {
        resolve(res.status);
      }
      )
      .catch((err) => {
        reject(err.response.data);
      }
      );
  }
  );
};

const unpublishBrand = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.unpublishBrand, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export {
  // NEW API
  portalBrands,
  portalUsers,
  portalUpdateBrand,
  portalUpdateUser,
  portalUpdateProduct,
  portalProducts,
  portalPending,
  portalProduct,
  portalProductV2,
  portalAddProduct,
  portalAddPending,
  portalAddProducts,
  portalAddBrand,
  portalSubscriptions,
  portalDocuments,
  portalMessages,
  portalMainProfile,
  portalTasks,
  portalAcceptPolicies,
  portalAddMapper,
  portalModelling,
  portalModellingRequest,

  // product publishing
  publishProduct,
  unpublishProduct,

  // ANALYTICS
  getViewsPerDay,
  getTopList,
  locationData,

  // GOOGLE ANALYTICS
  pageViews,
  downloads,
  
  // CONTRACTS
  addContract,
  getContracts,
  updateContract,
  //getSingleContract,
  signContract,
  getSignedContract,
  // PRODUCTS
  getProducts,
  // CLIENTS & PRODUCTS
  getCompanyInfo,
  // SUBSCRIPTIONS
  // UPLOAD
  upload,
  uploadAsWebp,
  getBrands,
  // ETIM
  getEtimFeatures,
  getEtimValues,

  // CATEGORIES
  categories,

  // BRAND PUBLISHING
  publishBrand,
  unpublishBrand
};
