import { createSvgIcon } from '@mui/material/utils';

export const Bimroom = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" fill="currentColor">
    <path
       fill="#fff"
       d="m 27.48679,9.4583448 c 0,8.2550002 0,16.4835402 0,24.7385402 0.58208,-0.60854 1.08479,-1.19062 1.64041,-1.71979 2.3548,-2.19604 5.13292,-3.59833 8.28146,-4.20687 1.05834,-0.21167 2.16959,-0.34396 3.254379,-0.39688 4.18041,-0.26458 8.17562,0.58208 11.87979,2.51354 5.60916,2.91042 9.28687,7.46125 11.05958,13.52021 0.44979,1.50813 0.76729,3.04271 0.84667,4.60375 0.0794,1.53458 0.18521,3.06917 0.10583,4.57729 -0.18521,3.62479 -1.03187,7.11729 -2.69875,10.37167 -1.48167,2.88396 -3.51896,5.34458 -6.05896,7.38187 -2.93687,2.30188 -6.27062,3.75709 -9.89541,4.52438 -1.13771,0.23812 -2.30188,0.37042 -3.46605,0.47625 -1.11124,0.10583 -2.248949,0.15875 -3.360199,0.13229 -4.92125,-0.15875 -9.55146,-1.34937 -13.67896,-4.15396 -2.88396,-1.95791 -5.13292,-4.47146 -6.77333,-7.51416 -1.24355,-2.30188 -2.09021,-4.73605 -2.540005,-7.32896 -0.291041,-1.56104 -0.449791,-3.09563 -0.449791,-4.68313 0,-5.55625 -0.05292,-11.1125 -0.05292,-16.66875 0,-8.5725 0,-17.145 0,-25.7439602 0,-0.44979 0,-0.44979 0.423333,-0.44979 3.704173,0 7.381883,0 11.086043,0 0.10584,0.0265 0.23813,0.0265 0.39688,0.0265 z M 40.00158,38.932925 c -0.9525,0.10584 -1.93146,0.18521 -2.88396,0.37042 -2.32833,0.42333 -4.36562,1.45521 -6.0325,3.12208 -2.35479,2.3548 -3.38667,5.31813 -3.57187,8.59896 -0.0794,1.42875 0.0265,2.83104 0.3175,4.23334 0.44979,2.01083 1.27,3.86291 2.61937,5.45041 2.27542,2.64584 5.23875,3.86292 8.65188,4.07459 1.27,0.0794 2.513539,0 3.757079,-0.26459 3.14854,-0.66146 5.68854,-2.2225 7.51416,-4.89479 1.74625,-2.56646 2.38125,-5.42396 2.2225,-8.49312 -0.0529,-1.0848 -0.1852,-2.14313 -0.5027,-3.20146 -0.79375,-2.80459 -2.30188,-5.10646 -4.70959,-6.74688 -2.19604,-1.53458 -4.68312,-2.16958 -7.381869,-2.24896 z" />
    <path
       fill="#fff"
       d="m 68.470739,1.8648048 c 0,0.15875 0,0.26458 0,0.37042 0,3.75708 0,7.51416 0,11.2712502 0,0.21166 -0.0265,0.37041 -0.23812,0.5027 -3.81,2.32834 -7.62,4.68313 -11.43,7.03792 -0.0794,0.0529 -0.18521,0.10583 -0.3175,0.15875 0,-0.13229 -0.0265,-0.21167 -0.0265,-0.29104 0,-3.81 0,-7.62 0,-11.4035402 0,-0.18521 0.0265,-0.29104 0.21167,-0.39688 3.88937,-2.38125 7.75229,-4.7625 11.64166,-7.17021 0.0265,-0.0529 0.0529,-0.0529 0.15875,-0.0794 z" />
    </svg>
, 'Bimroom');
