import { Box, Button, IconButton, MenuItem, Popover, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Scrollbar } from '../scrollbar';
import { X as XIcon } from '../../icons/x';

const camelCaseToTitle = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export const ImportTable = (props) => {

  const {
    //updateProducts,
    //updated,
    version,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    products,
    setProducts,
    productsCount
  } = props;

  const handleDelete = (name) => {
    // CHANGE TO ID LATER
    const index = products.findIndex(object => {
      return object.name === name;
    });
    
    if (index > -1) {
      setProducts((current) =>
        current.filter((object) => object.name !== name)
      );
    }
  }

  const BonVariables = (props) => {
    const { product } = props;
    const bon = product?.details;
    let bonArray = []

    Object.entries(bon).forEach(([key, value]) => {
      if (value?.multipleValues) {
        const multipleValues = value?.values?.join(", ");
        bonArray.push({
          name: camelCaseToTitle(key),
          value: multipleValues + " " + value?.unit
        })
      } else if (value?.range) {
        const rangeValues = value?.min + "-" + value?.max;
        bonArray.push({
          name: camelCaseToTitle(key),
          value: rangeValues + " " + value?.unit
        })
      } else if (value?.value !== "") {
        bonArray.push({
          name: camelCaseToTitle(key),
          value: value?.value + " " + value?.unit
        })
      }
    });

    const output = bonArray.map((object, index) => {
      return (
        <div key={"bon" + index}>
          <Typography variant="caption" >
            { object?.name + ": " + object?.value }
          </Typography>
          <br />
        </div>
      )
    })
    
    return (output)
  }

  const CustomVariables = (props) => {
    const { product } = props;
    const custom = product?.customDetails;

    const customArray = custom.map((object, index) => {
      return (
        <div key={"custom" + index}>
          <Typography variant="caption" >
            {object?.name + ": " + object?.values.toString()}
          </Typography>
          <br />
        </div>
      )
    })
    
    return (customArray)
  }
  
  return (
    <div  >
      {/* <Scrollbar> */}
        <Table sx={{my: 2}}>
          <TableHead>
            <TableRow>
              {/* <TableCell width="20%" >
                { version === "preview" ? "Product ID" : "Status" }
              </TableCell> */}
              <TableCell width="5%" align="left">
                { version === "preview" ? "" : "Actions" }
              </TableCell>
              <TableCell width="20%" >
                { version === "preview" ? "Product name" : "ID" }
              </TableCell>
              <TableCell width="40%" >
                { version === "preview" ? "Description" : "Product name" }
              </TableCell>
              <TableCell width="30%" >
                { version === "preview" ? "Parameters" : "Description" }
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => {
              //console.log(product?.name);
              return (
                <Fragment key={index}>
                  <TableRow
                    hover
                    key={index}
                  >
                    {/* <TableCell>
                      {product?.id}
                    </TableCell> */}
                    <TableCell align="left">
                      <IconButton onClick={() => handleDelete(product?.name)}>
                        <XIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell >
                      {/* {product?.name} */}
                      {product?.name}
                    </TableCell>
                    <TableCell >
                      {/* {product?.description} */}
                      {product?.description}
                    </TableCell>
                    <TableCell >
                      {/* {product?.status} */}
                      {/* {printVariables(product)} */}
                      <Box sx={{maxHeight: 100, overflowY: "auto"}}>
                        <Typography variant="caption" sx={{fontWeight: 600}}>Filter</Typography>
                        <br />
                        <BonVariables product={product} />
                        <Typography variant="caption" sx={{fontWeight: 600}}>Custom</Typography>
                        <CustomVariables product={product} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      {/* </Scrollbar> */}
      <TablePagination
        component="div"
        count={productsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </div>
  )
};
