import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBrand } from '../redux/reducers/user-reducer';
import { Plus as PlusIcon } from '../icons/plus';
import { Check as CheckIcon } from '../icons/check';



// const organizations = [
//   {
//     id: "123",
//     name: "Test 123"
//   },
//   {
//     id: "124",
//     name: "Test 124"
//   }
// ];

export const OrganizationPopover = (props) => {
  const { anchorEl, onClose, open, openModal, organizations, ...other } = props;
  const selectedOrganization = useSelector((store) => store.user.selectedBrand);
  const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const dispatch = useDispatch();

  const handleChange = (organization) => {
    dispatch(setSelectedBrand(organization));
    localStorage.setItem('selectedBrand', JSON.stringify(organization));
    onClose?.();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 248 } }}
      transitionDuration={0}
      {...other}>
      {organizations?.map((organization) => (
        <MenuItem
          key={organization?.brandId}
          onClick={() => handleChange(organization)}
        >
          {/* {organization?.name} */}
          <ListItemIcon sx={{width: "24px"}}>
            {selectedOrganization.brandId === organization.brandId ? <CheckIcon /> : <Box></ Box>}
          </ListItemIcon>
          <ListItemText primary={organization.brandName} />
        </MenuItem>
      ))}
      {mainProfileId && <MenuItem
          //key={organization?.id}
          //onClick={() => handleChange(organization)}
          //sx={{borderTop: "1px solid #ccc"}}
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            "&:hover": {
              
              border: "1px dashed #175c83"
            }
          }}
        >
          {/* <Typography variant="body2">Add a new brand</Typography> */}
          <ListItemIcon sx={{width: "24px", color: '#175c83'}}>
            <PlusIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{fontWeight: 'medium'}} sx={{color: '#175c83'}} onClick={openModal} primary="Add a brand" />
        </MenuItem> }
    </Popover>
  );
};
