import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Scrollbar } from '../../scrollbar';

const messages = [
];

export const InboxTable = (props) => {
  const {
    // customers,
    // customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    refresh,
    ...other
  } = props;
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [reportOpen, setReportOpen] = useState(false);
  const navigate = useNavigate();

  // Reset selected customers when customers change
  useEffect(() => {
      if (selectedMessages.length) {
        setSelectedMessages([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages]);

  const handleSelectAllReports = (event) => {
    setSelectedReport(event.target.checked
      ? messages.map((report) => report.id)
      : []);
  };


  const getColor = (status) => {

    if (status === "Completed") {
      return "success.main";
    } else if (["Sign document","Created"].includes(status)) {
      return "#000";
    } else if (["Cancelled", "Deleted"].includes(status)) {
      return "error.main";
    } else {
      return "#000";
    }
  }

  const enableBulkActions = selectedMessages.length > 0;
  const selectedSomeReports = selectedMessages.length > 0
    && selectedMessages.length < messages.length;
  const selectedAllReports = selectedMessages.length === messages.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllReports}
          indeterminate={selectedSomeReports}
          onChange={handleSelectAllReports}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell align="center" width="5%">
                Select
              </TableCell>
              <TableCell width="20%">
                Sender
              </TableCell>
              <TableCell  width="55%">
                Message
              </TableCell>
              <TableCell align="center" width="15%" >
                Reply
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.length === 0 && <TableRow
              hover
              key="not-available"
              //sx={{cursor: "pointer"}}
            >
              <TableCell colSpan={4} sx={{height: "64px"}} align="center">No messages</TableCell>
            </TableRow>}
            {messages.map((message) => {
              const isMessageSelected = selectedMessages.includes(message.id);

              return (
                <TableRow
                  hover
                  key={message.id}
                  selected={isMessageSelected}
                  sx={{cursor: "pointer"}}
                >                 
                  <TableCell align="center">
                    <Checkbox />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                    >
                      {message?.sender?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                    >
                      {message?.sender?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={message?.read ? "body2" : "subtitle2"}
                      className="truncated-message"
                    >
                      {message?.message}
                    </Typography>
                    <Typography
                      
                      variant="body2"
                    >
                      {message?.email}
                    </Typography>
                  </TableCell>
                  <TableCell >
                    <Typography
                      color={getColor(message?.status)}
                      variant="body2"
                    >
                      {message?.status}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={messages.length}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

// CustomerListTable.propTypes = {
//   customers: PropTypes.array.isRequired,
//   customersCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
