import { Box, Button, IconButton, MenuItem, Popover, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Scrollbar } from '../scrollbar';
import { X as XIcon } from '../../icons/x';
import { Plus as PlusIcon } from '../../icons/plus';
import { AttachFilesModal } from './attach-files-modal';


export const AttachFilesTable = (props) => {

  const {
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    products,
    setProducts,
    productsCount
  } = props;

  const [ openModal, setOpenModal ] = useState(false);
  const [ openProduct, setOpenProduct ] = useState({});
  
  const handleCloseModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenModal = (product) => {
    setOpenProduct(product);
    setOpenModal(!openModal);
  }

  const Files = (props) => {
    const { product } = props;
    //console.log(product);

    const images = product?.images;
    const revitFiles = product?.revitFiles;
    const otherBimFormats = product?.otherBimFormats;
    const documentation = product?.documentation;

    let filenames = [];

    for (let i = 0; i < images.length; i++) {
      filenames.push(images[i]?.name);
    }

    for (let i = 0; i < revitFiles.length; i++) {
      filenames.push(revitFiles[i]?.name);
    }

    for (let i = 0; i < otherBimFormats.length; i++) {
      filenames.push(otherBimFormats[i]?.name);
    }

    for (let i = 0; i < documentation.length; i++) {
      filenames.push(documentation[i]?.name);
    }

    const output = filenames.map((object, index) => {
      return (
        <div key={"file" + product?.id + index}>
          <Typography variant="caption" >
            {object}
          </Typography>
          <br />
        </div>
      )
    })

    return output;

  }
   
  
  return (
    <div  >
      {/* <Scrollbar> */}
        <Table sx={{my: 2}}>
          <TableHead>
            <TableRow>
              <TableCell>
                Product name
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Files
              </TableCell>
              <TableCell align="right">
                
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => {
              //console.log(product?.name);
              return (
                <Fragment key={index}>
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell >
                      { product?.name }
                    </TableCell>
                    <TableCell >
                      { product?.status }
                    </TableCell>
                    <TableCell >
                      <Box sx={{maxHeight: 100, overflowY: "auto"}}>
                        <Files product={product} />

                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Button startIcon={<PlusIcon />} onClick={() => handleOpenModal(product)} variant="contained">
                        Add files
                      </Button>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      <TablePagination
        component="div"
        count={productsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
      <AttachFilesModal products={products} setProducts={setProducts} setOpenProduct={setOpenProduct} product={openProduct} open={openModal} handleClose={handleCloseModal} />
    </div>
  )
};
