import { 
  useEffect, 
  useState 
} from 'react';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardHeader
  // Slide,
  // Paper
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { wait } from '../utils/wait';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { portalAcceptPolicies } from '../api';
import { useMsal } from '@azure/msal-react';


export const AcceptPoliciesModal = (props) => {
  const { open, handleClose } = props;
  const { accounts } = useMsal();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //minWidth: 700,
    width: "80vw",
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto"
  };


  const formik = useFormik({
    initialValues: {
      userId: accounts[0]?.localAccountId || '',
      gtc: false,
      signed: '',
      privacyPolicy: false
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      userId: Yup.string().max(255),
      gtc: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted."),
      privacyPolicy: Yup.boolean()
        .required("The privacy policy must be reviewed.")
        .oneOf([true], "The privacy policy must be reviewed.")
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        
        try {
          if (values.userId) {
            //console.log(values);
            values.signed = new Date();
            const updatedUser = await portalAcceptPolicies(values);
            
            if (updatedUser) {
              //console.log(updatedUser);
              handleClose();
            }
          }
          //const updatedInfo = await portalUpdateBrand(values);

        } catch (err) {
          console.error(err);
        }

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Changes saved!');
        await wait(500);
        //helpers.resetForm();
        //refresh();

        //close();

      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
      onSubmit={formik.handleSubmit}
      // {...other}
      >
        <Box sx={style}>
          <Typography
            color="textSecondary"
            sx={{
              my: 1,
              //mt: 2,
            }}
            //variant="subtitle2"
            variant="h6"
          >
            Welcome to Bimroom!
          </Typography>
          <Typography
            color="textSecondary"
            sx={{
              my: 1
            }}
            variant="body1"
          >
            We have created an account for you on our Admin portal, granting you control over your assets in the Bimroom platform. 
            Upon agreeing to our terms, you will be automatically subscribed to our free product hosting package. Furthermore, we kindly request your signature for the <Link style={{color: '#175c83'}} to="/cla">copyright license agreement</Link>, which can be completed in the <Link style={{color: '#175c83'}} to="/account/documents">Account - Documents</Link> page. We won't be able to publish any of your content without it.
          </Typography>
          <br />
          <Typography
            color="textSecondary"
            sx={{
              my: 1,
              fontWeight: 600
            }}
            variant="body1"
          >
            Here are the main features included in the free product hosting package:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card sx={{backgroundColor: '#175c831A'}}>
                <CardContent>
                  <Typography color="textSecondary" variant="subtitle2" align="center">Free product hosting in the Bimroom library</Typography>
                  <List dense>
                    <ListItem >
                      <ListItemText
                        primary="Number of products"
                        secondary="Unlimited"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Number of brands"
                        secondary="Unlimited"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Automated quality control and compatibility check"
                        secondary="For all BIM objects"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Render images and 3D previews"
                        secondary="For all products with compatible BIM objects"
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{backgroundColor: '#1750001A'}}>
                <CardContent>
                  <Typography color="#175000" variant="subtitle2" align="center">Bimroom (admin) portal</Typography>
                  <List dense>
                    <ListItem >
                      <ListItemText
                        primary="Analytics and reports"
                        secondary="Basic level"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Communication tools with users"
                        secondary="Basic level"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Product upload"
                        secondary="Individually or in bulk (csv, excel, json)"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Integrations"
                        secondary="Basic level"
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Typography
            color="textSecondary"
            sx={{
              my: 1
            }}
            variant="body1"
          >
            Please carefully review all documents provided:
          </Typography> 
          <FormGroup>
            <FormControlLabel required name="gtc" onChange={formik.handleChange} control={<Checkbox checked={formik.values.gtc} />} label={<div>
              <span>I accept the </span>
              <a style={{color: '#175c83'}} target='_blank' rel='noopener noreferrer' href="https://cdn.bimroom.com/static/Terms%20and%20Conditions.pdf">terms & conditions</a>
            </div>} />
            <Typography
              color="textSecondary"
              sx={{
                my: 1
              }}
              variant="body2"
            >
              By checking "I accept the terms & conditions," you acknowledge your agreement with the terms and conditions.
            </Typography>
            <FormControlLabel required name="privacyPolicy" onChange={formik.handleChange} control={<Checkbox checked={formik.values.privacyPolicy} />} label={<div>
              <span>I have read the </span>
              <a style={{color: '#175c83'}} target='_blank' rel='noopener noreferrer' href="https://cdn.bimroom.com/static/Privacy%20Policy.pdf">privacy policy</a>
            </div>} />
            <Typography
              color="textSecondary"
              sx={{
                my: 1
              }}
              variant="body2"
            >
              By checking "I have read the privacy policy," you confirm that you have thoroughly reviewed the privacy policy.            
            </Typography>
          </FormGroup>
          <Typography
            color="textSecondary"
            sx={{
              my: 1
            }}
            variant="body2"
          >
            By clicking "Agree & Proceed," your selection on this page will be saved in your profile, and you will be redirected to the Account - Documents page, where you can sign the copyright license agreement. Subsequently, you will not encounter this page again. However, if you click "Later", this page will be displayed each time you refresh the page.
          </Typography>
          <Grid container sx={{mt: 2}}>
            <Grid item xs={6}>
              <Button disabled={formik.values.gtc && formik.values.privacyPolicy ? false : true} type="submit" variant="contained">Agree & Proceed</Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button onClick={handleClose} variant="outlined">Later</Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  )
}
