import { useState } from 'react';
import { 
  FieldArray,
  FormikProvider,
  useFormik
} from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createFilterOptions,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
  styled,
  Chip,
} from '@mui/material';
//import { etimFeatures } from '../../../data/etim';
import { Plus, Plus as PlusIcon } from '../../../icons/plus';
import { useEffect } from 'react';
import { 
  getEtimFeatures, 
  getEtimValues 
} from '../../../api';
import { dataSchema } from '../Schema/data-schema';
import { ecoLabelsList  } from '../../../data/formOptions';
//import { variantData } from '../Schema/variant-data';
import { FaCross } from 'react-icons/fa';
import { AiOutlineDeleteColumn } from 'react-icons/ai';
import { X } from '../../../icons/x';
import { v4 as uuidv4 } from "uuid";
import { VariantModal } from './variant-modal';
import { ParameterGroup } from '../../parameter-group';
import { CustomParameterField } from '../../custom-parameter-field';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: "#d1d1d1",
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#8badc1",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const variableNameAsString = (name, replace) => {
  const output = name.replace(".value", replace);
  console.log(output);
  return output;
}

const TechnicalDetailsForm = (props) => {
  const { formik } = props;
  const [etimFeature, setEtimFeature] = useState();
  const [etimNumericValue, setEtimNumericValue] = useState(0);
  const [etimMinValue, setEtimMinValue] = useState(0);
  const [etimMaxValue, setEtimMaxValue] = useState(0);
  const [etimLogicalValue, setEtimLogicalValue] = useState(false);
  const [etimAlphaNumericValue, setEtimAlphaNumericValue] = useState("");
  const [etimFeatureList, setEtimFeatureList] = useState([]);
  const [etimAlphaNumericValueList, setEtimAlphaNumericValueList] = useState([]);
  const [variantCount, setVariantCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [ selectedVariant, setSelectedVariant] = useState({});
  const [unitTab, setUnitTab] = useState(false);
  //const [unitSystem, setUnitSystem ] = useState("metric");

  const handleChangeUnitSystem = (event, newValue) => {
    setUnitTab(newValue);
    if (newValue) {
      formik.setFieldValue("unitSystem", "imperial")
    } else {
      formik.setFieldValue("unitSystem", "metric")
    }
  };

  useEffect(() => {
    if (formik.values.unitSystem === "imperial") {
      setUnitTab(1)
    }
  },[])

  //const testId = "EC000001";
  const filter = createFilterOptions();

  useEffect(() => {
    
    async function fetchData() {
      const response = await getEtimValues(etimFeature?.ArtClassFeatureNr);
      if (response) {
        setEtimAlphaNumericValueList(response);
      }
    }

    if (etimFeature?.FeatureType === "A") {
      fetchData();
    }

  }, [etimFeature])

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const handleOpenEdit = () => {
    setOpenEdit(!openEdit)
  }


  const handleDeleteCertification = (index) => {
    let array = formik.values.certifications;
    array.splice(index, 1);
    formik.setFieldValue("certifications", array)
  }

  const handleDeleteVariant = (index) => {
    let array = formik.values.variants;
    array.splice(index, 1);
    formik.setFieldValue("variants", array)
  }

  const addCertification = () => {
    let output = formik.values.certifications;
    
    const newCertification = {
      ecoLabel: '',
      ecoLabelSpecifier: ''
    }

    output.push(newCertification);

    formik.setFieldValue("certifications", output);
  }

  const addVariant = (values) => {
    setVariantCount(variantCount + 1);
    let output = formik.values.variants;
    const newVariant = values;
    output.push(newVariant);
    formik.setFieldValue("variants", output);
  }

  const editVariant = (values) => {
    //console.log(formik.values);
    setVariantCount(variantCount + 1);
    let output = formik.values.variants;
    
    const i = output.findIndex(x => x.id === values.id)
    output[i] = values;
    formik.setFieldValue("variants", output);
  }

  const selectVariant = (index) => {
    //setModifyingGlobal(false);
    let array = formik.values.variants;
    const object = array[index];
    //setSelectedVariant(object);
    setSelectedVariant(object);
    handleOpenEdit();
  }
  
  const getFeatureLabel = (option) => {
    if (option?.Unit !== null) {
      return (option?.FeatureDesc + " (" + option?.Unit + ")");
    } else {
      return (option?.FeatureDesc);
    }
  }

  const handleLogicalValueChange = (value) => {
    setEtimLogicalValue(value);
  }  

  const handleFeatureChange = (value) => {
    setEtimFeature(value);
  }

  const handleAlphaNumericValueChange = (value) => {
    setEtimAlphaNumericValue(value);
  }

  const handleValueChange = (event) => {
    setEtimNumericValue(event.target.value);
  };

  const handleMinValueChange = (event) => {
    setEtimMinValue(event.target.value);
  };

  const handleMaxValueChange = (event) => {
    setEtimMaxValue(event.target.value);
  };

  // const handleNewFeature = (event, newValue) => {
  //   setNewFeature(newFeature + 1);
  // };

  const addToEtimList = () => {

    if (etimFeature) {
      let newEtimRow = {}
      if (etimFeature?.FeatureType === "A") {
        const newEtimValues = etimAlphaNumericValueList.filter(value => value.ValueId === etimAlphaNumericValue);
        
        newEtimRow = {
          FeatureId: etimFeature.FeatureId,
          FeatureDesc: etimFeature.FeatureDesc,
          ValueId: newEtimValues[0]?.ValueId,
          ValueDesc: newEtimValues[0]?.ValueDesc,
          StringValue: newEtimValues[0]?.ValueDesc,
          NumberValue: null,
          MinValue: null,
          MaxValue: null,
          Unit: checkUnit(etimFeature?.Unit),
          VisibleValue: newEtimValues[0]?.ValueDesc
        }
      } else if (etimFeature?.FeatureType === "L") {
        
        const etimLogicalValueText = (value) => {
          if (value === true) {
            return "Yes"
          } else {
            return "No"
          }
        }
        newEtimRow = {
          FeatureId: etimFeature.FeatureId,
          FeatureDesc: etimFeature.FeatureDesc,
          ValueId: "",
          ValueDesc: "",
          StringValue: "",
          NumberValue: null,
          MinValue: null,
          MaxValue: null,
          Value: etimLogicalValue,
          Unit: checkUnit(etimFeature?.Unit),
          VisibleValue: etimLogicalValueText(etimLogicalValue)
        }
      } else if (etimFeature?.FeatureType === "N") {
        newEtimRow = {
          FeatureId: etimFeature.FeatureId,
          FeatureDesc: etimFeature.FeatureDesc,
          ValueId: "",
          ValueDesc: "",
          StringValue: "",
          NumberValue: null,
          MinValue: null,
          MaxValue: null,
          Value: etimNumericValue,
          Unit: checkUnit(etimFeature?.Unit),
          VisibleValue: etimMinValue + "-" + etimMaxValue
        }
      } else if (etimFeature?.FeatureType === "R") {
        newEtimRow = {
          FeatureId: etimFeature.FeatureId,
          FeatureDesc: etimFeature.FeatureDesc,
          ValueId: "",
          ValueDesc: "",
          StringValue: "",
          NumberValue: null,
          MinValue: etimMinValue,
          MaxValue: etimMaxValue,
          Value: "",
          Unit: checkUnit(etimFeature?.Unit),
          VisibleValue: etimMinValue + "-" + etimMaxValue
        }
      }

      formik.setFieldValue("etimFeatures", [
        ...formik.values.etimFeatures,
        newEtimRow
      ]);

      handleFeatureChange();
      handleAlphaNumericValueChange("");
      //handleValueChange();

     // console.log("added");
    } else {
      console.log("failed");
    }
  }

  const checkUnit = (unit) => {
    if (typeof(unit) === "undefined") {
      return ("")
    } else {
      return (unit)
    }
  }

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Variable product</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                error={Boolean(formik.touched.variableProduct && formik.errors.variableProduct)}
                fullWidth
                select
                label="Variable product"
                name="variableProduct"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mb: 1 }}
                value={formik.values.variableProduct}
              >
                <MenuItem key="non-variable" value={false}>Non-variable</MenuItem>
                <MenuItem key="variable" value={true}>Variable</MenuItem>
              </TextField>
              {formik.values.variableProduct && 
                <>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{mb: 1}}
                  >
                      Variants
                  </Typography>
                  <FormikProvider value={formik}>
                    <FieldArray 
                      name="variants" 
                      render={() => (
                        <div>
                          {formik.values.variants.map((p, index) => {
                            const variant = `variants[${index}].variant`;
                            //const ecoLabelSpecifier = `variants[${index}].ecoLabelSpecifier`;
                            return (
                              <Box key={"variants" + index}>
                                <Grid container spacing={1}>
                                  <Grid item xs={9}>
                                    <TextField
                                      //margin="dense"
                                      variant="outlined"
                                      label="Variant"
                                      size="small"
                                      name={variant}
                                      value={p.variant}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      sx={{mb: 1}}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button sx={{mb: 1, width: "100%"}} onClick={() => selectVariant(index)}>Modify</Button>
                                  </Grid>
                                  <Grid item xs={1} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center"
                                  }}>
                                    <IconButton sx={{mb:1 }} aria-label="delete" size="small" color="error" onClick={() => handleDeleteVariant(index)}>
                                      <X />
                                    </IconButton>
                                    {/* <Button sx={{mb: 1}} size="small" color="error" fullWidth onClick={() => handleDeleteVariant(index)}>Delete</Button> */}
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </div>
                      )}
                    />
                  </FormikProvider>
                  <Button
                    sx={{
                      //mt: 1,
                      mr: "auto",
                    }}
                    size="small"
                    variant="outlined"
                   // onClick={addVariant}
                    onClick={handleOpenModal}
                    startIcon={<Plus />}
                  >
                    Add variant
                  </Button>
                </>
              } 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={7} md={12}>
                  <Typography variant="h6">Dimensions</Typography>
                </Grid>
                <Grid item xs={5} md={12}>
                  {/* <Box sx={{backgroundColor: "#d1d1d1", borderRadius: 1}}> */}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            checked={unitTab}
                            onChange={handleChangeUnitSystem}
                            inputProps={{ 'aria-label': 'unit-controller' }}
                            // color="secondary"
                          />
                        }
                        label={<Typography sx={{fontSize: 12, fontWeight: 700, color: "#175c83"}}>Imperial/US Customary Units</Typography>}
                        labelPlacement='end'
                      />
                    </FormGroup>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12}>
              <ParameterGroup key="parameter-group-dimensions" dataSchema={dataSchema} formik={formik} parameterClass="Dimensions" showTitle={false} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={7} md={12}>
                  <Typography variant="h6">Technical details</Typography>
                </Grid>
                <Grid item xs={5} md={12}>
                  {/* <Box sx={{backgroundColor: "#d1d1d1", borderRadius: 1}}> */}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            checked={unitTab}
                            onChange={handleChangeUnitSystem}
                            inputProps={{ 'aria-label': 'unit-controller' }}
                            // color="secondary"
                          />
                        }
                        label={<Typography sx={{fontSize: 12, fontWeight: 700, color: "#175c83"}}>Imperial/US Customary Units</Typography>}
                        labelPlacement='end'
                      />
                    </FormGroup>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12}>
              {["Appearance", "Technical features", "Construction", "Lighting features", "Acoustic", "Quantities", "Fire properties", "Electrical", "Materials", "Approvals"].map((paramClass, index) => 
                <ParameterGroup key={`parameter-group-${paramClass}`} dataSchema={dataSchema} formik={formik} parameterClass={paramClass} showTitle={true} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={7} md={12}>
                  <Typography variant="h6">Technical details</Typography>
                </Grid>
                <Grid item xs={5} md={12}>
                  {/* <Box sx={{backgroundColor: "#d1d1d1", borderRadius: 1}}> */}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            checked={unitTab}
                            onChange={handleChangeUnitSystem}
                            inputProps={{ 'aria-label': 'unit-controller' }}
                            // color="secondary"
                          />
                        }
                        label={<Typography sx={{fontSize: 12, fontWeight: 700, color: "#175c83"}}>Imperial/US Customary Units</Typography>}
                        labelPlacement='end'
                      />
                    </FormGroup>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12}>
              {/* <Grid container spacing={1}> */}
                {["Energy efficiency", "Sustainability"].map((paramClass, index) => 
                  <ParameterGroup key={`parameter-group-${paramClass}`} dataSchema={dataSchema} formik={formik} parameterClass={paramClass} showTitle={true} />
                )}
                <Grid container spacing={1}  sx={{mb: 1}}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                    >
                      Certifications & Eco Labels
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikProvider value={formik}>
                      <FieldArray 
                        name="certifications" 
                        render={() => (
                          <div>
                            {/* <Grid container spacing={1} sx={{mt: 1}}> */}
                              {formik.values.certifications.map((p, index) => {
                                const ecoLabel = `certifications[${index}].ecoLabel`;
                                const ecoLabelSpecifier = `certifications[${index}].ecoLabelSpecifier`;

                                return (
                                  <Box key={index}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={5}>
                                        <Autocomplete
                                          //disableClearable
                                          id="combo-box-demo"
                                          freeSolo
                                          selectOnFocus
                                          //clearOnBlur
                                          //defaultValue={defaultValue}
                                          sx={{mb: 1}}
                                          size="small"
                                          options={ecoLabelsList}
                                          //onBlur={formik.handleBlur}
                                          //onChange={handleEcoLabelChange}
                                          onChange={(e, value) => value?.label ? formik.setFieldValue(ecoLabel, value?.label) : formik.setFieldValue(ecoLabel, "")}
                                          onBlur={formik.handleBlur}
                                          value={p.ecoLabel}
                                          name={ecoLabel}
                                          renderInput={(params) => <TextField {...params} label="Eco label" />}
                                          filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            //console.log(inputValue);
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option);
                                            
                                            if (inputValue !== '' && !isExisting) {
                                              // const newObject = {
                                              //   value: 1000 + index,
                                              //   label: inputValue
                                              // }
                                              filtered.push(inputValue);
                                            }

                                            return filtered;
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={5}>
                                        <TextField
                                          //margin="dense"
                                          variant="outlined"
                                          label="Specifier"
                                          size="small"
                                          name={ecoLabelSpecifier}
                                          value={p.ecoLabelSpecifier}
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          sx={{mb: 1}}
                                        />
                                      </Grid>
                                      <Grid item xs={2} sx={{
                                        display: "flex",
                                        alignContent: "center",
                                        justifyContent: "center"
                                      }}>
                                        <Button sx={{mb: 1}} size="small" color="error" fullWidth onClick={() => handleDeleteCertification(index)}>Delete</Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                );
                              })}
                            {/* </Grid> */}
                          </div>
                        )}
                      />
                    </FormikProvider>
                    <Button
                      sx={{
                        //mt: 1,
                        mr: "auto",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={addCertification}
                      startIcon={<Plus />}
                    >
                      Add certification
                    </Button>
                  </Grid>
               </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Custom parameters</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}> 
                  
                  <CustomParameterField formik={formik} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {formik.values.etimClassification !== "" && <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">ETIM</Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Table sx={{borderRadius: 2, mb: 2}}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Feature
                        </TableCell>
                        <TableCell align="right">
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.etimFeatures.map((feature, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">
                                {feature?.FeatureDesc}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" align="right">
                                {feature?.Value} {feature?.Unit}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )})}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Autocomplete
                    key={etimFeature}
                    id="etimFeature"
                    name="etimFeature"
                    options={etimFeatureList}
                    //groupBy={(option) => option.state}
                    getOptionLabel={(option) => getFeatureLabel(option)}
                    //style={{ width: 300 }}
                    value={etimFeature}
                    onChange={(event, value) => handleFeatureChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //onChange={formik.handleChange}
                        //margin="normal"
                        label="ETIM FEATURE"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {/* Logical values */}
                {etimFeature?.FeatureType === "L" && 
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      id="etim-select-logical-value"
                      select
                      fullWidth
                      label="ETIM VALUE"
                      value={etimLogicalValue}
                      // helperText="Please select"
                      //onChange={handleAlphaNumericValueChange}
                      onChange={(event) => handleLogicalValueChange(event.target.value)}
                    >
                      {/* {etimAlphaNumericValueList.map((option) => (
                        <MenuItem key={option.ValueId} value={option.ValueId}>
                          {option.ValueDesc}
                        </MenuItem>
                      ))} */}
                      <MenuItem key={false} value={false}>No</MenuItem>
                      <MenuItem key={true} value={true}>Yes</MenuItem>
                    </TextField>
                  </Grid>
                }
                    {/* Alphanumeric values */}
                {etimFeature?.FeatureType === "A" && 
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      id="etim-select-alphanumeric-value"
                      select
                      fullWidth
                      label="ETIM VALUE"
                      value={etimAlphaNumericValue}
                      // helperText="Please select"
                      //onChange={handleAlphaNumericValueChange}
                      onChange={(event) => handleAlphaNumericValueChange(event.target.value)}
                    >
                      {etimAlphaNumericValueList.map((option) => (
                        <MenuItem key={option.ValueId} value={option.ValueId}>
                          {option.ValueDesc}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ Grid>
                }
                {/* Range values */}
                {etimFeature?.FeatureType === "R" && 
                  <>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                      <TextField
                        //error={Boolean(formik.touched.width && formik.errors.width)}
                        //key={etimValue}
                        fullWidth
                        label="MIN VALUE"
                        name="etimMinValue"
                        type="number"
                        value={etimMinValue}
                        onChange={handleMinValueChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{checkUnit(etimFeature?.Unit)}</InputAdornment>,
                        }}
                        //sx={{ mt: 2 }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                      <TextField
                        //error={Boolean(formik.touched.width && formik.errors.width)}
                        //key={etimValue}
                        fullWidth
                        label="MAX VALUE"
                        name="etimMaxValue"
                        type="number"
                        value={etimMaxValue}
                        onChange={handleMaxValueChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{checkUnit(etimFeature?.Unit)}</InputAdornment>,
                        }}
                        //sx={{ mt: 2 }}
                      />
                    </Grid>
                  </>
                  }
                {/* Numeric values */}
                {etimFeature?.FeatureType === "N" &&                     
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      //error={Boolean(formik.touched.width && formik.errors.width)}
                      //key={etimValue}
                      fullWidth
                      label="ETIM VALUE"
                      name="etimNumericValue"
                      type="number"
                      value={etimNumericValue}
                      onChange={handleValueChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{checkUnit(etimFeature?.Unit)}</InputAdornment>,
                      }}
                      //sx={{ mt: 2 }}
                    />
                  </ Grid>
                  }
                </Grid>
              <IconButton onClick={addToEtimList} sx={{mt: 1}} ><PlusIcon /></IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>}
      <VariantModal 
        open={openEdit} 
        handleClose={handleOpenEdit} 
        addVariant={editVariant}
        category={formik.values.category}
        selectedVariant={selectedVariant}
        edit
      />
      <VariantModal 
        open={openModal} 
        handleClose={handleOpenModal} 
        addVariant={addVariant} 
        category={formik.values.category}
        selectedVariant={{}}
        edit={false}
      />
    </> 
  )
}

export default TechnicalDetailsForm;
