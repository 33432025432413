import React from "react";
import { HTML5Backend } from 'react-dnd-html5-backend'

// components

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import Loader from "../components/Loader/loader";
import useDocumentTitle from "../useDocumentTitle";
import { BrandGuard } from "../components/brand-guard";
import { DndProvider } from "react-dnd";
import ZipUploader from "../components/ZipUploader";

const ZipUploaderPage = () => {
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom Portal - Zip uploader');

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
          <DashboardLayout>
            <BrandGuard>
              <DndProvider backend={HTML5Backend}>
                <ZipUploader />
              </DndProvider>
            </BrandGuard>
          </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ZipUploaderPage;