const categoryOptions = [
    {
        label: 'No category selected',
        value: '',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    },
    {
        label: 'Building Elements & Modules',
        value: 'building-elements-and-modules',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Balconies',
            value: 'balconies',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Modular Spaces',
            value: 'modular-spaces',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Shelters, Booths etc.',
            value: 'shelters-booths-etc',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Casework',
        value: 'casework',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Shelving & Storage',
            value: 'shelving-and-storage',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Lockers',
                value: 'lockers'
            }, {
                label: 'Warehouse Shelves',
                value: 'warehouse-shelves'
            }]
        }, {
            label: 'Kitchen Cabinets',
            value: 'kitchen-cabinets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Wardrobes',
            value: 'wardrobes',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Bathroom Cabinets',
            value: 'bathroom-cabinets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Circulation',
        value: 'circulation',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Autowalks',
            value: 'autowalks',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Elevators',
            value: 'elevators',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Escalators',
            value: 'escalators',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Gates & Barriers',
            value: 'gates-and-barriers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Ladders',
            value: 'ladders',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Loading Equipment',
            value: 'loading-equipment',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Ramps',
            value: 'ramps',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Stairs',
            value: 'stairs',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Construction',
        value: 'construction',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Acoustics',
            value: 'acoustics',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Beams',
            value: 'beams',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Ceilings',
            value: 'ceilings',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Columns',
            value: 'columns',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Curtain Systems & Facades',
            value: 'curtain-systems-and-facades',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Curtain Walls',
                value: 'curtain-walls'
            }, {
                label: 'Curtain Roofs',
                value: 'curtain-roofs'
            }, {
                label: 'Facade Systems',
                value: 'facade-systems'
            }]
        }, {
            label: 'Louvres & Shutters',
            value: 'louvres-and-shutters',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Materials & Coatings',
            value: 'materials-and-coatings',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Profiles',
            value: 'profiles',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Trusses & Frames',
            value: 'trusses-and-frames',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Structural Framing',
                value: 'structural-framing'
            }, {
                label: 'Structural Trusses',
                value: 'structural-trusses'
            }]
        }]
    }, {
        label: 'Doors',
        value: 'doors',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Door Accessories',
            value: 'door-accessories',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Door Handles',
                value: 'door-handles'
            }, {
                label: 'Door Hardware',
                value: 'door-hardware'
            }, {
                label: 'Door Locks',
                value: 'door-locks'
            }, {
                label: 'Door Openers',
                value: 'door-openers'
            }]
        }, {
            label: 'Swing Doors',
            value: 'swing-doors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Single Leaf',
                value: 'single-leaf'
            }, {
                label: 'Double Leaf',
                value: 'double-leaf'
            }, {
                label: 'Pivot Doors',
                value: 'pivot-doors'
            }]
        }, {
            label: 'Sliding Doors',
            value: 'sliding-doors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Telescopic Doors',
                value: 'telescopic-doors'
            }]
        }, {
            label: 'Folding Doors',
            value: 'folding-doors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Roll-up & Sectional Doors',
            value: 'roll-up-and-sectional-doors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Revolving Doors',
            value: 'revolving-doors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Access Doors & Latches',
            value: 'access-doors-and-latches',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Electrical',
        value: 'electrical',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Communication',
            value: 'communication',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Control Panels',
            value: 'control-panels',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Controls',
            value: 'controls',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Distribution',
            value: 'distribution',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Installations',
            value: 'installations',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Power Supply & Distribution',
            value: 'power-supply-and-distribution',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Sensors',
            value: 'sensors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Conduits & Cable Trays',
            value: 'conduits-and-cable-trays',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Electronics',
        value: 'electronics',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Appliances',
            value: 'appliances',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Freezers',
                value: 'freezers'
            }, {
                label: 'Dishwashers',
                value: 'dishwashers'
            }, {
                label: 'Dryers',
                value: 'dryers'
            }, {
                label: 'Microwaves',
                value: 'microwaves'
            }, {
                label: 'Refrigerators',
                value: 'refrigerators'
            }, {
                label: 'Washing Machines',
                value: 'washing-machines'
            }, {
                label: 'Other Appliances',
                value: 'other-appliances'
            }, {
                label: 'Ovens',
                value: 'ovens'
            }, {
                label: 'Stovetops',
                value: 'stovetops'
            }]
        }, {
            label: 'Entertainment',
            value: 'entertainment',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Audio Systems',
                value: 'audio-systems'
            }, {
                label: 'Projectors',
                value: 'projectors'
            }, {
                label: 'Televisions',
                value: 'televisions'
            }]
        }, {
            label: 'Information Technology',
            value: 'information-technology',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Communication',
            value: 'communication',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Controls & Displays',
            value: 'controls-and-displays',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Security Devices',
            value: 'security-devices',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Fire Products',
        value: 'fire-products',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Fire Dampers',
            value: 'fire-dampers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Fire Hydrants & Extinguishers',
            value: 'fire-hydrants-and-extinguishers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Firestops',
            value: 'firestops',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Sprinklers',
            value: 'sprinklers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Flooring',
        value: 'flooring',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Carpets',
            value: 'carpets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Laminate',
            value: 'laminate',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Linoleum',
            value: 'linoleum',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Stone',
            value: 'stone',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Systems',
            value: 'systems',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Tiles',
            value: 'tiles',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Vinyl',
            value: 'vinyl',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Wood',
            value: 'wood',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Furniture',
        value: 'furniture',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Acoustics',
            value: 'acoustics',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Armchairs & Recliners',
            value: 'armchairs-and-recliners',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Beds',
            value: 'beds',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Benches',
            value: 'benches',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Chairs',
            value: 'chairs',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Decoration',
            value: 'decoration',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Desks & Workstations',
            value: 'desks-and-workstations',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Footrests & Ottomans',
            value: 'footrests-and-ottomans',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Hooks & Hangers',
            value: 'hooks-and-hangers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Mirrors',
            value: 'mirrors',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Room Dividers & Screens',
            value: 'room-dividers-and-screens',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Sofas',
            value: 'sofas',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Stools',
            value: 'stools',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Tables',
            value: 'tables',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Sports Equipment',
            value: 'sports-equipment',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Storage & Display',
            value: 'storage-and-display',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Materials',
            value: 'materials',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'HVAC',
        value: 'hvac',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Air Conditioning',
            value: 'air-conditioning',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Control Panels',
            value: 'control-panels',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Hanging Systems',
            value: 'hanging-systems',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Heating',
            value: 'heating',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Fireplaces',
                value: 'fireplaces'
            }, {
                label: 'Heat Exchangers & Heat Recovery',
                value: 'heat-exchangers-and-heat-recovery'
            }, {
                label: 'Boilers & Heaters',
                value: 'boilers-and-heaters'
            }, {
                label: 'Solar Heating',
                value: 'solar-heating'
            }]
        }, {
            label: 'Mechanical Ventilation',
            value: 'mechanical-ventilation',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Smoke Extraction',
            value: 'smoke-extraction',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Kitchen',
        value: 'kitchen',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Appliances',
            value: 'appliances',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Freezers',
                value: 'freezers'
            }, {
                label: 'Dishwashers',
                value: 'dishwashers'
            }, {
                label: 'Microwaves',
                value: 'microwaves'
            }, {
                label: 'Refrigerators',
                value: 'refrigerators'
            }, {
                label: 'Ovens',
                value: 'ovens'
            }, {
                label: 'Stovetops',
                value: 'stovetops'
            }]
        }, {
            label: 'Kitchen Cabinets',
            value: 'kitchen-cabinets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Countertops',
                value: 'countertops'
            }]
        }, {
            label: 'Sinks',
            value: 'sinks',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Taps & Faucets',
            value: 'taps-and-faucets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Lighting',
        value: 'lighting',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Ceiling',
            value: 'ceiling',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Daylighting',
            value: 'daylighting',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Floor',
            value: 'floor',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Desk & Table',
            value: 'desk-and-table',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Other',
            value: 'other',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Outdoor',
            value: 'outdoor',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Pendants',
            value: 'pendants',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Wall',
            value: 'wall',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Medical & Research Equipment',
        value: 'medical-and-research-equipment',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Outdoor Furniture',
        value: 'outdoor-furniture',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Benches',
            value: 'benches',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Chairs',
            value: 'chairs',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Playground',
            value: 'playground',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Sports Equipment',
            value: 'sports-equipment',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Storage',
            value: 'storage',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Tables',
            value: 'tables',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Waste & Recycling',
            value: 'waste-and-recycling',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Planting',
        value: 'planting',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Trees',
            value: 'trees',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Shrubs',
            value: 'shrubs',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Vines',
            value: 'vines',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Plumbing',
        value: 'plumbing',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Drains',
            value: 'drains',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Other',
            value: 'other',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Piping',
            value: 'piping',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Taps & Faucets',
            value: 'taps-and-faucets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Toilets',
            value: 'toilets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Water Heaters',
            value: 'water-heaters',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Pumps',
            value: 'pumps',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Railings & Fences',
        value: 'railings-and-fences',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Fences',
            value: 'fences',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Railings',
            value: 'railings',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }, {
        label: 'Sanitary',
        value: 'sanitary',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Appliances',
            value: 'appliances',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Dryers',
                value: 'dryers'
            }, {
                label: 'Washing Machines',
                value: 'washing-machines'
            }]
        }, {
            label: 'Basins & Sinks',
            value: 'basins-and-sinks',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Bathtubs',
            value: 'bathtubs',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Hot Tubs',
                value: 'hot-tubs'
            }]
        }, {
            label: 'Bathroom Accessories',
            value: 'bathroom-accessories',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Bathroom Cabinets',
            value: 'bathroom-cabinets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Bidets',
            value: 'bidets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Sauna',
            value: 'sauna',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Shower Screens & Cabinets',
            value: 'shower-screens-and-cabinets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Showers',
            value: 'showers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Taps & Faucets',
            value: 'taps-and-faucets',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Toilets Seats',
            value: 'toilets-seats',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Urinals',
            value: 'urinals',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    },
    {
        label: 'Signage',
        value: 'signage',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Circulation',
            value: 'circulation',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Emergency Signage',
            value: 'emergency-signage',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    },
    {
        label: 'Windows',
        value: 'windows',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Fixed Windows',
            value: 'fixed-windows',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Hinged Windows',
            value: 'hinged-windows',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Hung Windows',
            value: 'hung-windows',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Roof Windows',
            value: 'roof-windows',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        },

        {
            label: 'Sliding Windows',
            value: 'sliding-windows',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Window Accessories',
            value: 'window-accessories',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }, {
                label: 'Blinds & Curtains',
                value: 'blinds-and-curtains'
            }, {
                label: 'Window Handles',
                value: 'window-handles'
            }, {
                label: 'Window Hardware',
                value: 'window-hardware'
            }, {
                label: 'Window Locks',
                value: 'window-locks'
            }, {
                label: 'Window Openers',
                value: 'window-openers'
            }]
        }
        ]
    }, {
        label: 'Waste & Recycling',
        value: 'waste-and-recycling',
        subcategory: [{
            label: 'No category selected',
            value: '',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Waste Systems',
            value: 'waste-systems',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }, {
            label: 'Waste Bins & Containers',
            value: 'waste-bins-and-containers',
            subcategory: [{
                label: 'No category selected',
                value: ''
            }]
        }]
    }
];

function getCategoryIndex(value) {
    let indexNumber = categoryOptions.findIndex(obj => obj.value === value);
    if (indexNumber === -1) {
        return 0;
    } else {
        return indexNumber;
    }
};

function getSubcategoryIndex(upperCategory, value) {
    let indexNumber = categoryOptions[getCategoryIndex(upperCategory)].subcategory.findIndex(obj => obj.value === value);
    if (indexNumber === -1) {
      return 0;
    } else {
      return indexNumber;
    }
}

function checkSubcategory(level, value, category, subcategory1) {
    
    if (level === 1) {
      
      let indexNumber = categoryOptions[getCategoryIndex(category)].subcategory.findIndex(obj => obj.value === value);

      if (indexNumber > 0) {
        return value;
      } else {
        value = '';
        return value;
      }

    } else if (level === 2) {

      let indexNumber = categoryOptions[getCategoryIndex(category)].subcategory[getSubcategoryIndex(category,subcategory1)].subcategory.findIndex(obj => obj.value === value);
      
      if (indexNumber > 0) {
        return value;
      } else {
        value = '';
        return value;
      }

    } else {
      return value;
    }
}




export { categoryOptions, getCategoryIndex, getSubcategoryIndex, checkSubcategory };