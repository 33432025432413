import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyInfo, portalAddBrand, portalBrands, portalUpdateBrand, publishBrand, upload } from '../../api';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { wait } from '../../utils/wait';
import { useMsal } from '@azure/msal-react';
import ModalComponent from '../ModalComponent';
import { ImageDropzone } from '../image-dropzone';
import { v4 as uuidv4} from 'uuid';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CropperDropzone } from '../cropper-dropzone';
// import { stateToHTML } from 'draft-js-export-html';
// import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { ImageCropper } from '../image-cropper';
import { Plus } from '../../icons/plus';
import BrandCard from './BrandCard';
import CoverImageSelector from './CoverImageSelector';
import PublicContacts from './PublicContacts';
import ContactPerson from './ContactPerson';
import BrandPageStatus from './BrandPageStatus';
import BrandLogo from './BrandLogo';
import BrandDetails from './BrandDetails';
import SocialMedia from './SocialMedia';
import Videos from './Videos';


async function exists(url) {
  const result = await fetch(url, { method: 'HEAD' });
  //console.log(result)
  return result.ok;
}

const cdnUrl = (id) => {
  // const url = "https://cdn.bimroom.com/logo/" + id
  const url = "https://platformdev123.blob.core.windows.net/logo/" + id
  return url
}

const BrandInformation = (props) => {
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const [ openModal, setOpenModal ] = useState(false);
  const [ openContactModal, setOpenContactModal ] = useState(false);
  //const [ editingImage, setEditingImage ] = useState(false);
  const [ counter, setCounter] = useState(0);
  const [ brandData, setBrandData ] = useState({});
  const [ images, setImages ] = useState([]);
  const [imageFile, setImageFile] = useState();
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const { accounts } = useMsal();
  const [ changesMade, setChangesMade ] = useState(false);
  const [updated, setUpdated] = useState(false);
  // const [editorState, setEditorState] = useState(
  //   EditorState.createEmpty()
  // );
  // const editor = useRef(null);

  // useEffect(() => {
  //   console.log("testing")
  // }, [imageFile])

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };

  const formik = useFormik({
    initialValues: {
      id: brandData?.id || '',
      brandId: brandData?.brandId || '',
      mainProfileId: brandData?.mainProfileId || '',
      brandName: brandData?.brandName || '',
      brandSlug: brandData?.brandSlug || '',
      brandUrl: brandData?.brandUrl || '',
      description: brandData?.description || '',
      //logoUrl: brandData?.logoUrl || '',
      logoUrl: '',
      contactPerson: {
        name: brandData?.contactPerson?.name || '',
        address: brandData?.contactPerson?.address || '',
        phoneNumber: brandData?.contactPerson?.phoneNumber || '',
        email: brandData?.contactPerson?.email || ''
      },
      publicContacts: brandData?.publicContacts || [],
      socialMedia: brandData?.socialMedia || [],
      coverImage: brandData?.coverImage || '',
      userUploadedCoverImages: brandData?.userUploadedCoverImages || [],
      videos: brandData?.videos || [],
      images: brandData?.images || [],
      status: brandData?.status || 'unpublished',
      collections: brandData?.collections || [],
      fallbackLanguage: brandData?.fallbackLanguage || 'en',
      fallbackLocation: brandData?.fallbackLocation || 'default',
      descriptions: brandData?.descriptions || [],
      companyContactDetails: brandData?.companyContactDetails || [],
      formId: brandData?.formId || 'bon.0.2',
      lastUpdated: brandData?.lastUpdated || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      brandId: Yup.string().max(255),
      mainProfileId: Yup.string().max(255),
      brandName: Yup.string().max(255),
      brandSlug: Yup.string().max(255),
      brandUrl: Yup.string().max(255),
      description: Yup.string(),
      logoUrl: Yup.string().max(255),
      contactPerson: Yup.object({
        name: Yup.string().max(255),
        address: Yup.string().max(255),
        phoneNumber: Yup.string().max(255),
        email: Yup.string().max(255)
      }),
      publicContacts: Yup.array(),
      socialMedia: Yup.array(),
      userUploadedCoverImages: Yup.array(),
      coverImage: Yup.string().max(255),
      videos: Yup.array(),
      images: Yup.array(),
      collections: Yup.array(),
      status: Yup.string().max(255),
      fallbackLanguage: Yup.string().max(255),
      fallbackLocation: Yup.string().max(255),
      descriptions: Yup.array(),
      companyContactDetails: Yup.array(),
      formId: Yup.string().max(255)
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        values["status"] = "published";
        values["lastUpdated"] = new Date().toLocaleString();
        
        const updatedInfo = await portalUpdateBrand(values);
        if (updatedInfo) {
          // publish
          const publishedBrand = await publishBrand(values);
          if (publishedBrand) {
            toast.success('Changes saved!');
            setUpdated(!updated);
            setChangesMade(false);
          }
        }

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        //toast.success('Changes saved!');
        await wait(500);

      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  
  const getBrandData = async () => {
    try {
      if (selectedBrand) {
        const payload = {
          brandId: selectedBrand?.brandId,
          userId: accounts[0].localAccountId
        }
        const data = await portalBrands(payload);
        if (data) {
          setBrandData(data[0]);
          setCounter(counter + 1);
        }
      
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedBrand?.brandId) {
      getBrandData();
    }
  }, [selectedBrand, updated])

  useEffect(() => {
    if (selectedBrand?.brandId) {
      const url = cdnUrl(selectedBrand?.brandId + ".webp")
      if (exists(url)) {
        //console.log(url)
        formik.setFieldValue("logoUrl", url)
      }
    }
  }, [brandData])

  const saveAsDraft = async (values) => {
    try {
      // NOTE: Make API request
      const updatedInfo = await portalUpdateBrand(values);
      if (updatedInfo) {
        toast.success('Changes saved as draft!');
        setUpdated(!updated);
        setChangesMade(false);
      }
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };


  // useEffect(() => {
  //   // html text to editor
  //   if (brandData?.description) {
  //     const blocksFromHTML = convertFromHTML(brandData?.description);
  //     const state = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap
  //     )
  //     setEditorState( EditorState.createWithContent(state));
  //   }

  // }, [counter])
  
  // useEffect(() => {
  //   let html = stateToHTML(editorState.getCurrentContent());
  //   formik.setFieldValue('description', html);
  // }, [editorState])

  // const handleCloseEditing = () => {
  //   setEditingImage(false);
  // }

  // const handleOpenEditing = () => {
  //   setEditingImage(true);
  // }

  // const uploadFileToBlob = async (file, folder) => {
  //     const newFile = new File([file], uuidv4(), {
  //       type: file?.type,
  //     });

  //   const response = await upload(newFile, folder);
  //   return response;
  // };

  // const handleDrop = (newFiles) => {
  //   setImages((prevFiles) => [...prevFiles, ...newFiles]);
  //   setImageFile(URL.createObjectURL(newFiles[0]));
  // };

  // const handleRemove = (file) => {
  //   setImages((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  // };

  // const handleRemoveAll = () => {
  //   setImages([]);
  // };

  // const handleUpload = () => {
  //   images.map((file) => {
  //     if (file) {
  //       try {
  //         uploadFileToBlob(file, "logo").then(res => {
  //           if (res.status === 200) {
  //             formik.setFieldValue("logoUrl", cdnUrl(res?.data))
  //             setEditingImage(false);
  //             setImages([]);
  //           }
  //         });
  //       } catch (error) {
  //         console.error(error);
  //         // Expected output: ReferenceError: nonExistentFunction is not defined
  //         // (Note: the exact output may be browser-dependent)
  //       }
  //     }
  //   })
  // };

  const handleChange = (e) => {
    formik.handleChange(e);
    setChangesMade(true);
  }

  return (
    <form
    onSubmit={formik.handleSubmit}
    // {...other}
    >
      
        <Box
          sx={{
            //backgroundColor: 'background.default',
            flexGrow: 1,
            p: 3,
            minHeight: "calc(100vh - 48px)"
          }}
          {...props}>
          
          <BrandCard title="Bimroom brand page" helperText="">
            <BrandPageStatus formik={formik} updated={updated} setUpdated={setUpdated} changesMade={changesMade} />
          </BrandCard>

          <BrandCard title="Brand logo" helperText="This image is used throughout Bimroom services.">
            <BrandLogo formik={formik} />
          </BrandCard>
          
          <BrandCard title="Details" helperText="Brand details">
            <BrandDetails formik={formik} brandData={brandData} counter={counter} setChangesMade={setChangesMade} handleChange={handleChange} />
          </BrandCard>

          <BrandCard title="Cover image" helperText="">
            <Box sx={{ padding: 2, border: "1px solid #e6e8f0", borderRadius: 1, cursor: "pointer" }}>
              <CoverImageSelector formik={formik} handleChange={handleChange} />
            </Box>
          </BrandCard>

          <BrandCard title="Social media" helperText="">
            <SocialMedia formik={formik} handleOpenContactModal={handleOpenContactModal} setChangesMade={setChangesMade} />
          </BrandCard>

          <BrandCard title="Videos" helperText="">
            <Videos formik={formik} setChangesMade={setChangesMade} />
          </BrandCard>

          <BrandCard title="Bimroom contact person" helperText="">
            <ContactPerson formik={formik} handleChange={handleChange} />
          </BrandCard>

          <BrandCard title="Public contacts" helperText="">
            <PublicContacts formik={formik} setChangesMade={setChangesMade}/>
          </BrandCard>

          {changesMade &&
            <Box sx={{
                position: 'sticky',
                bottom: '0px',
                left: '0px',
                paddingBottom: 2,
                //width: "100%",
                zIndex: 1000
              }}>
              <BrandCard title="Save changes!" helperText="" cardProps={{border: "1px solid #175c83", backgroundColor: "#fefefe"}}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button fullWidth type="submit" variant="contained">
                      Save & Publish
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button fullWidth variant="outlined" onClick={() => saveAsDraft(formik.values)}>
                      Save as Draft
                    </Button>                    
                  </Grid>
                </Grid>
              </BrandCard>
            </Box>
          }

        </Box> 

      <ModalComponent open={openModal} handleClose={handleCloseModal} modal="upload-image" />
      {/* <ModalComponent open={openContactModal} handleClose={handleCloseContactModal} formik={formik} modal="add-contact" /> */}
      
    </form>
  );
};

export default BrandInformation;