import { Box, TextField } from "@mui/material";

const ContactPerson = (props) => {
    const { formik, handleChange } = props;

    return (
        <>
            <Box
              sx={{
                display: 'flex',
                // mt: 2,
                alignItems: 'center'
              }}
            >
              <TextField
                id="contactPersonName"
                label="Name"
                size="small"
                sx={{ flexGrow: 1 }}
                name="contactPerson.name"
                onBlur={formik.handleBlur}
                onChange={handleChange}
                value={formik.values?.contactPerson?.name}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                mt: 1,
                alignItems: 'center'
              }}
            >
              <TextField
                id="contactPersonAddress"
                label="Address"
                size="small"
                sx={{ flexGrow: 1 }}
                name="contactPerson.address"
                onBlur={formik.handleBlur}
                onChange={handleChange}
                value={formik.values?.contactPerson?.address}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                mt: 1,
                alignItems: 'center'
              }}
            >
              <TextField
                id="contactPersonEmail"
                label="Email"
                size="small"
                sx={{ flexGrow: 1 }}
                name="contactPerson.email"
                onBlur={formik.handleBlur}
                onChange={handleChange}
                value={formik.values?.contactPerson?.email}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                mt: 1,
                alignItems: 'center'
              }}
            >
              <TextField
                id="contactPersonPhoneNumber"
                label="Phone number"

                size="small"
                sx={{ flexGrow: 1 }}
                name="contactPerson.phoneNumber"
                onBlur={formik.handleBlur}
                onChange={handleChange}
                value={formik.values?.contactPerson?.phoneNumber}
              />
            </Box>
        </>
    )
}

export default ContactPerson;
