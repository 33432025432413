import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Stepper,
  Step,
  Grid,
  FormHelperText,
  Button,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SubjectIcon from '@mui/icons-material/Subject';
import SendIcon from '@mui/icons-material/Send';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import GeneralForm from './Forms/GeneralForm';
import TechnicalDetailsForm from './Forms/TechnicalDetailsForm';
import FilesForm from './Forms/FilesForm';
import ReviewForm from './Forms/ReviewForm';
import { validationSchema } from './FormModel/validationSchemas';
import { useSelector } from 'react-redux';
import { portalAddProduct, portalProduct } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { initialData } from './Schema/initial-data';
const merge = require('deepmerge')
const slugify = require('slugify');

const steps = ['General', 'Technical details', 'Files', 'Review and Submit'];

const addProduct = async (payload) => {
  try {
      const addedProduct = await portalAddProduct(payload);
      return addedProduct;
  } catch (err) {
    console.error(err);
  }
};





const Form = (props) => {
 // const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const [editingMode, setEditingMode] = useState("new");
  const [previousEdit, setPreviousEdit] = useState("new");

  const [activeStep, setActiveStep] = useState(0);
  const [productCounter, setProductCounter] = useState(0);
  const [currentValidation, setCurrentValidation] = useState(validationSchema[0]);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const [successMessage, setSuccessMessage] = useState(false);
  //const { mode, productId } = props;
  const navigate = useNavigate();

  const saveDraft = async (payload) => {
    let updatedData = payload;
    updatedData.status = "draft"
    try {
        const addedProduct = await portalAddProduct(updatedData);
        if (addedProduct) {
          navigate("/product/search")
        }
    } catch (err) {
      console.error(err);
    }
  };


  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    //console.log("the current step is: " + (activeStep - 1));
    setCurrentValidation(validationSchema[(activeStep - 1)]);
  };

  const handleStep = (step) => () => {
    //console.log(step);
    setActiveStep(step);
  };

  const getProduct = async (payload) => {
    const product = await portalProduct(payload)
    if (product[0]) {
      const merged_form = merge(initialData, product[0])
      formik.setValues(merged_form);
      setEditingMode("edit");
    }
  };
  
  // change brand name when selected brand (redux) changes

  useEffect(() => {
    if (selectedBrand) {
      formik.setFieldValue("brandId", selectedBrand?.brandId);
      formik.setFieldValue("brandName", selectedBrand?.brandName);
    }
  }, [selectedBrand, productCounter])

  // set editing mode

  useEffect(() => {
    const mode = searchParams.get("mode");
    const productId = searchParams.get("id");

    if (mode === "edit") {
      try {
        getProduct(
          {
          //brandId: selectedBrand?.brandId,
            productId: productId
          }
        )
      } catch (err) {
        console.error(err);
      }
    } else {
      //console.log("creating a new product")
      formik.setFieldValue("id", uuidv4());
      formik.setFieldValue("productId", uuidv4());
      formik.setFieldValue("sku", uuidv4());
    }
  }, [productCounter])

  useEffect(() => {
    if (successMessage && activeStep !== 0) {
      setSuccessMessage(false);
    }
  }, [activeStep])

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: currentValidation,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      //console.log(values);
      if (activeStep === (steps.length - 1)) {
        let payload = values;
        payload.status = "pending";
        if (payload.slug === "") {
          payload.slug = slugify(payload.name, {lower: true}) + "-" + Math.floor(Math.random() * 899999 + 100000);
        }
        const response = await addProduct(payload);
        if (response === 200) {
          helpers.resetForm();
          setSuccessMessage(true);
          setActiveStep(0);
          setPreviousEdit(editingMode);
          setEditingMode("new");
          setSearchParams({});
          setProductCounter(productCounter + 1);
          navigate("/product/search")
        }
      } else {
        setActiveStep((prevStep) => prevStep + 1);
        //console.log("the current step is: " + (activeStep + 1));
        setCurrentValidation(validationSchema[(activeStep + 1)]);
      }
    }
  });

  const formContent = (step) => {
    switch(step) {
      case 0:
        return <GeneralForm formik={formik} editingMode={editingMode} />;
      case 1:
        return <TechnicalDetailsForm formik={formik} />;
      case 2:
        return <FilesForm formik={formik} />;
      case 3:
        return <ReviewForm formik={formik} />;
      default:
        return <div>404: Not Found</div>
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 25,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
        // '#fff',
        '#175c83',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
        // '#fff',
        '#175c83',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#88898B',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
    zIndex: 1,
    //color: '#175c83',
    color: '#88898B',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    border: "2px solid #88898B",
    alignItems: 'center',
    cursor: 'pointer',
    ...(ownerState.active && {
      backgroundColor:
        // '#6e8694',
        // '#fff',
        '#175c83',
        border: "2px solid #175c83",
        color: "#fff"
     // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor:
        '#fff',
      border: "2px solid #175c83",
      color: "#175c83"
        //'#175c83',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <InfoIcon />,
      2: <SubjectIcon />,
      3: <UploadFileIcon />,
      4: <SendIcon />
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.label}`]: {
      [`&.${stepLabelClasses.completed}`]: {
        //color: "#fff"
        color: "#175c83"
      },
      [`&.${stepLabelClasses.active}`]: {
        //color: "#fff"
        color: "#175c83"
      },
      //color: "#ccc"
      color: "#88898B"
    }
  }));

  return (
    <Box
      sx={{
        // paddingTop: 4,
        // paddingBottom: 4,
        paddingLeft: 3,
        paddingRight: 1,
        py: 3,
        //backgroundColor: '#175C831A',
       // backgroundColor: 'background.default',
        minHeight: "calc(100vh - 128px)",
        flexGrow: 1,
        //p: 4,
        //px: 2
      }}
    >
      {successMessage && 
        <Alert 
          sx={{
            position: "fixed",
            top: 50,
            zIndex: 1000,
            width: "300px",
            //height: 200,
            //top: 50%;
            left: "50%",
            marginLeft: "-150px",
            // mr: 2, 
            // mb: 2
          }} 
          variant="filled" 
          severity="success"
        >
          Product was successfully {previousEdit === "new" ? "added" : "edited"}
        </Alert>
      } 
      <Box sx={{
        backgroundColor: '#fff', 
        paddingTop: 5,
        paddingBottom: 3,
        borderRadius: 1,
        mr: 2,
        mt: 1
      }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              {/* <StepLabel onClick={handleStep(index)} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel> */}
              <ColorlibStepLabel onClick={handleStep(index)} StepIconComponent={ColorlibStepIcon}>
                {label}
              </ColorlibStepLabel>
            </Step>

          ))}
        </Stepper>
      </Box>
      
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ padding: 2 }}
        >
          {formContent(activeStep)}
        </Grid>
        {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )}
        <Grid container sx={{m: 2}}>
          <Grid
            item
            xs={6}
            align="left"
          >
            {editingMode === "new" && 
              <Button 
                variant="outlined"
                sx={{
                  color: '#fff', 
                  border: '1px solid #fff'
                  }}
                onClick={() => saveDraft(formik.values)}
              >
                Save as draft
              </Button>
            }
            {editingMode === "edit" && 
              <Button 
                variant="outlined"
                sx={{
                  color: '#fff', 
                  border: '1px solid #fff'
                  }}
                onClick={() => setActiveStep(3)}
              >
                Save changes
              </Button>
            }
          </Grid>
          <Grid
            item
            xs={6}
            align="right"
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              sx={{
                mr: 2,
                color: '#fff', 
                backgroundColor: '#175c83',
                border: activeStep === 0 ? '1px solid #175c83' : '1px solid #fff',
                '&:hover': {
                  backgroundColor: '#ffffff1A',
                  //color: '#fff'
                }
                }} 
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button variant="contained" sx={{
                color: '#175c83', 
                backgroundColor: '#fff',
                border: '1px solid #fff',
                '&:hover': {
                  backgroundColor: '#e7eef2',
                  //opacity: 0.5
                }
                }}
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
            ) : (
              <Button variant="contained" sx={{
                color: '#175c83', 
                backgroundColor: '#fff',
                border: '1px solid #fff',
                '&:hover': {
                  backgroundColor: '#e7eef2',
                  //opacity: 0.5
                }
                }} 
                onClick={formik.handleSubmit}
              >
                Next
              </Button>
            ) }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Form;