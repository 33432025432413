import { useEffect, useMemo, useRef, useState } from 'react';
import { 
  useLocation,
  useNavigate 
} from 'react-router-dom';
import { 
  useSelector
} from 'react-redux';
import { 
  Avatar, 
  Box, 
  Button, 
  Divider, 
  Drawer, 
  Grid, 
  Tooltip, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Home as HomeIcon } from '../icons/home';
import { Mail as MailIcon } from '../icons/mail';
import { Selector as SelectorIcon } from '../icons/selector';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { DocumentText as DocumentTextIcon } from '../icons/document-text';
import { Users as UsersIcon } from '../icons/users';
import { Box as BoxIcon } from '../icons/box';
import { Logo } from './logo';
import { Scrollbar } from './scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { OrganizationPopover } from './organization-popover';
import { Pencil as PencilIcon } from '../icons/pencil';
import { Tag as TagIcon } from '../icons/tag';
import { ArrowRight as ArrowRightIcon } from '../icons/arrow-right';
import { useMsal } from '@azure/msal-react';
import ModalComponent from './ModalComponent';
import { LanguagePopover } from './language-popover';
import { Code as CodeIcon } from '../icons/code';

const languages = ["English"];

const getSections = () => [
  {
    //title: "General",
    items: [
      {
        title: "Brand information",
        path: '/brand-information',
        icon: <TagIcon fontSize="small" />
      },

      {
        title: 'Products',
        path: '/product',
        icon: <BoxIcon fontSize="small" />,
        children: [
          {
            title: 'Brand products',
            path: '/product/search'
          },
          {
            title: 'Add a new product',
            path: '/product/form/v2'
          },
          // {
          //   title: 'Importer',
          //   path: '/product/importer'
          // }
        ]
      },
      {
        title: 'Analytics',
        path: '/analytics',
        icon: <ChartBarIcon fontSize="small" />,
        children: [
          {
            title: 'Dashboard',
            path: '/analytics/dashboard'
          },
          {
            title: 'Reports',
            path: '/analytics/reports'
          }
        ]
      },
      {
        title: 'Inbox',
        path: '/inbox',
        icon: <MailIcon fontSize="small" />
      },
      // {
      //   title: 'API',
      //   path: '/api',
      //   icon: <CodeIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'Documentation',
      //       path: '/api/documentation'
      //     },
      //     {
      //       title: 'Credentials',
      //       path: '/api/credentials'
      //     }
      //   ]
      // }
    ]
  }
];

const getManufacturerSections = () => [
  {
    title: "Admin profile",
    items: [
      {
        title: "Home",
        path: '/',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: 'Modelling services',
        path: '/modelling',
        icon: <PencilIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/account',
        icon: <UserCircleIcon fontSize="small" />,
        children: [
          {
            title: 'General',
            path: '/account'
          },
          {
            title: 'Documents',
            path: '/account/documents'
          },
          {
            title: 'Subscriptions',
            path: '/account/subscriptions'
          },
          {
            title: 'Billing',
            path: '/account/billing'
          }
        ]
      },
      {
        title: 'Privacy & Legal',
        path: '/legal',
        icon: <DocumentTextIcon fontSize="small" />,
        children: [
          {
            title: 'Terms & Conditions',
            path: '/legal?document=terms'
          },
          {
            title: 'Privacy Policy',
            path: '/legal?document=privacy'
          }
        ]
      }
    ]
  }
];

const getBrandSections = () => [
  {
    title: "Brand profile",
    items: [
      {
        title: "Home",
        path: '/',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/account',
        icon: <UserCircleIcon fontSize="small" />,
      },
      {
        title: 'Privacy & Legal',
        path: '/legal',
        icon: <DocumentTextIcon fontSize="small" />,
        children: [
          {
            title: 'Terms & Conditions',
            path: '/legal?document=terms'
          },
          {
            title: 'Privacy Policy',
            path: '/legal?document=privacy'
          }
        ]
      }
    ]
  }
];

export const DashboardSidebar = (props) => {
  const { onClose, open } = props;
  const location = useLocation();
  const organizations = useSelector((store) => store.user.brands);
  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const mainProfileId = useSelector((store) => store.user.mainProfileId);
  const [openModal, setOpenModal] = useState(false);
  
  // const dispatch = useDispatch();
  // const router = useRouter();
  // const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  // const sections = useMemo(() => getSections(t), [t]);
  const sections = useMemo(() => getSections());
  const manufacturerSections = useMemo(() => getManufacturerSections());
  const brandSections = useMemo(() => getBrandSections());
  const organizationsRef = useRef(null);

  const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState(false);

  const navigate = useNavigate();

  const handleOpenOrganizationsPopover = () => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = () => {
    setOpenOrganizationsPopover(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const AccountBar = () => {
    const languageRef = useRef(null);

    const [openLanguagePopover, setOpenLanguagePopover] = useState(false);

    const handleOpenLanguagePopover = () => {
      setOpenLanguagePopover(true);
    };
  
    const handleCloseLanguagePopover = () => {
      setOpenLanguagePopover(false);
    };
   
    const [ user, setUser ] = useState([]);
    const { instance, accounts } = useMsal();

    useEffect(() => {
      if (accounts[0]) {
        //console.log(accounts)
        
        setUser({
          avatar: UsersIcon,
          name: accounts[0].name,
          initials: 'U'
        })
      }
    }, [accounts])
  
    const handleLogout = async () => {
      try {
        onClose?.();
        await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      } catch (err) {
        console.error(err);
      }
    };
  

  
    return (
      <>
        <Grid container sx={{
            justifyContent: 'space-between',
            display: 'flex',
            p: 1,
            alignItems: 'center'}}>
          <Grid item sx={{width: "20%"}}>
            <Avatar
              sx={{
                height: 40,
                width: 40,
                color: '#175c83',
                backgroundColor: '#fff'
              }}
            >
              {user.initials}
            </Avatar>
          </Grid>
          <Grid  item sx={{width: "50%"}}>
            <Box >
              <Tooltip title={user.name}>
                <Box sx={{ flexGrow: 1, fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                  {user.name}
                </Box>
              </Tooltip>
              <Button onClick={handleLogout} variant="text" size="small" sx={{color: '#fff', p: 0}} endIcon={<ArrowRightIcon />}>
                <Typography align="left" variant="caption" sx={{cursor: 'pointer'}}>Logout</Typography>
              </Button>
            </Box>
          </Grid>
          <Grid align="right" item sx={{width: "30%", alignSelf: 'flex-end'}}>
            <Box
              onClick={handleOpenLanguagePopover}
              ref={languageRef}
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 1,
                py: 1,
                borderRadius: 1,
                ml: 2
              }}
            >
              <div>
                <Typography
                  color="inherit"
                  variant="subtitle1"
                >
                  EN
                </Typography>
              </div>
              <SelectorIcon
                sx={{
                  color: 'neutral.500',
                  width: 14,
                  height: 14
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <LanguagePopover
        anchorEl={languageRef.current}
        onClose={handleCloseLanguagePopover}
        open={openLanguagePopover}
        languages={languages}
      />
      </>
    );
  };



  

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <div>
            <Box onClick={() => navigate("/")} sx={{ p: 3, cursor: "pointer" }}>
              <Logo
                sx={{
                  height: "auto",
                  width: "100%"
                }}
                variant="light"
              />
            </Box>
            <Box sx={{ px: 2 }}>
              <Box
                onClick={handleOpenOrganizationsPopover}
                ref={organizationsRef}
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 2,
                  py: 2,
                  borderRadius: 1
                }}
              >
                <div>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                  >
                    {selectedBrand?.brandName}
                  </Typography>
                </div>
                <SelectorIcon
                  sx={{
                    color: 'neutral.500',
                    width: 14,
                    height: 14
                  }}
                />
              </Box>
            </Box>
          </div>
          <Box sx={{ flexGrow: 1 }}>

            {sections.map((section, index) => (
              <DashboardSidebarSection
                key={index}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...section} />
            ))}
            {mainProfileId ? 
              manufacturerSections.map((section, index) => (
                <DashboardSidebarSection
                  key={index}
                  path={location.pathname}
                  sx={{
                    //mt: 2,
                    '& + &': {
                      mt: 2
                    }
                  }}
                  {...section} />
              )) : 
              brandSections.map((section, index) => (
                <DashboardSidebarSection
                  key={index}
                  path={location.pathname}
                  sx={{
                    //mt: 2,
                    '& + &': {
                      mt: 2
                    }
                  }}
                  {...section} />
              ))
            }
          </Box>
          <AccountBar />
        </Box>
      </Scrollbar>
      <OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
        openModal={handleOpenModal}
        organizations={organizations}
      />

      <ModalComponent open={openModal} handleClose={handleCloseModal} modal="add-brand" />
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            //backgroundColor: 'neutral.900',
            backgroundColor: 'transparent',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          background: 'linear-gradient(144deg, rgba(11,46,65,1) 0%, rgba(23,92,131,1) 86%)',
          backgroundAttachment: 'fixed',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

// DashboardSidebar.propTypes = {
//   onClose: PropTypes.func,
//   open: PropTypes.bool
// };
