import React, { useEffect } from "react";
import "./style.scss";

// packages
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { setCategory, setBrand, setPreviousSearch } from "../../redux/reducers/product-reducer";

// components
//import ProductView from "./product-view";

// import ProductDescription from "./product-description";
// import ProductInfo from "./product-info";
// import ProductReview from "./product-review";
//import RelatedProducts from "./related-products";
import ProductView from "./ProductView";

// images & icons
//import brand from "../../assets/images/product/brand.png";
import { FaArrowLeft } from "react-icons/fa";

import Brand from "../Brand";
import { Box, Modal } from "@mui/material";
import ProductDetail from "./ProductDetail";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //minWidth: 400,
  width: "80vw",
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto"
};



const ProductPreview = (props) => {
  const {product, open, handleClose} = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="bimroom-product-page">
        
          <div className="product-view-header-section">
            <div className="product-view-brand">
              <Brand product={product} />
            </div>
          </div>

          <div className="product-view-detail-section">
            <ProductView product={product} />
            <ProductDetail product={product} />
          </div>
        </div>

        {/* <div style={{ backgroundColor: "rgba(23, 92, 131, 0.1)" }}>
          <RelatedProducts relatedProductIDs={product?.related_ids} />
        </div> */}
      </Box>
    </Modal>
  );
};

export default ProductPreview;
