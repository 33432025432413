import { memo, useCallback, useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes';
import { TargetBin } from './TargetBin';
import update from 'immutability-helper'
import { Box, Grid } from '@mui/material';

const style = {
  height: '12rem',
  width: '12rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
}


export const TargetBins = memo(function TargetBins({
  // accept,
  // items,
  // onDrop,
  droppedBoxNames,
  setDroppedBoxNames,
  images,
  revitFiles,
  other,
  documentation,
  setItems,
  productIndex
}) {
  const [targetBins, setTargetBins] = useState([
    { accepts: [ItemTypes.IMAGE], lastDroppedItem: null, items: images, type: "images" },
    { accepts: [ItemTypes.REVIT], lastDroppedItem: null, items: revitFiles, type: "revit" },
    { accepts: [ItemTypes.OTHER], lastDroppedItem: null, items: other, type: "other" },
    { accepts: [ItemTypes.DOCUMENTATION], lastDroppedItem: null, items: documentation, type: "documentation" },
  ])
  // const [droppedBoxNames, setDroppedBoxNames] = useState([])
  // function isDropped(boxName) {
  //   return droppedBoxNames.indexOf(boxName) > -1
  // }
  const handleDropDrag = useCallback(
    (index, item) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
      )
      setTargetBins(
        update(targetBins, {
          [index]: {
            // lastDroppedItem: {
            //   $set: item,
            // },
            items: {$push: [item]}
          },
        }),
      )
      setItems(item, productIndex, targetBins[index])
    },
    [droppedBoxNames, targetBins],
  )


  // return (
  //   <div style={{ overflow: 'hidden', clear: 'both' }}>
  //   {targetBins.map(({ accepts, lastDroppedItem, items }, index) => (
  //     <TargetBin
  //       accept={accepts}
  //       lastDroppedItem={lastDroppedItem}
  //       items={items}
  //       onDrop={(item) => handleDropDrag(index, item)}
  //       key={index}
  //     />
  //   ))}                 
  // </div>
  // )
  return (
    <Box sx={{ overflow: 'hidden', clear: 'both', paddingRight: 1 }}>
      <Grid container spacing={2}>
        {targetBins.map(({ accepts, lastDroppedItem, items }, index) => (
          <Grid item xs={3}>
            <TargetBin
              accept={accepts}
              lastDroppedItem={lastDroppedItem}
              items={items}
              onDrop={(item) => handleDropDrag(index, item)}
              key={index}
            />
          </Grid>
        ))}   
      </Grid>         
    </Box>
  )
})