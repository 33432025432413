import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { portalAddPending, portalAddProduct } from '../../api';
import { initialData } from '../ProductForm/Schema/initial-data';

import { v4 as uuidv4} from 'uuid'
const merge = require('deepmerge')

const columns = [
  { 
    field: 'id', 
    headerName: 'Unique ID', 
    flex: 1,
    valueGetter: (params) =>
    `${params.row.manufacturerProductId || ''}`
  },
  { field: 'name', headerName: 'Product name', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
  { field: 'source', headerName: 'Source', flex: 1 },
  {
    field: 'data',
    headerName: 'Data format',
    //description: 'This column has a value getter and is not sortable.',
    //sortable: false,
    flex: 1,
    valueGetter: (params) =>
      `${params.row.origin?.format || ''}`,
  },
  {
    field: 'converted',
    headerName: 'Converted',
    flex: 1,
    valueGetter: (params) =>
      `${params.row.bon?.converted ? 'Yes' : 'No'}`,
  },
];



export const ExternalSourceDatagrid = (props) => {
    const { products, update, removeStatus, setRemoveStatus} = props;
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const importProducts = async () => {
        // Loop through rowSelectionModel and select product from products based on product id
        for (let i = 0; i < rowSelectionModel.length; i++) {
            const product = products.find((object) => object.id === rowSelectionModel[i]);
            if (product) {
                await importProduct(product)
            }
        }

        for (let i = 0; i < rowSelectionModel.length; i++) {
            const product = products.find((object) => object.id === rowSelectionModel[i]);
            if (product) {
                await newStatus(product, "imported")
            }
        }

        update()
    }

    const changeProductStatus = async () => {
        // Loop through rowSelectionModel and select product from products based on product id
        for (let i = 0; i < rowSelectionModel.length; i++) {
            const product = products.find((object) => object.id === rowSelectionModel[i]);
            if (product) {
                await newStatus(product, "deleted")
            }
        }
        // and change the status to 'deleted'
        // let newProducts = [...products];
        // for (let i = 0; i < rowSelectionModel.length; i++) {
        //     newProducts = newProducts.filter((item) => item.id !== rowSelectionModel[i])
        // }
        // setProducts([...newProducts]);

        update()
    }

    const importProduct = async (data) => {
        // add new product
        let newProduct = {
            id: data.id,
            manufacturerProductId: data.manufacturerProductId,
            brandId: data.brandId,
            productId: uuidv4(),
            name: data.name,
            status: 'pending'
        }

        if (data?.bon?.converted === true) {
            if (data?.bon?.data) {
                newProduct = merge(newProduct, data?.bon?.data)
            }
            // 
        }
        // upsert products
        await portalAddProduct(merge(initialData, newProduct));
    }

    const newStatus = async (data, status) => {
        // upsert pending
        data["status"] = status;
        await portalAddPending(data);
    }

    const showDeleted = async (status) => {
        await setRemoveStatus(status)
        update()
    }
    
    return (
        <Box sx={{width: '100%' }}>
            <Box sx={{px: 2, my: 2}}>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" disabled={rowSelectionModel.length > 0 ? false : true} onClick={importProducts}>Import selected</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" color="error" disabled={rowSelectionModel.length > 0 ? false : true} onClick={changeProductStatus}>Delete selected</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Grid container spacing={1} >
                            <Grid item>
                                {removeStatus === "deleted" ? <Button variant="contained" onClick={() => showDeleted("")}>Show deleted</Button> : <Button variant="contained" onClick={() => showDeleted("deleted")}>Hide deleted</Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <DataGrid
                rows={products}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { psource: 0, psourceSize: 25 },
                },
                }}
                psourceSizeOptions={[10, 25, 50, 100]}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
            />
        </Box>
    );
}


ExternalSourceDatagrid.defaultProps = {
    products: []
};

ExternalSourceDatagrid.propTypes = {
    products: PropTypes.array
};

