import { useEffect, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { downloads, pageViews } from '../../api';
import { AnalyticsViewsTopList } from './ga-analytics-top-list';
import { AnalyticsViews } from './ga-analytics-views';
import GoogleAnalyticsMap from './ga-analytics-map';
import { format, subDays } from 'date-fns';
import { DateRangePopover } from './date-range-popover';
import { Selector } from '../../icons/selector';
import { useSelector } from 'react-redux';
import { get, orderBy, set } from 'lodash';
import { CardFrame } from './card-frame';
import { AnalyticsPie } from './ga-analytics-pie';
import { useMsal } from '@azure/msal-react';
import { AnalyticsBarChart } from './ga-analytics-bar-chart';


// import { gtm } from '../../../lib/gtm';

function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date).getTime());
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

const pageViewOptions = [
  "Product",
  "Country"
]

const downloadsOptions = [
  "Product",
  "Country",
  "Application",
  "File type",
 // "User group"
]

const locationOptions = [
  "World",
  "Europe",
  "Americas",
  "Asia",
  "Africa",
  "Oceania"
]

const getPageViewDimension = (type) => {
  switch (type) {
    case "Product":
      return "dateProduct";
    case "Country":
      return "countryDate";
    default:
      return "dateProduct";
  }
}

const getDownloadsDimension = (type) => {
  switch (type) {
    case "Product":
      return "dateProduct";
    case "Country":
      return "countryDate";
    case "Application":
      return "applicationDate";
    case "File type":
      return "fileTypeDate";
    case "User group":
      return "userGroup";
    default:
      return "dateProduct";
  }
}

const getLocationDimension = (type) => {
  switch (type) {
    case "Country":
      return "country";
    case "Region":
      return "region";
    default:
      return "country";
  }
}

const dataByProductDate = (response, startDate, endDate) => {
  const data = [];
  const dayArray = getDatesInRange(new Date(startDate),new Date(endDate));
  const uniqueProducts = [...new Set(response.map(item => item.product_id))];

  uniqueProducts.forEach(productId => {
    // Get product name for product id
    const productName = response.find(item => item.product_id === productId)?.product_name;

    const output = {
      id: productId,
      name: productName,
      values: [],
      totals: 0
    }

    dayArray.forEach(day => {
      const dayData = response.find(item => item.date === format(day, "yyyy-MM-dd") && item.product_id === productId);
      if (dayData) {
        output.values.push(dayData.count);
        output.totals += dayData.count;
      } else {
        output.values.push(0);
      }
    });
    data.push(output);
  });

  return data;
}

const dataByCountryDate = (response, startDate, endDate) => {
  const data = [];
  const dayArray = getDatesInRange(new Date(startDate),new Date(endDate));
  const uniqueCountries = [...new Set(response.map(item => item.country))];

  uniqueCountries.forEach(country => {
    // Get product name for product id
    const output = {
      id: null,
      name: country,
      values: [],
      totals: 0
    }

    dayArray.forEach(day => {
      const dayData = response.find(item => item.date === format(day, "yyyy-MM-dd") && item.country === country);
      if (dayData) {
        output.values.push(dayData.count);
        output.totals += dayData.count;
      } else {
        output.values.push(0);
      }
    });
    data.push(output);
  });

  return data;
}

const dataByApplicationDate = (response, startDate, endDate) => {
  const data = [];
  const dayArray = getDatesInRange(new Date(startDate),new Date(endDate));
  const uniqueApplications = [...new Set(response.map(item => item.source_type))];

  uniqueApplications.forEach(application => {
    // Get product name for product id
    const output = {
      id: null,
      name: application,
      values: [],
      totals: 0
    }

    dayArray.forEach(day => {
      const dayData = response.find(item => item.date === format(day, "yyyy-MM-dd") && item.source_type === application);
      if (dayData) {
        output.values.push(dayData.count);
        output.totals += dayData.count;
      } else {
        output.values.push(0);
      }
    });
    data.push(output);
  });

  return data;
}

const dataByFileTypeDate = (response, startDate, endDate) => {
  const data = [];
  const dayArray = getDatesInRange(new Date(startDate),new Date(endDate));
  const uniqueFileTypes = [...new Set(response.map(item => item.filetype))];

  uniqueFileTypes.forEach(fileType => {
    // Get product name for product id
    const output = {
      id: null,
      name: fileType,
      values: [],
      totals: 0
    }

    dayArray.forEach(day => {
      const dayData = response.find(item => item.date === format(day, "yyyy-MM-dd") && item.filetype === fileType);
      if (dayData) {
        output.values.push(dayData.count);
        output.totals += dayData.count;
      } else {
        output.values.push(0);
      }
    });
    data.push(output);
  });

  return data;
}

const getRegion = (region) => {
  switch (region) {
    case "World":
      return "world";
    case "Europe":
      return "150";
    case "Americas":
      return "019";
    case "Asia":
      return "142";
    case "Africa":
      return "002";
    case "Oceania":
      return "009";
    default:
      return "world";
  }
}

export const Analytics = () => {
  const anchorRef = useRef(null);
  const anchorRef2 = useRef(null);
  const anchorRef3 = useRef(null);
  const [updateRange, setUpdateRange] = useState(0);
  const [selectedRange, setSelectedRange] = useState("30");
  const [startDate, setStartDate] = useState(subDays(new Date, 30));
  const [customRangeStart, setCustomRangeStart] = useState(subDays(new Date, 30));
  const [customRangeEnd, setCustomRangeEnd] = useState(new Date);
  const [endDate, setEndDate] = useState(new Date);
  const [pageViewsChartData, setPageViewsChartData] = useState([]);
  const [downloadsChartData, setDownloadsChartData] = useState([]);
  const [pageViewsTopListData, setPageViewsTopListData] = useState([]);
  const [downloadsTopListData, setDownloadsTopListData] = useState([]);
  const [pageViewLocations, setPageViewLocations] = useState([]);
  const [downloadsLocations, setDownloadsLocations] = useState([]);
  const [pageViewsBy, setPageViewsBy] = useState("Product");
  const [downloadsBy, setDownloadsBy] = useState("Product");
  const [pageViewLocationIndex, setPageViewLocationIndex] = useState(null);
  const [downloadsLocationIndex, setDownloadsLocationIndex] = useState(null);
  const [pageViewLocationRegion, setPageViewLocationRegion] = useState("world");
  const [downloadsLocationRegion, setDownloadsLocationRegion] = useState("world");
  const [openPopover, setOpenPopover] = useState(false);
  const [pageViewsCategories, setPageViewsCategories] = useState([]);
  const [downloadsCategories, setDownloadsCategories] = useState([]);
  const [loadingViewsChart, setLoadingViewsChart] = useState(false);
  const [loadingTopList, setLoadingTopList] = useState(false);

  const selectedBrand = useSelector((store) => store.user.selectedBrand);
  const { accounts } = useMsal();

  function _onChangeStartDate(date) {
    if (date) {
      try {
        setStartDate(date);
      } catch (error) {
        console.log(error);
      }
    } else {
      setStartDate(new Date);
    }
  }

  function _onChangeEndDate(date) {
    if (date) {
      try {
        setEndDate(date);
      } catch (error) {
        console.log(error);
      }
    } else {
      setEndDate(new Date);
    }
  }
  
  const handleUpdateRange = () => {
    let start;
    let end;
    switch (selectedRange) {
      case '7':
        start = subDays(new Date, 7);
        end = new Date;
        break;
      case '30':
        start = subDays(new Date, 30);
        end = new Date;
        break;
      case '90':
        start = subDays(new Date, 90);
        end = new Date;
        break;
      case 'other':
        start = customRangeStart;
        end = customRangeEnd;
        break; 
      default:
        start = subDays(new Date, 30);
        end = new Date;
        console.log(`Sorry, not possible to change date range.`);
    }
    _onChangeStartDate(start);
    _onChangeEndDate(end);
    // setDatesInRange(getDatesInRange(start, end));
    setUpdateRange(updateRange + 1);
    setOpenPopover(false);
  };

  const handleCustomRangeStart = (date) => {
    setCustomRangeStart(date);
  };

  const handleCustomRangeEnd = (date) => {
    setCustomRangeEnd(date);
  };

  const handleSelectedRange = (event) => {
    setSelectedRange(event.target.value);
  };

  const dataLocation = (input, name, key, title) => {
    let data = []
    data.push([name, title]);

    input.forEach(location => {
      // if (location.country === "(not set)") {
      //   location.country = "Unknown";
      // } 
      // check if country already exists in data
      const index = data.findIndex(item => item[0] === location[key]);

      if (index !== -1) {
        data[index][1] += location.count;
      } else {
        data.push([location[key], location.count]);
      }
    });

    return data;
  }



  const getPageViewLocations = async (type) => {
    try {
      const payload = {
        userId: "1d86799a-18fb-4e65-a28f-8ba651bf30c4",
        manufacturerId: "3bf3be38-28ca-42c3-893e-ae1e9287cc09",
        // userId: accounts[0]?.localAccountId,
        // manufacturerId: selectedBrand?.brandId,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        dimension: getLocationDimension(type),
        metric: "event"
      }
      const response = await pageViews(payload);
      if (response && type === "Country") {
        // Restructure data
        const data = dataLocation(response, "Country", "country", "Page views");
        setPageViewLocations(data);
      } else if (response && type === "Region") {
        // Restructure data
        const data = dataLocation(response, "Region", "region", "Page views");
        setPageViewLocations(data);
      } else {
        setPageViewLocations([]);
      }
     
    }
    catch (err) {
      console.error(err);
    }
  };

  const getDownloadsLocations = async (type) => {
    try {
      const payload = {
        userId: "1d86799a-18fb-4e65-a28f-8ba651bf30c4",
        manufacturerId: "3bf3be38-28ca-42c3-893e-ae1e9287cc09",
        // userId: accounts[0]?.localAccountId,
        // manufacturerId: selectedBrand?.brandId,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        dimension: getLocationDimension(type),
        metric: "event"
      }
      const response = await downloads(payload);
      if (response && type === "Country") {
        // Restructure data
        const data = dataLocation(response, "Country", "country", "Downloads");
        setDownloadsLocations(data);
      } else if (response && type === "Region") {
        // Restructure data
        const data = dataLocation(response, "Region", "region", "Downloads");
        setDownloadsLocations(data);
      } else {
        setDownloadsLocations([]);
      }
     
    }
    catch (err) {
      console.error(err);
    }
  };

  const getPageViews = async (type) => {

    // GET PAGE VIEWS BY PRODUCT
    try {
      const payload = {
        userId: "1d86799a-18fb-4e65-a28f-8ba651bf30c4",
        manufacturerId: "3bf3be38-28ca-42c3-893e-ae1e9287cc09",
        // userId: accounts[0]?.localAccountId,
        // manufacturerId: selectedBrand?.brandId,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        dimension: getPageViewDimension(type),
        metric: "event"
      }
      const response = await pageViews(payload);
      
      if (response && type === "Product") {
        // Restructure data
        const data = dataByProductDate(response, startDate, endDate);
        setPageViewsChartData(orderBy(data, "totals", "desc"));
        setPageViewsTopListData(orderBy(data, "totals", "desc"));
        setPageViewsCategories(getDatesInRange(startDate, endDate));
      } else if (response && type === "Country") {
        // Restructure data
        const data = dataByCountryDate(response, startDate, endDate);
        setPageViewsChartData(orderBy(data, "totals", "desc"));
        setPageViewsTopListData(orderBy(data, "totals", "desc"));
        setPageViewsCategories(getDatesInRange(startDate, endDate));
      } else {
        setPageViewsChartData([]);
        setPageViewsTopListData([]);
        setPageViewsCategories([]);
      }
     
    }
    catch (err) {
      console.error(err);
    }
  }

  const getDownloads = async (type) => {

    // GET PAGE VIEWS BY PRODUCT
    try {
      const payload = {
        userId: "1d86799a-18fb-4e65-a28f-8ba651bf30c4",
        manufacturerId: "3bf3be38-28ca-42c3-893e-ae1e9287cc09",
        // userId: accounts[0]?.localAccountId,
        // manufacturerId: selectedBrand?.brandId,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        dimension: getDownloadsDimension(type),
        metric: "event"
      }
      const response = await downloads(payload);
      
      if (response && type === "Product") {
        // Restructure data
        const data = dataByProductDate(response, startDate, endDate);
        setDownloadsChartData(orderBy(data, "totals", "desc"));
        setDownloadsTopListData(orderBy(data, "totals", "desc"));
        setDownloadsCategories(getDatesInRange(startDate, endDate));
      } else if (response && type === "Country") {
        // Restructure data
        const data = dataByCountryDate(response, startDate, endDate);
        setDownloadsChartData(orderBy(data, "totals", "desc"));
        setDownloadsTopListData(orderBy(data, "totals", "desc"));
        setDownloadsCategories(getDatesInRange(startDate, endDate));
      } else if (response && type === "Application") {
        // Restructure data
        const data = dataByApplicationDate(response, startDate, endDate);
        setDownloadsChartData(orderBy(data, "totals", "desc"));
        setDownloadsTopListData(orderBy(data, "totals", "desc"));
        setDownloadsCategories(getDatesInRange(startDate, endDate));
      } else if (response && type === "File type") {
        // Restructure data
        const data = dataByFileTypeDate(response, startDate, endDate);
        setDownloadsChartData(orderBy(data, "totals", "desc"));
        setDownloadsTopListData(orderBy(data, "totals", "desc"));
        setDownloadsCategories(getDatesInRange(startDate, endDate));
      } else {
        setDownloadsChartData([]);
        setDownloadsTopListData([]);
        setDownloadsCategories([]);
      }
    }
    catch (err) {
      console.error(err);
    }
  }


  useEffect(() => {
    //getAnalytics();
    getPageViews(pageViewsBy);
    getPageViewLocations("Country");
    getDownloads(downloadsBy);
    getDownloadsLocations("Country");
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [updateRange]);

  const handlePageViewsBy = (option) => {
    setPageViewsBy(option);
    getPageViews(option);
  }

  const handleDownloadsBy = (option) => {
    setDownloadsBy(option);
    getDownloads(option);
  }

  const handlePageViewRegion = (option) => {
    const region = getRegion(option);
    setPageViewLocationRegion(region);
  }

  const handleDownloadsRegion = (option) => {
    const region = getRegion(option);
    setDownloadsLocationRegion(region);
  }

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mt: 1, mb: 0 }} >
            <Grid container>
              <Grid item xs={12} sx={{mb: 2}}>
                <Card>
                  <CardContent sx={{paddingY: 2}}>
                    <Grid container>
                      <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="h6">Analytics for {selectedBrand?.brandName}</Typography>
                      </Grid>
                      <Grid align="right" item xs={6}>
                        <Box sx={{width: 250}}>
                          <Box
                            onClick={handleOpenPopover}
                            ref={anchorRef3}
                            sx={{
                              alignItems: 'center',
                              border: "1px solid #175c831A",
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'space-between',
                              px: 3,
                              py: '11px',
                              borderRadius: 1
                            }}
                          >
                            <div>
                              <Typography
                                //color="#fff"
                                variant="subtitle1"
                              >
                                {format(startDate,"d.M.yyyy")} - {format(endDate,"d.M.yyyy")}
                              </Typography>
                            </div>
                            <Selector
                              sx={{
                                color: 'neutral.500',
                                width: 14,
                                height: 14
                              }}
                            />
                          </Box>
                        </Box>
                        <DateRangePopover 
                          anchorEl={anchorRef3.current}
                          onClose={handleClosePopover}
                          open={openPopover}
                          selectedRange={selectedRange}
                          handleSelectedRange={handleSelectedRange}
                          handleUpdate={handleUpdateRange}
                          customRangeStart={customRangeStart}
                          customRangeEnd={customRangeEnd}
                          handleCustomRangeStart={handleCustomRangeStart}
                          handleCustomRangeEnd={handleCustomRangeEnd}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <CardFrame 
                  header={"Downloads by " + downloadsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {downloadsOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsBy(option)}
                          sx={{
                            color: downloadsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <AnalyticsViews data={downloadsChartData} categories={downloadsCategories} />
                </CardFrame>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <CardFrame 
                  header={"Downloads by " + downloadsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {downloadsOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsBy(option)}
                          sx={{
                            color: downloadsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <AnalyticsViewsTopList loading={loadingTopList} products={downloadsTopListData} title={downloadsBy}/>
                </CardFrame>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <CardFrame 
                  header={"Distribution of downloads by " + downloadsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {downloadsOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsBy(option)}
                          sx={{
                            color: downloadsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <AnalyticsPie data={downloadsTopListData} />
                </CardFrame>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <CardFrame 
                  header={"Downloads map"} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {locationOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsRegion(option)}
                          sx={{
                            color: downloadsLocationRegion === getRegion(option) ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsLocationRegion === getRegion(option) ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <GoogleAnalyticsMap data={downloadsLocations} region={downloadsLocationRegion} resolution={"countries"} setLocationIndex={setDownloadsLocationIndex}/>
                </CardFrame>
              </Grid>
              
              <Grid
                item
                md={12}
                xs={12}
              >
                <CardFrame 
                  header={"Downloads by " + downloadsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {downloadsOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsBy(option)}
                          sx={{
                            color: downloadsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  {/* <AnalyticsViews data={downloadsChartData} categories={downloadsCategories} /> */}
                  <AnalyticsBarChart data={downloadsChartData} categories={downloadsCategories} />
                </CardFrame>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <CardFrame 
                  header={"Downloads by " + downloadsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {downloadsOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsBy(option)}
                          sx={{
                            color: downloadsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <AnalyticsViewsTopList loading={loadingTopList} products={downloadsTopListData} title={downloadsBy}/>
                </CardFrame>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <CardFrame 
                  header={"Distribution of downloads by " + downloadsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {downloadsOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsBy(option)}
                          sx={{
                            color: downloadsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <AnalyticsPie data={downloadsTopListData} />
                </CardFrame>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <CardFrame 
                  header={"Downloads map"} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {locationOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handleDownloadsRegion(option)}
                          sx={{
                            color: downloadsLocationRegion === getRegion(option) ? 'primary.main' : 'text.primary',
                            fontWeight: downloadsLocationRegion === getRegion(option) ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <GoogleAnalyticsMap data={downloadsLocations} region={downloadsLocationRegion} resolution={"countries"} setLocationIndex={setDownloadsLocationIndex}/>
                </CardFrame>
              </Grid>
              
              <Grid
                item
                md={7}
                xs={12}
              >
                <CardFrame 
                  header={"Page views by " + pageViewsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {pageViewOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handlePageViewsBy(option)}
                          sx={{
                            color: pageViewsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: pageViewsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  {/* <AnalyticsViews data={pageViewsChartData} categories={pageViewsCategories} /> */}
                  <AnalyticsBarChart data={pageViewsChartData} categories={pageViewsCategories} />
                </CardFrame>
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
              >
                <CardFrame 
                  header={"Page views by " + pageViewsBy.toLowerCase()} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {pageViewOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handlePageViewsBy(option)}
                          sx={{
                            color: pageViewsBy === option ? 'primary.main' : 'text.primary',
                            fontWeight: pageViewsBy === option ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <AnalyticsViewsTopList loading={loadingTopList} products={pageViewsTopListData} title={pageViewsBy} />
                </CardFrame>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <CardFrame 
                  header={"Page view map"} 
                  loading={loadingViewsChart} 
                  actions={
                    <ButtonGroup variant="text" size="small">
                      {locationOptions.map((option, index) => (
                        <Button
                          key={index}
                          onClick={() => handlePageViewRegion(option)}
                          sx={{
                            color: pageViewLocationRegion === getRegion(option) ? 'primary.main' : 'text.primary',
                            fontWeight: pageViewLocationRegion === getRegion(option) ? 'bold' : 'normal'
                          }}
                        >
                          {option}
                        </Button>
                      ))}
                    </ButtonGroup>
                  }
                >
                  <GoogleAnalyticsMap data={pageViewLocations} region={pageViewLocationRegion} resolution={"countries"} setLocationIndex={setPageViewLocationIndex}/>
                </CardFrame>
              </Grid>
              
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

// ProductCreate.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default Analytics;
