import React, { Suspense, useEffect, useState } from "react";
import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from "@mui/material";
import parse from 'html-react-parser';
import { initialData } from "../../data/formOptions";
import { SeverityPill } from "../../severity-pill";
import Carousel from 'react-material-ui-carousel';
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Canvas } from "@react-three/fiber";
import { Center, OrbitControls, Stage } from "@react-three/drei";
import Image3D from "./Image3D";
import { VariantAccordion } from "../variant-accordion";
const merge = require('deepmerge')

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //minWidth: 400,
  width: "80vw",
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto"
};

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <div>
            <p>Something went wrong.</p>
          </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const getFromAzureStorage = (container, file) => {
    return `https://cdn.bimroom.com/${container}/${file}`;
};



function ImageSlider(props) {
    const { list, container } = props;
    let imageArray = [];
    
    if (Array.isArray(list)) {
        for (let i = 0; i < list.length; i++) {
            const image = list[i]
            if ('deleted' in image) {
                if (!image?.deleted) {
                    imageArray.push(getFromAzureStorage(container, image?.newName))
                }
            } else {
                imageArray.push(getFromAzureStorage(container, image?.newName))
            }
        }
    }

    return (
        <Carousel>
            {
                imageArray.length > 0 ? imageArray.map( (item, i) => 
                <Box key={`box-${item?.newName}`} sx={{height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img key={`img-${item?.newName}`} style={{maxHeight: "100%", maxWidth: "100%"}} src={item} />
                </Box> ) :
                <Box key="empty-image-carousel" sx={{height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography variant="h6">
                        Images not yet available.
                    </Typography>
                </Box>
            }
        </Carousel>
    );
}

// function Slider3D(props) {
//     const {list, container} = props;
//     let imageArray = [];
    
//     if (Array.isArray(list)) {
//         for (let i = 0; i < list.length; i++) {
//             const image = list[i]
//             if ('deleted' in image) {
//                 if (!image?.deleted) {
//                     imageArray.push(getFromAzureStorage(container, image?.newName))
//                 }
//             } else {
//                 imageArray.push(getFromAzureStorage(container, image?.newName))
//             }
//         }
//     }

//     return (
//         <Carousel>
//             <Box sx={{height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
//                 {
//                     imageArray.length > 0 ? imageArray.map( (item, i) => <Box sx={{height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
//                         <Canvas>
//                             <Suspense fallback={null}>
//                                 <Center alignTop={false}>
//                                     <Stage
//                                         environment={null}
//                                         //environment="warehouse"
//                                         intensity={0.2}
//                                         // preset="rembrandt"
//                                         //shadows={false}
//                                         contactShadow={false}
//                                         adjustCamera={true}
//                                     >
//                                         <Image3D image={item} />
//                                         <OrbitControls rotation={true}/>
//                                         {/* <PresentationControls /> */}
//                                     </Stage>
//                                 </Center>
//                             </Suspense>
//                         </Canvas>
//                     </Box>) : 
//                     <Box sx={{height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
//                         <Typography variant="h6">
//                             3D files not yet available.
//                         </Typography>
//                     </Box>
//                 }
//             </Box>
//         </Carousel>
//     );
// }

function Selector3D(props) {
    const { list, container } = props;
    const [ selected3d, setSelected3D ] = useState(null);
    const [ selectedValue, setSelectedValue ] = useState("");
    const [ imageArray, setImageArray ] = useState([]);
    //let imageArray = [];
    
    useEffect(() => {
        if (Array.isArray(list)) {
            for (let i = 0; i < list.length; i++) {
                const image = list[i]
                if ('deleted' in image) {
                    if (!image?.deleted) {
                        const newObject = {
                            name: image?.name,
                            newName: image?.newName
                        }
                        setImageArray(current => [...current, newObject]);
                    }
                } else {
                    const newObject = {
                        name: image?.name,
                        newName: image?.newName
                    }
                    setImageArray(current => [...current, newObject]);
                }
            }

            if (list?.length > 0) {
                setSelected3D(getFromAzureStorage(container, list[0]?.newName))
                setSelectedValue(list[0]?.newName)
            }
        }
    }, [ list ])

    const handleChange = (event) => {

        if (event.target.value) {
        //     console.log(result)
            setSelectedValue(event.target.value)
            setSelected3D(getFromAzureStorage(container, event.target.value))
        }
        
    }

    return (
        <>
            <Box sx={{height: "350px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                
                {
                    selected3d ? 
                    <ErrorBoundary>
                        <Box sx={{height: "350px", width: "800px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Canvas>
                                <Suspense fallback={null}>
                                    <Center alignTop={false}>
                                        <Stage
                                            environment={null}
                                            //environment="warehouse"
                                            intensity={0.2}
                                            // preset="rembrandt"
                                            //shadows={false}
                                            contactShadow={false}
                                            adjustCamera={true}
                                            //center={true}
                                        >
                                            <Image3D image={selected3d}  />
                                            <OrbitControls rotation={true}/>
                                            {/* <PresentationControls /> */}
                                        </Stage>
                                    </Center>
                                </Suspense>
                            </Canvas>
                        </Box>
                    </ErrorBoundary> : 
                    <Box sx={{height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography variant="h6">
                            3D files not yet available.
                        </Typography>
                    </Box>
                }
            </Box>
            <Box sx={{height: "50px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select 3D</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="Select 3D"
                        onChange={handleChange}
                    >
                        {imageArray?.map((object, i) => <MenuItem value={object?.newName}>{object?.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </>
    );
}

const camelCaseToText = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  
const dashedToText = (text) => {
    const result = text.replace(/-/g, ' ');;
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const ProductSummary = (props) => {
  const { productData, open, handleClose } = props;
  const [ product, setProduct ] = useState(initialData);
  const [ tabValue, setTabValue ] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (productData) {
        setProduct(merge(initialData, productData))
    }
  }, [productData])

  const generalKeys = [
    {
      name: "name", 
      label: "Product name", 
      type: "string"
    }, 
    {
      name: "brandName", 
      label: "Brand", 
      type: "string"
    }, 
    {
      name: "model", 
      label: "Model", 
      type: "string"
    }, 
    {
      name: "sku", 
      label: "SKU", 
      type: "string"
    }, 
    {
      name: "countryOfManufacture", 
      label: "Country of Manufacture", 
      type: "string"
    }, 
    {
      name: "description", 
      label: "Description", 
      type: "html" // can rich text be stored in a regular string?
    }
  ];

  const categoryKeys = [
    {
      name: "category", 
      label: "Category", 
      type: "category"
    }, 
    {
      name: "subcategory1", 
      label: "Subcategory 1", 
      type: "category"
    }, 
    {
      name: "subcategory2", 
      label: "Subcategory 2", 
      type: "category"
    }, 
    {
      name: "usage", 
      label: "Usage", 
      type: "array"
    }, 
    {
      name: "room", 
      label: "Room", 
      type: "array"
    }
  ];

  const marketAreaKeys = [
    {
      name: "europe", 
      label: "European countries", 
      type: "array"
    }, 
    {
      name: "africa", 
      label: "African countries", 
      type: "array"
    }, 
    {
      name: "asia", 
      label: "Asian countries", 
      type: "array"
    }, 
    {
      name: "northAmerica", 
      label: "North American countries", 
      type: "array"
    }, 
    {
      name: "southAmerica", 
      label: "South American countries", 
      type: "array"
    }, 
    {
      name: "antarctica", 
      label: "Antarctica", 
      type: "array"
    }, 
    {
      name: "Oceania", 
      label: "Oceanian countries", 
      type: "array"
    }
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
                <Typography variant="h6">{product?.name}</Typography>
            </Grid>
            <Grid item>
                <SeverityPill sx={{borderRadius: 2}} color={product.status === 'published' ? 'success' : 'info'}>
                    {product.status}
                </SeverityPill>
            </Grid>           
        </Grid>
        <Box sx={{mt: 2, boxShadow: "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)", borderRadius: 1}}>
            <Tabs value={tabValue} variant="fullWidth" onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Product images" {...a11yProps(0)} />
                <Tab label="Render images" {...a11yProps(1)} />
                <Tab label="3D" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
                <ImageSlider key="product-image-slider" list={product?.images} container="image" />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <ImageSlider key="render-image-slider" list={product?.renders} container="renders" />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
                <Selector3D list={product?.glbs} container="glbs" /> 
            </CustomTabPanel>
        </Box>
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="h6">General</Typography>
                    </Grid>
                    <Grid item md={9} xs={12} >
                    <Table>
                        <TableBody>
                        {generalKeys.map((key, index) => {
                            
                            return (
                            <TableRow
                            // hover
                                key={`general-${index}`}
                            >
                                <TableCell sx={{width: "30%"}}>
                                    <Typography
                                        variant="subtitle2"
                                    >
                                        {key.label}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{width: "70%"}}>
                                    {/* <Typography
                                        variant="body2"
                                    >
                                        {key.type === "html" ?  parse(`${product[key.name]}`) : product[key.name]}
                                    </Typography> */}
                                    {
                                    key.type === "html" ?  
                                    parse(`${product[key.name]}`) : 
                                    <Typography
                                        variant="body2"
                                    >
                                        {product[key.name]}
                                    </Typography>
                                    }
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        </TableBody> 
                    </Table> 
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        
        <Card sx={{mt: 2}}>
            <CardContent>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Typography variant="h6">Category</Typography>
                </Grid>
                <Grid item md={9} xs={12} >
                <Table>
                    <TableBody>
                    {categoryKeys.map((key, categoryIndex) => {
                        const object = product[key.name];
                        //console.log(object);
                    // console.log(product[key]);

                        return (
                        <TableRow
                        // hover
                            key={`category-${categoryIndex}`}
                        >
                            <TableCell sx={{width: "30%"}}>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {key.label}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{width: "70%"}}>
                                {key.type === "array" ? 
                                    object.map((o, index) => 
                                    <Typography
                                    variant="body2"
                                    key={`category-2-${key.name}$-${categoryIndex}-${index}`}
                                    >
                                    {dashedToText(o)}
                                    </Typography>
                                    ) : 
                                    <Typography
                                    variant="body2"   
                                    >
                                    {dashedToText(product[key.name])}
                                    </Typography>
                                }
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    </TableBody> 
                </Table> 
                </Grid>
            </Grid>
            </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
            <CardContent>
            <Grid container spacing={3}>
                <Grid sx={{paddingBottom: 2}} item md={3} xs={12}>
                    <Typography variant="h6">Technical details</Typography>
                </Grid>
                <Grid item md={9} xs={12} >
                <Table>
                    <TableBody>
                    {Object.keys({...product.details}).map((key, index) => {
                    
                    const object = product.details[key];
                    
                    return (
                        object.min !== "" || object.max !== "" || object.value !== "" || object.values.length > 0 ? 
                        <TableRow
                        // hover
                            key={`bon-variable-${index}`}
                        >
                            <TableCell sx={{width: "30%"}}>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {camelCaseToText(key)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{width: "70%"}}>
                                <Typography
                                    variant="body2"
                                >
                                    {object.range ? object.min + "-" + object.max + (object.unit !== "" && (" " + object.unit)) : (object?.value !== "" ? object?.value + (object.unit && (" " + object.unit)) : object.values.map((value, index) => (index === 0 ? value : " " + value)) + (object.unit && (" " + object.unit)))}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        : 
                        <></>
                        );
                    })}
                    </TableBody> 
                </Table> 
                </Grid>
            </Grid>
            </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid sx={{paddingBottom: 2}} item md={3} xs={12}>
                        <Typography variant="h6">Custom variables</Typography>
                    </Grid>
                    <Grid item md={9} xs={12} >
                        <Table>
                            <TableBody>
                                {product?.customDetails && product?.customDetails.map((object, index) => {
                                    return (
                                        object.min !== "" || object.max !== "" || object.value !== "" || object.values.length > 0 ? 
                                            <TableRow
                                            // hover
                                            key={`custom-detail-${index}`}
                                            >
                                                <TableCell sx={{width: "30%"}}>
                                                    <Typography
                                                    variant="subtitle2"
                                                    >
                                                    {object.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{width: "70%"}}>
                                                    <Typography
                                                    variant="body2"
                                                    >
                                                     {object.range ? object?.min + "-" + object.max + (object.unit !== "" && (" " + object.unit)) : (object?.value !== "" ? object?.value + (object.unit && (" " + object.unit)) : object?.values?.map((value, index) => (index === 0 ? value : " " + value)) + (object.unit && (" " + object.unit)))}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        : 
                                            <></>
                                        );
                                })}
                            </TableBody> 
                        </Table> 
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item sx={{paddingBottom: 2}} md={3} xs={12}>
                        <Typography variant="h6">Product variants</Typography>
                    </Grid>
                    <Grid item md={9} xs={12} >
                        <Table sx={{mb: 1}}>
                            <TableBody>
                                <TableRow
                                    // hover
                                    key="variable-product-row"
                                >
                                    <TableCell sx={{width: "30%"}}>
                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Variable product
                                    </Typography>
                                    </TableCell>
                                    <TableCell sx={{width: "70%"}}>
                                        {product?.variableProduct ? <Typography>Yes</Typography> : <Typography>No</Typography>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {product?.variants && (product?.variants.length > 0 && <Typography sx={{m: 2}} variant="subtitle2">Variants:</Typography>)}
                        {product?.variants && (product?.variants.map((item, index) => {
                            return (
                            <VariantAccordion key={`variant-${index}`} product={item}/>
                            )
                        }))}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="h6">Files</Typography>
                    </Grid>
                    <Grid item md={9} xs={12} >
                        <Table>
                            <TableBody>
                            <TableRow
                                // hover
                                key="image-files"
                                >
                                    <TableCell>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                        Images
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {product?.images && product.images.map((key, index) => {
                                        return (
                                            <Typography key={`images-${index}`} variant="body2">{!key.deleted && key.name}</Typography>
                                        )
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                // hover
                                key="revit-files"
                                >
                                    <TableCell>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                        Revit files
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {product?.revitFiles && product.revitFiles.map((key, index) => {
                                        return (
                                            <Typography key={`revit-file-formats-${index}`} variant="body2">{!key.deleted && key.name}</Typography>
                                        )
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                // hover
                                key="other-bim-files"
                                >
                                    <TableCell>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                        Other BIM formats
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {product?.otherBimFormats && product.otherBimFormats.map((key, index) => {
                                        return (
                                            <Typography key={`other-bim-formats-${index}`} variant="body2">{!key.deleted && key.name}</Typography>
                                        )
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                // hover
                                key="documentation-files"
                                >
                                    <TableCell>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                        Documentation
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {product?.documentation && product.documentation.map((key, index) => {
                                        return (
                                            <Typography key={`documentation-${index}`} variant="body2">{!key.deleted && key.name}</Typography>
                                        )
                                        })}
                                    </TableCell>
                                </TableRow>
                            </TableBody> 
                        </Table> 
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default ProductSummary;
