import React from "react";

// components
import { ProductList as ProductListComponent }  from "../components/ProductList/ProductList";


import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
import Loader from "../components/Loader/loader";
import useDocumentTitle from "../useDocumentTitle";
import { BrandGuard } from "../components/brand-guard";

const ProductList = () => {
  const isLoading = useSelector((store) => store.user.isLoading);
  useDocumentTitle('Bimroom Portal - Products');


  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? 
          <Loader text={"Loading profile"} /> : 
            <DashboardLayout header="Products">
              <BrandGuard>
                <ProductListComponent /> 
              </BrandGuard>
            </DashboardLayout>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ProductList;