const europeList = [
    { value: 'AX', label: 'Åland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'EE', label: 'Estonia' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'VA', label: 'Holy See (Vatican City State)' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IT', label: 'Italy' },
    { value: 'JE', label: 'Jersey' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MK', label: 'Macedonia' },
    { value: 'MT', label: 'Malta' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NO', label: 'Norway' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'SM', label: 'San Marino' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'ES', label: 'Spain' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'TR', label: 'Turkey' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'GB', label: 'United Kingdom' }
];

const africaList = [
    { value: 'DZ', label: 'Algeria' },
    { value: 'AO', label: 'Angola' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CI', label: "Côte d'Ivoire" },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'EG', label: 'Egypt' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'KE', label: 'Kenya' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'ML', label: 'Mali' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'RE', label: 'Réunion' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'SH', label: 'Saint Helena' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SN', label: 'Senegal' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'CD', label: 'the Democratic Republic of the Congo' },
    { value: 'TG', label: 'Togo' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'UG', label: 'Uganda' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' }
];

const antarcticaList = [
    { value: 'AQ', label: 'Antarctica' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' }
];

const asiaList = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'KP', label: "Democratic People's Republic of Korea" },
    { value: 'GE', label: 'Georgia' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IL', label: 'Israel' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: "Lao People's Democratic Republic" },
    { value: 'LB', label: 'Lebanon' },
    { value: 'MO', label: 'Macao' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NP', label: 'Nepal' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PS', label: 'Palestine' },
    { value: 'PH', label: 'Philippines' },
    { value: 'QA', label: 'Qatar' },
    { value: 'KR', label: 'Republic of Korea' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SG', label: 'Singapore' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VN', label: 'Viet Nam' },
    { value: 'YE', label: 'Yemen' }
];

const northAmericaList = [
    { value: 'AI', label: 'Anguilla' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AW', label: 'Aruba' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BQ', label: 'Bonaire' },
    { value: 'VG', label: 'British Virgin Islands' },
    { value: 'CA', label: 'Canada' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HN', label: 'Honduras' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MX', label: 'Mexico' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'PA', label: 'Panama' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'SX', label: 'Sint Maarten' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'VI', label: 'U.S. Virgin Islands' },
    { value: 'US', label: 'United States' },
    { value: 'UM', label: 'United States Minor Outlying Islands' }
];

const southAmericaList = [
    { value: 'AR', label: 'Argentina' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BR', label: 'Brazil' },
    { value: 'CL', label: 'Chile' },
    { value: 'CO', label: 'Colombia' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'FK', label: 'Falkland Islands' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'GY', label: 'Guyana' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'SR', label: 'Suriname' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'VE', label: 'Venezuela' }
];

const oceaniaList = [
    { value: 'AS', label: 'American Samoa' },
    { value: 'AU', label: 'Australia' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'GU', label: 'Guam' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'PW', label: 'Palau' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'WF', label: 'Wallis and Futuna' }
];

const countryList = [
    { value: '', label: 'No country selected' },
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: 'Åland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia, Plurinational State of' },
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo, the Democratic Republic of the' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: "Côte d'Ivoire" },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'VA', label: 'Holy See (Vatican City State)' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran, Islamic Republic of' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: "Korea, Democratic People's Republic of" },
    { value: 'KR', label: 'Korea, Republic of' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: "Lao People's Democratic Republic" },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macao' },
    { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia, Federated States of' },
    { value: 'MD', label: 'Moldova, Republic of' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestine, State of' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Réunion' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin (French part)' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SX', label: 'Sint Maarten (Dutch part)' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan, Province of China' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania, United Republic of' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
    { value: 'VN', label: 'Viet Nam' },
    { value: 'VG', label: 'Virgin Islands, British' },
    { value: 'VI', label: 'Virgin Islands, U.S.' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' }
];

const areaList = [
  { value: '', label: 'No area selected', group: '' },
  { value: 'europe', label: 'Europe', group: 'continent' },
  { value: 'asia', label: 'Asia', group: 'continent' },
  { value: 'africa', label: 'Africa', group: 'continent' },
  { value: 'northAmerica', label: 'North America', group: 'continent' },
  { value: 'southAmerica', label: 'South America', group: 'continent' },
  { value: 'oceania', label: 'Oceania', group: 'continent' },
  { value: 'antarctica', label: 'Antarctica', group: 'continent' },
  { value: 'AF', label: 'Afghanistan', group: 'country' },
  { value: 'AX', label: 'Åland Islands', group: 'country' },
  { value: 'AL', label: 'Albania', group: 'country' },
  { value: 'DZ', label: 'Algeria', group: 'country' },
  { value: 'AS', label: 'American Samoa', group: 'country' },
  { value: 'AD', label: 'Andorra', group: 'country' },
  { value: 'AO', label: 'Angola', group: 'country' },
  { value: 'AI', label: 'Anguilla', group: 'country' },
  { value: 'AQ', label: 'Antarctica', group: 'country' },
  { value: 'AG', label: 'Antigua and Barbuda', group: 'country' },
  { value: 'AR', label: 'Argentina', group: 'country' },
  { value: 'AM', label: 'Armenia', group: 'country' },
  { value: 'AW', label: 'Aruba', group: 'country' },
  { value: 'AU', label: 'Australia', group: 'country' },
  { value: 'AT', label: 'Austria', group: 'country' },
  { value: 'AZ', label: 'Azerbaijan', group: 'country' },
  { value: 'BS', label: 'Bahamas', group: 'country' },
  { value: 'BH', label: 'Bahrain', group: 'country' },
  { value: 'BD', label: 'Bangladesh', group: 'country' },
  { value: 'BB', label: 'Barbados', group: 'country' },
  { value: 'BY', label: 'Belarus', group: 'country' },
  { value: 'BE', label: 'Belgium', group: 'country' },
  { value: 'BZ', label: 'Belize', group: 'country' },
  { value: 'BJ', label: 'Benin', group: 'country' },
  { value: 'BM', label: 'Bermuda', group: 'country' },
  { value: 'BT', label: 'Bhutan', group: 'country' },
  { value: 'BO', label: 'Bolivia, Plurinational State of', group: 'country' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba', group: 'country' },
  { value: 'BA', label: 'Bosnia and Herzegovina', group: 'country' },
  { value: 'BW', label: 'Botswana', group: 'country' },
  { value: 'BV', label: 'Bouvet Island', group: 'country' },
  { value: 'BR', label: 'Brazil', group: 'country' },
  { value: 'IO', label: 'British Indian Ocean Territory', group: 'country' },
  { value: 'BN', label: 'Brunei Darussalam', group: 'country' },
  { value: 'BG', label: 'Bulgaria', group: 'country' },
  { value: 'BF', label: 'Burkina Faso', group: 'country' },
  { value: 'BI', label: 'Burundi', group: 'country' },
  { value: 'KH', label: 'Cambodia', group: 'country' },
  { value: 'CM', label: 'Cameroon', group: 'country' },
  { value: 'CA', label: 'Canada', group: 'country' },
  { value: 'CV', label: 'Cape Verde', group: 'country' },
  { value: 'KY', label: 'Cayman Islands', group: 'country' },
  { value: 'CF', label: 'Central African Republic', group: 'country' },
  { value: 'TD', label: 'Chad', group: 'country' },
  { value: 'CL', label: 'Chile', group: 'country' },
  { value: 'CN', label: 'China', group: 'country' },
  { value: 'CX', label: 'Christmas Island', group: 'country' },
  { value: 'CC', label: 'Cocos (Keeling) Islands', group: 'country' },
  { value: 'CO', label: 'Colombia', group: 'country' },
  { value: 'KM', label: 'Comoros', group: 'country' },
  { value: 'CG', label: 'Congo', group: 'country' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the', group: 'country' },
  { value: 'CK', label: 'Cook Islands', group: 'country' },
  { value: 'CR', label: 'Costa Rica', group: 'country' },
  { value: 'CI', label: "Côte d'Ivoire", group: 'country' },
  { value: 'HR', label: 'Croatia', group: 'country' },
  { value: 'CU', label: 'Cuba', group: 'country' },
  { value: 'CW', label: 'Curaçao', group: 'country' },
  { value: 'CY', label: 'Cyprus', group: 'country' },
  { value: 'CZ', label: 'Czech Republic', group: 'country' },
  { value: 'DK', label: 'Denmark', group: 'country' },
  { value: 'DJ', label: 'Djibouti', group: 'country' },
  { value: 'DM', label: 'Dominica', group: 'country' },
  { value: 'DO', label: 'Dominican Republic', group: 'country' },
  { value: 'EC', label: 'Ecuador', group: 'country' },
  { value: 'EG', label: 'Egypt', group: 'country' },
  { value: 'SV', label: 'El Salvador', group: 'country' },
  { value: 'GQ', label: 'Equatorial Guinea', group: 'country' },
  { value: 'ER', label: 'Eritrea', group: 'country' },
  { value: 'EE', label: 'Estonia', group: 'country' },
  { value: 'ET', label: 'Ethiopia', group: 'country' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)', group: 'country' },
  { value: 'FO', label: 'Faroe Islands', group: 'country' },
  { value: 'FJ', label: 'Fiji', group: 'country' },
  { value: 'FI', label: 'Finland', group: 'country' },
  { value: 'FR', label: 'France', group: 'country' },
  { value: 'GF', label: 'French Guiana', group: 'country' },
  { value: 'PF', label: 'French Polynesia', group: 'country' },
  { value: 'TF', label: 'French Southern Territories', group: 'country' },
  { value: 'GA', label: 'Gabon', group: 'country' },
  { value: 'GM', label: 'Gambia', group: 'country' },
  { value: 'GE', label: 'Georgia', group: 'country' },
  { value: 'DE', label: 'Germany', group: 'country' },
  { value: 'GH', label: 'Ghana', group: 'country' },
  { value: 'GI', label: 'Gibraltar', group: 'country' },
  { value: 'GR', label: 'Greece', group: 'country' },
  { value: 'GL', label: 'Greenland', group: 'country' },
  { value: 'GD', label: 'Grenada', group: 'country' },
  { value: 'GP', label: 'Guadeloupe', group: 'country' },
  { value: 'GU', label: 'Guam', group: 'country' },
  { value: 'GT', label: 'Guatemala', group: 'country' },
  { value: 'GG', label: 'Guernsey', group: 'country' },
  { value: 'GN', label: 'Guinea', group: 'country' },
  { value: 'GW', label: 'Guinea-Bissau', group: 'country' },
  { value: 'GY', label: 'Guyana', group: 'country' },
  { value: 'HT', label: 'Haiti', group: 'country' },
  { value: 'HM', label: 'Heard Island and McDonald Islands', group: 'country' },
  { value: 'VA', label: 'Holy See (Vatican City State)', group: 'country' },
  { value: 'HN', label: 'Honduras', group: 'country' },
  { value: 'HK', label: 'Hong Kong', group: 'country' },
  { value: 'HU', label: 'Hungary', group: 'country' },
  { value: 'IS', label: 'Iceland', group: 'country' },
  { value: 'IN', label: 'India', group: 'country' },
  { value: 'ID', label: 'Indonesia', group: 'country' },
  { value: 'IR', label: 'Iran, Islamic Republic of', group: 'country' },
  { value: 'IQ', label: 'Iraq', group: 'country' },
  { value: 'IE', label: 'Ireland', group: 'country' },
  { value: 'IM', label: 'Isle of Man', group: 'country' },
  { value: 'IL', label: 'Israel', group: 'country' },
  { value: 'IT', label: 'Italy', group: 'country' },
  { value: 'JM', label: 'Jamaica', group: 'country' },
  { value: 'JP', label: 'Japan', group: 'country' },
  { value: 'JE', label: 'Jersey', group: 'country' },
  { value: 'JO', label: 'Jordan', group: 'country' },
  { value: 'KZ', label: 'Kazakhstan', group: 'country' },
  { value: 'KE', label: 'Kenya', group: 'country' },
  { value: 'KI', label: 'Kiribati', group: 'country' },
  { value: 'KP', label: "Korea, Democratic People's Republic of", group: 'country' },
  { value: 'KR', label: 'Korea, Republic of', group: 'country' },
  { value: 'KW', label: 'Kuwait', group: 'country' },
  { value: 'KG', label: 'Kyrgyzstan', group: 'country' },
  { value: 'LA', label: "Lao People's Democratic Republic", group: 'country' },
  { value: 'LV', label: 'Latvia', group: 'country' },
  { value: 'LB', label: 'Lebanon', group: 'country' },
  { value: 'LS', label: 'Lesotho', group: 'country' },
  { value: 'LR', label: 'Liberia', group: 'country' },
  { value: 'LY', label: 'Libya', group: 'country' },
  { value: 'LI', label: 'Liechtenstein', group: 'country' },
  { value: 'LT', label: 'Lithuania', group: 'country' },
  { value: 'LU', label: 'Luxembourg', group: 'country' },
  { value: 'MO', label: 'Macao', group: 'country' },
  { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', group: 'country' },
  { value: 'MG', label: 'Madagascar', group: 'country' },
  { value: 'MW', label: 'Malawi', group: 'country' },
  { value: 'MY', label: 'Malaysia', group: 'country' },
  { value: 'MV', label: 'Maldives', group: 'country' },
  { value: 'ML', label: 'Mali', group: 'country' },
  { value: 'MT', label: 'Malta', group: 'country' },
  { value: 'MH', label: 'Marshall Islands', group: 'country' },
  { value: 'MQ', label: 'Martinique', group: 'country' },
  { value: 'MR', label: 'Mauritania', group: 'country' },
  { value: 'MU', label: 'Mauritius', group: 'country' },
  { value: 'YT', label: 'Mayotte', group: 'country' },
  { value: 'MX', label: 'Mexico', group: 'country' },
  { value: 'FM', label: 'Micronesia, Federated States of', group: 'country' },
  { value: 'MD', label: 'Moldova, Republic of', group: 'country' },
  { value: 'MC', label: 'Monaco', group: 'country' },
  { value: 'MN', label: 'Mongolia', group: 'country' },
  { value: 'ME', label: 'Montenegro', group: 'country' },
  { value: 'MS', label: 'Montserrat', group: 'country' },
  { value: 'MA', label: 'Morocco', group: 'country' },
  { value: 'MZ', label: 'Mozambique', group: 'country' },
  { value: 'MM', label: 'Myanmar', group: 'country' },
  { value: 'NA', label: 'Namibia', group: 'country' },
  { value: 'NR', label: 'Nauru', group: 'country' },
  { value: 'NP', label: 'Nepal', group: 'country' },
  { value: 'NL', label: 'Netherlands', group: 'country' },
  { value: 'NC', label: 'New Caledonia', group: 'country' },
  { value: 'NZ', label: 'New Zealand', group: 'country' },
  { value: 'NI', label: 'Nicaragua', group: 'country' },
  { value: 'NE', label: 'Niger', group: 'country' },
  { value: 'NG', label: 'Nigeria', group: 'country' },
  { value: 'NU', label: 'Niue', group: 'country' },
  { value: 'NF', label: 'Norfolk Island', group: 'country' },
  { value: 'MP', label: 'Northern Mariana Islands', group: 'country' },
  { value: 'NO', label: 'Norway', group: 'country' },
  { value: 'OM', label: 'Oman', group: 'country' },
  { value: 'PK', label: 'Pakistan', group: 'country' },
  { value: 'PW', label: 'Palau', group: 'country' },
  { value: 'PS', label: 'Palestine, State of', group: 'country' },
  { value: 'PA', label: 'Panama', group: 'country' },
  { value: 'PG', label: 'Papua New Guinea', group: 'country' },
  { value: 'PY', label: 'Paraguay', group: 'country' },
  { value: 'PE', label: 'Peru', group: 'country' },
  { value: 'PH', label: 'Philippines', group: 'country' },
  { value: 'PN', label: 'Pitcairn', group: 'country' },
  { value: 'PL', label: 'Poland', group: 'country' },
  { value: 'PT', label: 'Portugal', group: 'country' },
  { value: 'PR', label: 'Puerto Rico', group: 'country' },
  { value: 'QA', label: 'Qatar', group: 'country' },
  { value: 'RE', label: 'Réunion', group: 'country' },
  { value: 'RO', label: 'Romania', group: 'country' },
  { value: 'RU', label: 'Russian Federation', group: 'country' },
  { value: 'RW', label: 'Rwanda', group: 'country' },
  { value: 'BL', label: 'Saint Barthélemy', group: 'country' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha', group: 'country' },
  { value: 'KN', label: 'Saint Kitts and Nevis', group: 'country' },
  { value: 'LC', label: 'Saint Lucia', group: 'country' },
  { value: 'MF', label: 'Saint Martin (French part)', group: 'country' },
  { value: 'PM', label: 'Saint Pierre and Miquelon', group: 'country' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines', group: 'country' },
  { value: 'WS', label: 'Samoa', group: 'country' },
  { value: 'SM', label: 'San Marino', group: 'country' },
  { value: 'ST', label: 'Sao Tome and Principe', group: 'country' },
  { value: 'SA', label: 'Saudi Arabia', group: 'country' },
  { value: 'SN', label: 'Senegal', group: 'country' },
  { value: 'RS', label: 'Serbia', group: 'country' },
  { value: 'SC', label: 'Seychelles', group: 'country' },
  { value: 'SL', label: 'Sierra Leone', group: 'country' },
  { value: 'SG', label: 'Singapore', group: 'country' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)', group: 'country' },
  { value: 'SK', label: 'Slovakia', group: 'country' },
  { value: 'SI', label: 'Slovenia', group: 'country' },
  { value: 'SB', label: 'Solomon Islands', group: 'country' },
  { value: 'SO', label: 'Somalia', group: 'country' },
  { value: 'ZA', label: 'South Africa', group: 'country' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands', group: 'country' },
  { value: 'SS', label: 'South Sudan', group: 'country' },
  { value: 'ES', label: 'Spain', group: 'country' },
  { value: 'LK', label: 'Sri Lanka', group: 'country' },
  { value: 'SD', label: 'Sudan', group: 'country' },
  { value: 'SR', label: 'Suriname', group: 'country' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen', group: 'country' },
  { value: 'SZ', label: 'Swaziland', group: 'country' },
  { value: 'SE', label: 'Sweden', group: 'country' },
  { value: 'CH', label: 'Switzerland', group: 'country' },
  { value: 'SY', label: 'Syrian Arab Republic', group: 'country' },
  { value: 'TW', label: 'Taiwan, Province of China', group: 'country' },
  { value: 'TJ', label: 'Tajikistan', group: 'country' },
  { value: 'TZ', label: 'Tanzania, United Republic of', group: 'country' },
  { value: 'TH', label: 'Thailand', group: 'country' },
  { value: 'TL', label: 'Timor-Leste', group: 'country' },
  { value: 'TG', label: 'Togo', group: 'country' },
  { value: 'TK', label: 'Tokelau', group: 'country' },
  { value: 'TO', label: 'Tonga', group: 'country' },
  { value: 'TT', label: 'Trinidad and Tobago', group: 'country' },
  { value: 'TN', label: 'Tunisia', group: 'country' },
  { value: 'TR', label: 'Turkey', group: 'country' },
  { value: 'TM', label: 'Turkmenistan', group: 'country' },
  { value: 'TC', label: 'Turks and Caicos Islands', group: 'country' },
  { value: 'TV', label: 'Tuvalu', group: 'country' },
  { value: 'UG', label: 'Uganda', group: 'country' },
  { value: 'UA', label: 'Ukraine', group: 'country' },
  { value: 'AE', label: 'United Arab Emirates', group: 'country' },
  { value: 'GB', label: 'United Kingdom', group: 'country' },
  { value: 'US', label: 'United States', group: 'country' },
  { value: 'UM', label: 'United States Minor Outlying Islands', group: 'country' },
  { value: 'UY', label: 'Uruguay', group: 'country' },
  { value: 'UZ', label: 'Uzbekistan', group: 'country' },
  { value: 'VU', label: 'Vanuatu', group: 'country' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of', group: 'country' },
  { value: 'VN', label: 'Viet Nam', group: 'country' },
  { value: 'VG', label: 'Virgin Islands, British', group: 'country' },
  { value: 'VI', label: 'Virgin Islands, U.S.', group: 'country' },
  { value: 'WF', label: 'Wallis and Futuna', group: 'country' },
  { value: 'EH', label: 'Western Sahara', group: 'country' },
  { value: 'YE', label: 'Yemen', group: 'country' },
  { value: 'ZM', label: 'Zambia', group: 'country' },
  { value: 'ZW', label: 'Zimbabwe', group: 'country' }
];

function showCountries(available) {
    if (available === false) {
      return 'none'
    } else if (available === true) {
      return 'block'
    } else {
      return 'none'
    }
  }

export { europeList, africaList, antarcticaList, asiaList, northAmericaList, southAmericaList, oceaniaList, countryList, areaList, showCountries };