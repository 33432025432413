import { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import JSZip from 'jszip';
import { TargetBin } from './TargetBin';
import { DraggableFile } from './DraggableFile';
import { ItemTypes } from './ItemTypes';
import update from 'immutability-helper'
import { TargetBins } from './TargetBins';
import { X as XIcon } from '../../icons/x';

function getFileExtension(filename){
  // get file extension
  try {
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    return extension;
  } catch (error) {
    console.error(error);
    return ""
    // Expected output: ReferenceError: nonExistentFunction is not defined
    // (Note: the exact output may be browser-dependent)
  }
  
}

const revitExtensions = ["rvt", "rfa", "rte"];
const imageExtensions = ["png", "webp", "jpg", "jpeg", "tiff"];
const documentationExtensions = ["pdf", "docx", "doc"];

const ZipUploader = () => {

  const [ products, setProducts ] = useState([ 
    {
      name: "product_1",
      images: [],
      revit: [],
      documentation: [],
      other: []
    },
    {
      name: "product_2",
      images: [],
      revit: [],
      documentation: [],
      other: []
    },
    {
      name: "product_3",
      images: [],
      revit: [],
      documentation: [],
      other: []
    }
  ])
  const [ extractedZip, setExtractedZip ] = useState([]);
  const [uploaderVisibility, setUploaderVisibility] = useState(false);
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1
  }

  const handleUpdateProduct = useCallback(
    (item, index, targetBin) => {
      //const { name } = item
      const type = targetBin?.type;
      if (type === "revit") {
        setProducts(
          update(products, {
            [index]: {
              revit: {$push: [item]}
            },
          }),
        )
      } else if (type === "images") {
        setProducts(
          update(products, {
            [index]: {
              images: {$push: [item]}
            },
          }),
        )
      } else if (type === "documentation") {
        setProducts(
          update(products, {
            [index]: {
              documentation: {$push: [item]}
            },
          }),
        )
      } else {
        setProducts(
          update(products, {
            [index]: {
              other: {$push: [item]}
            },
          }),
        )
      }
    },
    [products],
  )

  const extractZip = async (file) => {
    const zip = new JSZip();
    const extractedFiles = await zip.loadAsync(file);
    
    extractedFiles.forEach(async (relativePath, file) => {
      //const content = await file.async("string");
      //save the file in the desired location
      //const newArray = extractedZip.push(relativePath);

      const extension = getFileExtension(file?.name)
      let type = "other";
      if (revitExtensions.indexOf(extension) >= 0) {
        type = "revit"
      } else if (imageExtensions.indexOf(extension) >= 0) {
        type = "images"
      } else if (documentationExtensions.indexOf(extension) >= 0) {
        type = "documentation"
      }

      const object = {
        name: file.name,
        path: file.path,
        type: type,
        file: file
      }
      console.log(object);
      //const newArray = extractedZip.push(relativePath)
      setExtractedZip(oldArray => [...oldArray, object]);
    });
  }

  const changeZipUploader = () => {
    setUploaderVisibility(!uploaderVisibility)
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          //backgroundColor: 'background.default',
          flexGrow: 1,
          py: 3,
          minHeight: "calc(100vh - 48px)"
        }}
      >
        <Container maxWidth="xl">
          <Card sx={{mt: 1}}>
            <Box>
              <div>
                <Box sx={{mx: 2, p: 2}}>
                  <Grid container spacing={3}>
                    <Grid item xs={uploaderVisibility ? 8 : 11}>
                      <TableContainer sx={{borderRadius: "5px", border: "1px solid #175c831A"}}>
                        <Table>
                          <TableHead>
                            <TableCell width="15%">
                              Name
                            </TableCell>
                            <TableCell colSpan={4} align="center">
                              Files
                            </TableCell>
                          </TableHead>
                          <TableBody> 
                          {products.map((product, index) => (
                            <>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="subtitle2">{product?.name}</Typography>
                                </TableCell>
                                <TableCell colSpan={4}>
                                  <TargetBins 
                                    key={"bin-" + index} 
                                    droppedBoxNames={droppedBoxNames} 
                                    setDroppedBoxNames={setDroppedBoxNames}
                                    images={product?.images}
                                    revitFiles={product?.revit}
                                    other={product?.other}
                                    documentation={product?.documentation}
                                    setItems={handleUpdateProduct}
                                    productIndex={index}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                          </TableBody> 
                        </Table>
                      </TableContainer>
                    </Grid>
                    {uploaderVisibility ? 
                      <Grid item xs={4}>
                        <TableContainer sx={{borderRadius: "5px", border: "1px solid #175c831A"}}>
                          <Table>
                            <TableHead>
                              <TableCell>
                                  UPLOAD ZIP
                              </TableCell>
                              <TableCell align="right">
                                <Button size="small" variant="text" onClick={changeZipUploader}>
                                  {/* <XIcon /> */}
                                  <Typography variant="caption">Close</Typography>
                                </Button>
                              </TableCell>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <input type="file" onChange={(e) => extractZip(e.target.files[0])} />
                                </TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell colSpan={2}>
                                  <Box sx={{ overflow: 'hidden', clear: 'both' }}>
                                    {extractedZip.map(({ name, type }, index) => (
                                      <DraggableFile
                                        name={name}
                                        type={type}
                                        isDropped={isDropped(name)}
                                        key={index}
                                      />
                                    ))}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid> : 
                      <Grid item xs={1}>
                        <Button size="small" variant="contained" onClick={changeZipUploader}>
                          <Typography variant="caption">Extract Zip</Typography>
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </div>
              <Button onClick={() => console.log(products)}>Check product list</Button>
            </Box> 
          </Card>
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default ZipUploader;