import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Pencil as PencilIcon } from '../../icons/pencil';
import { Scrollbar } from '../../scrollbar';
import { Pdf as PdfIcon } from '../../icons/pdf';

const reports = [
  // {
  // },
  // {
  // },
  // {
  // }
];

export const ReportsTable = (props) => {
  const {
    // customers,
    // customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    refresh,
    ...other
  } = props;
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [reportOpen, setReportOpen] = useState(false);
  const navigate = useNavigate();

  // Reset selected customers when customers change
  useEffect(() => {
      if (selectedReports.length) {
        setSelectedReports([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reports]);

  const handleSelectAllReports = (event) => {
    setSelectedReport(event.target.checked
      ? reports.map((report) => report.id)
      : []);
  };

  const handleOpenReport = (report) => {
    setSelectedReport(report);
    setReportOpen(true);
  };

  const handleCloseReport = () => {
    setReportOpen(false);
  };


  const handleDownloadReport = async (report) => {
    const payload = {
      id: report.externalID,
      filename: "Bimroom - " + report.documentType + ".pdf"
    };

    //const response = await getSignedReport(payload);
    //return response?.file_url;
    
    //window.open(response?.file_url, '_blank', 'noreferrer');

		// fetch(response?.file_url)
		// 	.then(response => {
		// 		response.blob().then(blob => {
		// 			let url = window.URL.createObjectURL(blob);
		// 			let a = document.createElement('a');
		// 			a.href = url;
		// 			a.download = 'bimroom.pdf';
		// 			a.click();
		// 		});
		// 		//window.location.href = response.url;
		// });
  };

  const getColor = (status) => {

    if (status === "Completed") {
      return "success.main";
    } else if (["Sign document","Created"].includes(status)) {
      return "#000";
    } else if (["Cancelled", "Deleted"].includes(status)) {
      return "error.main";
    } else {
      return "#000";
    }
  }

  const enableBulkActions = selectedReports.length > 0;
  const selectedSomeReports = selectedReports.length > 0
    && selectedReports.length < reports.length;
  const selectedAllReports = selectedReports.length === reports.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllReports}
          indeterminate={selectedSomeReports}
          onChange={handleSelectAllReports}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell width="30%">
                Company
              </TableCell>
              <TableCell width="30%">
                Report name
              </TableCell>
              <TableCell width="20%" >
                Report timespan
              </TableCell>
              <TableCell width="20%" align="right">
                Download
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.length === 0 && <TableRow
              hover
              key="not-available"
              //sx={{cursor: "pointer"}}
            >
              <TableCell colSpan={4} sx={{height: "64px"}} align="center">No reports available</TableCell>
            </TableRow>}
            {reports.map((report) => {
              const isReportSelected = selectedReports.includes(report.id);

              return (
                <TableRow
                  hover
                  key={report.id}
                  selected={isReportSelected}
                  //sx={{cursor: "pointer"}}
                >
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                    >
                      {report?.documentType}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      
                      variant="body2"
                    >
                      {report?.name}
                    </Typography>
                    <Typography
                      
                      variant="body2"
                    >
                      {report?.email}
                    </Typography>
                  </TableCell>
                  <TableCell >
                    <Typography
                      color={getColor(report?.status)}
                      variant="body2"
                    >
                      {report?.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                  {report?.status === "Sign document" && 
                    <IconButton
                      onClick={() => handleOpenReport(report)}
                      //sx={{mb: 1, ml: 1}}
                      color="success"
                      size="medium"
                    >
                      <PencilIcon />
                      <Typography
                        variant="subtitle2"
                        sx={{ml: 1}}
                      >
                        
                        Sign
                      </Typography>
                    </IconButton>
                  }
                  {report?.status === "Completed" && 
                      <IconButton
                        //onClick={() => handleOpenContract(contract)}
                        //sx={{mb: 1, ml: 1}}
                        onClick={() => handleDownloadReport(report)}
                        color="success"
                        //a href={() => handleDownloadContract(contract)}
                      >
                        <PdfIcon />
                        {/* <Typography
                          variant="subtitle2"
                          sx={{ml: 1}}
                        >
                          PDF
                        </Typography> */}
                      </IconButton> 
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* <ContractModal open={contractOpen} close={handleCloseContract} contractdata={selectedContract} refresh={refresh}/> */}
      </Scrollbar>
      <TablePagination
        component="div"
        count={reports.length}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

// CustomerListTable.propTypes = {
//   customers: PropTypes.array.isRequired,
//   customersCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
