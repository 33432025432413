import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { Duplicate as DuplicateIcon } from '../icons/duplicate';
import { X as XIcon } from '../icons/x';
import { bytesToSize } from '../utils/bytes-to-size';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useEffect, useRef } from 'react';

export const CropperDropzone = (props) => {
  const cropperRef = useRef(null);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    console.log(cropper.getCroppedCanvas());
    // setOutput(cropper.getCroppedCanvas().toDataURL());
  };

  const {
    accept,
    disabled,
    files = [],
    setOutput,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    // onRemove,
    // onRemoveAll,
    //onUpload,
    preventDropOnDocument,
    ...other
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop
  });

  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          borderColor: 'divider',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 2,
          ...(isDragActive && {
            backgroundColor: 'action.active',
            opacity: 0.5
          }),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
            opacity: 0.5
          }
        }}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <Box sx={{ p: 1 }}>
          <Box sx={{ mt: 0 }}>
            <Typography variant="body1">
              {`Drag & drop file${(maxFiles && maxFiles === 1) ? '' : 's'}`}
              {' or '}
              <Link underline="always">
                browse
              </Link>
              {' '}
              through your computer
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* {files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {files[0] ? 
            <Cropper
              src={URL.createObjectURL(files[0])}
              style={{ height: 300, width: 535 }}
              // Cropper.js options
              aspectRatio={16 / 9}
              guides={true}
              crop={onCrop}
              ref={cropperRef}
            /> : 
            <></>
          }
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2
            }}
          >
          </Box>
        </Box>
      )} */}
    </div>
  );
};

// FileDropzone.propTypes = {
//   accept: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.arrayOf(PropTypes.string.isRequired)
//   ]),
//   disabled: PropTypes.bool,
//   files: PropTypes.array,
//   getFilesFromEvent: PropTypes.func,
//   maxFiles: PropTypes.number,
//   maxSize: PropTypes.number,
//   minSize: PropTypes.number,
//   noClick: PropTypes.bool,
//   noDrag: PropTypes.bool,
//   noDragEventsBubbling: PropTypes.bool,
//   noKeyboard: PropTypes.bool,
//   onDrop: PropTypes.func,
//   onDropAccepted: PropTypes.func,
//   onDropRejected: PropTypes.func,
//   onFileDialogCancel: PropTypes.func,
//   onRemove: PropTypes.func,
//   onRemoveAll: PropTypes.func,
//   onUpload: PropTypes.func,
//   preventDropOnDocument: PropTypes.bool
// };
