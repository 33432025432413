import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBrand } from '../redux/reducers/user-reducer';
import { Plus as PlusIcon } from '../icons/plus';
import { Check as CheckIcon } from '../icons/check';



// const organizations = [
//   {
//     id: "123",
//     name: "Test 123"
//   },
//   {
//     id: "124",
//     name: "Test 124"
//   }
// ];

export const LanguagePopover = (props) => {
  const { anchorEl, onClose, open, openModal, languages, ...other } = props;
  const selectedOrganization = useSelector((store) => store.user.selectedBrand);
  const dispatch = useDispatch();

  const handleChange = (organization) => {
    // dispatch(setSelectedBrand(organization));
    // localStorage.setItem('selectedBrand', JSON.stringify(organization));
    onClose?.();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'}}
      keepMounted
      onClose={onClose}
      open={!!open}
      //PaperProps={{ sx: { width: 248 } }}
      transitionDuration={0}
      {...other}>
      {languages?.map((language, index) => (
        <MenuItem
          key={index}
          onClick={() => handleChange(language)}
        >
          {language}
          {/* <ListItemText primary={organization.brandName} /> */}
        </MenuItem>
      ))}
    </Popover>
  );
};
