import { Box, Button, Grid, Typography } from "@mui/material";
import { ImageCropper } from "../image-cropper";
import { useState } from "react";

const BrandLogo = (props) => {
    const { formik } = props;
    const [ editingImage, setEditingImage ] = useState(false);

    const handleCloseEditing = () => {
      setEditingImage(false);
    }
  
    const handleOpenEditing = () => {
      setEditingImage(true);
    }

    // const handleUpload = () => {
    //   images.map((file) => {
    //     if (file) {
    //       try {
    //         uploadFileToBlob(file, "logo").then(res => {
    //           if (res.status === 200) {
    //             formik.setFieldValue("logoUrl", cdnUrl(res?.data))
    //             setEditingImage(false);
    //             setImages([]);
    //           }
    //         });
    //       } catch (error) {
    //         console.error(error);
    //         // Expected output: ReferenceError: nonExistentFunction is not defined
    //         // (Note: the exact output may be browser-dependent)
    //       }
    //     }
    //   })
    // };

    return (
        <>
            {editingImage === false ? 
              <Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  {formik.values?.logoUrl !== "" ? 
                    <Grid item sx={{ mt: 1 }}>
                      <Box
                        component="img"
                        sx={{
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                        }}
                        alt="Brand logo"
                        src={`${formik.values?.logoUrl}?${performance.now()}`}
                      />
                    </Grid> : 
                    <Box>
                      No image
                    </Box>
                  }
                </Box>
                <Button onClick={handleOpenEditing} sx={{mt: 1}} variant="contained">
                  Change
                </Button>
              </Box> : 
              <Box>
                <Box
                  sx={{my: 2}}
                >
                  <Box>
                    {formik.values.logoUrl !== "" ? 
                      <Grid item>
                        <Box
                          component="img"
                          sx={{
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="Brand logo"
                          src={`${formik.values.logoUrl}?${performance.now()}`}
                        /> 
                      </Grid> : 
                      <Box>No image</Box>
                    }
                  </Box>
                  <Box>
                    <Grid item sx={{ mt: 2 }}>
                      <Typography variant="subtitle2">New Image</Typography>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                      <ImageCropper setEditingImage={setEditingImage} formik={formik} handleCloseEditing={handleCloseEditing} />
                    </Grid>
                  </Box>
                </Box>
              </Box>
            }
        </>
    )
}

export default BrandLogo;
