import { format, subDays } from 'date-fns';
//import numeral from 'numeral';
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  CardActions,
  Button,
  Divider,
  TextField,
  MenuItem
} from '@mui/material';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { ExternalLink as ExternalLinkIcon } from '../../icons/external-link';

const pages = [
  // {
  //   bounceRate: 5,
  //   uniqueVisits: 648,
  //   url: '/authentication/login',
  //   visitors: 7500
  // },
  // {
  //   bounceRate: 2,
  //   uniqueVisits: 568,
  //   url: '/dashboard',
  //   visitors: 85406
  // },
  // {
  //   bounceRate: 12,
  //   uniqueVisits: 12322,
  //   url: '/blog/top-5-react-frameworks',
  //   visitors: 75050
  // },
  // {
  //   bounceRate: 10,
  //   uniqueVisits: 11645,
  //   url: '/blog/understand-programming-principles',
  //   visitors: 68003
  // },
  // {
  //   bounceRate: 8,
  //   uniqueVisits: 10259,
  //   url: '/blog/design-patterns',
  //   visitors: 49510
  // }
];

export const OverviewMostDownloaded = (props) => (
  <Card {...props}>
    <CardHeader 
      title="Most downloaded products" 
      action={
        <TextField
          defaultValue="week"
          label="Period"
          select
          size="small"
          sx={{ m: 1 }}
        >
          <MenuItem value="week">
            Last week
          </MenuItem>
          <MenuItem value="month">
            Last month
          </MenuItem>
          <MenuItem value="year">
            Last year
          </MenuItem>
          <MenuItem value="all-time">
            All time
          </MenuItem>
        </TextField>
      } 
    />
    {pages.length === 0 ?
      <Box sx={{ 
        height: "100px",
        alignItems: 'center',
        border: 1,
        //borderRadius: 1,
        borderStyle: 'solid',
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'center'}}
      >
        <Typography variant="body2">Available after launch</Typography>
      </Box> :
      <Scrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Product name
              </TableCell>
              <TableCell  align="right">
                Downloads
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.map((page) => (
              <TableRow
                key={page.url}
                sx={{
                  '&:last-child td': {
                    border: 0
                  }
                }}
              >
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <ExternalLinkIcon
                      sx={{
                        color: 'action.active',
                        cursor: 'pointer'
                      }}
                    />
                    <Typography
                      sx={{ ml: 2 }}
                      variant="body2"
                    >
                      {page.url}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {page.visitors}
                </TableCell>
              </TableRow>
            ))}
          </TableBody> 
        </Table>
      </Scrollbar>
    }
    <Divider />
    <CardActions> 
      <Button
        endIcon={<ArrowRightIcon fontSize="small" />}
        size="small"
        variant="outlined"
      >
        Go to Product catalog
      </Button>
    </CardActions>
  </Card>
);
