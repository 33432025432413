import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { wait } from '../../utils/wait';

const invoices = [
  // {
  //   date: '2023-01-01',
  //   invoiceId: 'Invoice 123',
  //   status: 'paid'
  // }
];

const Billing = (props) => {
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const [ manufacturerData, setManufacturerData ] = useState({});
  const selectedBrand = useSelector((store) => store.user.selectedBrand);

  const formik = useFormik({
    initialValues: {
      id: manufacturerData?.id || '',
      address1: manufacturerData?.address1 || '',
      address2: manufacturerData?.address2 || '',
      city: manufacturerData?.city || '',
      zip: manufacturerData?.zip || '',
      state: manufacturerData?.state || '',
      country: manufacturerData?.country || '',
      submit: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      address1: Yup.string().max(255),
      address2: Yup.string().max(255),
      city: Yup.string().max(255),
      zip: Yup.string().max(255),
      state: Yup.string().max(255),
      country: Yup.string().max(255),
      //contactDate: Yup.string().max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        const updatedBillingData = {
          id: formik.values.id,
          address1: formik.values.address1,
          address2: formik.values.address2,
          city: formik.values.city,
          zip: formik.values.zip,
          state: formik.values.state,
          country: formik.values.country
        }

        try {
        //  const updatedInfo = await updateManufacturerInfo(updateBillingData);

        } catch (err) {
          console.error(err);
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Contract sent!');
        await wait(500);
        helpers.resetForm();
        //refresh();

        //close();
        

      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  
  return (
    <form
    onSubmit={formik.handleSubmit}
    // {...other}
    >

     <Box
        sx={{
          //backgroundColor: 'background.default',
          flexGrow: 1,
          p: 3,
          minHeight: "calc(100vh - 48px)"
        }}
        {...props}>
        <Card sx={{mt: 1}}>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
                
              >
                <Typography variant="h6">
                  Details
                </Typography>
                <Typography sx={{mt: 1}} variant="body2">
                  
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
                //align="right"
              >

                {/* <Divider /> */}
                <Tooltip title="Company name">
                  <Box
                    sx={{
                      display: 'flex',
                      //mt: 2,
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      id="companyName"
                      label="Company name"
                      size="small"
                      sx={{ flexGrow: 1 }}
                      name="companyName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Address line 1">
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 2,
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      id="address1"
                      label="Address line 1"
                      
                      size="small"
                      sx={{ flexGrow: 1 }}
                      name="address1"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.address1}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Address line 2">
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      id="address2"
                      label="Address line 2"
                      size="small"
                      sx={{ flexGrow: 1 }}
                      name="address2"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.address2}
                    />
                  </Box>
                </Tooltip>
                <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Grid item sx={{width: "75%"}}>
                    <Tooltip title="City">
                      <Box
                        sx={{
                          display: 'flex',
                          mt: 1,
                          alignItems: 'center'
                        }}
                      >
                        <TextField
                          id="city"
                          label="City"
                          size="small"
                          sx={{ flexGrow: 1 }}
                          name="city"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.city}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                  <Grid item sx={{width: "23%"}}>
                    <Tooltip title="Zip code">
                      <Box
                        sx={{
                          display: 'flex',
                          mt: 1,
                          alignItems: 'center'
                        }}
                      >
                        <TextField
                          id="zip"
                          label="Zip code"
                          size="small"
                          sx={{ flexGrow: 1 }}
                          name="zip"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.zip}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Tooltip title="State">
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      id="state"
                      label="State"
                      size="small"
                      sx={{ flexGrow: 1 }}
                      name="state"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.state}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title="Country">
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      id="country"
                      label="Country"
                      size="small"
                      sx={{ flexGrow: 1 }}
                      name="country"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.country}
                    />
                  </Box>
                </Tooltip>
  
                <Button variant="contained" sx={{mt: 2}}>
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{mt: 2}}>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Typography variant="h6">
                  Invoices
                </Typography>
                <Typography sx={{mt: 1}} variant="body2">
                  
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
              >
                <Table>
                  <TableHead>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Date
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Invoice number
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{width: '25%'}}>
                      Download
                    </TableCell>
                  </TableHead>
                    {invoices.length === 0 && 
                      <TableRow>
                        <TableCell align="center" sx={{height: "64px"}} colSpan={4}>
                          No invoices available
                        </TableCell>
                      </TableRow>
                    }
                    {invoices.map((invoice, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {invoice.date}
                        </TableCell>
                        <TableCell align="center">
                          {invoice.invoiceId}
                        </TableCell>
                        <TableCell align="center">
                          {invoice.status}
                        </TableCell>
                        <TableCell align="center">
                          {/* {invoice.date} */}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </form>
  );
};

export default Billing;